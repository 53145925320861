import { Component, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-modal-escala-ramsey',
  templateUrl: './modal-escala-ramsey.component.html',
  styleUrls: ['./modal-escala-ramsey.component.css']
})
export class ModalEscalaRasey implements OnChanges {

  @Output() resultEmiter: EventEmitter<any> = new EventEmitter();

  escala: any = {
    escala_ramsey: 0
  };
  constructor() { }

  ngOnChanges() {

  }

  // close modal 
  fecharModal = () => {
    $("#modalEscalaRamsey").toggleClass("show");
    $("#modalEscalaRamsey").css("display", "none");
  }

  checkStatus() {
    this.resultEmiter.emit(this.escala.escala_ramsey);
  }


}
