import { Component, OnInit, OnChanges, Inject, Input, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import { Global } from '../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-modal-consulta',
  templateUrl: './modal-add-appointment.component.html',
  styleUrls: ['./modal-add-appointment.component.css']
})
export class ModalAddAppointmentComponent implements OnInit {

  especialidade: any = '';
  listaEspecialidades: any = [];

  @Input() plano = {};
  @Output() atualizaListaEspecialidades = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }

  buscarConsulta = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Ocupacao/ObterPeloNomeOuCodigo?busca=${this.especialidade}`).then(
      result => {
        
        this.listaEspecialidades = result;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  fecharModal = () => {
    this.listaEspecialidades = []
    $("#modalAddConsulta").removeClass("show");
    $("#modalAddConsulta").css("display", "none");
    this.atualizaEfecha()
  }

  salvarEspecialidade = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    item.plano_id = this.plano
    this.validaDados(item).then(
      result => {
        var obj = result
        obj['ocupacao_id'] = result['id'];
        delete result['id'];
        this.service.Post(`PlanoOcupacao`, obj).then(
          result => {
            Swal.fire('Sucesso', 'Especialidade cadastrada com sucesso', 'success').then(
              result => {
                this.buscarConsulta()
              }
            )
          },
          error => {
            Swal.fire('Erro', error.messagem, 'error')
          }
        )
      },
      error => {
        Swal.fire('Erro', error, 'error');
      }
    )
  }

  validaDados = (item) => {
    return new Promise((resolve, reject) => {
      if (isNaN(item.valor)) {
        reject('É preciso informar um valor válido para a especialidade')
        return
      }
      if (!item.valor || item.valor <= 0 || item.valor == '') {
        reject('É preciso informar um valor para a especialidade')
        return
      }
      if (item.valor){
        if (item.valor.includes(','))
          item.valor = item.valor.replace(',', '.')
        item.valor = parseFloat(item.valor)
      }
      resolve(item)
    })
  }

  salvarVarios = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var obj: any = []
    for (let item of this.listaEspecialidades){
      if (isNaN(item.valor)) {
        delete item.valor
      }
      if (item.valor){
        if (item.valor.includes(','))
          item.valor = item.valor.replace(',', '.')
        item.valor = parseFloat(item.valor)
        obj.push(item)
      }
    }

    this.enviaRecursivo(obj, 0)
  }

  enviaRecursivo = (obj, ordem) => {
    if (ordem == obj.length){
      var sucesso = 0
      for (let item of obj) {
        if (item.sucesso){
          sucesso +=1;
        }
      }
      if (sucesso == obj.length){
        Swal.fire('Sucesso', 'Todos as especialidades foram enviadas com sucesso','success').then(
          result => {
            this.buscarConsulta()
            return
          }
        )
      }
      if (sucesso < obj.length){
        Swal.fire('Atenção', 'Algumas especialidades não foram enviadas com sucesso. Verifique a lista e tente novamente','warning').then(
          result => {
            this.buscarConsulta()
            return
          }
        )
      }
      if (sucesso == 0){
        Swal.fire('Erro', 'As especialidades não foram salvas. Verifique a lista e tente novamente','error').then(
          result => {
            this.buscarConsulta()
            return
          }
        )
      }
      return
    }
    // obj[ordem].sucesso = true;
    // this.enviaRecursivo(obj, ordem+1)
    obj[ordem].plano_id = this.plano
    obj[ordem].ocupacao_id = obj[ordem].id
    this.service.Post(`PlanoOcupacao`, obj[ordem]).then(
      result => {
        obj[ordem].sucesso = true;
        this.enviaRecursivo(obj,ordem+1)
      },
      error => {
        this.enviaRecursivo(obj,ordem+1)
      }
    )
  }

  atualizaEfecha = () => {
    this.atualizaListaEspecialidades.emit(true)
  }

}
