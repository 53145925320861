import { Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-hospital-bed',
  templateUrl: './hospital-bed.component.html',
  styleUrls: ['./hospital-bed.component.css']
})
export class HospitalBedComponent implements OnInit {

  filtro: string = "";
  @Input() listaBloco: any = [];
  leito: any = {};
  bloco_id_filtro: any = 0;
  clinica_id_filtro: any = 0;
  posto_enfermagem_id: any = 0;
  editando: boolean = false;

  bloco_id: any = 0;
  clinica_id: any = 0;
  listaClinicas: any = [];
  listaLeitos: any = [];
  listaPostoEnfermagens: any = [];
  statusLeito: any = [];
  tiposLeito: any = [];

  constructor(public service: ApiService,
    private authService: AuthService) { }

  ngOnInit() {
    this.obterStatusLeito();
    this.obterTipoLeito();
  }


  obterStatusLeito = () => {
    this.service.Get(`Leito/StatusLeito`).then(res => {
      this.statusLeito = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  obterTipoLeito = () => {
    this.service.Get(`TipoLeito`).then(res => {
      this.tiposLeito = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  obterListaClinica = (bloco_id) => {
    if (bloco_id == 0) {
      this.clinica_id_filtro = 0;
      this.listaClinicas = [];
      this.listaPostoEnfermagens = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Bloco/${bloco_id}/ClinicaMedica`).then(res => {
      this.listaClinicas = res;
      this.listaPostoEnfermagens = [];
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  obterListapostoEnfermagem = (clinica_id) => {
    if (clinica_id == 0) {
      this.listaPostoEnfermagens = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`ClinicaMedica/${clinica_id}/postoEnfermagem`).then(res => {
      this.listaPostoEnfermagens = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  obterListaLeitos = (posto_enfermagem_id) => {
    if (posto_enfermagem_id == 0) {
      this.listaLeitos = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`PostoEnfermagem/${posto_enfermagem_id}/Leito`).then(res => {
      this.listaLeitos = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  filtroLista() {
    if (this.listaLeitos.length === 0) {
      return this.listaLeitos;
    }
    return this.listaLeitos.filter(p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  editarLeitoModal = (leito) => {
    this.editando = true;
    var leito_ = Object.assign({}, leito);
    this.leito = leito_;
    this.leito.posto_enfermagem_id = this.posto_enfermagem_id;
    this.leito.blocoId = this.bloco_id_filtro
    this.leito.clinica_medica_id = this.clinica_id_filtro;
    var tipoLeito = this.tiposLeito.filter(x => x.codigo == leito.tipo_leito_codigo);
    if (tipoLeito.length > 0) {
      this.leito.tipo_leito_id = tipoLeito[0].id;
    }

    var statusLeito = this.statusLeito.filter(x => x.nome == leito.status);
    if (statusLeito.length > 0) {
      this.leito.status = statusLeito[0].id;
    }
    //aqui
    // this.leito.blocoId = 0;
    this.abrirModalLeitos();
  }


  abrirModalLeitos = () => {
    if (this.leito.id == null) {
      this.editando = false;
      this.leito.ativo = true;
      this.leito.blocoId = 0;
      this.bloco_id_filtro = 0;
      this.leito.posto_enfermagem_id = 0;
      this.leito.status = 1;
      this.leito.tipo_leito_id = 0;
      this.clinica_id_filtro = 0;
      this.listaClinicas = [];
    }

    $("#modalAdicionarLeito").toggleClass("show");
    $("#modalAdicionarLeito").css("display", "block");
  }

  fecharModal = () => {
    this.leito = {};
    $("#modalAdicionarLeito").toggleClass("show");
    $("#modalAdicionarLeito").css("display", "none");
  }

  selecionarBloco = (bloco_id) => {
    this.bloco_id = bloco_id;
    this.obterListaClinica(bloco_id);
  }

  selecionarClinica = (clinica_id) => {
    this.clinica_id = clinica_id;
    this.obterListapostoEnfermagem(clinica_id);
  }

  validaPost = () => {
    if (this.leito.nome == null || this.leito.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome do leito.", 'error');
      return false;
    }
    if (this.leito.abreviatura == null || this.leito.abreviatura == "") {
      Swal.fire('Erro', "Favor preencher a abreviatura.", 'error');
      return false;
    }
    if (this.leito.posto_enfermagem_id == null || this.leito.posto_enfermagem_id == 0) {
      Swal.fire('Erro', "Favor preencher o posto de enfermagem.", 'error');
      return false;
    }
    if (this.leito.status == null || this.leito.status == 0) {
      Swal.fire('Erro', "Favor preencher o status.", 'error');
      return false;
    }
    if (this.leito.tipo_leito_id == null || this.leito.tipo_leito_id == 0) {
      Swal.fire('Erro', "Favor preencher o tipo de leito.", 'error');
      return false;
    }
    return true;
  }

  editarLeito() {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Put(`leito/${this.leito.id}`, this.leito).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Leito atualizado com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.bloco_id_filtro = this.leito.blocoId;
              this.clinica_id_filtro = this.leito.clinica_medica_id;
              this.posto_enfermagem_id = this.leito.posto_enfermagem_id;
              this.obterListaLeitos(this.leito.posto_enfermagem_id);
              this.fecharModal();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    });
  }


  salvarLeito = () => {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    if (this.leito.id == null) {
      this.service.Post(`Leito`, this.leito).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Leito salvo com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.bloco_id_filtro = this.leito.blocoId;
                this.clinica_id_filtro = this.leito.clinica_medica_id;
                this.posto_enfermagem_id = this.leito.posto_enfermagem_id;
                this.obterListaLeitos(this.leito.posto_enfermagem_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    }
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse Leito?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`leito/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  // this.bloco_id_filtro = this.leito.blocoId;
                  // this.clinica_id_filtro = this.leito.clinica_medica_id;
                  // this.posto_enfermagem_id = this.leito.posto_enfermagem_id;
                  this.obterListaLeitos(this.posto_enfermagem_id);
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }
}
