import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-modal-schedule-forwarding',
  templateUrl: './modal-schedule-forwarding.component.html',
  styleUrls: ['./modal-schedule-forwarding.component.css']
})
export class ModalScheduleForwardingComponent implements OnInit {
  @Input() consulta: any = {};
  @Input() cidadao: any = {};
  @Output() resultPostModalAgendamento: EventEmitter<any> = new EventEmitter();
  @Input() regulacao: any = {};
  @Input() filtro: any = {};

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  fecharModal = () => {
    this.resultPostModalAgendamento.emit();
    $("#modalAgendarEncaminhamento").toggleClass("show");
    $("#modalAgendarEncaminhamento").css("display", "none");
  }

}
