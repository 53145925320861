import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var Simditor: any;
import * as $ from "jquery";
import { Global } from 'src/app/global';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';

@Component({
  selector: 'app-referral-to-specialist-registration',
  templateUrl: './referral-to-specialist-registration.component.html',
  styleUrls: ['./referral-to-specialist-registration.component.css']
})
export class ReferralToSpecialistRegistrationComponent implements OnInit {

  prescricao: any = {};
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Output() goBack = new EventEmitter();
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();

  encaminhamento: any = {};
  listaCid: any = [];
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];
  listaOcupacao: any = [];
  especialidade_id: any;
  usuario: any = {};
  @Input() atendimento: any = {};
  @Input() listaEncaminhamentos: any = [];
  global_: any;
  constructor(
    public service: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) {

    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });

    /// configuração do editor de texto
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    Simditor.locale = 'en-US';
    var that = this;
    setTimeout(() => {
      var motivo = new Simditor({
        textarea: $('#motivo_encaminhamento'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
    }, 1000);
  }

  adicionarCid(item) {
    if (!item) return;
    if (this.listaCid.length == 0) {
      item.cid_subcategoria_id = item.id;
      item.cid_subcategoria_codigo = item.codigo;
      item.cid_subcategoria_nome = item.nome;
      item.principal = false;
      item.diagnostico_confirmado = false;
      item.doenca_cronica = false;
      item.novo = true;
      this.listaCid.push(item);
    }
    else {
      var repetido = false;
      this.listaCid.forEach((s) => {
        if (s.id == item.id)
          repetido = true;
      });
      if (!repetido) {
        item.cid_subcategoria_id = item.id;
        item.cid_subcategoria_codigo = item.codigo;
        item.cid_subcategoria_nome = item.nome;
        item.principal = false;
        item.diagnostico_confirmado = false;
        item.doenca_cronica = false;
        item.novo = true;
        this.listaCid.push(item);
      }
    }
  }

  selecionaCidPrincipal(index) {
    this.listaCid.forEach((item) => {
      item.principal = false;
    });
    this.listaCid[index].principal = true;
  }

  removerCid(i) {
    if (!this.encaminhamento.id || this.listaCid[i].novo)
      this.listaCid.splice(i, 1);
    else {
      Swal.fire({
        title: 'Favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      this.listaCid[i].ativo = false;
    }

  }

  buscarConsulta = (text: any) => {
    if (text.length > 2) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.service.Get(`Ocupacao/ObterPeloNomeOuCodigo?busca=${text}`).then(
        result => {

          this.listaOcupacao = result;
          Swal.close()
        },
        error => {
          Swal.fire('Erro', error.messagem, 'error')
        }
      )
    }
  }

  addEspecialidade(item) {
    if (!item) return;
    this.especialidade_id = item.id;
  }

  saveEncaminhamento() {
    var objSend = Object.assign({}, this.encaminhamento);

    if (this.listaCid.length == 0) {
      Swal.fire('Erro', 'Favor inserir ao menos um CID', 'error');
      return;
    }

    if (this.especialidade_id == null) {
      Swal.fire('Erro', 'Favor inserir uma especialidade', 'error');
      return;
    }

    if (this.encaminhamento.prioridade_encaminhamento == null || this.encaminhamento.prioridade_encaminhamento == 0) {
      Swal.fire('Erro', 'Favor inserir uma prioridade', 'error');
      return;
    }


    if ($("#motivo_encaminhamento").val() == null || $("#motivo_encaminhamento").val() == "") {
      Swal.fire('Erro', 'Favor inserir um motivo do encaminhamento', 'error');
      return;
    }

    var obj = {
      "presenca_id": this.presenca.id,
      "ocupacao_id": this.especialidade_id,
      "peso": !objSend.peso ? null : objSend.peso,
      "altura": !objSend.altura ? null : objSend.altura,
      "diagnostico": objSend.diagnostico,
      "motivo_encaminhamento": $("#motivo_encaminhamento").val(),
      "status_encaminhamento": "PENDENTE",
      "prioridade_encaminhamento": objSend.prioridade_encaminhamento,
      "cids": this.listaCid
    }
    this.salvarEncaminhamento(obj);
  }

  salvarEncaminhamento = (obj) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`Atendimento/${this.atendimento.id}/Encaminhamento`, obj).then(
      result => {
        this.encaminhamento = {};
        this.listaCid = [];
        this.especialidade_id = 0;
        $('#motivo_encaminhamento').val("");
        var editor = new Simditor({
          textarea: $('#motivo_encaminhamento')
        })
        editor.setValue("");
        Swal.close();
        this.voltar();
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  voltar = () => {
    this.encaminhamento = {};
    $('#texto_evolucao').val("");
    this.goBack.emit();
  }

}
