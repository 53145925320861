import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-maker',
  templateUrl: './list-maker.component.html',
  styleUrls: ['./list-maker.component.css']
})
export class ListMakerComponent implements OnInit {

  listaFabricantes: any = []
  filtro: string = "";
  ativo: boolean = true;

  listaStatus: any = [
    {id: 1, status: true, desc: 'Ativo'},
    {id: 2, status: false, desc: 'Inativo'}
  ]

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.obterLista()
  }

  obterLista = () => {
    var filtro: any = '';
    if (this.filtro) {
      filtro = `busca=${this.filtro.replace(/\D/g,'')}&`;
    }
    filtro += `ativo=${this.ativo}`;
    this.service.Get(`Fabricante?${filtro}`).then(
      result => {
        this.listaFabricantes = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error');
      }
    )
  }

  obterListaFiltros = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.obterLista()
  }

}
