import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
declare var jquery: any;
declare var $: any;
import * as moment from 'moment';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.css']
})
export class ProfessionalComponent implements OnInit {

  professional: any = {};
  uid: any = 0;
  loading: any = false;
  cpfInvalid: any = false;
  listaConselhoClasse: any = [];
  listaEstados: any = [];
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  global_: any

  //aba lotação
  listaOcupacoes: any = [];
  listaEstabelecimentos: any = [];
  listaOcupacoesProfissional: any = [];
  ocupacao: any = ''
  estabelecimento: any = ''
  lotacaoStatus: boolean = true;
  tempo_maximo_cancelamento: any;
  tempo_minimo_agendamento: any;
  ocupacaoSelecionada: any = null;
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private location: Location,
    private global: Global,
    private authService: AuthService
  ) {
    this.global_ = this.global
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (!this.uid) {
      this.professional.ativo = true;
      this.professional.sexo = 0;
      this.professional.conselho_id = 0;
      this.professional.conselho_estado_id = 0;
    }
    if (uid != null) {
      await this.getProfessional(uid);
    }
    this.getConselhoClasse().then(
      result => {
        this.listaConselhoClasse = result;
        this.getEstados().then(
          result => {
            this.listaEstados = result;
            this.getOcupacoes().then(
              result => {
                this.listaOcupacoes = result;
                this.getEstabelecimentos().then(
                  result => {
                    this.listaEstabelecimentos = result;
                    this.getLotacoes(this.uid).then(
                      result => {
                        this.listaOcupacoesProfissional = result
                      },
                      error => {
                        this.emitError(error);
                      }
                    )
                  },
                  error => {
                    this.emitError(error)
                  }
                )
              },
              error => {
                this.emitError(error)
              }
            )
          },
          error => {
            this.emitError(error)
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  getProfessional = (id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Profissional/${id}`).then(res => {
      Swal.close();
      this.professional = res;
      this.global.applyMask(this.professional)
      this.professional.cpf_masked = this.professional.cpf;
      this.professional.cns_masked = this.professional.cns;
      this.professional.telefone_masked = this.professional.telefone;
      this.professional.data_nascimento_masked = this.professional.data_nascimento;
      this.professional.data_nascimento_masked = this.professional.data_nascimento_masked.split("T")[0]
      this.professional.data_nascimento_masked = this.professional.data_nascimento_masked.split("-")[2] + "/" + this.professional.data_nascimento_masked.split("-")[1] + "/" + this.professional.data_nascimento_masked.split("-")[0]
    }).catch(res => {
      Swal.close();
      this.alerts.notifyError({
        body: `${res.messagem}`,
        title: 'Erro'
      });
    })
  }

  validarCPF(inputCPF) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;
    if (inputCPF == '00000000000' || inputCPF == '000.000.000-00') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    return true;
  }

  onSubmit = (professional) => {
  }

  sendValidation() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    if (this.loading) {
      return;
    }

    this.loading = true;

    var obj = Object.assign({}, this.professional);

    if (obj.nome == "" || obj.nome == null) {
      this.emitError('Favor preencher o campo nome');
      return;
    }

    if (obj.nome.length < 3) {
      this.emitError(`Campo nome precisa ter mais de 3 caracteres`);
      return;
    }

    if (obj.cns == "" || obj.cns == null) {
      this.emitError(`Favor preencher o campo CNS`);
      return;
    }

    if (obj.cns.length < 15) {
      this.emitError(`Favor preencher o campo CNS corretamente`)
      return;
    }

    if (obj.cns) {
      obj.cns = obj.cns.replace(/\D/g, "");
    }

    if (obj.data_nascimento_masked) {
      var data = obj.data_nascimento_masked
      obj.data_nascimento = data.split("/")[2] + "-" + data.split("/")[1] + "-" + data.split("/")[0] + "T00:00:00.000"
    }

    if (obj.cpf_masked == "" || obj.cpf_masked == null) {
      this.emitError(`Favor preencher o campo cpf`)
      return;
    }

    if (obj.cpf_masked) {
      obj.cpf = obj.cpf_masked.replace(/\D/g, "");
    }

    if (obj.cpf.length < 11) {
      this.emitError(`Favor preencher o campo CPF`)
      return;
    }

    if (obj.email == "" || obj.email == null) {
      this.emitError(`Favor preencher o campo e-mail`)
      return;
    }

    if (obj.email) {
      if (!this.global_.validaEmail(obj.email)) {
        Swal.fire('Erro', 'Favor digitar um e-mail válido', 'error').then(
          result => {
            this.loading = false;
          }
        )
        return
      }
    }

    if (obj.telefone_masked == "" || obj.telefone_masked == null) {
      this.emitError(`Favor preencher o campo telefone`)
      return;
    }

    if (obj.telefone_masked) {
      obj.telefone = obj.telefone_masked.replace(/\D/g, "")
    }

    if (obj.sexo == null || obj.sexo == 0) {
      this.emitError(`Favor preencher o campo sexo`)
      return;
    }

    if (obj.numero_registro != null && obj.numero_registro != "" && obj.conselho_id != null && obj.conselho_id != 0 && obj.conselho_estado_id != null && obj.conselho_estado_id != 0) { }
    else {
      this.emitError(`Favor preencher os campos referentes ao conselho.`)
      return;
    }

    if (obj.data_nascimento_masked == "" || obj.data_nascimento_masked == null) {
      this.emitError(`Favor preencher data de nascimento`)
      return;
    }

    if (new Date(obj.data_nascimento).toString() == "Invalid Date") {
      this.emitError(`Favor preencher o campo data nascimento com uma data válida`)
      return;
    }

    if (new Date() < new Date(obj.data_nascimento) || new Date(obj.data_nascimento).getFullYear() < 1900) {
      this.emitError(`Favor informar uma data de nascimento válida.`)
      return;
    }

    obj.nome = obj.nome.toLocaleLowerCase();
    obj.conselho_estado_id = parseInt(obj.conselho_estado_id);
    obj.conselho_id = parseInt(obj.conselho_id)

    delete obj.ConselhoClasse;
    delete obj.cpf_masked;
    delete obj.data_nascimento_masked
    delete obj.telefone_masked

    if (obj.id == null || obj.id == 0) {
      this.postProfessional(obj).then(
        result => {
          this.professional = result;
          this.professional.cpf_masked = this.global.cpfMask(result['cpf']);
          this.professional.data_nascimento_masked = this.global.dateMask(result['data_nascimento']);
          this.professional.telefone_masked = this.global.telefoneMask(result['telefone']);
          this.professional.cns = this.global.cnsMask(result['cns']);
          Swal.close();
          this.loading = false
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Profissional cadastrado com sucesso!'
          });
        },
        error => {
          this.emitError(error)
        }
      )
    } else {
      this.putProfessional(obj).then(
        result => {
          this.professional = result;
          this.validateReturn().then(
            result => {
              this.professional = result;
              this.global.applyMask(this.professional)
              Swal.close();
              this.loading = false;
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Profissional atualizado com sucesso!'
              }).then(
                result => {
                  this.goBack();
                }
              )
            },
            error => {
              this.emitError(error)
            }
          )
        },
        error => {
          this.emitError(error)
        }
      )
    }
  }

  postProfessional = (data: any) => {
    return new Promise((resolve, reject) => {
      this.service.Post(`Profissional`, data).then(
        res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  }

  putProfessional = (data: any) => {
    return new Promise((resolve, reject) => {
      this.service.Put(`Profissional/${data.id}`, data).then(
        res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  }

  getConselhoClasse = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`ConselhoClasse`).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  }

  getEstados = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado`).then(res => {
        resolve(res);
      }).catch(res => {
        reject(res)
      })
    })
  }

  goBack() {
    this.location.back();
  }

  getOcupacoes = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Ocupacao`).then(
        res => {
          resolve(res);
        }
      ).catch(err => {
        reject(err)
      })
    })
  }

  getEstabelecimentos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento`).then(
        res => {
          resolve(res);
        }
      ).catch(err => {
        reject(err)
      })
    })
  }

  emitError = (error) => {
    this.loading = false;
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  postLotacao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (this.ocupacao == 0) {
      this.emitError("É preciso informar uma ocupação")
      this.loading = false;
      return
    }
    if (this.estabelecimento == 0) {
      this.emitError("É preciso informar um estabelecimento")
      this.loading = false;
      return
    }

    if (this.tempo_minimo_agendamento === null || this.tempo_minimo_agendamento === undefined || this.tempo_minimo_agendamento === '') {
      this.emitError("É preciso informar um tempo mínimo de agendamento");
      this.loading = false;
      return;
    }

    if (this.tempo_maximo_cancelamento === null || this.tempo_maximo_cancelamento === undefined || this.tempo_maximo_cancelamento === '') {
      this.emitError("É preciso informar um tempo máximo de cancelamento");
      this.loading = false;
      return;
    }



    var obj = {
      ativo: this.lotacaoStatus,
      estabelecimento_id: parseInt(this.estabelecimento),
      ocupacao_id: parseInt(this.ocupacao),
      profissional_id: parseInt(this.professional.id),
      tempo_maximo_cancelamento: this.tempo_maximo_cancelamento,
      tempo_minimo_agendamento: this.tempo_minimo_agendamento
    }
    this.loading = false;
    this.service.Post(`Lotacao`, obj).then(
      result => {
        this.uid = result['profissional_id']
        Swal.fire('Sucesso', 'Lotação adicionada com sucesso!', 'success').then(
          result => {
            if (result.isConfirmed) {
              Swal.fire('Favor aguarde');
              Swal.showLoading()
              this.getLotacoes(this.uid).then(
                result => {
                  Swal.close()
                  this.listaOcupacoesProfissional = result;
                  this.loading = false;
                  this.estabelecimento = 0;
                  this.ocupacao = 0;
                },
                error => {
                  this.emitError(error)
                }
              )
            }
          }
        )

      },
      error => {
        this.emitError(error)
      }
    )
  }

  putLotacao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (this.ocupacao == 0) {
      this.emitError("É preciso informar uma ocupação")
      this.loading = false;
      return
    }
    if (this.estabelecimento == 0) {
      this.emitError("É preciso informar um estabelecimento")
      this.loading = false;
      return
    }

    if (this.tempo_minimo_agendamento === null || this.tempo_minimo_agendamento === undefined || this.tempo_minimo_agendamento === '') {
      this.emitError("É preciso informar um tempo mínimo de agendamento");
      this.loading = false;
      return;
    }

    if (this.tempo_maximo_cancelamento === null || this.tempo_maximo_cancelamento === undefined || this.tempo_maximo_cancelamento === '') {
      this.emitError("É preciso informar um tempo máximo de cancelamento");
      this.loading = false;
      return;
    }



    var obj = {
      ativo: this.lotacaoStatus,
      estabelecimento_id: parseInt(this.estabelecimento),
      ocupacao_id: parseInt(this.ocupacao),
      profissional_id: parseInt(this.professional.id),
      tempo_maximo_cancelamento: this.tempo_maximo_cancelamento,
      tempo_minimo_agendamento: this.tempo_minimo_agendamento,
      id: this.ocupacaoSelecionada.id
    }
    this.loading = false;
    this.service.Put(`Lotacao/${this.ocupacaoSelecionada.id}`, obj).then(
      result => {
        this.uid = result['profissional_id']
        Swal.fire('Sucesso', 'Lotação alterada com sucesso!', 'success').then(
          result => {
            if (result.isConfirmed) {
              this.ocupacaoSelecionada = null;
              this.ocupacaoSelecionada = null;
              this.tempo_maximo_cancelamento = null;
              this.tempo_minimo_agendamento = null;
              this.lotacaoStatus = true;
              Swal.fire('Favor aguarde');
              Swal.showLoading()
              this.getLotacoes(this.uid).then(
                result => {
                  Swal.close()
                  this.listaOcupacoesProfissional = result;
                  this.loading = false;
                  this.estabelecimento = 0;
                  this.ocupacao = 0;

                },
                error => {
                  this.emitError(error)
                }
              )
            }
          }
        )

      },
      error => {
        this.emitError(error)
      }
    )
  }

  getLotacoes = (userId: any) => {
    return new Promise((resolve, reject) => {
      if (userId) {
        this.service.Get(`Lotacao?ProfissionalId=${userId}`).then(
          result => {
            Swal.close();
            if (result['length'] > 0) {
              resolve(result);
              this.listaOcupacoesProfissional = result;
            }
            else {
              //var alert = {alert: true, txt: 'Não há lotações para exibir'}
              //reject(alert)
            }
          },
          error => {
            Swal.close();
            reject(error);
          }
        )
      }
      else {
        //var alert = {alert: true, txt: 'Não há lotações para exibir'}
        //reject(alert)
      }
    })
  }

  deleteLotacoes = (item) => {
    // this.service.Delete(`Lotacao/${item.id}`
  }

  changeStatus = () => {
    this.lotacaoStatus != this.lotacaoStatus;
  }

  changeStatusProfessional = () => {
    this.professional.ativo != this.professional.ativo;
  }

  validateReturn = () => {
    return new Promise((resolve, reject) => {
      if (this.professional.cns) {
        this.professional.cns = this.global.cnsMask(this.professional.cns)
      }
      if (this.professional.cpf) {
        this.professional.cpf_masked = this.global.cpfMask(this.professional.cpf)
      }
      if (this.professional.telefone) {
        this.professional.telefone_masked = this.global.telefoneMask(this.professional.telefone)
      }
      if (this.professional.data_nascimento) {
        this.professional.data_nascimento_masked = this.global.dateMask(this.professional.data_nascimento)
      }
      else {
        reject('Não foi possível validar o retorno')
      }
      resolve(this.professional)
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} essa lotação?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Lotacao/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {

                  if (this.uid != 0) {
                    Swal.fire('Favor aguarde');
                    Swal.showLoading();
                    this.getLotacoes(this.uid);
                  }

                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  abaLotacao = () => {
    this.estabelecimento = 0;
    this.ocupacao = 0;
  }

  editarLotacao = (item) => {
    this.ocupacaoSelecionada = Object.assign({}, item);
    this.estabelecimento = item.estabelecimento_id;
    this.ocupacao = item.ocupacao_id;
    this.tempo_minimo_agendamento = item.tempo_minimo_agendamento;
    this.tempo_maximo_cancelamento = item.tempo_maximo_cancelamento;
    this.lotacaoStatus = item.ativo;
  }

  cancelarEditarLotacao = () => {
    this.ocupacaoSelecionada = null;
    this.estabelecimento = 0;
    this.ocupacao = 0;
    this.tempo_maximo_cancelamento = null;
    this.tempo_minimo_agendamento = null;
    this.lotacaoStatus = true;
  }
}
