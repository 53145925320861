import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-modal-schedule-procedure',
  templateUrl: './modal-schedule-procedure.component.html',
  styleUrls: ['./modal-schedule-procedure.component.css']
})
export class ModalScheduleProcedureComponent implements OnInit {

  @Input() agendarExame: any = [];
  @Output() fechaModal = new EventEmitter();

  filtro: any = {}
  cidadao: any = {}
  exame: any = {}
  regulacao: any = {}

  limpaFiltro: boolean = false;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {

    if (this.agendarExame.id != null) {
      this.regulacao = this.agendarExame.id
      this.exame = this.agendarExame;
      this.exame.solicitado_em = this.agendarExame.data_hora_solicitacao;
      this.cidadao = this.exame.Cidadao;
      this.exame.EstabelecimentoExecutor = this.exame.EstabelecimentoSolicitante;
    } else {
      this.exame ={};
      this.exame.regulacoes=[] 
      this.exame.regulacoes = this.agendarExame;
    
      if (this.exame.regulacoes.length > 1) {
        this.exame.Cidadao = this.agendarExame[0].Cidadao;
      }
      if (this.exame.regulacoes.length > 1) {
        this.exame.Profissional = {
          nome: this.agendarExame[0].Usuario.nome
        }
      }
      if (this.exame.regulacoes.length > 1) {
        this.exame.EstabelecimentoSolicitante = this.agendarExame[0].EstabelecimentoSolicitante;
        this.exame.EstabelecimentoExecutor = this.agendarExame[0].EstabelecimentoSolicitante;
      }
    }
  }

  fecharModal = () => {
    this.limpaFiltro = true;
    this.fechaModal.emit()
  }


}
