import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-resultados-exames',
  templateUrl: './resultados-exames.component.html',
  styleUrls: ['./resultados-exames.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ResultadosExameComponent implements OnInit {

  ListaAtendimento: any = [];
  filtroAguardandoAtendimento: any = '';
  filtroAtendido: any = '';
  dados = JSON.parse(localStorage.getItem('dadosPresencaUser'));
  usuario: any = {};
  presenca: any;
  statusSelecionado: any = 'AGUARDANDO_ATENDIMENTO,AGUARDANDO_CLASSIFICACAO_RISCO,INTERNADO'

  listaClinicas: any = [];
  filtro: string = "";
  loading: boolean = false;

  constructor(public service: ApiService,
    private global: Global,
    private router: Router,
    private authService: AuthService) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });

    if (!this.dados) {
      this.authService.logoutUser();
    }
  }

  ngOnInit() {
    this.obterListaClinicas().then(
      result => {
        this.filtro = this.presenca.clinica_medica_id
        this.getListaAtendimento('AGUARDANDO_ATENDIMENTO,AGUARDANDO_CLASSIFICACAO_RISCO', this.filtro)
      }
    )
  }

  obterListaClinicas = () => {
    return new Promise(resolve => {
      this.service.Get(`Bloco/${this.presenca.bloco_id}/ClinicaMedica?ativo=true`).then(
        result => {
          this.listaClinicas = result
          this.filtro = this.presenca.clinica_medica_id;
          resolve('ok')
        },
        error => {
          if (error.status == 401 || error.error.status == 401) {
            Swal.close()
            this.authService.logoutUser()
          }
          Swal.close();
          this.loading = false;
          Swal.fire('Erro', `${error.error.error.messagem}`, 'error').then(
            result => {
              resolve('ok')
            }
          )
        }
      )
    })
  }

  getListaAtendimento(status, clinica) {
    this.statusSelecionado = status;
    this.filtro = clinica
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var url = `Atendimento?clinica_medica_id=${clinica}`
    if (status.includes(",")) {
      var split = status.split(",");
      split.forEach(element => {
        url += `&status_atendimento=${element}`
      });
    } else {
      url += `&status_atendimento=${status}`
    }
    url += `&ordenacao=true`;

    this.service.Get(url).then(res => {
      this.ListaAtendimento = res;
      for (let item of this.ListaAtendimento) {
        this.global.applyMask(item)
        if (item.cidadao_idade_extenso.split('Anos ')) {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Anos ')[0] + 'Anos\n' + item.cidadao_idade_extenso.split('Anos ')[1]
        }
        else {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Ano ')[0] + 'Ano\n' + item.cidadao_idade_extenso.split('Ano ')[1]
        }
        if (item.profissional_nome) {
          var temp_profissional = item.profissional_nome.split(' ');
          var mid_index = Math.ceil(item.profissional_nome.split(' ').length / 2);
          temp_profissional[mid_index] = '\n' + temp_profissional[mid_index]
          item.profissional_nome = temp_profissional.join(' ')
        }
      }
      Swal.close();
    }).catch(res => {
      Swal.close();
      if (res.status == 401 || res.error && res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }


  getListaAguardandoAtendimento(status, clinica) {
    this.statusSelecionado = status;
    this.filtro = clinica
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Atendimento?clinica_medica_id=${clinica}&status_atendimento=AGUARDANDO_ATENDIMENTO&status_atendimento=AGUARDANDO_CLASSIFICACAO_RISCO`).then(res => {
      this.ListaAtendimento = res;
      for (let item of this.ListaAtendimento) {
        this.global.applyMask(item)
        if (item.cidadao_idade_extenso.split('Anos ')) {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Anos ')[0] + 'Anos\n' + item.cidadao_idade_extenso.split('Anos ')[1]
        }
        else {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Ano ')[0] + 'Ano\n' + item.cidadao_idade_extenso.split('Ano ')[1]
        }
        if (item.profissional_nome) {
          var temp_profissional = item.profissional_nome.split(' ');
          var mid_index = Math.ceil(item.profissional_nome.split(' ').length / 2);
          temp_profissional[mid_index] = '\n' + temp_profissional[mid_index]
          item.profissional_nome = temp_profissional.join(' ')
        }
      }
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  filtroListaAguardandoAtendimento() {
    if (this.ListaAtendimento.length === 0) {
      return this.ListaAtendimento;
    }
    return this.ListaAtendimento.filter(
      a => a.cidadao_nome.toLocaleLowerCase().includes(this.filtroAguardandoAtendimento.toLocaleLowerCase()) ||
        a.profissional_nome.toLocaleLowerCase().includes(this.filtroAguardandoAtendimento.toLocaleLowerCase())
    );
  }

  filtroListaAtendido() {
    if (this.ListaAtendimento.length === 0) {
      return this.ListaAtendimento;
    }
    return this.ListaAtendimento.filter(
      a => a.cidadao_nome.toLocaleLowerCase().includes(this.filtroAtendido.toLocaleLowerCase()) ||
        a.profissional_nome.toLocaleLowerCase().includes(this.filtroAtendido.toLocaleLowerCase())
    );
  }

  iniciarAtendimento(item: any) {
    Swal.fire('Iniciando atendimento');
    Swal.showLoading();
    var obj = {
      presenca_id: this.presenca.id
    }
    this.service.Post(`Atendimento/${item.id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${item.id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  continuarAtendimento(item: any) {
    Swal.fire('Iniciando atendimento');
    Swal.showLoading();
    var obj = {
      atendimento_id: item.id,
      presenca_id: this.presenca.id
    }
    this.service.Put(`Atendimento/${item.id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${item.id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

}
