import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

import { catchError } from 'rxjs/operators';

import {
  Endereco,
  CEPError,
  CEPErrorCode, NgxViacepService
} from '@brunoc/ngx-viacep';

import { AuthService } from '../../service/auth/auth.service'


@Component({
  selector: 'estabelecimento',
  templateUrl: './establishment-data.component.html',
  styleUrls: ['./establishment-data.component.css']
})

export class EstablishmentDataComponent implements OnInit {

  uid: any;

  estab: any = {};
  listStates: any = [];
  listCities: any = [];
  estabType: any = [];
  listSubType: any = []
  listaDependencias: any = [];
  listaNaturezaJuridica: any = [];
  listaGestaoEstabelecimento: any = [];

  buscandoPorCep: boolean = false;

  @Output() dadosEstabelecimento = new EventEmitter()

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private viacep: NgxViacepService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (this.uid) {
      this.enviaDadosEstabelecimento()
    }
    this.estab.natureza_juridica = 0;
    this.estab.municipio_id = 0;
    this.getDependecies().then(
      result => {
        this.listaDependencias = result;
        this.listaDependencias.unshift({ id: 0, nome: 'Selecione..' });
        this.estab.dependencia = this.listaDependencias[0].id
        this.getEstabType().then(
          result => {
            this.estabType = result;
            this.estabType.unshift({ id: 0, nome: 'Selecione..' });
            this.estab.tipo_estabelecimento_id = this.estabType[0].id
            this.listSubType.push({ id: '0', nome: 'Não há dados para exibir' })
            this.estab.subtipo_estabelecimento_id = this.listSubType[0].id
            this.getLegalNature().then(
              result => {
                this.listaNaturezaJuridica = result;
                this.listaNaturezaJuridica.unshift({ id: 0, nome: 'Selecione..' });
                // this.estab.natureza_juridica = this.listaNaturezaJuridica[0].id
                this.getManagement().then(
                  result => {
                    this.listaGestaoEstabelecimento = result
                    this.listaGestaoEstabelecimento.unshift({ id: 0, nome: 'Selecione..' })
                    this.estab.gestao = this.listaGestaoEstabelecimento[0].id
                    this.getStates().then(
                      result => {
                        this.listStates = result;
                        this.listStates.unshift({ id: 0, nome: 'Selecione' });
                        this.estab.estado_id = this.listStates[0].id
                        if (uid != null) {
                          this.getStablishment(uid);
                        }
                        Swal.close()
                      },
                      error => {
                        this.emitError(error)
                      }
                    )
                  },
                  error => {
                    this.emitError(error)
                  }
                )
              },
              error => {
                this.emitError(error)
              }
            )
          },
          error => {
            this.emitError(error)
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  enviaDadosEstabelecimento = () => {
    this.dadosEstabelecimento.emit(this.uid)
  }

  getStablishment = (id) => {
    this.service.Get(`Estabelecimento/${id}`).then(
      result => {
        this.estab = result;
        this.global.applyMask(this.estab)
        this.estab.cnpj_masked = this.estab.cnpj
        this.estab.cep_masked = this.estab.cep
        this.estab.gestao = this.estab.gestao_id
        this.estab.natureza_juridica = this.estab.natureza_juridica_id
        this.estab.dependencia = this.estab.dependencia_id
        this.estab.telefone_masked = this.estab.telefone
        if (this.estab.telefone_masked)
          this.estab.telefone_masked = this.global.telefoneMask(this.estab.telefone_masked)
        this.getStates().then(
          result => {
            this.listStates = result;
            this.getEstabType().then(
              result => {
                this.estabType = result;
                this.getCities(this.estab.estado_id)
                if (this.estab.tipo_estabelecimento_id) {
                  this.getSubTypes(this.estab.tipo_estabelecimento_id)
                }
                Swal.close()
              },
              error => {
                this.emitError(error)
              }
            )
          },
          error => {
            this.emitError(error)
          }
        )
      },
      error => {
        this.emitError(error);
      }
    )
  }

  saveStablishment = (estab) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.validateData(estab).then(
      result => {
        var obj: any;
        obj = result;
        if (obj.municipio) {
          delete obj.municipio
        }
        if (estab.id) {
          this.service.Put(`Estabelecimento/${obj.id}`, obj).then(
            result => {
              Swal.close()
              Swal.fire('Sucesso', 'Estabelecimento atualizado com sucesso', 'success')
            },
            error => {
              this.emitError(error)
            }
          )
        }
        else {
          this.service.Post(`Estabelecimento`, obj).then(
            result => {
              if (result['id']){
                this.estab.id = result['id']
              }
             
              Swal.close()
              Swal.fire('Sucesso', 'Estabelecimento criado com sucesso', 'success').then(
                result => {
                  this.dadosEstabelecimento.emit(this.estab.id)
                }
              )
            },
            error => {
              this.emitError(error)
            }
          )
        }
      },
      error => {
        this.emitError(error);
      }
    )
  }

  getStates = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado`).then(res => {
        resolve(res);
      }).catch(res => {
        reject(res)
      })
    })
  }

  getCities = (iduf) => {
    this.service.Get(`Estado/${iduf}/Municipios`).then(res => {
      this.listCities = res;
      this.listCities.unshift({ id: 0, nome: 'Selecione' });
      if (this.buscandoPorCep) {
        let municipioEstab = this.estab.municipio;
        let municipio = this.listCities.filter((item) => {
          return item.nome == municipioEstab
        })
        if (municipio != null && municipio.length > 0) {
          this.estab.municipio_id = municipio[0].id
        }
        this.buscandoPorCep = false;
        Swal.close()
      }
    }).catch(res => {
      this.emitError(res)
    })
  }

  getEstabType = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`TipoEstabelecimento`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  emitError = (error) => {
    if (typeof error == 'string'){
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
      return
    }
    if (error.error.erros) {
      Swal.fire('Erro', `${error.error.erros[0].messagem}`, 'error')
      return
    }
    if (error.status) {
      Swal.fire('Erro', `${error.status} - ${error.statusText}`, 'error')
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning')
      return
    }
  }

  getDependecies = () => {
    return new Promise((resolve, reject) => {
      this.service.Get('Estabelecimento/DependenciaEstabelecimento').then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getLegalNature = () => {
    return new Promise((resolve, reject) => {
      this.service.Get('Estabelecimento/NaturezaJuridicaEstabelecimento').then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getManagement = () => {
    return new Promise((resolve, reject) => {
      this.service.Get('Estabelecimento/GestaoEstabelecimento').then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  validateData = (data) => {
    return new Promise((resolve, reject) => {
      if (!data.nome) {
        reject('É preciso informar um nome para o estabelecimento')
        return
      }
      if (!data.cnes) {
        reject('É preciso informar um CNES para o estabelecimento')
        return
      }
      if (data.cnes) {
        if (data.cnes.length < 7) {
          reject('Favor informar um CNES válido')
        }
      }
      if (!data.cnpj_masked) {
        reject('É preciso informar um CNPJ para o estabelecimento')
        return
      }
      if (data.cnpj_masked) {
        data.cnpj = data.cnpj_masked.replace(/\D/g, '');
      }
      if (data.cnpj) {
        if (data.cnpj.length < 14) {
          reject('Favor informar um CNPJ válido')
        }
      }
      if (!data.nome_empresarial) {
        reject('É preciso informar o nome empresarial do estabelecimento')
        return
      }
      if (!data.natureza_juridica) {
        reject('É preciso informar uma natureza jurídica para o estabelecimento')
        return
      }
      if (data.natureza_juridica) {
        data.natureza_juridica = parseInt(data.natureza_juridica)
        delete data.natureza_juridica_id
      }
      // if (data.natureza_juridica_id){
      //   data.natureza_juridica = data.natureza_juridica_id;
      // }
      if (!data.cep_masked) {
        reject('É preciso informar um CEP para o estabelecimento')
      }
      if (data.cep_masked) {
        data.cep = data.cep_masked.replace(/\D/g, '');
      }
      if (!data.estado_id) {
        reject('É preciso informar um estado para o estabelecimento')
        return
      }
      if (!data.municipio_id) {
        reject('É preciso informar um município para o estabelecimento')
        return
      }
      if (!data.municipio_id) {
        delete data.municipio
      }
      if (!data.bairro) {
        reject('É preciso informar um bairro para o estabelecimento')
        return
      }
      if (!data.logradouro) {
        reject('É preciso informar um logradouro para o estabelecimento')
        return
      }
      if (!data.dependencia) {
        reject('É preciso informar uma dependência para o estabelecimento')
        return
      }
      if (data.dependencia) {
        data.dependencia = parseInt(data.dependencia)
        delete data.dependencia_id
      }
      // if (data.dependencia){
      //   data.dependencia = data.dependencia_id;
      // }
      if (!data.tipo_estabelecimento_id) {
        reject('É preciso informar um tipo de estabelecimento')
        return
      }
      if (this.estab.subtipo_estabelecimento_id == 0) {
        this.estab.subtipo_estabelecimento_id = null
      }
      if (!data.gestao) {
        reject('É preciso informar um tipo de gestão para o estabelecimento')
        return
      }
      if (data.gestao) {
        data.gestao_id = parseInt(data.gestao)
      }
      // if (data.gestao_id){
      //   data.gestao = data.gestao_id;
      // }    
      if (data.telefone_masked) {
        data.telefone = data.telefone_masked.replace(/\D/g, '');
      }
      resolve(data)
    })
  }

  goBack() {
    this.location.back();
  }

  getSubTypes = (type) => {
    this.listSubType = [];
    this.service.Get(`TipoEstabelecimento/${type}/SubTipoEstabelecimento`)
      .then(
        result => {
          var subtipos: any = []
          subtipos = result;
          if (subtipos.length > 0) {
            this.listSubType = subtipos;
            this.listSubType.unshift({ id: 0, nome: 'Selecione..' })
            if (this.estab.subtipo_estabelecimento_id == 0) {
              this.estab.subtipo_estabelecimento_id = this.listSubType[0].id
            }
          }
          else {
            this.listSubType.unshift({ id: '0', nome: 'Não há dados para exibir' })
            this.estab.subtipo_estabelecimento_id = this.listSubType[0].id
          }
        },
        error => {
          this.emitError(error);
        }
      )
  }

  buscaCep = () => {
    Swal.fire('Buscando CEP');
    Swal.showLoading();
    this.estab.cep = this.estab.cep_masked;
    this.estab.cep = this.estab.cep.replace(/\D/g, "");
    if (this.estab.cep == null || this.estab.cep.length != 8) {
      Swal.fire('Atenção', "Favor informar um cep válido", 'warning')
      return;
    }
    this.viacep
      .buscarPorCep(this.estab.cep)
      .pipe(
        catchError((error: CEPError) => {
          // Ocorreu algum erro :/
          return [{ 'erro': error.message }];
        })
      )
      .subscribe((endereco: Endereco) => {
        if (endereco['erro'] != null) {
          Swal.fire('Atenão', 'CEP não encontrado', 'warning');
          return
        }
        // Endereço retornado :)
        let estado = this.listStates.filter(function (item) {
          return item.sigla == endereco.uf;
        });
        if (estado != null && estado.length > 0) {
          this.estab.estado_id = estado[0].id;
          this.estab.municipio = endereco.localidade.toUpperCase();
          this.buscandoPorCep = true;
          this.getCities(estado[0].id);
        }
        this.estab.bairro = endereco.bairro;
        this.estab.logradouro = endereco.logradouro;

      });
  }

  changeStatusProfessional = () => {
    this.estab.ativo != this.estab.ativo;
  }

}
