import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var jquery: any;
declare var $: any;
declare var FullCalendar: any;
import Swal from 'sweetalert2';
import * as moment from "moment";
@Component({
  selector: 'app-laboratorio',
  templateUrl: './laboratorio.component.html',
  styleUrls: ['./laboratorio.component.css']
})
export class LaboratorioComponent implements OnInit {
  usuario: any = {};
  presenca: any = {};
  cpfCnsFilter: any = "";
  listaSolicitacoes: any = [];
  loading: any = false;
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnInit() {   
  }

  pesquisarFiltro = () => {
    if (!this.cpfCnsFilter) {
      return;
    }

    const filtroLimpo = this.cpfCnsFilter.replace(/[^a-zA-Z0-9]/g, '');
    this.loading = true;
    this.service.Get(`IntegracaoLaboratorio/Solicitacao?busca=${filtroLimpo}`).then(res => {
      this.loading = false;
      if (res != null) {
        this.listaSolicitacoes = res;
      }
    }).catch(res => {
      this.loading = false;
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  obterSolicitacoes = (item) => {
    if (item.loading) {
      return;
    }

    item.loading = true;
    this.service.Get(`IntegracaoLaboratorio/Solicitacao/${item.co_seq_requisicao_exame}/Exame`).then(res => {
      item.loading = false;
      if (res != null) {
        item.solicitacoes = res;
      }
    }).catch(res => {
      item.loading = false;
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  importarSolicitacoes = (item) => {
    if (item.loadingImportar) {
      return;
    }

    item.loadingImportar = true;
    this.service.Post(`IntegracaoLaboratorio/Solicitacao/${item.co_seq_requisicao_exame}/Importar`, {}).then(res => {
      item.loadingImportar = false;      
      this.alerts.notifySuccess({
        body: 'Dados importados com sucesso',
        title: 'Sucesso'
      });

    }).catch(res => {
      item.loadingImportar = false;
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }



}
