import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../service/apiServices';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Global } from '../../global';
import { Location } from '@angular/common';
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-evolution-print',
  templateUrl: './evolution-print.component.html',
  styleUrls: ['./evolution-print.component.css']
})
export class EvolutionPrintComponent implements OnInit {
  dadosImpresso: any = {};
  global_: any;
  
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.global_ = this.global
    this.authService.dadosImpresso.subscribe(res => {
      if (res) {
        this.dadosImpresso = res;
      }
    });
  }

  ngOnInit() {
  }

}
