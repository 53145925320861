import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashBoardsComponent implements OnInit {

  listaDashboards: any = []
  filtro: any = "";
  usuario: any = {};
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {

    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
  }

  ngOnInit() {
    this.getDashBoards()
  }

  getDashBoards = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Get(`DashboardExterno?usuario_id=${this.usuario.usuario_id}&ativo=true`).then(
      result => {
        this.listaDashboards = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }


  openUrl = (item) => {
    if (item.url) {
      window.open(item.url);
    }
  }

  filtroLista() {
    if (this.listaDashboards.length === 0) {
      return this.listaDashboards;
    }
    return this.listaDashboards.filter(
      c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase())
    );
  }

}
