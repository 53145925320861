import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-list-plans',
  templateUrl: './list-plans.component.html',
  styleUrls: ['./list-plans.component.css']
})
export class ListPlansComponent implements OnInit {

  listaPlanos: any = [];
  filtro: string = "";

  constructor(
    public service: ApiService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.obterListaPlanos().then(
      result => {
        this.listaPlanos = result;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  filtroLista() {
    if (this.listaPlanos.length === 0) {
      return this.listaPlanos;
    }
    return this.listaPlanos.filter(c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  obterListaPlanos = () => {
    return new Promise((res,rej) => {
      this.service.Get(`Plano`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )        
    })
  }

  // alterarStatusPlano = (item, status) => {
  alterarStatusPlano = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    item.ativo = !item.ativo
    this.service.Put(`Plano/${item.id}`, item).then(
      result => {
        Swal.fire('Sucesso', 'Plano alterado com sucesso', 'success').then(
          result => {
            Swal.fire('Favor aguarde')
            Swal.showLoading()
            this.obterListaPlanos().then(
              result => {
                this.listaPlanos = result;
                Swal.close()
              },
              error => {
                Swal.fire('Erro', error.messagem, 'error')
              }
            )
          }
        )
      },
      error =>{
        Swal.fire('Error', error.messagem, 'error')
      }
    )
  }


  alterarBloqueioPlano = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    item.bloqueado = !item.bloqueado
    this.service.Patch_body(`Plano/${item.id}/Bloqueado`, item.bloqueado).then(
      result => {
        Swal.fire('Sucesso', 'Plano alterado com sucesso', 'success').then(
          result => {
            Swal.fire('Favor aguarde')
            Swal.showLoading()
            this.obterListaPlanos().then(
              result => {
                this.listaPlanos = result;
                Swal.close()
              },
              error => {
                Swal.fire('Erro', error.messagem, 'error')
              }
            )
          }
        )
      },
      error =>{
        Swal.fire('Error', error.messagem, 'error')
      }
    )
  }
}
