import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import * as moment from 'moment';
import { Location } from '@angular/common';
import {
  Endereco,
  CEPError,
  CEPErrorCode, NgxViacepService
} from '@brunoc/ngx-viacep';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'
import * as $ from "jquery";
declare var window: any;
@Component({
  selector: 'app-citizen',
  templateUrl: './citizen.component.html',
  styleUrls: ['./citizen.component.css']
})
export class CitizenComponent implements OnInit {

  cidadao: any = {};
  estados: any = [];
  municipios: any = [];
  //listaEstadosCarregada: boolean = false;
  buscandoPorCep: boolean = false;
  editando = false;
  uid: any = '';
  loading = false;
  generos: any = [];
  planos: any = [];
  buscaDependenteTxt: any = "";
  listaCidadao: any = [];
  listaCidadaoDependentes: any = [];
  @ViewChild('selectElement', { static: false }) selectElement: ElementRef;

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private viacep: NgxViacepService,
    private route: ActivatedRoute,
    private location: Location,
    public global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.obterListaEstados();
    this.obterListaPlanos();
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterCidadao(uid);
      this.buscarDependenteCidadao()
      this.editando = true;
    }
  }

  obterListaEstados = () => {
    //if (this.listaEstadosCarregada)
    //  return
    if (!this.editando) {
      this.cidadao.estado_id = 0;
      this.cidadao.municipio_id = 0;
    }
    this.service.Get(`Estado`).then(res => {
      this.estados = res;
      //this.listaEstadosCarregada = true;
      this.obterListaGenero();
      this.init();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaGenero = () => {
    this.service.Get(`Cidadao/Genero`).then(res => {
      this.generos = res;
      this.cidadao.sexo_id = 0;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaMunicipios = (estado_id, cadsus = false) => {
    this.cidadao.municipio_id = 0;
    if (this.cidadao.municipio_id_ != null)
      this.cidadao.municipio_id = this.cidadao.municipio_id_;
    this.service.Get(`Estado/${estado_id}/Municipios`).then(res => {
      this.municipios = res;
      if (this.buscandoPorCep) {
        let municipioCidadao = this.cidadao.localidade;
        let municipio = this.municipios.filter(function (item) {
          return item.nome == municipioCidadao;
        });
        if (municipio != null && municipio.length > 0)
          this.cidadao.municipio_id = municipio[0].id;
        this.buscandoPorCep = false;
      }
      if (cadsus) {
        if (this.cidadao.endereco_cidade_nome) {
          var fil_m = this.municipios.filter(x => x.nome == this.cidadao.endereco_cidade_nome.toUpperCase());
          if (fil_m.length > 0) {
            this.cidadao.municipio_id = fil_m[0].id;
          }
        }
      }

    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  salvarCidadao = () => {
    if (!this.validaPostCidadao())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.cidadao.sexo = this.cidadao.sexo_id;

    var obj = Object.assign({}, this.cidadao);
    if (obj.plano_id == 0) {
      delete obj.plano_id;
    }

    this.service.Post(`Cidadao`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Paciente salvo com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  goBack() {
    this.location.back();
  }

  validaPostCidadao = () => {
    if (this.cidadao.nome == null || this.cidadao.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome do paciente", 'error');
      return false;
    }
    if (!this.cidadao.cpf && !this.cidadao.cns) {
      Swal.fire('Erro', "É preciso informar CPF ou CNS", 'error');
      return false;
    }
    if (this.cidadao.cpf) {
      if (this.cidadao.cpf.length < 11) {
        Swal.fire('Erro', "É preciso informar um CPF válido", 'error');
        return false;
      }
    }
    if (this.cidadao.cns) {
      if (this.cidadao.cns.length < 15) {
        Swal.fire('Erro', "É preciso informar um CNS válido", 'error');
        return false;
      }
    }
    if (this.cidadao.nome_mae == null || this.cidadao.nome_mae == "") {
      Swal.fire('Erro', "Favor preencher o nome da mãe", 'error');
      return false;
    }
    if (this.cidadao.nascido_em_ == null || this.cidadao.nascido_em_.length != 8) {
      Swal.fire('Erro', "Favor informar uma data de nascimento válida.", 'error');
      return false;
    } else {
      this.cidadao.nascido_em = `${this.cidadao.nascido_em_[4] + this.cidadao.nascido_em_[5] + this.cidadao.nascido_em_[6] + this.cidadao.nascido_em_[7] + "-" + this.cidadao.nascido_em_[2] + this.cidadao.nascido_em_[3] + "-" + this.cidadao.nascido_em_[0] + this.cidadao.nascido_em_[1]}`;
      let d = new Date(this.cidadao.nascido_em);
      if (isNaN(d.getDate())) {
        Swal.fire('Erro', "Favor informar uma data de nascimento válida.", 'error');
        return false;
      }
    }
    if (this.cidadao.sexo_id == null || this.cidadao.sexo_id == 0) {
      Swal.fire('Erro', "Favor preencher o gênero", 'error');
      return false;
    }
    if (!this.cidadao.cep) {
      Swal.fire('Erro', "Favor informar o CEP.", 'error');
      return false;
    }
    if (this.cidadao.estado_id == 0) {
      Swal.fire('Erro', "Favor informar o estado.", 'error');
      return false;
    }
    if (this.cidadao.municipio_id == 0) {
      Swal.fire('Erro', "Favor informar o município.", 'error');
      return false;
    }
    if (this.cidadao.bairro == null || this.cidadao.bairro == "") {
      Swal.fire('Erro', "Favor informar o bairro.", 'error');
      return false;
    }
    if (this.cidadao.logradouro == null || this.cidadao.logradouro == "") {
      Swal.fire('Erro', "Favor informar o logradouro.", 'error');
      return false;
    }
    if (this.cidadao.telefone != null && this.cidadao.telefone.length > 0 && this.cidadao.telefone.length != 10) {
      Swal.fire('Erro', "Favor informar um telefone válido", 'error');
      return false;
    }
    if (this.cidadao.celular != null && this.cidadao.celular.length > 0 && this.cidadao.celular.length != 11) {
      Swal.fire('Erro', "Favor informar um celular válido", 'error');
      return false;
    }
    return true;
  }

  obterCidadao = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Cidadao/${id}`).then(
      result => {
        this.cidadao = result;
        if (this.cidadao.nascido_em != null) {
          this.cidadao.nascido_em_ = this.global.dateMask(this.cidadao.nascido_em)
        }
        if (this.cidadao.estado_id) {
          this.obterMunicipios(this.cidadao.estado_id).then(
            result => {
              this.municipios = result;
            },
            error => {
              this.emitErro(error)
            }
          )
        }
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  obterEnderecoCidadao = (id) => {
    this.service.Get(`Endereco/${id}`).then(res => {
      this.cidadao.Endereco = res;
      this.cidadao.estado_id = this.cidadao.Endereco.estado_id;
      this.cidadao.municipio_id_ = this.cidadao.Endereco.municipio_id;
      this.cidadao.cep = this.cidadao.Endereco.cep;
      this.cidadao.complemento = this.cidadao.Endereco.complemento;
      this.cidadao.logradouro = this.cidadao.Endereco.logradouro;
      this.cidadao.numero = this.cidadao.Endereco.numero;
      this.cidadao.bairro = this.cidadao.Endereco.bairro;
      if (this.cidadao.estado_id != null)
        this.obterListaMunicipios(this.cidadao.estado_id);
    }).catch(res => {
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  buscarCep = () => {
    if (this.cidadao.cep == null || this.cidadao.cep.length != 8) {
      Swal.fire('Erro', "Favor informar um CEP válido", 'error');
      return;
    }
    this.viacep
      .buscarPorCep(this.cidadao.cep)
      .pipe(
        catchError((error: CEPError) => {
          return [{ 'erro': error.message }];
        })
      )
      .subscribe((endereco: Endereco) => {
        if (endereco['erro'] != null) {
          Swal.fire('Erro', endereco['erro'], 'error');
          return
        }
        let estado = this.estados.filter(function (item) {
          return item.sigla == endereco.uf;
        });
        if (estado != null && estado.length > 0) {
          this.cidadao.estado_id = estado[0].id;
          this.cidadao.localidade = endereco.localidade.toUpperCase();
          this.buscandoPorCep = true;
          this.obterListaMunicipios(estado[0].id);
        }
        this.cidadao.bairro = endereco.bairro;
        this.cidadao.logradouro = endereco.logradouro;

      });
  }

  editarCidadao = () => {
    if (!this.validaPostCidadao())
      return;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    delete this.cidadao.Estabelecimento;
    delete this.cidadao.Profissional;
    this.cidadao.sexo = this.cidadao.sexo_id;

    this.service.Put(`Cidadao/${this.uid}`, this.cidadao).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Paciente atualizado com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  obterMunicipios = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado/${id}/Municipios`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  searchCadSus = (dado, tipo) => {
    var url;

    if (tipo == 1) {
      url = `Cidadao/Buscar?cpf=${dado}`;
    } else {
      url = `Cidadao/Buscar?cns=${dado}`;
    }
    this.service.GetAnotherPath(`https://cadsus.digt.app.br/`, url).then(res => {
      if (res[0]) {
        var responsavel = this.cidadao.eh_responsavel;
        this.cidadao = res[0];
        this.cidadao.eh_responsavel = responsavel;
        this.cidadao.nascido_em_ = this.global.dateMask(res[0]['nascimento_em']);
        if (this.cidadao.genero) {
          var fil_g = this.generos.filter(x => x.nome == this.cidadao.genero.toUpperCase());
          if (fil_g.length > 0) {
            this.cidadao.sexo_id = fil_g[0].id;
          }
        }
        if (this.cidadao.endereco_cep) {
          this.cidadao.cep = this.cidadao.endereco_cep;
        }

        if (this.cidadao.endereco_cep) {
          this.cidadao.cep = this.cidadao.endereco_cep;
        }
        if (this.cidadao.endereco_estado_sigla) {
          var fil_es = this.estados.filter(x => x.sigla == this.cidadao.endereco_estado_sigla.toUpperCase());
          if (fil_es.length > 0) {
            this.cidadao.estado_id = fil_es[0].id;
            this.obterListaMunicipios(this.cidadao.estado_id, true);
          }
        }
        if (this.cidadao.endereco_bairro) {
          this.cidadao.bairro = this.cidadao.endereco_bairro;
        }
        if (this.cidadao.endereco_numero) {
          this.cidadao.numero = this.cidadao.endereco_numero;
        }
        if (this.cidadao.endereco_logradouro) {
          this.cidadao.logradouro = this.cidadao.endereco_logradouro;
        }
        if (res[0].telefone) {

          var fone: any;

          fone = res[0].telefone.replace(/\D/g, '');

          if (fone.length == 13) {
            this.cidadao.telefone = ''
            this.cidadao.celular = fone.slice(-11)
          }
          else {
            this.cidadao.celular = ''
            this.cidadao.telefone = fone.slice(-10)
          }
        }
      }


    }).catch(res => {

      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      } else if (res.status == 523 || res.error.status == 523) {
        Swal.close()
        Swal.fire('Erro', `Sistema de busca está offline, favor tentar novamente mais tarde`, 'error');
      } else {
        Swal.fire('Erro', `${res.error}`, 'error');
      }
    });
  }

  obterListaPlanos = () => {
    this.service.Get(`Plano?ativo=true&bloqueado=false`).then(res => {
      var list: any = res;
      this.planos = list.filter(x => x.ativo);
      this.cidadao.plano_id = 0;
      this.initSelect();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  async initSelect() {
    await window.$("#select").select2({
      tags: true,
      theme: "bootstrap",
    });
    setTimeout(() => {
      window.$("#select").val(this.cidadao.plano_id).trigger('change');
    }, 1000);

    window.$("#select").on('select2:select', (e: any) => {
      const selectedValue = e.params.data.id;
      this.cidadao.plano_id = selectedValue;
    });
  }

  buscarCidadao = () => {
    if (this.buscaDependenteTxt) {
      if (this.buscaDependenteTxt.length < 3) {
        return;
      }
      this.loading = true;
      this.service.Get(`Cidadao?Busca=${this.buscaDependenteTxt}&skip=1&take=10`).then(res => {
        this.loading = false;
        this.listaCidadao = res['conteudo'];


        if (this.listaCidadao.length == 0) {
          Swal.fire({
            title: 'Atenção',
            text: `Esse paciente não foi encontrado em nosso sistema. Deseja cadastra-lo agora?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545'
          }).then(
            result => {
              if (result.isConfirmed) {
                $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
                $("#modalNovoCidadaoDemandaEspontanea").css("display", "block");
              }
            }
          );
        }
      }).catch(res => {
        this.loading = false;
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      })
    }
  }

  retornoNovoCidadao = (cidadao) => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      html: 'Operação realizada com sucesso!'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.buscaDependenteTxt = cidadao.nome;
          this.fecharModal();
          this.buscarCidadao();
        }
      }
    );
  }

  fecharModal = () => {
    $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
    $("#modalNovoCidadaoDemandaEspontanea").css("display", "none");
  }

  buscarDependenteCidadao = () => {
    this.loading = false;
    this.service.Get(`Cidadao/${this.uid}/Dependente`).then(res => {
      this.loading = false;
      this.listaCidadaoDependentes = res;
    }).catch(res => {
      this.loading = false;
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  salvarDependenteCidadao = (id) => {
    if (this.uid) {
      this.loading = true;
      this.service.Post(`Cidadao/${this.uid}/Dependente`, id).then(res => {
        this.loading = false;
        this.listaCidadao = [];
        this.buscaDependenteTxt = "";
        this.buscarDependenteCidadao();

      }).catch(res => {
        this.loading = false;
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      })
    } else {
      if (!this.validaPostCidadao())
        return;
      Swal.fire({
        title: 'Favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      this.cidadao.sexo = this.cidadao.sexo_id;

      var obj = Object.assign({}, this.cidadao);
      if (obj.plano_id == 0) {
        delete obj.plano_id;
      }

      this.service.Post(`Cidadao`, obj).then(res => {
        setTimeout(() => {
          Swal.close()

          this.uid = res['id'];
          this.salvarDependenteCidadao(id);
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      })
    }
  }

  deletarDependenteCidadao = (id) => {
    this.loading = true;
    this.service.Delete(`Cidadao/${this.uid}/Dependente/${id}`).then(res => {
      this.loading = false;
      this.buscarDependenteCidadao()
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }
}
