import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import { Global } from '../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

import * as $ from 'jquery';

import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-modal-manual-regulation',
  templateUrl: './modal-manual-regulation.component.html',
  styleUrls: ['./modal-manual-regulation.component.css']
})

export class ModalManualRegulationComponent implements OnChanges {

  enviaTipoRegulacao: any;
  @Input() tipoRegulacao: any;
  @Output() reloadLista = new EventEmitter();

  busca: any = '';
  paciente: any = [];

  mostraBuscaPaciente: boolean = true;
  cadastrarPaciente: boolean = false;
  pacienteEncontrado: boolean = false;
  pacienteNaoEncontrado: boolean = false;
  realizarRegulacao: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
  }

  ngOnChanges() {
    this.enviaTipoRegulacao = this.tipoRegulacao;
    this.busca = this.global.cpfMask(this.busca);
  }

  fecharModal = () => {
    this.voltarBusca()
    $("#modalRegulacaoManual").toggleClass("show");
    $("#modalRegulacaoManual").css("display", "none");
    this.reloadLista.emit();
  }

  buscaPaciente = () => {
    this.paciente = [];
    this.pacienteEncontrado = false;
    this.pacienteNaoEncontrado = false;
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var tipoFiltro = ''
    if (this.busca.length == 14) {
      tipoFiltro = 'Cpf'
    }
    if (this.busca.length == 18) {
      tipoFiltro = 'Cns'
    }
    if (!tipoFiltro) {
      this.emitError('Os dados buscados estão errados. Tente digitar novamente')
      return
    }
    this.service.Get(`Cidadao?${tipoFiltro}=${this.global.removeMask(this.busca)}&skip=1&take=100`).then(
      result => {
        //this.paciente = result[0];
        this.paciente = result['conteudo'][0];
        if (this.paciente) {
          this.pacienteEncontrado = true;
          if (this.paciente.estado_id) {
            this.obterEstadoEndereco(this.paciente).then(
              result => {
                this.paciente.estado_nome = result['nome'];
                this.obterMunicipioEndereco(this.paciente).then(
                  result => {
                    this.paciente.municipio_nome = result['nome']
                    Swal.close()
                  },
                  error => {
                    this.emitError(error)
                  }
                )
              },
              error => {
                this.emitError(error)
              }
            )
          }else{
            Swal.close();
          }
        }
        else {
          this.pacienteNaoEncontrado = true
          Swal.close()
        }
      },
      error => {
        this.emitError(error)
      }
    )
  }

  validaMascara(event: any) {
    this.busca = this.removeMask(this.busca);

    if (this.busca.length === 11) {
      this.busca = this.applyCPFFormat(this.busca);
    } else if (this.busca.length > 11) {
      this.busca = this.applyCNSFormat(this.busca);
    }
  }

  removeMask(value: string): string {
    return value.replace(/\D/g, '');
  }

  applyCPFFormat(value: string): string {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  applyCNSFormat(value: string): string {
    return value.replace(/^(\d{3})(\d{4})(\d{4})(\d{4})$/, '$1 $2 $3 $4');
  }

  // validaMascara = (event) => {    
  //   // this.busca = this.global.removeMask(this.busca)
  //   if (this.busca.length == 11) {
  //     this.busca = this.global.cpfMask(this.busca)
  //   }
  //   if (this.busca.length > 11) {
  //     this.busca = this.global.cnsMask(this.busca)
  //   }
  // }

  emitError = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  obterEstadoEndereco = (endereco) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado/${endereco.estado_id}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  obterMunicipioEndereco = (endereco) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado/${endereco.estado_id}/Municipios`).then(
        result => {
          var listaMunicipios: any = result;
          for (let municipio of listaMunicipios) {
            if (municipio.id === endereco.municipio_id) {
              resolve(municipio)
            }
          }
          resolve('NÃO INFORMADO')
        },
        error => {
          reject(error)
        }
      )
    })
  }

  iniciarRegulacaoManual = () => {
    this.pacienteEncontrado = false;
    this.mostraBuscaPaciente = false;
    this.realizarRegulacao = true;
  }

  addCidadao = () => {
    this.mostraBuscaPaciente = false;
    this.cadastrarPaciente = true;
    this.pacienteEncontrado = false;
    this.pacienteNaoEncontrado = false;
    this.realizarRegulacao = false;
  }

  listaCidadao = (cidadao) => {
    this.paciente = cidadao;
    if (this.paciente) {
      this.pacienteEncontrado = true;
      this.mostraBuscaPaciente = true;
      this.cadastrarPaciente = false;
      this.pacienteNaoEncontrado = false;
      this.realizarRegulacao = false;
    }
    this.obterEstadoEndereco(this.paciente).then(
      result => {
        this.paciente.estado_nome = result['nome'];
        this.obterMunicipioEndereco(this.paciente).then(
          result => {
            this.paciente.municipio_nome = result['nome']
            Swal.close()
          },
          error => {
            this.emitError(error)
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  voltarBusca = () => {
    this.busca = ''
    this.pacienteEncontrado = false;
    this.mostraBuscaPaciente = true;
    this.cadastrarPaciente = false;
    this.pacienteNaoEncontrado = false;
    this.realizarRegulacao = false;
    // this.fecharModal()
  }

}
