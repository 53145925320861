import { Component, OnInit } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import * as moment from 'moment';
import { Location } from '@angular/common';
import {
  Endereco,
  CEPError,
  CEPErrorCode, NgxViacepService
} from '@brunoc/ngx-viacep';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service';
import * as $ from "jquery";

@Component({
  selector: 'app-criar-resultado',
  templateUrl: './criar-resultado.component.html',
  styleUrls: ['./criar-resultado.component.css']
})
export class CriarResultadoComponent implements OnInit {

  exame: any = {};
  estados: any = [];
  filtro: any = '';
  municipios: any = [];
  resultCidadao: any = [];
  //listaEstadosCarregada: boolean = false;
  buscandoPorCep: boolean = false;
  editando = false;
  uid: any = '';
  loading = false;
  generos: any = [];

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private viacep: NgxViacepService,
    private route: ActivatedRoute,
    private location: Location,
    public global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {

  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      // await this.obterCidadao(uid);
      // this.editando = true;
    }
  }


  goBack() {
    this.location.back();
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }


  obterListCidadao = () => {
    if (this.filtro.length == 0) {
      Swal.fire('Erro', 'Favor digitar um filtro.', 'error');
      return;
    }

    var busca = this.filtro;
    if (!isNaN(parseInt(this.filtro[0])))
      var busca = this.global.removeMask(this.filtro);

    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Cidadao?Skip=${1}&Take=${100}&Busca=${busca}`).then(res => {
      this.resultCidadao = res['conteudo'];

      Swal.close();
      if (this.resultCidadao.length == 0) {
        Swal.fire({
          title: 'Atenção',
          text: `Esse paciente não foi encontrado em nosso sistema. Deseja cadastra-lo agora?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#dc3545'
        }).then(
          result => {
            if (result.isConfirmed) {
              $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
              $("#modalNovoCidadaoDemandaEspontanea").css("display", "block");
            }
          }
        );
      } else {
        this.exame = this.resultCidadao[0];
      }
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  fecharModal = () => {
    $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
    $("#modalNovoCidadaoDemandaEspontanea").css("display", "none");
  }

  retornoNovoCidadao = (exame) => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      html: 'Operação realizada com sucesso!'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.filtro = exame.nome;
          this.fecharModal();
          this.obterListCidadao();
        }
      }
    );
  }

}
