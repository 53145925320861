import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
declare var jquery: any;
declare var $: any;
import * as moment from 'moment';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  uid: any = 0;

  profile: any = {};
  loading: boolean = false;
  listaMenusLaterais: any = [];
  subMenus: any = [];
  menuSelecionado: any = "";
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private location: Location,
    private global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.init()
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (!this.uid) {
      this.profile.ativo = true;
    }
    if (this.uid) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.getProfile(this.uid)
    } else {
      this.uid = 0;
    }

    this.getListMenus();
  }

  changeStatusProfile = () => {
    this.profile.ativo = !this.profile.ativo;
  }

  goBack() {
    this.location.back();
  }

  saveProfile = () => {
    if (!this.profile.nome) {
      this.emitErro('É preciso informar um nome para o perfil');
      return;
    }


    if (this.loading) {
      return;
    }
    this.loading = true;

    this.profile.menu_lateral = [];
    this.profile.menu_lateral = this.listaMenusLaterais;

    this.listaMenusLaterais.filter(x => x.selecionado).forEach(item => {
      item.sub_menu_lateral.forEach(sub => {
        if (sub.is_menu_lateral) {
          sub.selecionado = true;
        }
      })
    })
    this.listaMenusLaterais.filter(x => x.selecionado == false).forEach(item => {
      item.sub_menu_lateral.forEach(sub => {
        if (sub.is_menu_lateral) {
          sub.selecionado = false;
        }
      })
    })
    if (this.uid) {
      this.editarPerfil(this.profile);
    } else {
      this.salvarPerfil(this.profile);
    }
  }


  salvarPerfil = (dados) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`PerfilUsuario`, dados).then(
      result => {
        Swal.fire('Sucesso', 'Perfil salvo com sucesso', 'success').then(
          result => {
            if (result.isConfirmed) {
              this.loading = false;
              Swal.close();
              this.goBack();
            }
          }
        )
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  editarPerfil = (dados) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`PerfilUsuario/${this.uid}`, dados).then(
      result => {
        Swal.fire('Sucesso', 'Perfil salvo com sucesso', 'success').then(
          result => {
            if (result.isConfirmed) {
              this.loading = false;
              Swal.close();
              this.goBack();
            }
          }
        )
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  validateProfileData = (perfil) => {
    return new Promise((resolve, reject) => {
      if (!perfil.nome) {
        reject('É preciso informar um nome para o perfil')
        return
      }
      if (!perfil.pode_agendar_exame) {
        perfil.pode_agendar_exame = false
      }
      if (!perfil.pode_autorizar_exame) {
        perfil.pode_autorizar_exame = false
      }
      if (!perfil.pode_cancelar_exame) {
        perfil.pode_cancelar_exame = false
      }
      if (!perfil.pode_laudar_exame) {
        perfil.pode_laudar_exame = false
      }
      if (!perfil.pode_realizar_coletar_exame) {
        perfil.pode_realizar_coletar_exame = false
      }
      if (!perfil.pode_agendar_consulta) {
        perfil.pode_agendar_consulta = false
      }
      if (!perfil.pode_autorizar_consulta) {
        perfil.pode_autorizar_consulta = false
      }
      if (!perfil.pode_cancelar_consulta) {
        perfil.pode_cancelar_consulta = false
      }
      if (!perfil.pode_realizar_consulta) {
        perfil.pode_realizar_consulta = false
      }
      resolve(perfil)
    })
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  getProfile = (uid) => {
    this.service.Get(`PerfilUsuario/${uid}`).then(
      result => {
        this.profile = result
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  getListMenus = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`PerfilUsuario/${this.uid}/MenuLateral`).then(
      result => {
        this.listaMenusLaterais = result;
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  selecionarSubMenus = (item) => {
    this.menuSelecionado = item.nome;
    var itens = item.sub_menu_lateral.filter(x => x.is_menu_lateral == true);
    itens.forEach(element => {
      element.selecionado = true;
    });
    this.subMenus = item.sub_menu_lateral.filter(x => x.is_menu_lateral == false);
  }

  verificarSubMenus = (item) => {
    var itens = item.sub_menu_lateral.filter(x => x.is_menu_lateral == false);
    if (itens.length > 0) {
      return true
    } else {
      return false;
    }
  }

  validarSePossuiSub = (item) => {
    
    var itens = item.sub_menu_lateral.filter(x => x.is_menu_lateral == false);
    if (itens.length > 0) {
      if (item.selecionado) {
        item.sub_menu_lateral.filter(x => x.selecionado = true);
      }else{
        item.sub_menu_lateral.filter(x => x.selecionado = false);
      }
    }
  }
}
