import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { filter, map, tap } from 'rxjs/operators';
import { Global } from '../../global';
import { AuthService } from "../../service/auth/auth.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {

  @Input() path: any = '';
  @Input() label: any = '';
  @Input() campoObrigatorio: boolean = true;
  @Input() placeholder: '';
  @Input() params: '';
  @Input() cleanField: boolean = false;
  @Output() itemSelecionado = new EventEmitter();
  @Output() textoBuscado = new EventEmitter();
  @Output() resultBusca = new EventEmitter();

  @Input() filtroListar: boolean = false;

  @Input() skip: any;
  @Input() take: any;


  queryField = new FormControl();
  results$: Observable<any>;
  readonly FIELDS = 'Busca';

  campoAutocomplete: any = '';
  campoFocado: boolean = false;
  clickFora: boolean = false;
  hoverLi: boolean = false;
  itemAutoComplete: any = 0;
  apagado: boolean = false;
  selecionado: boolean = false;


  constructor(
    private http: HttpClient,
    private global: Global,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if (this.filtroListar) {

      this.results$ = this.queryField.valueChanges.pipe(
        map(value => value.trim()),
        filter(value => value.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(value => this.http.get(this.skip && this.take ? `${this.global.apiUrl}${this.path}?${this.params}=${this.campoAutocomplete}&skip=${this.skip}&take=${this.take}` : `${this.global.apiUrl}${this.path}?${this.params}=${this.campoAutocomplete}`,
          {
            //params: {
            //  Busca: value
            //},
            headers: new HttpHeaders()
              .set("Content-Type", "application/json")
              .set("Authorization", `Bearer ${this.authService.authToken.value}`)
          })),
        tap((res: any) => this.resultBusca.emit(res)),
        map((res: any) => res = [...new Set(res['conteudo'])])
      );
    }
    else {

      this.results$ = this.queryField.valueChanges.pipe(
        map(value => value.trim()),
        filter(value => value.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(value => this.http.get(this.skip && this.take ? `${this.global.apiUrl}${this.path}?${this.params}=${this.campoAutocomplete}&skip=${this.skip}&take=${this.take}` : `${this.global.apiUrl}${this.path}?${this.params}=${this.campoAutocomplete}`,
          {
            //params: {
            //  Busca: value
            //},
            headers: new HttpHeaders()
              .set("Content-Type", "application/json")
              .set("Authorization", `Bearer ${this.authService.authToken.value}`)
          })),
        tap((res: any) => res),
        map((res: any) => res = [...new Set(res['conteudo'] ? res['conteudo'] : res)])
      );
    }
  }

  textoDigitado = (texto) => {
    this.hoverLi = false
    this.clickFora = false;
    this.textoBuscado.emit(texto);
  }

  onSelect(item) {
    this.campoFocado = false;
    this.itemAutoComplete = item.id;
    if (item.nome)
      this.campoAutocomplete = item.nome;
    else if (item.principio_ativo)
      this.campoAutocomplete = `${item.codigo_catmat} - ${item.principio_ativo}`;
    else
      this.campoAutocomplete = item;
    this.selecionado = true;
    if (this.cleanField) {
      this.campoAutocomplete = ''
    }
    this.itemSelecionado.emit(item);
  }

  search(term: string) {
    if (term == "" && this.filtroListar)
      this.resultBusca.emit();

    if (this.apagado) {
      this.apagado = false;
      return;
    }
    if (term.length < this.campoAutocomplete.length && this.selecionado) {
      this.itemAutoComplete = 0;
      this.campoAutocomplete = '';
      this.apagado = true;
      this.selecionado = false;
      $('#campoAutocomplete1').val("");
      $('#campoAutocomplete2').val("");
      this.itemSelecionado.emit();
      return;
    }
  }


}
