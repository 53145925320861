import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../service/apiServices';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.css']
})
export class PreparationComponent implements OnInit {

  preparo: any = {};
  uid: any = 0;
  loading: boolean = false;
  editando: boolean = false;

  busca: any = 'busca'
  listaSigtap: any = [];


  constructor(
    public service: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService
  ) { }

  async init() {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterPreparo(uid);
    } else {
      this.preparo.ativo = true;
      this.preparo.descricao = "";
      this.preparo.Sigtaps = [];
    }
  }
  ngOnInit() {
    this.init();
  }

  salvarPreparo = () => {
    if (!this.validaPostPreparo())
      return;

    this.listaSigtap.forEach((item) => {
      this.preparo.Sigtaps.push({ "sigtap_id": item.id });
    });
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`Preparo`, this.preparo).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Preparo salvo com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  editarPreparo = () => {
    if (!this.validaPostPreparo())
      return;
    //this.listaSigtap.forEach((item) => {
    //  this.preparo.Sigtaps.push({ "sigtap_id": item.id });
    //});
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`Preparo/${this.preparo.id}`, this.preparo).then(res => {
      this.loading = false;
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  goBack() {
    this.location.back();
  }

  validaPostPreparo = () => {
    if (this.preparo.descricao == null || this.preparo.descricao == "") {
      Swal.fire('Erro', "Favor preencher a descrição do preparo", 'error');
      return false;
    }
    return true;
  }

  obterPreparo = (id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Preparo/${id}`).then(res => {
      this.preparo = res;
      this.listaSigtap = this.preparo.Sigtaps;
      //this.preparo.Sigtaps.forEach((item) => {
      //  this.listaSigtap.push(item.SigTap);
      //});
      this.editando = true;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  changeStatus = () => {
    this.preparo.ativo = !this.preparo.ativo;
  }

  adicionarSigtap(item) {
    if (!item) return;
    if (this.listaSigtap.length == 0) {
      if (!this.editando)
        this.listaSigtap.push(item);
      else
        this.salvarPreparoSigtap(item);
    }
    else {
      var repetido = false;
      if (!this.editando)
        this.listaSigtap.forEach((s) => {
          if (s.id == item.id)
            repetido = true;
        });
      else
        this.listaSigtap.forEach((s) => {
          if (s.SigTap.id == item.id)
            repetido = true;
        });
      if (!repetido) {
        if (!this.editando)
          this.listaSigtap.push(item);
        else
          this.salvarPreparoSigtap(item);
      }

    }

  }

  salvarPreparoSigtap = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`PreparoSigtap`, { "preparo_id": this.uid, "sigtap_id": item.id })
      .then(res => {
        setTimeout(() => {
          Swal.close();
          this.listaSigtap.push({ "SigTap": item, "id": res["id"] });
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      })
  }


  removerSigtap(i) {
    this.listaSigtap.splice(i, 1);
  }

  confirmacaoExcluirSigtap = (id, index) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja excluir esse item?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.Delete(`PreparoSigtap/${id}`).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.removerSigtap(index)
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

}
