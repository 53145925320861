import { Component, OnInit, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../service/apiServices';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Global } from '../../global';
import { Location } from '@angular/common';
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-solicitacao-exame',
  templateUrl: './exam-request.component.html',
  styleUrls: ['./exam-request.component.css']
})
export class ExamRequestComponent implements OnInit {

  dadosExame: any = {};

  @Input() infoExame: any = {};
  @Output() limparImpressos = new EventEmitter();

  dataHoje: any = ''
  dataInicio: any = ''
  apac: boolean = false

  global_: any
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.global_ = this.global
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.apac = false;
    if(this.infoExame.id) {
      var today = new Date();
      if (this.infoExame.apac == true){
        this.apac = true;
      }
      this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
      this.dataInicio = this.global_.dateFormat(today)
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.service.Get(`Relatorios/ImpressoSolicitacaoExame?Id=${this.infoExame.id}`).then(
        result => {
          this.dadosExame = result
         
          setTimeout(() =>{
            Swal.close()
            $("#modalHistoricoRegulacao").addClass("no-print");
            if (this.dadosExame.listaLogsRegulacaoExame){
              $("#solicitacao_exame_historico").addClass("only-print");
            }
            else {
              $("#solicitacao_exame").addClass("only-print");
            }
          },500)
          setTimeout(() =>{
            window.print()
          },750)
          setTimeout(() =>{
            $("#modalHistoricoRegulacao").removeClass("no-print");
            if (this.dadosExame.listaLogsRegulacaoExame){
              $("#solicitacao_exame_historico").removeClass("only-print");
            }
            else {
              $("#solicitacao_exame").removeClass("only-print");
            }
            this.dadosExame = {}
            this.infoExame = {}
            this.limparImpressos.emit()
          },1000)
        },
        error => {
          console.log(error)
          if (typeof error == 'string'){
            Swal.fire('Erro', `${error}`, 'error');
            return
          }
          if (error.status == 401 || error.error.status == 401){
            Swal.close()
            this.authService.logoutUser()
            return
          }
          Swal.fire('Erro', error.error.messagem, 'error')
        }
      )    
    }
  }

  outro() {
    if (this.dadosExame.id){
      if (this.dadosExame.Cidadao.estado_id){
        this.obterEstadoEndereco(this.dadosExame.Cidadao.estado_id).then(
          result => {
            var estado: any = result;
            this.dadosExame.Cidadao.estado = estado.nome
            this.obterMunicipioEndereco(estado.id).then(
              result => {
                var municipios: any = result
                for (let municipio of municipios){
                  if (municipio.id == this.dadosExame.Cidadao.municipio_id) {
                    this.dadosExame.Cidadao.municipio = municipio.nome
                    this.dadosExame.Cidadao.cod_ibge_municipio = municipio.co_ibge
                  }
                }
                if (this.dadosExame.estabelecimento_solicitante_id){
                  this.service.Get(`Estabelecimento/${this.dadosExame.estabelecimento_solicitante_id}`).then(
                    result => {
                      this.dadosExame.estabelecimento_solicitante_nome = result['nome']
                      this.dadosExame.estabelecimento_solicitante_cnes = result['cnes']
                      this.dadosExame.EstabelecimentoSolicitante = result;
                      if (this.dadosExame.profissional_solicitante_id) {
                        this.service.Get(`Profissional/${this.dadosExame.profissional_solicitante_id}`).then(
                          result => {
                            this.dadosExame.profissional_solicitante_nome = result['nome']
                            this.dadosExame.profissional_solicitante_cns = result['cns']
                            this.dadosExame.ProfissionalSolicitante = result;
                            this.obterEstadoEndereco(this.dadosExame.ProfissionalSolicitante.conselho_estado_id).then(
                              result => {
                                this.dadosExame.ProfissionalSolicitante.conselho_estado_nome = result['nome']
                                this.dadosExame.ProfissionalSolicitante.conselho_estado_sigla = result['sigla']
                                this.obterEstadoEndereco(this.dadosExame.EstabelecimentoSolicitante.estado_id).then(
                                  result => {
                                    this.dadosExame.EstabelecimentoSolicitante.estado_nome = result['nome']
                                    this.dadosExame.EstabelecimentoSolicitante.estado_sigla = result['sigla']
                                    this.obterMunicipioEndereco(this.dadosExame.EstabelecimentoSolicitante.estado_id).then(
                                      result => {
                                        var municipios: any = result
                                        for (let municipio of municipios){
                                          if (municipio.id == this.dadosExame.EstabelecimentoSolicitante.municipio_id) {
                                            this.dadosExame.EstabelecimentoSolicitante.municipio = municipio.nome
                                            this.dadosExame.EstabelecimentoSolicitante.cod_ibge_municipio = municipio.co_ibge
                                            setTimeout(() =>{
                                              Swal.close()
                                              $("#modalHistoricoRegulacao").addClass("no-print");
                                              if (this.dadosExame.listaLogsRegulacaoExame){
                                                $("#solicitacao_exame_historico").addClass("only-print");
                                              }
                                              else {
                                                $("#solicitacao_exame").addClass("only-print");
                                              }
                                            },500)
                                            setTimeout(() =>{
                                              window.print()
                                            },750)
                                            setTimeout(() =>{
                                              $("#modalHistoricoRegulacao").removeClass("no-print");
                                              if (this.dadosExame.listaLogsRegulacaoExame){
                                                $("#solicitacao_exame_historico").removeClass("only-print");
                                              }
                                              else {
                                                $("#solicitacao_exame").removeClass("only-print");
                                              }
                                              this.dadosExame = {}
                                              this.infoExame = {}
                                              this.limparImpressos.emit()
                                            },1000)
                                          }
                                        }
                                      },
                                      error => {
                                        if (error.status == 401 || error.error.status == 401){
                                          Swal.close()
                                          this.authService.logoutUser()
                                        }
                                        Swal.fire('Erro', error, 'error')
                                      }
                                    )
                                  },
                                  error => {
                                    if (error.status == 401 || error.error.status == 401){
                                      Swal.close()
                                      this.authService.logoutUser()
                                    }
                                    Swal.fire('Erro', error, 'error')
                                  }
                                )
                              },
                              error => {
                                if (error.status == 401 || error.error.status == 401){
                                  Swal.close()
                                  this.authService.logoutUser()
                                }
                                Swal.fire('Erro', error, 'error')
                              }
                            )                            
                          },
                          error => {     
                            if (error.status == 401 || error.error.status == 401){
                              Swal.close()
                              this.authService.logoutUser()
                            }                       
                            Swal.fire('Erro', error, 'error')
                          }
                        )
                      }
                    },
                    error => {
                      if (error.status == 401 || error.error.status == 401){
                        Swal.close()
                        this.authService.logoutUser()
                      }
                      Swal.fire('Erro', error, 'error')
                    }
                  )
                }
              },
              error => {
                if (error.status == 401 || error.error.status == 401){
                  Swal.close()
                  this.authService.logoutUser()
                }
                Swal.fire('Erro', error, 'error')
              }
            )
          },
          error => {
            if (error.status == 401 || error.error.status == 401){
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.fire('Erro', error, 'error')
          }
        )
      }
      
    }
  }

  obterEstadoEndereco = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado/${id}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject('Estado não encontrado')
        }
        )
    })
  }
  
  obterMunicipioEndereco = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estado/${id}/Municipios`).then(
        result => {
          resolve(result)
        },
        error => {
          reject('Município não encontrado')
        }
      )
    })
  }
}
