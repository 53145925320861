import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { Global } from '../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

declare var Simditor: any;

@Component({
  selector: 'app-psicologia',
  templateUrl: './psicologia.component.html',
  styleUrls: ['./psicologia.component.css']
})
export class PsicologiaComponent implements OnInit {
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() listaPsicologia: any = [];
  @Input() atendimento: any = {};

  psicologiaObj: any = {}
  visualizar_prontuario: any = false;
  usuario:any;
  uid:any;

  /// editor de texto
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]
  constructor(public service: ApiService, private authService: AuthService, private route: ActivatedRoute, private global: Global,) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
      /// configuração do editor de texto
      Simditor.locale = 'en-US';
  }

  ngOnInit() {
    setTimeout(() => {
      var that = this;
      var descricao = new Simditor({
        textarea: $('#texto_psicologia'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
      });
    }, 500);
  }



  obterListaPsicologia = () => {
    if (!this.prontuario) {
      return;
    }
    this.service.Get(`AtendimentoRestrito?cidadao_id=${this.atendimento.cidadao_id}`).then(
      result => {
        this.listaPsicologia = result
      },
      error => {
        if (error.codigo == 'NotFound') {
          Swal.fire('Atenção', error.error.messagem, 'warning')
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  salvarPsicologia = () => {

    if(!$('#texto_psicologia').val()){
      return;
    }
    Swal.fire('Favor aguarde')
    Swal.showLoading();

    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    var Obj =  {
      "texto": $('#texto_psicologia').val(),
      "profissional_id": this.atendimento.profissional_id,
      "cidadao_id": this.atendimento.cidadao_id
    };

    this.service.Post(`Atendimento/${this.uid}/AtendimentoRestrito`, Obj).then(
      result => {
        Swal.close();
        $('#texto_psicologia').val("");
        this.psicologiaObj = {};
        this.obterListaPsicologia();
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

}
