import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from "jquery";
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import Swal from 'sweetalert2';
declare var Simditor: any;
@Component({
  selector: 'app-referral-to-specialist',
  templateUrl: './referral-to-specialist.component.html',
  styleUrls: ['./referral-to-specialist.component.css']
})
export class ReferralToSpecialistComponent implements OnInit {
  @Input() atendimento: any = {};
  @Input() listaEncaminhamentos: any = [];
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() listaEvolucao: any = [];
  seeList: boolean = true;
  visualizar_prontuario: any = false;

  constructor(public service: ApiService, private authService: AuthService,) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  goBack = () => {
    this.seeList = true;

    this.obterlistaEncaminhamentos();
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }

  obterlistaEncaminhamentos = () => {
    if (!this.atendimento) {
      return;
    }

    this.service.Get(`Atendimento/${this.atendimento.id}/Encaminhamento`).then(
      result => {
        this.listaEncaminhamentos = result;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }
}
