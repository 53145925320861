import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { Global } from '../../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-exams-attendance-registration',
  templateUrl: './exams-registration.component.html',
  styleUrls: ['./exams-registration.component.css']
})
export class ExamsRegistrationComponent implements OnInit {

  solicitacao: any = {}
  solicitacao_exames: any = []

  //listaTipoPrioridade: any = [
  //  {
  //    id: 1,
  //    nome: `Gestante`
  //  },
  //  {
  //    id: 2,
  //    nome: `Idoso`
  //  },
  //  {
  //    id: 3,
  //    nome: `Necessidades Especiais`
  //  },
  //  {
  //    id: 4,
  //    nome: `Outros`
  //  },
  //];

  listaTipoPrioridade: any = [
    {
      id: 1,
      nome: `Eletivo`
    },
    {
      id: 2,
      nome: `Prioridade`
    },
    {
      id: 3,
      nome: `Urgência`
    },
    {
      id: 4,
      nome: `Emergência`
    },
  ];

  usuario: any = {};
  presenca: any;
  cidadao_id: any;
  atendimento_id: any;
  uid: any;
  erro_salvar: any = 0;

  @Output() fecharSolicitacaoExames = new EventEmitter<boolean>();
  @Input() grupoExames: any = [];
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      this.obterCidadao(uid).then(
        result => {
          this.cidadao_id = result['cidadao_id']
          this.atendimento_id = result['id']
        },
        error => {
          Swal.fire('Erro', error.error.messagem, 'error')
        }
      )
    }
  }

  obterCidadao = (uid) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Atendimento/${uid}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  selecionarSigtap = (item: any) => {
    if (item === undefined) {
      this.solicitacao.sigtap = ''
      return
    }
    if (item['id']) {
      this.solicitacao.sigtap = item.id
    }
    this.solicitacao.procedimento = item.nome
    this.solicitacao.grupo_sigtap = item.sub_grupo

    this.solicitacao_exames.push(item)
  }

  removerSolicitacao = (item: any) => {
    this.solicitacao_exames.forEach(exame => {
      if (exame.codigo === item.codigo) {
        var index = this.solicitacao_exames.indexOf(exame)
        this.solicitacao_exames.splice(index, 1)
      }
    })
  }

  finalizarSolicitacao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    var index = 0

    this.salvarSolicitacoes(index)
  }

  salvarSolicitacoes = (index: any) => {
    if (index == 0) {
      this.erro_salvar = 0
    }
    if (index == this.solicitacao_exames.length) {
      if (this.erro_salvar == 0) {
        Swal.fire('Sucesso', 'As solicitações foram salvas', 'success').then(
          result => {
            this.fecharSolicitacaoExames.emit(true);
          }
        )
      }
      if (this.erro_salvar > 0 && this.erro_salvar < this.solicitacao_exames.length - 1) {
        Swal.fire('Atenção', 'Algumas solicitações não foram salvas', 'warning').then(
          result => {
            this.fecharSolicitacaoExames.emit(true);
          }
        )
      }
      if (this.erro_salvar > 0 && this.erro_salvar == this.solicitacao_exames.length - 1) {
        Swal.fire('Erro', 'As solicitações não foram salvas', 'error').then(
          result => {
            this.fecharSolicitacaoExames.emit(true);
          }
        )
      }
    }

    else {
      // var Obj = {
      //   presenca_id: this.presenca.id,
      //   tipo_procedimento: 'EXAME',
      //   atendimento_id: this.atendimento_id,
      //   sigtap_id: this.solicitacao_exames[index].id,
      //   complemento: this.solicitacao_exames[index].complemento,
      //   tipo_prioridade: this.solicitacao_exames[index].tipo_prioridade
      // }
      var procedimentos = [];
      this.solicitacao_exames.forEach((item) => {
        procedimentos.push({
          "sigtap_id": item.id,
          "complemento": item.complemento,
          "criticidade": item.tipo_prioridade
        })
      })
      var obj =
      {
        "tipo_solicitacao": "EXAME",
        "presenca_id": this.presenca.id,
        "procedimentos": procedimentos
      }

      this.service.Post(`Atendimento/${this.uid}/Solicitacao`, obj).then(
        result => {
          setTimeout(() => {
            Swal.close()
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Salvo com sucesso!',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.fecharSolicitacaoExames.emit(true);
                }
              }
            );
          }, 1000);
        },
        error => {
          if (error.status === 400) {
            Swal.fire('Erro', error.error.messagem, 'error')
          }
          this.erro_salvar += 1;
          this.salvarSolicitacoes(index + 1)
        }
      )
    }

  }

  adicionarGrupoExame(item) {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`GrupoSigtap/${item.id}/Sigtap`).then(
      result => {
        Swal.close()
        if (result) {
          this.solicitacao_exames = [];
          var obj: any = result;
          obj.forEach(element => {
            this.solicitacao_exames.push(element)
          });
        }
      }
    )
  }

}
