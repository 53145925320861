import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var Simditor: any;
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';

@Component({
  selector: 'app-certificate-registration',
  templateUrl: './certificate-registration.component.html',
  styleUrls: ['./certificate-registration.component.css']
})
export class CertificateRegistrationComponent implements OnInit {

  atestado: any = {};
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Output() goBack = new EventEmitter();
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();

  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  constructor(private authService: AuthService,
    public service: ApiService) {
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    this.atestado.tipo = 1;
    this.atestado.tipo_atestado = "LIVRE";
    var that = this;
    setTimeout(() => {
      var descricao = new Simditor({
        textarea: $('#descricaoAtestadoAba'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
      });
    }, 1000);

  }

  salvarAtestado = () => {
    if (!this.validaPostAtestado())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    var atestadoLivre = {
      "presenca_id": this.presenca.id,
      "tipo_atestado": this.atestado.tipo_atestado,
      "texto": $('#descricaoAtestadoAba').val()
    }
    var atestadoPadrao = {
      "presenca_id": this.presenca.id,
      "tipo_atestado": this.atestado.tipo_atestado,
      "dias": this.atestado.dias,
      "imprimir_cid": this.atestado.mostrar_cid,
      "cid_codigo": this.atestado.cid_codigo
    }

    if (!this.atestado.mostrar_cid) {
      delete atestadoPadrao.cid_codigo;
    }
    var atestado = {};
    if (this.atestado.tipo == 1) {
      atestado = atestadoLivre;
    }
    else {
      atestado = atestadoPadrao;
    }
    this.service.Post(`Prontuario/${this.prontuario.id}/ProntuarioAtestado`, atestado).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Atestado salvo com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.voltar();
              this.retornoPost.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostAtestado = () => {
    if (this.prontuario.id == null) {
      Swal.fire('Erro', "Prontuario não encontrado.", 'error');
      return false;
    }
    if (this.presenca.id == null) {
      Swal.fire('Erro', "Presença não encontrado.", 'error');
      return false;
    }
    var texto = $('#descricaoAtestadoAba').val();
    if ((texto == null || texto == "") && this.atestado.tipo == 1) {
      Swal.fire('Erro', "Favor informar um texto de prescrição.", 'error');
      return false;
    }
    if ((this.atestado.dias == null || this.atestado.dias == "" || this.atestado.dias == 0) && this.atestado.tipo == 2) {
      Swal.fire('Erro', "Favor informar a quantidade de dias de atestado.", 'error');
      return false;
    }

    if (this.atestado.tipo == 2) {
      if (this.atestado.mostrar_cid && !this.atestado.cid_codigo) {
        Swal.fire('Erro', "Favor informar um cid", 'error');
        return false;
      }
    }

    return true;
  }

  voltar = () => {
    this.atestado = {};
    $('#descricaoAtestadoAba').val("");
    this.goBack.emit();
  }

  selecionarTipoAtestado(tipo) {
    this.atestado.tipo = tipo;
    if (this.atestado.tipo == 1) {
      this.atestado.tipo_atestado = "LIVRE";
      var that = this;
      setTimeout(() => {
        var descricao = new Simditor({
          textarea: $('#descricaoAtestadoAba'),
          upload: false,
          tabIndent: true,
          toolbar: that.toolbar,
          toolbarFloat: true
        });
      }, 1000);
      $('#descricaoAtestadoAba').val("");
    } else {
      this.atestado.mostrar_cid = true;
      this.atestado.dias = "";
      this.atestado.cid_codigo = "";
      this.atestado.tipo_atestado = "PADRAO";
    }
  }


  adicionarCid(item: any) {
    if (item) {
      this.atestado.cid_codigo = item.codigo;
    }
  }
}
