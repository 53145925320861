import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
import Swal from 'sweetalert2';
declare var jquery: any;
declare var $: any;
declare var document: any;
declare var simpleDatatables: any;
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-list-profile',
  templateUrl: './list-profile.component.html',
  styleUrls: ['./list-profile.component.css']
})
export class ListProfileComponent implements OnInit {

  listProfiles: any = [];
  filtro: string = "";

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getListProfiles();
  }

  getListProfiles = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Perfil`).then(
      result => {
        this.listProfiles = result;
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  emitErro = (error) => {
    if (typeof error == 'string'){
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401){
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse perfil?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Perfil/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.getListProfiles();
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.listProfiles.length === 0) {
      return this.listProfiles;
    }
    return this.listProfiles.filter(p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

}
