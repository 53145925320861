import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-arrival-time-setting',
  templateUrl: './arrival-time-setting.component.html',
  styleUrls: ['./arrival-time-setting.component.css']
})
export class ArrivalTimeSettingComponent implements OnInit {

  config: any = {};
  uid: any = 0;
  loading: boolean = false;
  editando: boolean = false;

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService) { }

  async ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterConfiguracao(uid);
    }
  }

  goBack() {
    this.location.back();
  }

  obterConfiguracao = (id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`ConfiguracaoHorarioChegada/${id}`).then(res => {
      this.config = res;
      this.editando = true;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  salvarConfigAgendamento = () => {
    if (!this.validaPostConfigAgendamento())
      return;
    this.config.hora_inicial = this.config.hora_inicial[0] + this.config.hora_inicial[1] + ":" + this.config.hora_inicial[2] + this.config.hora_inicial[3];
    this.config.hora_final = this.config.hora_final[0] + this.config.hora_final[1] + ":" + this.config.hora_final[2] + this.config.hora_final[3];
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`ConfiguracaoHorarioChegada`, this.config).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Configuração de horário de chegada salvo com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostConfigAgendamento = () => {
    if (this.config.hora_inicial == null || this.config.hora_inicial.length != 4) {
      Swal.fire('Erro', "Favor informar uma hora de inicio válida", 'error');
      return false;
    }
    if (this.config.hora_final == null || this.config.hora_final.length != 4) {
      Swal.fire('Erro', "Favor informar uma hora fim válida", 'error');
      return false;
    }
    if (this.config.nome == null || this.config.nome == "") {
      Swal.fire('Erro', "Favor preencher a descrição", 'error');
      return false;
    }

    var hi = Number(this.config.hora_inicial[0] + this.config.hora_inicial[1]);
    var mi = Number(this.config.hora_inicial[2] + this.config.hora_inicial[3]);

    if (hi > 23 || mi > 59) {
      Swal.fire('Erro', "Favor informar uma hora de inicio válida", 'error');
      return false;
    }

    var hf = Number(this.config.hora_final[0] + this.config.hora_final[1]);
    var mf = Number(this.config.hora_final[2] + this.config.hora_final[3]);

    if (hf > 23 || mf > 59) {
      Swal.fire('Erro', "Favor informar uma hora final válida", 'error');
      return false;
    }

    return true;
  }

  editarConfigAgendamento = () => {
    if (!this.validaPostConfigAgendamento())
      return;
    this.config.hora_inicial = this.config.hora_inicial[0] + this.config.hora_inicial[1] + ":" + this.config.hora_inicial[2] + this.config.hora_inicial[3];
    this.config.hora_final = this.config.hora_final[0] + this.config.hora_final[1] + ":" + this.config.hora_final[2] + this.config.hora_final[3];
    //this.loading = true;
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`ConfiguracaoHorarioChegada/${this.config.id}`, this.config).then(res => {
      this.loading = false;
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }


  FormataHora = (inicioFim) => {
    if (inicioFim == 1) {
      if (this.config.hora_inicial != null) {
        if (this.config.hora_inicial.length == 1)
          this.config.hora_inicial = `0${this.config.hora_inicial}00`;
        if (this.config.hora_inicial.length == 2)
          this.config.hora_inicial = `${this.config.hora_inicial}00`;
        if (this.config.hora_inicial.length == 3)
          this.config.hora_inicial = `${this.config.hora_inicial}0`;
      }
    } else if (inicioFim == 2) {
      if (this.config.hora_final != null) {
        if (this.config.hora_final.length == 1)
          this.config.hora_final = `0${this.config.hora_final}00`;
        if (this.config.hora_final.length == 2)
          this.config.hora_final = `${this.config.hora_final}00`;
        if (this.config.hora_final.length == 3)
          this.config.hora_final = `${this.config.hora_final}0`;
      }
    }
  }

}
