import { Component, OnInit } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from '../../../global';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exams-setting',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsSettingComponent implements OnInit {

  exames: any = [];
  filtro: string = "";
  pagina: any = 1;
  totalPorPagina: any = 100;
  totalPagina: any = 1;

  params: string = `Skip=${this.pagina}&Take=10&Busca`;

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private global: Global,
    private authService: AuthService
  ) { }


  ngOnInit() {
    this.init();
  }

  init = async () => {
    this.obterListaExames();
  }

  obterListaExames = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Sigtap?pagina=${this.pagina}&quantidade=${this.totalPorPagina}`).then(res => {
      this.exames = res['conteudo'];
      this.totalPagina = res['total_paginas'];
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  resultBuscaExame = (res) => {
    if (res != null) {
      this.exames = res;
      if (this.exames.length > 0) {
        this.totalPagina = Math.ceil(res.length / this.totalPorPagina)
      }
    }
    else {
      this.pagina = 1;
      this.service.Get(`Sigtap?pagina=${this.pagina}&quantidade=${this.totalPorPagina}`).then(res => {
        this.exames = res['conteudo'];
        this.totalPagina = res['total_paginas'];
        Swal.close();
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close();
        Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
      });
    }

  }

  //filtroLista() {
  //  if (this.exames.length === 0) {
  //    return this.exames;
  //  }
  //  return this.exames.filter(
  //    c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()) ||
  //      (c.cpf != null ? c.cpf.replace(".", "").replace(".", "").replace("-", "").includes(this.filtro.replace(".", "").replace(".", "").replace("-", "")) :
  //        c.cns != null ? c.cns.replace(" ", "").replace(" ", "").replace(" ", "").includes(this.filtro.replace(" ", "").replace(" ", "").replace(" ", "")) : '')
  //  );
  //}

  //resultBuscaExame = (result) => {
  //  if (result != null) {
  //    this.exames = result['conteudo'];
  //    if (this.exames.length > 0) {
  //      this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
  //    }
  //  }
  //  else {
  //    this.pagina = 1;
  //    this.service.Get(`Exame?Skip=${this.pagina}&Take=${this.totalPorPagina}`).then(res => {
  //      this.exames = res['conteudo'];
  //      if (this.exames.length > 0) {
  //        this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
  //      }
  //      Swal.close();
  //      this.loading = false;
  //    }).catch(res => {
  //      if (res.status == 401 || res.error.status == 401) {
  //        Swal.close()
  //        this.authService.logoutUser()
  //      }
  //      Swal.close();
  //      this.loading = false;
  //      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
  //    });
  //  }

  //}

  voltarPagina = () => {
    this.pagina = this.pagina - 1;
    this.obterListaExames()
  }

}
