import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {

  estoque: any = {};
  estabelecimentos: any = [];
  tiposEstoque: any = [];
  farmaceuticoLista: any = [];
  uid: any = 0;
  editando: boolean = false;
  fileToUpload: File | null = null;

  constructor(
    public service: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService
  ) { }

  async init() {
    this.obterEstabelecimentos();
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterEstoque(uid);
    } else {
      //this.estoque.ativo = true;
      this.estoque.nome = "";
      this.estoque.estabelecimento_id = 0;
    }
  }
  ngOnInit() {
    this.init();
  }

  goBack() {
    this.location.back();
  }

  obterEstabelecimentos = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Estabelecimento`).then(res => {
      this.estabelecimentos = res;
      this.obterTiposEstoque();
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterEstoque = (id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Estoque/${id}`).then(res => {
      this.estoque = res;
      this.editando = true;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterTiposEstoque = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`TipoEstoque`).then(res => {
      this.tiposEstoque = res;
      if (!this.editando)
        this.estoque.tipo_estoque_id = 0;
      this.obterFarmaceuticoLista();
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterFarmaceuticoLista = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Profissional?ConselhoSigla=CRF`).then(res => {
      this.farmaceuticoLista = res;
      if (!this.editando)
        this.estoque.profissional_responsavel_id = 0;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  salvarEstoque = () => {
    if (!this.validaPostEstoque())
      return;
    if (this.fileToUpload != null)
      this.enviarArquivo();
    else
      this.postEstoque();

  }

  postEstoque() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`Estoque`, this.estoque).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Estoque salvo com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  selecionarArquivo(event) {
    this.fileToUpload = null;
    let fileList: FileList = event.target.files;
    if (fileList.length == 0)
      return;
    this.fileToUpload = fileList.item(0);
  }

  enviarArquivo = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.postFile(`Arquivo/Upload`, this.fileToUpload).then(
      result => {
        Swal.close();
        if (result) {
          var regex = /^"|"$/g;
          var nomeMidia = result["_body"].replace(regex, "");
          this.estoque.logotipo = nomeMidia;
          if (this.editando)
            this.putEstoque();
          else
            this.postEstoque();
        }
      }
    ).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  editarEstoque = () => {
    if (!this.validaPostEstoque())
      return;
    if (this.fileToUpload != null)
      this.enviarArquivo();
    else
      this.putEstoque();
  }

  putEstoque() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`Estoque/${this.estoque.id}`, this.estoque).then(res => {
      this.editando = false;
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostEstoque = () => {
    if (this.estoque.nome == null || this.estoque.nome == "") {
      Swal.fire('Erro', "Favor informar o nome do estoque", 'error');
      return false;
    }
    if (this.estoque.estabelecimento_id == null || this.estoque.estabelecimento_id == 0) {
      Swal.fire('Erro', "Favor informar o estabelecimento do estoque", 'error');
      return false;
    }
    if (this.estoque.tipo_estoque_id == null || this.estoque.tipo_estoque_id == 0) {
      Swal.fire('Erro', "Favor informar o tipo do estoque", 'error');
      return false;
    }
    if (this.estoque.profissional_responsavel_id == null || this.estoque.profissional_responsavel_id == 0) {
      Swal.fire('Erro', "Favor informar o farmaceutico responsável", 'error');
      return false;
    }
    return true;
  }

  changeStatus = () => {
    this.estoque.ativo = !this.estoque.ativo;
  }


}
