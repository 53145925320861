import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-mudar-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.css']
})
export class ChangeStatusComponent implements OnInit {

  @Input() listaRegulacoes: any = []
  @Input() path: any = ''
  @Output() fechaModal = new EventEmitter();

  status: any = [];
  modificaStatus: boolean = true;
  erro: any = []

  constructor(
    public service: ApiService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.modificaStatus = true;
    if (this.listaRegulacoes.length > 0) {
      this.defineStatus()
    }
  }

  fecharModal = () => {
    this.fechaModal.emit()
  }

  defineStatus = () => {
    if (this.listaRegulacoes.every((val,i,arr) => val.status === arr[0].status)){
      if (this.listaRegulacoes[0].status == 'AUTORIZADO'){
        this.status = 'PENDENTE'
        return
      }
      if (this.listaRegulacoes[0].status == 'AGENDADO'){
        this.status = 'AUTORIZADO'
        return
      }
      this.modificaStatus = false;
    }
  }

  alterarStatus = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.enviarStatus(0)
  }

  enviarStatus = (i) => {
    if (i == this.listaRegulacoes.length) {
      if (this.erro.length > 0 && this.erro.length == this.listaRegulacoes.length) {
        Swal.fire('Erro', 'As alterações não puderam ser executadas.', 'error').then(
          result => {
            this.fecharModal()
            return
          }
        )
      }
      else if (this.erro.length > 0 && this.erro.length != this.listaRegulacoes.length) {
        Swal.fire('Atenção', 'Ocorreram alguns erros na execução', 'warning').then(
          result => {
            this.fecharModal()
            return
          }
        )
      }
      else {
        Swal.fire('Sucesso', 'Alterações executadas com sucesso', 'success').then(
          result => {
            this.fecharModal()
            return
          }
        )
      }
    }
    if (this.listaRegulacoes[i]){
      var obj = {
        status: this.status
      }
      // this.service.Patch_body(`${this.path}${this.listaRegulacoes[i].id}`,{status: this.listaRegulacoes[i].status}).then(
      if (this.listaRegulacoes[i].id){
        this.service.Patch_body(`${this.path}${this.listaRegulacoes[i].id}`,obj).then(
          result => {
            this.enviarStatus(i+1)
          },
          error => {
            this.erro.push(error.error.messagem)
            this.enviarStatus(i+1)
          }
        )
      }
      else if (this.listaRegulacoes[i].idRegulacao){
        this.service.Patch_body(`${this.path}${this.listaRegulacoes[i].idRegulacao}`,obj).then(
          result => {
            this.enviarStatus(i+1)
          },
          error => {
            this.erro.push(error.error.messagem)
            this.enviarStatus(i+1)
          }
        )
      }
    }
  }

}
