import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: any = {};
  loading: any = false;
  desativar_botao: any = false;
  etapaLogin: any = 0;
  dadosL;
  listaLotacao: any = [];
  listaBlocos: any = [];
  listaClinicas: any = [];
  listaSalas: any = [];
  presenca: any = {};
  estabelecimentoSelecionado: any = "";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private alerts: NgxAlertsService,
    public apiService: ApiService,
    public global: Global,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }


  valida(login) {
    if (login.login == null || login.login == '') {
      Swal.fire('Erro', `Favor inserir um login`, 'error');
      this.loading = false;
      this.desativar_botao = false;
      return false;
    }
    if (login.senha_ == null || login.senha_ == '') {
      Swal.fire('Erro', `Favor inserir uma senha`, 'error');
      this.loading = false;
      this.desativar_botao = false;
      return false;
    }
    return true;
  }

  submit(login) {
    if (this.loading) {
      return;
    }
    this.desativar_botao = true;
    this.loading = true;
    if (this.valida(login)) {
      var obj = Object.assign({}, login);
      obj.senha = this.global.MD5(login.senha_);
      delete obj.senha_;
      this.apiService.Post('Auth', obj).then((res) => {
        this.login = res
        if (this.login.type == 99) {
          this.authService.loginUser(this.login);
        } else {
          this.loading = false;
          this.etapaLogin = 1;
          this.authService.setTokenUser(this.login);
          this.presenca = {};
          this.presenca.lotacao_id = 0;
          this.presenca.bloco_id = 0;
          this.presenca.clinica_id = 0;
          this.presenca.sala_id = 0;

          this.obterListaLotacao(this.login.usuario_profissional_id);
        }
      }).catch((err) => {
        console.log(err)
        this.loading = false;
        this.desativar_botao = false;
        Swal.fire('Erro', `Erro ao tentar realizar Login`, 'error');
      });
    }
  }

  selecionarEstabelecimento(lotacao_id) {
    var estabelecimento = this.listaLotacao.filter(x => x.id == lotacao_id);
    if (estabelecimento.length > 0) {
      this.estabelecimentoSelecionado = estabelecimento[0].ocupacao_nome;
      this.obterListaBlocos(estabelecimento[0].estabelecimento_id);
      this.presenca.bloco_id = 0;
      this.presenca.clinica_id = 0;
      this.presenca.sala_id = 0;
    }
  }

  selecionarClinica(bloco_id) {
    this.obterListaClinicas(bloco_id);
    this.presenca.clinica_id = 0;
    this.presenca.sala_id = 0;

  }

  selecionarSalas(clinica_id) {
    this.obterListaSalas(clinica_id);
    this.presenca.sala_id = 0;
  }

  obterListaLotacao(usuario_profissional_id) {
    this.desativar_botao = true;
    this.loading = true;
    this.apiService.Get(`Profissional/${usuario_profissional_id}/Lotacao?ativo=true`).then((res) => {
      this.listaLotacao = res;
      this.loading = false;
    }).catch((err) => {
      console.log(err)
      this.loading = false;
      this.desativar_botao = false;
      Swal.fire('Erro', `Erro ao obter lotação`, 'error');
    });
  }

  obterListaBlocos(estabelecimento_id) {
    this.desativar_botao = true;
    this.loading = true;
    this.apiService.Get(`Estabelecimento/${estabelecimento_id}/Bloco`).then((res) => {
      this.listaBlocos = res;
      this.loading = false;
    }).catch((err) => {
      console.log(err)
      this.loading = false;
      this.desativar_botao = false;
      Swal.fire('Erro', `Erro ao obter blocos`, 'error');
    });
  }

  obterListaClinicas(bloco_id) {
    this.desativar_botao = true;
    this.loading = true;
    this.apiService.Get(`Bloco/${bloco_id}/ClinicaMedica?ativo=true`).then((res) => {
      this.listaClinicas = res;
      this.loading = false;
    }).catch((err) => {
      console.log(err)
      this.loading = false;
      this.desativar_botao = false;
      Swal.fire('Erro', `Erro ao obter clinicas`, 'error');
    });
  }

  selecionarClinicaMedica = (lotacao:any) => {
    let estabelecimento;
    this.listaLotacao.forEach(item => {
      if (item.id == lotacao){
        estabelecimento = item.estabelecimento_id
      }
    })


    this.desativar_botao = true;
    this.loading = true;
    this.apiService.Get(`Estabelecimento/${estabelecimento}/ClinicaMedica?ativo=true`).then(
      result => {
        this.listaClinicas = result;
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.desativar_botao = false;
        Swal.fire('Erro', `Erro ao obter clinicas`, 'error');
      }
    )
  }
  
  obterListaSalas(clinica_id) {
    this.desativar_botao = true;
    this.loading = true;
    this.apiService.Get(`ClinicaMedica/${clinica_id}/Sala?ativo=true`).then((res) => {
      this.listaSalas = res;
      this.loading = false;
    }).catch((err) => {
      console.log(err)
      this.loading = false;
      this.desativar_botao = false;
      Swal.fire('Erro', `Erro ao obter salas`, 'error');
    });
  }

  marcarPresenca() {
    if (this.loading) {
      return;
    }
    
    this.loading = true;
    var obj = {
      "usuario_id": this.login.usuario_id,
      "profissional_id": this.login.usuario_profissional_id,
      "lotacao_id": this.presenca.lotacao_id,
      "clinica_medica_id": this.presenca.clinica_id,
      "sala_id": this.presenca.sala_id
    }
    this.apiService.Post(`Presenca`, obj).then((res) => {
      localStorage.setItem('dadosPresencaUser', JSON.stringify(this.presenca));
      this.authService.setPresenca(res);
      this.authService.loginUser(this.login);
      this.loading = false;
    }).catch((err) => {
      console.log(err)
      this.loading = false;
      this.desativar_botao = false;
      Swal.fire('Erro', `Erro ao tentar realizar Login`, 'error');
    });
  }

  cancelarPresenca() {
    this.login = {};
    this.etapaLogin = 0;
  }
}
