import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../service/apiServices';
import { Location } from '@angular/common';
import { Global } from '../../global';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../service/auth/auth.service'

import * as $ from "jquery";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.authService.currentUser.subscribe((res) => {
      if (res) {
        
        this.obterMenus(res.usuario_id);
      }
    })
  }


  obterMenus = (usuario_id) => {
    this.service.Get(`Usuario/${usuario_id}/MenuLateral`).then((res) => {
      this.authService.saveMenus(res);
    }).catch((err) => {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

}
