import { Component, ElementRef, HostListener, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Global } from '../../global'
import * as moment from "moment";
import * as range from 'lodash.range'

export interface CalendarDate {
    mDate: moment.Moment;
    selected ? : boolean;
    today ? : boolean;
}

@Component({
    selector: 'app-calendario',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
    public currentDate: moment.Moment;
    public namesOfDays: any = [{ nome: 'DOM' }, { nome: 'SEG' }, { nome: 'TER' }, { nome: 'QUA' }, { nome: 'QUI' }, { nome: 'SEX' }, { nome: 'SÁB' }]
    public weeks: Array < CalendarDate[] > = [];

    public selectedDate;
    public show: boolean;

    dateList: any = [];
    diaSelecionados: any = '';

    @ViewChild('calendar', { static: true }) calendar;

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.show = false;
        }
    }

    @Output() enviaSelecaoDatas: EventEmitter<any> = new EventEmitter();

    constructor(
        private global: Global,
        private eRef: ElementRef
    ) {}

    ngOnInit() {
        moment.locale('pt-br')
        this.currentDate = moment();
        this.selectedDate = moment(this.currentDate).format('DD/MM/YYYY');
        this.generateCalendar();
    }

    private generateCalendar = () => {
        const dates = this.fillDates(this.currentDate);
        const weeks = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeks = weeks;
    }

    private fillDates(currentMoment: moment.Moment) {
        const firstOfMonth = moment(currentMoment).startOf('month').day();
        const lastOfMonth = moment(currentMoment).endOf('month').day();

        const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
        const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'days').add(7, 'days');
        const startCalendar = firstDayOfGrid.date();

        return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
            const newDate = moment(firstDayOfGrid).date(date);
            return {
                today: this.isToday(newDate),
                selected: this.isSelected(newDate),
                mDate: newDate,
            };
        });
    }

    private isToday(date: moment.Moment): boolean {
        return moment().isSame(moment(date), 'day');
    }

    private isSelected(date: moment.Moment): boolean {
        return this.selectedDate === moment(date).format('DD/MM/YYYY');
    }

    public prevMonth(): void {
        this.currentDate = moment(this.currentDate).subtract(1, 'months');
        this.generateCalendar();
    }

    public nextMonth(): void {
        this.currentDate = moment(this.currentDate).add(1, 'months');
        this.generateCalendar();
    }

    public isDisabledMonth(currentDate): boolean {
        const today = moment();
        return moment(currentDate).isAfter(today, 'months');
    }

    public isSelectedMonth(date: moment.Moment): boolean {
        const today = moment().subtract(1, 'days');
        return moment(date).isSame(this.currentDate, 'month') && moment(date).isSameOrAfter(today);
    }

    public selectDate(date: CalendarDate) {
        
        this.selectedDate = moment(date.mDate).format('DD/MM/YYYY');
        var compareDate = moment(date.mDate).format('YYYY-MM-DD');
        this.generateCalendar();
        //this.show = !this.show;
        this.addDays(compareDate)
    }

    addDays = (date) => {
        let verify = this.dateList.filter(x => x === date)
        if (verify.length > 0) {
            for (let index = 0; index < this.dateList.length; index++) {
                const element = this.dateList[index];
                if (element === date) {
                    this.dateList.splice(index, 1)
                }
            }
            var auxDate = [];
            this.dateList.map((date) => {
                auxDate.push(moment(date).format('DD/MM/YYYY'));
            })
            if (auxDate.length > 0) {
                this.diaSelecionados = auxDate.join(', ');
            } else {
                this.diaSelecionados = '';
            }
        } else {
            this.dateList.push(date);
            var auxDate = [];
            this.dateList.map((date) => {
                auxDate.push(moment(date).format('DD/MM/YYYY'));
            })
            this.diaSelecionados = auxDate.join(', ');
        }
        this.enviaSelecaoDatas.emit(this.dateList)
    }

    verifySelectedDay = (day) => {
        var diaComparacao = day.format('YYYY-MM-DD')
        let verify = this.dateList.filter(x => x === diaComparacao);
        if (verify.length > 0) {
            return true
        } else return false
    }

}