import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-registration',
  templateUrl: './dashboard-registration.component.html',
  styleUrls: ['./dashboard-registration.component.css']
})
export class DashBoardComponent implements OnInit {
  dashboard: any = {};
  presencaUser: any = [];
  rotaClinicas: any = ''
  uid: any = ''
  profissionaisSelecionados: any = [];

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('uuid');
    if (this.uid != null) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterDashboard(this.uid).then(
        result => {
          this.dashboard = result;
          this.profissionaisSelecionados = this.dashboard.usuarios;
          Swal.close();
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error')
        }
      )
    }
  }

  goBack() {
    this.location.back();
  }

  obterDashboard = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`DashboardExterno/${uid}`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }

  salvarDashboard = () => {
    if (this.profissionaisSelecionados.length == 0) {
      Swal.fire('Erro!', 'Favor inserir ao menos um usuário', 'error');
      return;
    }
    if (this.uid != null) {
      this.atualizarDashboards();
    } else {
      Swal.fire('Favor aguarde')
      Swal.showLoading();

      var usuarios_ = this.profissionaisSelecionados.map(item => {
        return { id: item.id }
      })
      var Obj = {
        "nome": this.dashboard.nome,
        "url": this.dashboard.url,
        "ativo": this.dashboard.ativo,
        "usuarios": usuarios_
      }

      this.service.Post(`DashboardExterno`, Obj).then(
        result => {
          Swal.fire('Sucesso!', 'Dashboard criado com sucesso', 'success').then(
            result => {
              this.location.back()
            }
          )
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error');
        }
      )
    }
  }

  atualizarDashboards = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    if (this.profissionaisSelecionados.length == 0) {
      Swal.fire('Erro!', 'Favor inserir ao menos um usuário', 'error');
      return;
    }

    var usuarios_ = this.profissionaisSelecionados.map(item => {
      return { id: item.id }
    })
    var Obj = {
      "nome": this.dashboard.nome,
      "url": this.dashboard.url,
      "ativo": this.dashboard.ativo,
      "usuarios": usuarios_
    }

    this.service.Put(`DashboardExterno/${this.uid}`, Obj).then(
      result => {
        Swal.fire('Sucesso!', 'Atualização realizada com sucesso', 'success').then(
          result => {
            this.location.back()
          }
        )
      },
      error => {
        Swal.fire('Erro!', error.error.messagem, 'error');
      }
    )
  }

  selecionarProfissional = (item) => {
    if (!item) {
      return
    }
    if (this.profissionaisSelecionados.length == 0) {
      this.profissionaisSelecionados.push(item)
    } else {
      var filt = this.profissionaisSelecionados.filter(x => x.id == item.id);
      if (filt.length > 0) {
        Swal.fire('Erro!', 'Usuário já adicionado', 'error');
        return
      } else {
        this.profissionaisSelecionados.push(item)
      }
    }
  }

  removerProfissional = (profissional) => {
    for (let index = 0; index < this.profissionaisSelecionados.length; index++) {
      const element = this.profissionaisSelecionados[index];
      if (element.id === profissional.id) {
        this.profissionaisSelecionados.splice(index, 1)
      }
    }
  }
}
