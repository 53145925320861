import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import * as $ from "jquery";

import { Location } from '@angular/common';
import { Global } from '../../../global';

import Swal from 'sweetalert2';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {
  editando: boolean = false;
  loading: boolean = false;
  usuario: any = {};
  profissionais: any = [];
  perfis: any = [];
  perfisUsuarios: any = [];
  uid: any = '';
  exibirSenha: boolean = false;
  exibirSenhaCertificado: boolean = false;
  lockBTN: boolean = false;
  fileToUpload: File | null = null;
  fileNameTOSave: any = "";
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe((res) => {
      if (res) {
        this.uid = res.usuario_id;
      }
    })

  }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.obterListaProfissionais().then(
      result => {
        this.profissionais = result;
        this.usuario.profissional_id = this.profissionais[0].id
        this.getListProfiles().then(
          result => {
            this.perfis = result;
            this.usuario.perfil_id = this.perfis[0].id
            if (this.uid != null) {
              this.editando = true;
              this.obterUsuario(this.uid);
            }
            Swal.close()
          },
          error => {
            this.emitErro(error)
          }
        )
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  obterListaProfissionais = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Profissional`).then(
        result => {
          var res: any = [];
          res = result
          res.unshift({ id: 0, nome: 'Selecione..' })
          resolve(res)
        },
        error => {
          reject(error)
        }
      )
    }
    )
  }

  getListProfiles = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Perfil`).then(
        result => {
          var res: any = result
          res = res.filter(x => x.ativo == true);
          res.unshift({ id: 0, nome: 'Selecione..' });
          resolve(res);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getListPerfilUsuario = () => {
    this.service.Get(`PerfilUsuario?ativo=true`).then(
      result => {
        this.perfisUsuarios = result;
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  changeStatusProfessional = () => {
    this.usuario.ativo != this.usuario.ativo;
  }

  salvarUsuario = () => {
    if (!this.validaPostUsuario()) {
      return;
    }
    var obj = Object.assign({}, this.usuario);
    obj.senha_md5 = this.global.MD5(obj.senha_);

    if (obj.email) {
      if (!this.validarEmail()) {
        this.emitErro('Favor digitar um email válido')
      }
    }
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.lockBTN = true;

    delete obj.senha_;

    this.service.Post(`Usuario`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Usuário salvo com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.lockBTN = false;
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {
            this.lockBTN = false;
          }
        }
      );
    })
  }

  goBack() {
    this.location.back();
  }

  validaPostUsuario = () => {
    if (this.usuario.nome == null || this.usuario.nome == "") {
      this.emitErro("Favor preencher o nome do usuario");
      return;
    }
    // if (this.usuario.cpf_mask == null || this.usuario.cpf_mask == "" || this.usuario.cpf_mask.length < 11) {
    if (!this.usuario.cpf_mask) {
      this.emitErro("Favor preencher um CPF válido");
      return;
    }
    this.usuario.cpf = this.usuario.cpf_mask;
    this.usuario.cpf = this.usuario.cpf_mask.replace(/\D/g, "")
    if (this.usuario.telefone_mask) {
      this.usuario.telefone = this.usuario.telefone_mask;
      this.usuario.telefone = this.usuario.telefone_mask.replace(/\D/g, "")
    }
    if (this.usuario.login == null || this.usuario.login == "") {
      this.emitErro("Favor preencher o login do usuário.");
      return;
    }
    if (!this.usuario.id) {
      if (this.usuario.senha_ == null || this.usuario.senha_ == "") {
        this.emitErro("Favor preencher a senha do usuário.");
        return;
      }
    }
    if (this.usuario.id) {
      if (this.usuario.senha_ == '') {
        delete this.usuario.senha_
      }
    }

    if (this.fileNameTOSave) {
      this.usuario.certificado_digital = this.fileNameTOSave;
      if (!this.usuario.senha_certificado_digital) {
        this.emitErro("Favor preencher a senha do certificado.");
        return;
      }
    }
    // if (!this.usuario.profissional_id){
    //   this.emitErro("Favor indicar o profissional.");
    //   return;
    // }
    if (!this.usuario.perfil_id) {
      this.emitErro("Favor selecionar um perfil para o usuário.");
      return;
    }
    return true;
  }

  editarUsuario = () => {
    if (!this.validaPostUsuario()) {
      return;
    }

    var obj = Object.assign({}, this.usuario);
    if (obj.senha_) {
      obj.senha_md5 = this.global.MD5(obj.senha_);
    }
    if (obj.email) {
      if (!this.validarEmail()) {
        this.emitErro('Favor digitar um email válido')
      }
    }
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    delete obj.senha_;
    this.lockBTN = true;
    this.service.Put(`Usuario/${obj.id}`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.lockBTN = false;
              // this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {
            this.lockBTN = false;
          }
        }
      );
    })
  }

  obterUsuario = (id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Usuario/${id}`).then(
      res => {
        this.usuario = res;
        this.usuario.cpf_mask = this.usuario.cpf;
        this.usuario.cpf_mask = this.global.cpfMask(this.usuario.cpf_mask);
        if (this.usuario.telefone) {
          this.usuario.telefone_mask = this.usuario.telefone;
          this.usuario.telefone_mask = this.global.telefoneMask(this.usuario.telefone_mask);
        }
        this.global.applyMask(this.usuario);

        this.getListPerfilUsuario();
        this.usuario.senha_ = "";
        if (this.usuario.length > 0)
          this.usuario = this.usuario[0];
        Swal.close()
      },
      error => {
        Swal.close()
        this.emitErro(error.error[0].messagem);
      }
    )
  }

  exibirEsconderSenha = () => {
    this.exibirSenha = !this.exibirSenha;
    if (this.exibirSenha)
      $('#passwordUser').get(0).type = 'text';
    if (!this.exibirSenha)
      $('#passwordUser').get(0).type = 'password';
  }


  validarEmail = () => {
    return this.global.validaEmail(this.usuario.email)
  }

  handleFileInput(files: FileList) {
    this.fileNameTOSave = null;
    this.fileToUpload = files.item(0);
  }

  enviarArquivo = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.postFile(`Arquivo/Certificado/Upload`, this.fileToUpload).then(
      result => {
        Swal.close();
        if (result) {
          var regex = /^"|"$/g;
          var nomeMidia = result["_body"].replace(regex, "");
          this.fileNameTOSave = nomeMidia;
        }
      }
    ).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  exibirEsconderSenha_ = () => {
    this.exibirSenhaCertificado = !this.exibirSenhaCertificado;
    if (this.exibirSenhaCertificado)
      $('#passwordCetificado').get(0).type = 'text';
    if (!this.exibirSenhaCertificado)
      $('#passwordCetificado').get(0).type = 'password';
  }

}
