import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
declare var jquery: any;
declare var $: any;
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.css']
})
export class ListClientComponent implements OnInit {

  client: any = {};
  listClients: any = [];
  filtro: string = "";

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    public global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getListClients();
  }

  saveClient = (client) => {
    if (client.cnpj) {
      if (this.compareData(client)) {
      }
    }
  }

  uploadLogo = () => {
    var x = document.getElementById('inputFile');
    x.click();
  }

  compareData = (data) => {
    if (data.cnpj) {
      data.clientCnpj = data.cnpj.replace(/\D/g, '');
      return data;
    }
  }

  getListClients = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Cliente`).then(res => {
      this.listClients = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401){
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.messagem}`, 'error');
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse cliente?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Cliente/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.getListClients();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401){
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.listClients.length === 0) {
      return this.listClients;
    }
    return this.listClients.filter(
      c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()) ||
        c.cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "").includes(this.filtro.replace(".", "").replace(".", "").replace("/", "").replace("-", ""))
    );
  }

}
