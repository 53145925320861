import { Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-componente-classificacao-risco',
  templateUrl: './componente-classificacao-risco.component.html',
  styleUrls: ['./componente-classificacao-risco.component.css']
})
export class ComponenteClassificacaoRiscoComponent implements OnChanges {

  @Output() emiteRisco: EventEmitter<any> = new EventEmitter();

  risco: any = {};
  listaTipoPrioridade: any = [
    {
      id: 1,
      nome: `Gestante`
    },
    {
      id: 2,
      nome: `Idoso`
    },
    {
      id: 3,
      nome: `Necessidades Especiais`
    },
    {
      id: 4,
      nome: `Outros`
    },
  ];
  
  constructor() { }

  ngOnChanges() {
    // this.risco.tipo_prioridade = 0;
  }

  changePrioridade() {
    this.emiteRisco.emit(this.risco);
  }

}
