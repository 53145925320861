import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit {

  estoques: any = [];
  filtro: string = "";

  constructor(public service: ApiService,
    private authService: AuthService
  ) { }


  ngOnInit() {
    this.init();
  }

  init = async () => {
    this.obterListaEstoque();
  }

  obterListaEstoque = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Estoque`).then(res => {
      this.estoques = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse estoque?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Estoque/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaEstoque();
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }


  filtroLista() {
    if (this.estoques.length === 0) {
      return this.estoques;
    }
    return this.estoques.filter(c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

}
