import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exam-setting',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamSettingComponent implements OnInit {

  exame: any = {};
  uid: any = '';
  estabeleciomentoSelecionado: any = {};
  estabeleciomentos: any = [];
  clinicas: any = [];
  listaClinicaExame: any = [];
  objPost: any = {};
  objPut: any = {};

  constructor(public service: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.obterEstabelecimentos();
    this.estabeleciomentoSelecionado = 0;
    this.init();
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterExame(uid);
    }
  }

  goBack() {
    this.location.back();
  }

  validaPostExame = () => {
    if (this.exame == null) {
      Swal.fire('Erro', "texto auqi", 'error');
      return false;
    }
    return true;
  }

  obterExame = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Sigtap/${id}`).then(
      result => {
        this.exame = result;
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  obterEstabelecimentos = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento?Ativo=true`).then(
      result => {
        this.estabeleciomentos = result;
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  obterClinicas = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento/${id}/ClinicaMedica?ativo=true`).then(
      result => {
        this.clinicas = result;
        this.clinicas.forEach((item) => {
          item.coletar = false;
          item.realizar = false;
          item.avaliar = false;
        });
        if (this.clinicas.length > 0)
          this.obterClinicasExame();
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  obterClinicasExame = () => {
    if (this.exame.id == null)
      return;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Sigtap/${this.exame.id}/ConfiguracaoClinica`).then(
      result => {
        this.listaClinicaExame = result;

        this.listaClinicaExame.forEach((item) => {

          this.clinicas.forEach((c) => {
            if (item.clinica_medica_id == c.id) {
              c.coletar = item.coletar;
              c.realizar = item.realizar;
              c.avaliar = item.avaliar;
              c.clinica_exame_id = item.id;
            }
          });
        });
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  postPut = (obj, status, acao) => {
    this.objPost = {};
    this.objPut = {};
    if (obj.clinica_exame_id == null) {
      if (acao == 1)
        obj.coletar = !status;
      if (acao == 2)
        obj.realizar = !status;
      if (acao == 3)
        obj.avaliar = !status;
      this.objPost = {
        "avaliar": obj.avaliar,
        "coletar": obj.coletar,
        "realizar": obj.realizar,
        "clinica_medica_id": obj.id
      };
    }
    if (obj.clinica_exame_id != null) {
      this.listaClinicaExame.forEach((ce) => {
        if (ce.id == obj.clinica_exame_id) {
          if (acao == 1)
            ce.coletar = !status;
          if (acao == 2)
            ce.realizar = !status;
          if (acao == 3)
            ce.avaliar = !status;
          this.objPut = ce;
        }
      });
    }
    if (this.objPost.clinica_medica_id != null)
      this.salvar();
    if (this.objPut.id != null)
      this.editar();
  }

  salvar = () => {
    Swal.fire({
      title: 'Salvando dados',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`Sigtap/${this.exame.id}/ConfiguracaoClinica`, this.objPost).then(res => {
      setTimeout(() => {
        Swal.close();
        this.obterClinicasExame();
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  editar = () => {
    Swal.fire('Salvando dados');
    Swal.showLoading();
    this.service.Put(`ConfiguracaoClinica/${this.objPut.id}`, this.objPut).then(res => {
      setTimeout(() => {
        Swal.close();
        this.obterClinicasExame();
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

}
