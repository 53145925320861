import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-medical-clinic',
  templateUrl: './medical-clinic.component.html',
  styleUrls: ['./medical-clinic.component.css']
})
export class MedicalClinicComponent implements OnInit {

  filtro: string = "";
  @Input() listaBloco: any = [];
  clinica: any = {};
  bloco_id_filtro: any = 0;
  bloco_id: any = 0;
  listaClinicas: any = [];

  @Output() retornoListaClinica = new EventEmitter()

  constructor(public service: ApiService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  obterListaClinica = (bloco_id) => {
    if (bloco_id == 0) {
      //this.listaClinicas = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Bloco/${bloco_id}/ClinicaMedica`).then(res => {
      this.listaClinicas = res;
      this.retornoListaClinica.emit(this.listaClinicas)
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  validaPost = () => {
    if (this.clinica.nome == null || this.clinica.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome do bloco.", 'error');
      return false;
    }
    if (this.clinica.bloco_id == null || this.clinica.bloco_id == 0) {
      Swal.fire('Erro', "Favor informar o bloco.", 'error');
      return false;
    }
    return true;
  }

  salvarEditarClinica = () => {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    if (this.clinica.id == null)
      this.service.Post(`ClinicaMedica`, this.clinica).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Clínica salvo com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.obterListaClinica(this.clinica.bloco_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    else {
      this.service.Put(`ClinicaMedica/${this.clinica.id}`, this.clinica).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Clínica atualizada com sucesso!'
          }).then(
            result => {
              if (result.isConfirmed) {
                this.obterListaClinica(this.clinica.bloco_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    }
  }

  editarClinica = (clinica) => {
    this.clinica = clinica;
    this.abrirModalNovaClinica();
  }

  selecionarBloco = (bloco_id) => {
    this.bloco_id = bloco_id;
  }

  changeStatusClinica = () => {
    this.clinica.ativo != this.clinica.ativo;
  }

  abrirModalNovaClinica = () => {
    if (this.clinica.id == null)
      this.clinica.ativo = true;
    $("#modalAdicionarClinica").toggleClass("show");
    $("#modalAdicionarClinica").css("display", "block");
  }

  fecharModal = () => {
    this.clinica = {};
    $("#modalAdicionarClinica").toggleClass("show");
    $("#modalAdicionarClinica").css("display", "none");
  }

  filtroLista() {
    if (this.listaClinicas.length === 0) {
      return this.listaClinicas;
    }
    return this.listaClinicas.filter(p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} essa clínica médica?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`ClinicaMedica/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaClinica(this.bloco_id_filtro);
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }


}
