import { Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements OnInit {

  filtro: string = "";
  @Input() listaBloco: any = [];
  sala: any = {};
  bloco_id_filtro: any = 0;
  clinica_id_filtro: any = 0;
  bloco_id: any = 0;
  listaClinicas: any = [];
  listaSalas: any = [];


  constructor(public service: ApiService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  obterListaClinica = (bloco_id) => {
    if (bloco_id == 0) {
      this.clinica_id_filtro = 0;
      this.listaClinicas = [];
      this.listaSalas = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Bloco/${bloco_id}/ClinicaMedica`).then(res => {
      this.listaClinicas = res;
      this.listaSalas = [];
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  obterListaSala = (clinica_id) => {
    if (clinica_id == 0) {
      this.listaSalas = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`ClinicaMedica/${clinica_id}/Sala`).then(res => {
      this.listaSalas = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  validaPost = () => {
    if (this.sala.nome == null || this.sala.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome da sala.", 'error');
      return false;
    }
    //if (this.sala.blocoId == null || this.sala.blocoId == 0) {
    //  Swal.fire('Erro', "Favor informar o bloco.", 'error');
    //  return false;
    //}
    if (this.sala.clinica_medica_id == null || this.sala.clinica_medica_id == 0) {
      Swal.fire('Erro', "Favor informar a clínica médica.", 'error');
      return false;
    }
    return true;
  }

  salvarEditarSala = () => {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    if (this.sala.id == null)
      this.service.Post(`Sala`, this.sala).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Sala salva com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.bloco_id_filtro = this.sala.blocoId;
                this.clinica_id_filtro = this.sala.clinica_medica_id;
                this.obterListaSala(this.sala.clinica_medica_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    else {
      this.service.Put(`Sala/${this.sala.id}`, this.sala).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Clínica atualizada com sucesso!'
          }).then(
            result => {
              if (result.isConfirmed) {
                this.bloco_id_filtro = this.sala.blocoId;
                this.clinica_id_filtro = this.sala.clinica_medica_id;
                this.obterListaSala(this.sala.clinica_medica_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    }
  }

  editarSala = (sala) => {
    this.sala = sala;
    //aqui
    this.sala.blocoId = 0;
    this.abrirModalNovaSala();
  }

  selecionarBloco = (bloco_id) => {
    this.bloco_id = bloco_id;
    this.obterListaClinica(bloco_id);
  }

  changeStatusSala = () => {
    this.sala.ativo != this.sala.ativo;
  }

  abrirModalNovaSala = () => {
    if (this.sala.id == null) {
      this.sala.ativo = true;
      this.sala.blocoId = 0;
      this.bloco_id_filtro = 0;
      this.sala.clinica_medica_id = 0;
      this.clinica_id_filtro = 0;
      this.listaClinicas = [];
      this.listaSalas = [];
    }
    $("#modalAdicionarSala").toggleClass("show");
    $("#modalAdicionarSala").css("display", "block");
  }

  fecharModal = () => {
    this.sala = {};
    $("#modalAdicionarSala").toggleClass("show");
    $("#modalAdicionarSala").css("display", "none");
  }

  filtroLista() {
    if (this.listaSalas.length === 0) {
      return this.listaSalas;
    }
    return this.listaSalas.filter(p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  confirmationAtivarDesativar = (id, ativo, clinica_id) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} essa sala?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Sala/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaSala(clinica_id);
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

}
