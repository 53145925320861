import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from "../global";
import { Router } from "@angular/router";
import { Http, RequestOptions } from '@angular/http';
import { Headers } from '@angular/http';
import { AuthService } from "./auth/auth.service";
import { Observable } from "rxjs";

@Injectable()
export class FileUploadService {

  constructor(private http: HttpClient, public global: Global) { }

  uploadFile(path: any, file: Blob, fileName: string): Observable<any> {
    const formData = new FormData();
    formData.append('uploadedFile', file, fileName);
    return this.http.post(`${this.global.apiUrl}${path}`, formData);
  }
}
