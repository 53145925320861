import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-product-model',
  templateUrl: './list-product-model.component.html',
  styleUrls: ['./list-product-model.component.css']
})
export class ListProductModelComponent implements OnInit {

  listaClosingReasons: any = []
  filtro: any = "";
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.listClosingReason()
  }

  listClosingReason = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Get(`ProdutoModelo`).then(
      result => {
        this.listaClosingReasons = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }


  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse modelo de produto?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`ProdutoModelo/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.listClosingReason();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.listaClosingReasons.length === 0) {
      return this.listaClosingReasons;
    }
    return this.listaClosingReasons.filter(
      c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase())
    );
  }

}
