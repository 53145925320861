import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import { Global } from '../../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

import { ModalAuthExamsComponent } from '../../../modals/modal-auth-exams/modal-auth-exams.component';

import * as $ from "jquery";
import * as moment from "moment";
import { AuthService } from '../../../service/auth/auth.service';


@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsComponent implements OnInit {

  tipoAgenda: any = [
    { index: 1, nome: 'Ordem de chegada' },
    { index: 2, nome: 'Horário pré-estabelecido' }
  ];

  listaRegulacoes: any = [];

  filtro: any = {};

  estabSolicitante: any = []
  estabExecutor: any = []
  profissionalSolicitante: any = []

  listaSigtap: any = [];
  listaEstabelecimentos: any = [];
  listaProfissionais: any = [];
  listaCriticidade: any = [];
  listaStatusSolicitacao: any = [];
  listaOrdenacao: any = [];
  usuarios: any = [];

  enviaTipoRegulacao: any;
  autorizar: any = [];
  agendar: any = [];
  laudar: any = [];
  hoje_servidor: any = '';

  selecaoMultipla: boolean = false;

  usuario: any = [];

  agendarExame: any = [];

  dadosRegulacao: any = {};

  errorLog: any = []

  pagina: any = 1;
  totalPagina: any = 1;
  totalPorPagina: any = 100;
  listaStatusVisualizacao: any = [];

  obterHistoricoRegulacaoId: any = '';
  tipoRegulacaoHistorico: any = '';
  navegarPaginas: boolean = false;
  busca: any = 'busca'

  visualizarAgendamento: any = '';
  path: any = 'RegulacaoExame/GerarCanhotoAgendamentoExame'

  erros: number = 0;
  acertos: number = 0;
  count: number = 0;

  //imprimir solicitação exames
  solicitacaoExame: any = {}

  //imprimir solicitação APAC
  solicitacaoApac: any = {}

  // alterar status
  alterarStatus: boolean = false;
  regulacoesAlterarStatus: any = [];
  pathStatus: any = 'RegulacaoExame/';
  isChecked: any = false;

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    var date = new Date();
    this.hoje_servidor = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    this.getUser()
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.obterListaUsuarios();
    this.getListaCriticidade().then(
      result => {
        this.listaCriticidade = result;
        this.listaCriticidade.unshift({ id: 0, nome: "TODOS" })
        this.filtro.criticidade = this.listaCriticidade[0].id;
        this.getListaStatusSolicitacao().then(
          result => {
            this.listaStatusSolicitacao = result;
            // this.listaStatusSolicitacao.unshift({id:0,nome:"TODOS"})
            // this.filtro.statusSolicitacao = this.listaStatusSolicitacao[0].id;
            for (let item of this.listaStatusSolicitacao) {
              if (item.nome.toLowerCase() == 'realizado' || item.nome.toLowerCase() == 'cancelado') {
                item.checked = false;
              }
              else {
                item.checked = true;
              }
            }
            this.filtro.listaStatusSolicitacao = this.listaStatusSolicitacao;
            //this.getListaSigtap().then(
            //  result => {
            // this.listaSigtap = result;
            // this.listaSigtap.unshift({id:0,nome:"TODOS"})
            // this.filtro.sigtap = this.listaSigtap[0].id;
            this.getListaEstabelecimentos().then(
              result => {
                this.listaEstabelecimentos = result;
                this.listaEstabelecimentos.unshift({ id: 0, nome: "TODOS" })
                this.filtro.estabSolicitante = this.listaEstabelecimentos[0].id;
                this.filtro.estabExecutor = this.listaEstabelecimentos[0].id;
                this.getListaProfissionais().then(
                  result => {
                    this.listaProfissionais = result;
                    this.listaProfissionais.unshift({ id: 0, nome: "TODOS" })
                    this.filtro.profissionalSolicitante = this.listaProfissionais[0].id;
                    this.filtro.UsuarioId = 0;
                    // this.getListaOrdenacao().then(
                    //   result => {
                    //this.listaOrdenacao = result;
                    this.listaOrdenacao = [{ id: 1, nome: 'CRITICIDADE' }];
                    this.filtro.ordenarPor = this.listaOrdenacao[0].id;
                    this.getListaRegulacoes().then(
                      result => {
                        if (result) {
                          this.listaRegulacoes = result['conteudo'];
                          this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
                          if (this.totalPagina > 1) {
                            this.navegarPaginas = true;
                          }
                        }
                        for (let regulacao of this.listaRegulacoes) {
                          this.global.applyMask(regulacao)
                        }
                        Swal.close()
                      },
                      error => {
                        this.emitError(error)
                      }
                    )
                    //   },
                    //   error => {
                    //     this.emitError(error);
                    //   }
                    // )
                  },
                  error => {
                    this.emitError(error)
                  }
                )
              },
              error => {
                this.emitError(error)
              }
            )
            //},
            // error => {
            //   this.emitError(error)
            // }
            //)
          },
          error => {
            this.emitError(error)
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  async getUser() {
    var currentUser = await localStorage.getItem('@DigtVentry:currentUser');
    var user = JSON.parse(currentUser);
    this.usuario = JSON.parse(currentUser);
    if (user) {
      this.authService.saveUser(user);
    } else {
      this.authService.logoutUser();
    }
  }

  getListaRegulacoes = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading()
    return new Promise((resolve, reject) => {
      var txt = `Skip=${this.pagina}&Take=${this.totalPorPagina}`
      if (this.filtro.listaStatusSolicitacao) {
        for (let item of this.filtro.listaStatusSolicitacao) {
          if (item.checked) {
            if (txt != '') {
              txt += '&'
            }
            txt += 'status_solicitacao=' + item.nome
          }
        }
      }
      this.service.Get(`RegulacaoExame?${txt}`).then(
        result => {
          Swal.close();
          resolve(result)
        },
        error => {
          Swal.close();
          reject(error)
        }
      )
    })
  }

  getListaEstabelecimentos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getListaProfissionais = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Profissional`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getListaSigtap = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`SigTap`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getListaCriticidade = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`RegulacaoExame/Criticidade`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getListaStatusSolicitacao = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`RegulacaoExame/StatusSolicitacao`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getListaOrdenacao = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Ordenacao`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  obterListaUsuarios = () => {
    this.service.Get(`Usuario`).then(res => {
      this.usuarios = res;
      this.usuarios.unshift({ id: 0, nome: "TODOS" });
      this.filtro.UsuarioId = 0;
    }).catch(
      res => {
        if (res.status == 401) {
          Swal.close()
          //this.auth.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      })
  }

  validateCancelExam = (data: any) => {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realmente cancelar esta solicitação?',
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: `Cancelar`
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.Patch_body(`RegulacaoExame/${data.id}`, ({ ativo: false, status: 'CANCELADO' })).then(
            result => {
              this.getListaRegulacoes().then(
                result => {
                  this.listaRegulacoes = result['conteudo'];
                  this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
                  if (this.totalPagina > 1) {
                    this.navegarPaginas = true;
                  }
                  this.getListaRegulacoes().then(
                    result => {
                      Swal.close()
                    }
                  )
                },
                error => {
                  this.emitError('error')
                }
              )
            }
          )
        }
        else {
          Swal.close()
          return
        }
      }
    )
  }

  setPeriodoSolicitacao = (novoPeriodo: any) => {
    if (novoPeriodo.length > 0) {
      this.filtro.data_fim_solicitacao = this.global.dateServerMask(novoPeriodo[1])
      this.filtro.data_inicio_solicitacao = this.global.dateServerMask(novoPeriodo[0])
      return
    }
    this.filtro.data_fim_solicitacao = ''
    this.filtro.data_inicio_solicitacao = ''
  }

  setPeriodoAgendamento = (novoPeriodo: any) => {
    if (novoPeriodo.length > 0) {
      this.filtro.data_inicio_agendamento = this.global.dateServerMask(novoPeriodo[0])
      this.filtro.data_fim_agendamento = this.global.dateServerMask(novoPeriodo[1])
      return
    }
    this.filtro.data_inicio_agendamento = ''
    this.filtro.data_fim_agendamento = ''
  }

  setPeriodoRealizacao = (novoPeriodo: any) => {
    if (novoPeriodo.length > 0) {
      this.filtro.data_inicio_realizacao = this.global.dateServerMask(novoPeriodo[0])
      this.filtro.data_fim_realizacao = this.global.dateServerMask(novoPeriodo[1])
      return
    }
    this.filtro.data_inicio_realizacao = ''
    this.filtro.data_fim_realizacao = ''
  }

  addProcedimento = () => {
    for (let item of this.listaSigtap) {
      if (parseInt(this.filtro.sigtap) === item.id) {
        this.filtro.procedimento = item.sub_grupo;
      }
    }
  }

  buscarDadosFiltro = (filtro) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading()
    var txt: string = '';
    //var num: any = (this.pagina-1)*this.totalPorPagina
    txt = `Skip=${this.pagina}&Take=${this.totalPorPagina}`
    if (filtro != undefined) {
      if (filtro.data_inicio_solicitacao) {
        if (filtro.data_inicio_solicitacao != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `data_inicio_solicitacao=${filtro.data_inicio_solicitacao}`
        }
      }
      if (filtro.data_fim_solicitacao) {
        if (filtro.data_fim_solicitacao != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `data_fim_solicitacao=${filtro.data_fim_solicitacao}`
        }
      }
      if (filtro.data_inicio_agendamento) {
        if (filtro.data_inicio_agendamento != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `data_inicio_agendamento=${filtro.data_inicio_agendamento}`
        }
      }
      if (filtro.data_fim_agendamento) {
        if (filtro.data_fim_agendamento != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `data_fim_agendamento=${filtro.data_fim_agendamento}`
        }
      }
      if (filtro.data_inicio_realizacao) {
        if (filtro.data_inicio_realizacao != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `data_inicio_realizacao=${filtro.data_inicio_realizacao}`
        }
      }
      if (filtro.data_fim_realizacao) {
        if (filtro.data_fim_realizacao != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `data_fim_realizacao=${filtro.data_fim_realizacao}`
        }
      }
      // if (filtro.statusSolicitacao){
      //   if(filtro.statusSolicitacao != 0){
      //     if (txt != ''){
      //       txt += '&'
      //     }
      //     // txt += `status_solicitacao_id=${filtro.statusSolicitacao}`
      //     txt += `status_solicitacao=${this.listaStatusRegulacao(filtro.statusSolicitacao)}`
      //   }
      // }
      if (filtro.profissionalSolicitante) {
        if (filtro.profissionalSolicitante != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `profissional_solicitante=${filtro.profissionalSolicitante}`
        }
      }
      if (filtro.UsuarioId) {
        if (filtro.UsuarioId != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `UsuarioId=${filtro.UsuarioId}`
        }
      }
      if (filtro.sigtap) {
        if (filtro.sigtap != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `sigtap_id=${filtro.sigtap}`
        }
      }
      // if (filtro.procedimento){
      //   if(filtro.procedimento != ''){
      //     if (txt != ''){
      //       txt += '&'
      //     }
      //     txt += `sub_grupo_sigtap=${filtro.procedimento}`
      //   }
      // }
      if (filtro.buscaPaciente) {
        if (filtro.buscaPaciente != '') {
          if (txt != '') {
            txt += '&'
          }
          filtro.buscaPacienteLowCase = filtro.buscaPaciente.toLowerCase()
          // filtro.buscaPacienteLowCase = filtro.buscaPaciente.replace(/[\W_]+/g,'')
          txt += `busca_paciente=${filtro.buscaPacienteLowCase}`
        }
      }
      if (filtro.ordenarPor) {
        if (filtro.ordenarPor != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `Ordenacao=${filtro.ordenarPor}`
        }
      }
      if (filtro.criticidade) {
        if (filtro.criticidade != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `criticidade=${this.listaItensCriticidade(filtro.criticidade)}`
        }
      }
      if (filtro.estabSolicitante) {
        if (filtro.estabSolicitante != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `estabelecimento_solicitante_id=${filtro.estabSolicitante}`
        }
      }
      if (filtro.estabExecutor) {
        if (filtro.estabExecutor != 0) {
          if (txt != '') {
            txt += '&'
          }
          txt += `estabelecimento_executor_id=${filtro.estabExecutor}`
        }
      }
      if (filtro.busca_grupoSigtap) {
        if (filtro.busca_grupoSigtap != '') {
          if (txt != '') {
            txt += '&'
          }
          txt += `sub_grupo_sigtap=${filtro.busca_grupoSigtap}`
        }
      }
      if (filtro.busca_sigtap) {
        if (filtro.busca_sigtap != '') {
          if (txt != '') {
            txt += '&'
          }
          txt += `nome_sigtap=${filtro.busca_sigtap}`
        }
      }
      if (filtro.listaStatusSolicitacao) {
        for (let item of filtro.listaStatusSolicitacao) {
          if (item.checked) {
            if (txt != '') {
              txt += '&'
            }
            txt += 'status_solicitacao=' + item.nome
          }
        }
      }
    }
    this.listaRegulacoes = []
    this.service.Get(`RegulacaoExame?${txt}`).then(
      result => {
        this.listaRegulacoes = result['conteudo'];
        this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
        if (this.totalPagina > 1) {
          this.navegarPaginas = true;
        }
        Swal.close()
      },
      error => {
        this.emitError(error)
      }
    )
  }

  // abertura de modais
  abrirModalAutorizar = (regulacao) => {
    this.autorizar = []
    if (regulacao.length > 1) {
      for (var i = 0; i < regulacao.length; i++) {
        if (i == 0) {
          for (let item of regulacao) {
            if (item.checked === true) {
              this.autorizar.push(item)
            }
          }
        }
        if (i > 0) {
          if (this.autorizar[0].Cidadao.id != regulacao[i].Cidadao.id) {
            this.emitError('Alguns dos procedimentos selecionados não são dos mesmos paciente. Verifique a seleção de itens')
          }
        }
        // if (regulacao[i].Cidadao.id === regulacao[i+1].Cidadao.id){
        //   for (let item of regulacao){
        //     if (item.checked === true){
        //       this.autorizar.push(item)
        //     }
        //   }
        // }
        // else {
        //   this.emitError('Alguns dos procedimentos selecionados não são dos mesmos paciente. Verifique a seleção de itens')
        // }
      }
    }
    else {
      this.autorizar.push(regulacao);
    }
    $("#modalAutorizarExame").toggleClass("show");
    $("#modalAutorizarExame").css("display", "block");
  }

  abrirModalAgendarProcedimento = (regulacao) => {
    var listaRegulacoes: any = [];
    this.validarSelecao().then(
      result => {
        listaRegulacoes = result[0]
        this.validaPaciente(result[1]).then(
          result => {
            if (regulacao) {
              this.agendarExame = regulacao
            }
            else {
              this.agendarExame = listaRegulacoes
            }
            $("#modalAgendarProcedimento").toggleClass("show");
            $("#modalAgendarProcedimento").css("display", "block");
          },
          error => {
            this.emitError(error)
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  abrirModalLaudo = (regulacao) => {
    this.dadosRegulacao = regulacao;
    $("#modalLaudo").toggleClass("show");
    $("#modalLaudo").css("display", "block");
  }

  fecharModalLaudo = () => {
    this.dadosRegulacao = {};
  }

  abrirModalRegulacaoManual = (tipoRegulacao: any) => {
    this.enviaTipoRegulacao = tipoRegulacao;
    $("#modalRegulacaoManual").toggleClass("show");
    $("#modalRegulacaoManual").css("display", "block");
  }

  emitError = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  updateList = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.selecaoMultipla = false;
    this.alterarStatus = false;
    this.getListaRegulacoes().then(
      result => {
        if (result) {
          this.listaRegulacoes = result['conteudo']
          this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
          if (this.totalPagina > 1) {
            this.navegarPaginas = true;
          }
          for (let regulacao of this.listaRegulacoes) {
            this.global.applyMask(regulacao)
          }
          Swal.close()
        }
      },
      error => {
        this.emitError(error)
      }
    )
  }

  coletarMaterial = (regulacao) => {
    Swal.fire({
      title: 'Deseja realizar o procedimento?',
      showDenyButton: true,
      denyButtonText: 'Cancelar',
      icon: 'question'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde')
          Swal.showLoading();
          this.service.Patch_body(`RegulacaoExame/${regulacao.id}`, { ativo: true, status: 'REALIZADO' }).then(
            result => {
              Swal.fire('Sucesso', 'Procedimento realizado com sucesso', 'success').then(
                result => {
                  if (result.isConfirmed) {
                    this.getListaRegulacoes().then(
                      result => {
                        if (result) {
                          this.listaRegulacoes = result['conteudo']
                          this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
                          if (this.totalPagina > 1) {
                            this.navegarPaginas = true;
                          }
                          this.getListaRegulacoes().then(
                            result => {
                              Swal.close()
                            }
                          )
                        }
                        for (let regulacao of this.listaRegulacoes) {
                          this.global.applyMask(regulacao)
                        }
                        Swal.close()
                      },
                      error => {
                        this.emitError(error)
                      }
                    )
                  }
                }
              )
            },
            error => {
              this.emitError(error)
            }
          )
        }
      }
    )
  }

  listaStatusRegulacao = (data) => {
    var nome = ''
    var lista = [
      { id: 1, nome: 'PENDENTE' },
      { id: 2, nome: 'AUTORIZADO' },
      { id: 3, nome: 'AGENDADO' },
      { id: 4, nome: 'REALIZADO' },
      { id: 5, nome: 'CANCELADO' }
    ]
    for (let item of lista) {
      if (item.id == data) {
        return item.nome
      }
    }
  }

  listaItensCriticidade = (data) => {
    var nome = ''
    var lista = [
      { id: 1, nome: "ELETIVO" },
      { id: 2, nome: "PRIORIDADE" },
      { id: 3, nome: "URGENCIA" },
      { id: 4, nome: "EMERGENCIA" }
    ]
    for (let item of lista) {
      if (item.id == data) {
        return item.nome
      }
    }
  }

  fecharModalAgendamento = () => {
    $("#modalAgendarProcedimento").toggleClass("show");
    $("#modalAgendarProcedimento").css("display", "none");
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.selecaoMultipla = false;
    this.alterarStatus = false;
    this.getListaRegulacoes().then(
      result => {
        this.listaRegulacoes = result['conteudo']
        this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
        if (this.totalPagina > 1) {
          this.navegarPaginas = true;
        }
        for (let regulacao of this.listaRegulacoes) {
          this.global.applyMask(regulacao)
        }
        this.getListaRegulacoes().then(
          result => {
            Swal.close()
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  //ação em blocos
  selectMultiple = (data) => {
    data.checked = !data.checked;
    var verfi = this.listaRegulacoes.filter(x => x.checked);
    if (this.listaRegulacoes.length != verfi.length) {
      this.isChecked = false;
      var checkboxes = document.getElementsByName('checkboxAll');
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i][`checked`] = false;
      }
    };
    this.permitirEmBloco();
    return data;
  }

  permitirEmBloco = () => {
    var emBloco = 0;
    this.selecaoMultipla = false;
    this.alterarStatus = false;
    for (let item of this.listaRegulacoes) {
      if (item.checked) {
        emBloco += 1;
        if (emBloco > 0) {
          this.alterarStatus = true;
        }
        if (emBloco > 1) {
          this.selecaoMultipla = true;
        }
      }
    }
  }

  validarSelecao = () => {
    return new Promise((resolve) => {
      var listaProcedimentos = []
      var listaCidadao = []
      for (let item of this.listaRegulacoes) {
        if (item.checked) {
          listaProcedimentos.push(item)
          listaCidadao.push(item.Cidadao.cpf)
        }
      }
      resolve([listaProcedimentos, listaCidadao])
    })
  }

  realizarEmBloco = () => {
    Swal.fire('Atenção', 'Deseja realmente realizar as solicitações selecionadas?', 'warning').then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.validarSelecao().then(
            result => {
              var listaProcedimentos = result[0]
              var listaCidadao = result[1]
              if (listaCidadao.every((val, i, arr) => val === arr[0])) {
                var tamanho = listaCidadao.length;
                this.errorLog = [];
                this.updateRecurse(listaProcedimentos, 0, ({ ativo: true, status: 'REALIZADO' }))
              }
              else {
                this.emitError('Os procedimentos selecionados não pertencem ao mesmo paciente. Por favor, verifique a seleção.')
                return
              }
            }
          )
        }
        else {
          Swal.close()
        }
      }
    )
  }

  updateRecurse = (item, index, obj) => {
    try {
      if (index == item.length) {
        this.exibeFinal()
        return
      }
      this.service.Patch_body(`RegulacaoExame/${item[index].id}`, (obj)).then(
        result => {
          this.updateRecurse(item, index + 1, obj)
        },
        error => {
          this.errorLog.push(error)
        }
      )
    } catch (e) {
      this.errorLog.push(e)
    }
  }

  exibeFinal = () => {
    Swal.close()
    if (this.errorLog.length > 0) {
      Swal.fire('Atenção', 'Alguns procedimentos não puderam ser alterados.', 'warning').then(
        result => {
          Swal.fire('Favor aguarde')
          Swal.showLoading()
          this.getListaRegulacoes().then(
            result => {
              this.listaRegulacoes = result['conteudo']
              this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina);
              if (this.totalPagina > 1) {
                this.navegarPaginas = true;
              }
              this.getListaRegulacoes().then(
                result => {
                  Swal.close()
                }
              )
            },
            error => {
              this.emitError(error)
            }
          )
        }
      )
      return
    }
    else {
      Swal.fire('Sucesso', 'Procedimentos alterados com sucesso.', 'success').then(
        result => {
          Swal.fire('Favor aguarde')
          Swal.showLoading()
          this.getListaRegulacoes().then(
            result => {
              this.listaRegulacoes = result['conteudo'];
              this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
              if (this.totalPagina > 1) {
                this.navegarPaginas = true;
              }
              this.getListaRegulacoes().then(
                result => {
                  Swal.close()
                }
              )
            },
            error => {
              this.emitError(error)
            }
          )
        }
      )
      return
    }
  }

  showProcedimentoNome = (id) => {
    for (let item of this.listaSigtap) {
      if (item.id === id) {
        return item.nome
      }
    }
  }

  showProfissionalNome = (id) => {
    for (let item of this.listaProfissionais) {
      if (item.id === id) {
        return item.nome
      }
    }
  }

  autorizarEmBloco = () => {
    var listaRegulacoes: any = [];
    this.validarSelecao().then(
      result => {
        if (result[length] > 1) {
          listaRegulacoes = result[0][0]
        }
        else {
          listaRegulacoes = result[0]
        }
        this.validaPaciente(result[1]).then(
          result => {
            this.abrirModalAutorizar(listaRegulacoes)
          },
          error => {
            this.emitError(error)
          }
        )
        // listaRegulacoes = result;
      }
    )
  }

  agendarEmBloco = () => {
    var listaRegulacoes: any = [];
    this.validarSelecao().then(
      result => {
        listaRegulacoes = result[0]
        this.validaPaciente(result[1]).then(
          result => {

            this.abrirModalAgendarProcedimento(listaRegulacoes)
          },
          error => {
            this.emitError(error)
          }
        )
        // listaRegulacoes = result;
      }
    )
  }

  negarEmBloco = () => {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realmente cancelar esta solicitação?',
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: `Cancelar`
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde')
          Swal.showLoading()
          var listaRegulacoes: any = [];
          this.validarSelecao().then(
            result => {
              listaRegulacoes = result[0]
              this.validaPaciente(result[1]).then(
                result => {
                  this.count = 0;
                  this.erros = 0;
                  this.acertos = 0;
                  this.cancelarExame(0, listaRegulacoes)
                },
                error => {
                  this.emitError(error)
                }
              )
              // listaRegulacoes = result;
            }
          )
        }
        else {
          Swal.close()
          return
        }
      }
    )
  }

  cancelarExame = (count, data) => {
    if (count == data.length) {
      if (this.erros == data.length) {
        Swal.fire('Erro', 'Os exames não puderam ser cancelados', 'error').then(
          result => {
            this.updateList()
            return
          }
        )
      }
      if (this.erros > 0) {
        // if (this.erros > 0 && this.acertos < data.length){
        Swal.fire('Atenção', 'Alguns exames não puderam ser cancelados. Verifique a lista novamente', 'warning').then(
          result => {
            this.updateList()
            return
          }
        )
      }
      if (this.acertos == data.length) {
        Swal.fire('Sucesso', 'Os exames foram cancelados', 'success').then(
          result => {
            this.updateList()
            return
          }
        )
      }
    }
    //this.service.Get(`RegulacaoExame/${data[count].id}`).then(
    this.service.Patch_body(`RegulacaoExame/${data[count].id}`, { status: 'CANCELADO' }).then(
      result => {
        this.acertos += 1;
        count += 1;
        this.cancelarExame(count, data)
      },
      error => {
        this.erros += 1;
        count += 1;
        this.cancelarExame(count, data)
      }
    )
  }

  validaPaciente = (listaCpf) => {
    return new Promise((resolve, reject) => {
      if (listaCpf.filter(x=>x).every((val, i, arr) => val === arr[0])) {
        resolve('Os dados pertencem a um único paciente')
      }
      else {
        reject('Os dados não pertencem ao mesmo paciente')
      }
    })

  }

  displayListStatus = (newList: any) => {
    this.filtro.listaStatusSolicitacao = newList;
  }

  selecionarSigtap(item) {
    this.filtro.busca_sigtap = '';
    this.filtro.sigtap = ''
    if (!item) return
    if (item.id) {
      this.filtro.sigtap = item.id
    }
    this.filtro.procedimento = item.nome
  }

  buscaSigtap = (item) => {
    this.filtro.busca_sigtap = '';
    this.filtro.busca_sigtap = item;
  }

  selecionarGrupoSigtap(item) {
    this.filtro.busca_grupoSigtap = '';
    if (typeof item == 'string') {
      this.filtro.grupo_sigtap = item
      this.busca = `ListarSubGrupo?nome_subGrupo=${this.filtro.grupo_sigtap}&busca`
    }
    else {
      this.busca = `busca`
      this.filtro.sigtap_id = '';
    }
  }

  buscaGrupoSigtap = (item) => {
    this.filtro.busca_grupoSigtap = '';
    this.filtro.busca_grupoSigtap = item;
  }

  //historico de regulação
  visualizarHistoricoRegulacao = (item: any, tipoRegulacao: any) => {
    this.obterHistoricoRegulacaoId = item.id;
    this.tipoRegulacaoHistorico = tipoRegulacao;
    $("#modalHistoricoRegulacao").toggleClass("show");
    $("#modalHistoricoRegulacao").css("display", "block");
  }

  limparVisualizarHistorico = () => {
    this.obterHistoricoRegulacaoId = '';
    this.tipoRegulacaoHistorico = '';
  }

  obterComprovanteAgendamento = (regulacao) => {
    this.visualizarAgendamento = regulacao.id
    setTimeout(() => {
      this.visualizarAgendamento = ''
    }, 250)
  }

  getListaPaginada = (pagina) => {
    //this.pagina = pagina;
    this.buscarDadosFiltro(this.filtro)
  }

  limparImpressos = () => {
    this.solicitacaoExame = {}
    this.solicitacaoApac = {}
    setTimeout(() => {
      Swal.close();
    }, 500)
  }

  //imprimir solicitação exames
  imprimirSolicitacaoExame = (historico) => {
    historico.apac = false;
    this.solicitacaoExame = {}
    this.solicitacaoExame = historico
  }

  //imprimir solicitação Apac
  imprimirSolicitacaoApac = (historico) => {
    historico.apac = true;
    this.solicitacaoExame = {}
    this.solicitacaoExame = historico
  }

  // voltar status
  voltarStatus = () => {
    this.regulacoesAlterarStatus = []
    for (let item of this.listaRegulacoes) {
      if (item.checked == true) {
        this.regulacoesAlterarStatus.push(item)
      }
    }
    if (this.regulacoesAlterarStatus.every((val, i, arr) => val.status === arr[0].status)) {
      this.abrirModalAlterarStatus()
    }
    else {
      this.emitError('Os procedimentos selecionados não possuem o mesmo status. Por favor, verifique a seleção.')
      return
    }
  }

  abrirModalAlterarStatus = () => {
    $("#modalAlterarStatus").toggleClass("show");
    $("#modalAlterarStatus").css("display", "block");
  }

  fecharModalAlterarStatus = () => {
    $("#modalAlterarStatus").toggleClass("show");
    $("#modalAlterarStatus").css("display", "none");
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.selecaoMultipla = false;
    this.alterarStatus = false;
    this.getListaRegulacoes().then(
      result => {
        this.listaRegulacoes = result['conteudo']
        this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
        if (this.totalPagina > 1) {
          this.navegarPaginas = true;
        }
        for (let regulacao of this.listaRegulacoes) {
          this.global.applyMask(regulacao)
        }
        this.getListaRegulacoes().then(
          result => {
            Swal.close()
          }
        )
      },
      error => {
        this.emitError(error)
      }
    )
  }

  checkValue = (checked) => {
    this.listaRegulacoes.forEach(element => {
      element.checked = !element.checked;
      this.permitirEmBloco();
    });
    var checkboxes = document.getElementsByName('checkRegulation');
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i][`checked`] = checked;
    }
  }
}
