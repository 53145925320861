import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from "../global";
import { Router } from "@angular/router";
import { Http, RequestOptions } from '@angular/http';
import { Headers } from '@angular/http';
import { AuthService } from "./auth/auth.service";

@Injectable()
export class ApiService {
  currentUser: any;
  headerOptions: any;
  headers = new Headers();
  constructor(
    private http: HttpClient,
    public global: Global,
    public router: Router,
    private http_: Http,
    private authService: AuthService,
  ) {

  }

  Get(path) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.global.apiUrl}${path}`,
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  Delete(path) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.global.apiUrl}${path}`,
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  Post(path, body) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.global.apiUrl}${path}`, JSON.stringify(body),
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }


  PostImpresso(path, body) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.global.apiUrl}${path}`, JSON.stringify(body),
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {          
            resolve(res);
          },
          err => {            
            if (err.status == 200) {
              resolve(err.error.text);
            } else {
              reject(err);
            }
          }
        );
    });
  }

  // postFile(path, fileToUpload: File) {
  //   var formData: FormData = new FormData();
  //   formData.append('uploadedFile', fileToUpload, fileToUpload.name);
  //   return new Promise((resolve, reject) => {
  //     this.http.post(`${this.global.apiUrl}${path}`, formData,
  //       {
  //         headers: new HttpHeaders()
  //           .set("Content-Type", "multipart/form-data")
  //       })
  //       .subscribe(
  //         res => {
  //           resolve(res);
  //         },
  //         err => {
  //           reject(err);
  //         }
  //       );
  //   });

  // }


  postFile(path, file: File) {
    let formData: FormData = new FormData();
    formData.append('uploadedFile', file);
    let headers = new Headers();

    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve, reject) => {
      this.http_.post(`${this.global.apiUrl}${path}`, formData, options)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  Put(path, body) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.global.apiUrl}${path}`, JSON.stringify(body),
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  AtivarDesativar(path, ativo) {
    return new Promise((resolve, reject) => {
      this.http.patch(`${this.global.apiUrl}${path}`, { "ativo": ativo },
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            //.set("Access-Control-Allow-Origin", "*")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  Patch_body(path, body) {
    return new Promise((resolve, reject) => {
      this.http.patch(`${this.global.apiUrl}${path}`, JSON.stringify(body),
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  GetAnotherPath(apiUrl, path) {
    return new Promise((resolve, reject) => {
      this.http.get(`${apiUrl}${path}`,
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${this.authService.authToken.value}`)
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

}
