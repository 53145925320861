import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Global } from '../../../../global';
import * as $ from "jquery";
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
declare var Simditor: any;
@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.css']
})
export class ProcedureListComponent implements OnInit {

  @Input() listaProcedimento: any = [];
  procedimento: any = {};
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() atendimento: any = {};
  dadosImpresso: any;
  motivoCancelamento; any = "";
  procedimetoSelecionado: any = {};
  global_: any;
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];

  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario:any;
  constructor(private authService: AuthService,
    public service: ApiService,
    private global: Global,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    private route: ActivatedRoute) {
    Simditor.locale = 'en-US';
    this.global_ = global;

    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
    Simditor.locale = 'en-US';
    var that = this;
    setTimeout(() => {
      var motivo = new Simditor({
        textarea: $('#motivo_cancelamento'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
    }, 1000);
  }

  visualizarProcedimento = (proc_id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Procedimento/${proc_id}`).then(res => {
      this.procedimento = res;
      $("#modalVisualizarProcAbaProc").toggleClass("show");
      $("#modalVisualizarProcAbaProc").css("display", "block");
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  fecharModal = () => {
    $("#modalVisualizarProcAbaProc").toggleClass("show");
    $("#modalVisualizarProcAbaProc").css("display", "none");
  }


  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  // carregarDadosImpresso(solicitacao_id: any) {
  //   Swal.fire('Favor aguarde');
  //   Swal.showLoading();
  //   this.service.Get(`Solicitacao/${solicitacao_id}/Impresso`).then(res => {
  //     if (res) {
  //       this.dadosImpresso = res;
  //       this.authService.setDadosImpresso(this.dadosImpresso);
  //       this.authService.setTipoImpresso('procedimento_impresso');
  //       Swal.close();
  //       setTimeout(() => {
  //         window.print()
  //       }, 750)
  //     }
  //   });
  // }



  carregarDadosImpresso(solicitacao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Solicitacao/${solicitacao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }



  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
       <tr>
          <td>
              <p>${element.protocolo}</p>
          </td>
          <td>
              <p>${element.sigtap_codigo}</p>
          </td>
          <td>
              <p>${element.sigtap_nome}</p>
          </td>
          <td>
              <p>${element.criticidade}</p>
          </td>                  
      </tr>
  ` 
    });
    return td;
  }


  gerarComplemento(lista) {
    var td;
    td = lista[0].complemento;
    return td;
  }


  gerarStatus(lista) {
    var td;
    td = lista[0].status;
    return td;
  }


  prepararImpressoHtml(dadosImpresso) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "SOLICITAÇÃO DE PROCEDIMENTOS")}

        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>PROCEDIMENTO SOLICITADO</h4>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td>
                    <b> Protocolo </b>
                </td>
                <td>
                    <b> Cod. Sigtap </b>
                </td>
                <td>
                    <b> Procedimento </b>
                </td>
                <td>
                    <b> Criticidade </b>
                </td>               
            </tr>
            <tr>
                ${this.gerarTabela(dadosImpresso.conteudo.procedimentos)}
            </tr>
        </table>
        <div>
          <p><b>COMPLEMENTO</b></p>
          <p>${this.gerarComplemento(dadosImpresso.conteudo.procedimentos)}</p>
        </div>        
        <div>
          <p><b>STATUS</b></p>
          <p>${this.gerarStatus(dadosImpresso.conteudo.procedimentos)}</p>
        </div>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>

</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }



  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

  cancelar = (item) => {
    this.procedimetoSelecionado = item;
    this.motivoCancelamento = "";
    var editor = new Simditor({
      textarea: $('#motivo_cancelamento')
    });
    editor.setValue('')
    $("#modalCancelarProcedimento").toggleClass("show");
    $("#modalCancelarProcedimento").css("display", "block");
  }

  fecharModalCancelamento = () => {
    this.procedimetoSelecionado = null;
    this.motivoCancelamento = "";
    var editor = new Simditor({
      textarea: $('#motivo_cancelamento')
    });
    editor.setValue('');
    $("#modalCancelarProcedimento").toggleClass("show");
    $("#modalCancelarProcedimento").css("display", "none");
  }

  realizar = (item) => {
    this.procedimetoSelecionado = item;
    this.motivoCancelamento = "";
    var editor = new Simditor({
      textarea: $('#resultado_texto_procedimento')
    });
    editor.setValue('')
    $("#modalRealizarProcedimento").toggleClass("show");
    $("#modalRealizarProcedimento").css("display", "block");
  }

  fecharModalRealizar = () => {
    this.procedimetoSelecionado = null;
    var editor = new Simditor({
      textarea: $('#resultado_texto_procedimento')
    });
    editor.setValue('');
    $("#modalRealizarProcedimento").toggleClass("show");
    $("#modalRealizarProcedimento").css("display", "none");
  }

  confirmarCancelar = () => {
    if (!$('#motivo_cancelamento').val()) {
      return;
    }
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja cancelar esse procedimento?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();

          var obj = {
            "presenca_id": this.presenca.id,
            "texto": $('#motivo_cancelamento').val()
          }
          this.service.Post(`Procedimento/${this.procedimetoSelecionado.procedimentos[0].id}/Cancelado`, obj).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaAbaProcedimento();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  obterListaAbaProcedimento = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.atendimento.id}/Solicitacao?tipo_solicitacao=PROCEDIMENTO`).then(res => {
      this.listaProcedimento = res;
      this.fecharModalCancelamento();
      this.fecharModalRealizar();
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  confirmarProcedimento = () => {
    if (!$('#resultado_texto_procedimento').val() && !this.procedimento.resultado_em) {
      return;
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = [
      {
        "presenca_id": this.presenca.id,
        "resultado": $('#resultado_texto_procedimento').val(),
        "resultado_em": `${this.procedimento.resultado_em.split("/")[2]}-${this.procedimento.resultado_em.split("/")[1]}-${this.procedimento.resultado_em.split("/")[0]}`,
        "tipo_resultado": "TEXTO"
      }
    ]
    this.service.Post(`Procedimento/${this.procedimetoSelecionado.procedimentos[0].id}/Resultado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.obterListaAbaProcedimento();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

}
