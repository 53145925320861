import { Component, Input, OnInit } from '@angular/core';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.css']
})
export class CertificateListComponent implements OnInit {

  @Input() ListaAbaAtestado: any = [];
  @Input() prontuario: any = [];

  atestado: any = {};
  dadosImpresso: any;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario: any = false;
  constructor(private global: Global,
    public service: ApiService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    private authService: AuthService) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  visualizarAtestado(atestado) {
    this.atestado = atestado;
    // if (atestado.tipo_atestado == 'PADRAO')
    //   this.atestado.texto = `Atesto que o paciente ${this.atestado.cidadao_nome} necessita de ${this.atestado.dias.toString()} dia(s) de afastamento do trabalho por motivo de doença, a partir do dia ${this.global.formateDate(this.atestado.criado_em)}.`;
    $("#modalVisualizarAtestado").toggleClass("show");
    $("#modalVisualizarAtestado").css("display", "block");
  }

  fecharModal = () => {
    $("#modalVisualizarAtestado").toggleClass("show");
    $("#modalVisualizarAtestado").css("display", "none");
  }


  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(atestado_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`ProntuarioAtestado/${atestado_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }

  prepararImpressoHtml(dadosImpresso) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "ATESTADO")}
        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>ATESTADO</h4>
                </td>
            </tr>
        </table>
        <table>                     
             <tr>   
             <td>
                ${dadosImpresso.conteudo.texto}
             </td>              
            </tr>
        </table>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

}
