import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var jquery: any;
declare var $: any;
declare var FullCalendar: any;
import Swal from 'sweetalert2';
import * as moment from "moment";
@Component({
  selector: 'app-gestao-escalas-adm',
  templateUrl: './gestao-escalas-adm.component.html',
  styleUrls: ['./gestao-escalas-adm.component.css', './gestao-escalas-adm.component.scss']
})
export class GestaoEscalasAdmComponent implements OnInit {
  usuario: any = {};
  presenca: any = null;
  profissionalSelecionado: any = 0;
  ocupacaoSelecionada: any = 0;
  eventos: any = [];
  estabelecimento_id: any = 0;
  estabelecimentoSelecionado: any = 0;
  data_inicial: any;
  data_final: any;
  calendar: any;
  calendar_: any;


  filtro: any = '';
  resultCidadao: any = [];
  pagina: any = 1;
  totalPorPagina: any = 300;
  totalPagina: any = 1;

  cidadado_selecionado: any = null;
  horario_selecionado: any = null;
  listaProfissional: any = [];
  listaOcupacoes: any = [];
  ListaEstabelecimentos: any = [];
  profissional: any = null;
  ocupacao: any = null;
  horarios: any = [];
  numHours = 5; // Substitua pelo número real de horas
  timeHeight = '30px'; // Substitua pela altura real


  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnInit() {
    const dataAtual = moment();
    this.data_inicial = dataAtual.format('YYYY-MM-DD');
    if (this.presenca) {
      this.ocupacaoSelecionada = this.presenca.ocupacao_id;
      this.estabelecimento_id = this.presenca.estabelecimento_id;
      this.estabelecimentoSelecionado = this.presenca.estabelecimento_id;
      this.getOcupacao();
    } else {
      this.getEstabelecimentos();
    }
  }


  getEstabelecimentos = () => {
    this.service.Get(`Estabelecimento?Ativo=true`).then(
      result => {
        this.ListaEstabelecimentos = result;
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  estabelecimentoTrocado = () => {
    this.ocupacaoSelecionada = 0;
    this.getOcupacao();
    this.obterAgendaDia();
  }

  getOcupacao = () => {
    if (!this.estabelecimentoSelecionado) {
      return
    }
    this.service.Get(`Estabelecimento/${this.estabelecimentoSelecionado}/Ocupacao`).then(
      result => {
        this.listaOcupacoes = result;
        if (this.presenca && this.presenca.ocupacao_id) {
          this.getProfissionais(this.presenca.ocupacao_id, true);
        }
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  getProfissionais = (ocupacao_id, primeira = false) => {
    if (!ocupacao_id) {
      return
    }
    this.service.Get(`Profissional?OcupacaoId=${ocupacao_id}&EstabelecimentoId=${this.estabelecimentoSelecionado}`).then(
      result => {
        this.listaProfissional = result;
        if (primeira) {
          this.profissionalSelecionado = this.usuario.usuario_profissional_id;
        }
        this.obterAgendaDia();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }


  gridTemplateRows(): string {
    return `repeat(${this.numHours}, ${this.timeHeight})`;
  }

  startCalendarPick() {
    let that = this;
    var calendarEl = document.getElementById('calendar_pick');

    this.calendar_ = new FullCalendar.Calendar(calendarEl, {
      locale: 'pt',
      aspectRatio: 1,
      headerToolbar: {
        left: 'prev,next',
        center: '',
        right: 'title'
      },
      titleFormat: { month: 'long' },
      initialDate: this.data_inicial,
      navLinks: false, // can click day/week names to navigate views
      businessHours: false, // display business hours
      editable: false,
      selectable: false,
      dateClick: function (info) {
        that.data_inicial = info.dateStr;
        that.obterAgendaDia();
      }
    });

    this.calendar_.render();
    this.calendar_.setOption('locale', 'pt');
  }

  pesquisarAgenda() {
    const [menorStart, maiorStart] = this.encontrarHorariosAtendimento(this.eventos);
    if (menorStart && maiorStart) {
      // Criar objetos moment para o menor e maior horário
      const menorMoment = moment.utc(menorStart);
      const maiorMoment = moment.utc(maiorStart);

      // Inicializar array de datas com o menor horário
      const datas = [menorMoment.format("HH:mm")];

      // Adicionar datas ao array em intervalos de 15 minutos até o maior horário
      while (menorMoment.add(5, 'minutes').isBefore(maiorMoment)) {
        datas.push(menorMoment.format("HH:mm"));
      }
      datas.push(maiorMoment.format("HH:mm"));

      this.numHours = datas.length;
      this.horarios = datas;
    } else {
      console.log(`Não há horários de atendimento disponíveis.`);
    }
    this.startCalendarPick();
  }

  getStart(item) {
    if (!item) {
      return;
    }

    const targetTime = moment(item.start).format("HH:mm");
    const index = this.horarios.findIndex(horario => horario === targetTime);

    if (index !== -1) {
      return (index + 1).toString();
    }
    // Se não encontrar uma correspondência exata, encontre o horário mais próximo
    const closestIndex = this.horarios.reduce((closest, horario, currentIndex) => {
      const currentDiff = Math.abs(moment(horario, "HH:mm").diff(moment(targetTime, "HH:mm"), 'minutes'));
      const closestDiff = Math.abs(moment(this.horarios[closest], "HH:mm").diff(moment(targetTime, "HH:mm"), 'minutes'));

      return currentDiff < closestDiff ? currentIndex : closest;
    }, 0);


    return (closestIndex + 1).toString();
  }

  getEnd(item) {
    if (!item) {
      return;
    }

    const targetTime = moment(item.end).format("HH:mm");
    const index = this.horarios.findIndex(horario => horario === targetTime);

    if (index !== -1) {
      return (index + 1).toString();
    }

    // Se não encontrar uma correspondência exata, encontre o horário mais próximo
    const closestIndex = this.horarios.reduce((closest, horario, currentIndex) => {
      const currentDiff = Math.abs(moment(horario, "HH:mm").diff(moment(targetTime, "HH:mm"), 'minutes'));
      const closestDiff = Math.abs(moment(this.horarios[closest], "HH:mm").diff(moment(targetTime, "HH:mm"), 'minutes'));

      return currentDiff < closestDiff ? currentIndex : closest;
    }, 0);

    return (closestIndex + 1).toString();
  }

  obterAgendaDia = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();

    var url = `AgendaDia/Calendario?data_inicio=${this.data_inicial}&estabelecimento_id=${this.estabelecimentoSelecionado}&diario=true`;

    if (this.ocupacaoSelecionada != null && this.ocupacaoSelecionada != 0) {
      url = url += `&ocupacao_id=${this.ocupacaoSelecionada}`;
    }

    this.service.Get(url).then(
      result => {
        this.eventos = result;
        this.pesquisarAgenda();
        setTimeout(() => {
          this.iniciarScrollBar();
        }, 1000);
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  iniciarScrollBar = () => {
    const topScroll = document.querySelector('.top-scroll');
    const bottomScroll = document.querySelector('.bottom-scroll');
    const scrollContent = document.querySelector('.scroll-content');

    // Sincronizar a rolagem horizontal
    topScroll.addEventListener('scroll', function () {
      scrollContent.scrollLeft = topScroll.scrollLeft;
      bottomScroll.scrollLeft = topScroll.scrollLeft;
    });

    bottomScroll.addEventListener('scroll', function () {
      scrollContent.scrollLeft = bottomScroll.scrollLeft;
      topScroll.scrollLeft = bottomScroll.scrollLeft;
    });

    scrollContent.addEventListener('scroll', function () {
      topScroll.scrollLeft = scrollContent.scrollLeft;
      bottomScroll.scrollLeft = scrollContent.scrollLeft;
    });
  }

  encontrarHorariosAtendimento(agenda) {
    // Extrair todos os horários de todos os profissionais
    const todosHorarios = agenda.flatMap(profissional => profissional.horarios);

    if (todosHorarios.length === 0) {
      return [null, null]; // Retorna [null, null] se não houver horários disponíveis
    }

    const horariosMoment = todosHorarios.map(horario => moment.utc(horario.start))
    const horariosMenor = todosHorarios.map(horario => moment.utc(horario.end))
    // Encontrar o menor e o maior horário de start
    const menorStart = moment.min(horariosMoment).toISOString();
    const maiorStart = moment.max(horariosMoment).toISOString();


    const menorEnd = moment.min(horariosMenor).toISOString();
    const maiorEnd = moment.max(horariosMenor).toISOString();

    return [menorStart, maiorEnd];
  }

  abrirHorario(item) {
    let that = this;
    if (item.bloqueado) {
      return;
    }
    if (item.vago) {
      that.horario_selecionado = item;
      that.cidadado_selecionado = null;
      that.resultCidadao = [];
      that.filtro = "";
      that.profissional = that.listaProfissional.filter(x => x.id == that.profissionalSelecionado)[0];
      that.ocupacao = that.listaOcupacoes.filter(x => x.id == that.ocupacaoSelecionada)[0];
      $("#modalAgendamentoCalendario").toggleClass("show");
      $("#modalAgendamentoCalendario").css("display", "block");
    } else {
      that.horario_selecionado = item;
      that.cidadado_selecionado = null;
      that.profissional = that.listaProfissional.filter(x => x.id == that.profissionalSelecionado)[0];
      that.ocupacao = that.listaOcupacoes.filter(x => x.id == that.ocupacaoSelecionada)[0];
      $("#modalDetalhesAgendamento").toggleClass("show");
      $("#modalDetalhesAgendamento").css("display", "block");
      that.obterDetalhes();
    }
  }

  fecharModalCalendario() {
    this.resultCidadao = [];
    this.profissional = null;
    this.ocupacao = null;
    this.cidadado_selecionado = null;
    this.horario_selecionado = null;
    this.filtro = "";
    $("#modalAgendamentoCalendario").toggleClass("show");
    $("#modalAgendamentoCalendario").css("display", "none");
  }

  obterListaCidadaos = () => {
    if (this.filtro.length == 0) {
      Swal.fire('Erro', 'Favor digitar um filtro.', 'error');
      return;
    }

    var busca = this.filtro;
    if (!isNaN(parseInt(this.filtro[0])))
      var busca = this.global.removeMask(this.filtro);

    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Cidadao?Skip=${this.pagina}&Take=${this.totalPorPagina}&Busca=${busca}`).then(res => {
      this.resultCidadao = res['conteudo'];
      if (this.resultCidadao.length > 0) {
        this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
      }
      for (let cidadao of this.resultCidadao) {
        this.global.applyMask(cidadao)
      }
      Swal.close();
      if (this.resultCidadao.length == 0)
        Swal.fire({
          title: 'Atenção',
          text: `Esse paciente não foi encontrado em nosso sistema. Deseja cadastra-lo agora?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#dc3545'
        }).then(
          result => {
            if (result.isConfirmed) {
              $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
              $("#modalNovoCidadaoDemandaEspontanea").css("display", "block");
            }
          }
        );
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  fecharModal = () => {
    $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
    $("#modalNovoCidadaoDemandaEspontanea").css("display", "none");
  }

  retornoNovoCidadao = (cidadao) => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      html: 'Operação realizada com sucesso!'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.filtro = cidadao.nome;
          this.fecharModal();
          this.obterListaCidadaos();
        }
      }
    );
  }

  selecionarCidadao(cidadao) {
    this.cidadado_selecionado = cidadao;
  }

  confirmarAgenda() {
    var obj = {
      "cidadao_id": this.cidadado_selecionado.id,
      "agenda_hora_id": this.horario_selecionado.agenda_hora_id,
      "agenda_configuracao_id": this.horario_selecionado.agenda_configuracao_id,
      "agenda_inicio": this.horario_selecionado.start
    }

    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`AgendaDia/Agendar`, obj).then(
      result => {
        this.fecharModalCalendario();
        this.obterAgendaDia();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  voltarModalAgendamento() {
    this.cidadado_selecionado = null;
  }

  removerAgenda() {
    Swal.fire({
      title: 'Atenção',
      text: `Deseja realmente cancelar esse agendamento?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.deletarAgenda();
        }
      }
    );
  }

  deletarAgenda = () => {
    // agenda_hora_id
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Delete(`AgendaHora/${this.cidadado_selecionado.id}`).then(
      result => {
        this.fecharModalDetalhesCalendario();
        this.obterAgendaDia();
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  obterDetalhes = () => {
    // agenda_hora_id
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`AgendaHora/${this.horario_selecionado.agenda_hora_id}`).then(
      result => {
        this.cidadado_selecionado = result;
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  fecharModalDetalhesCalendario() {
    this.resultCidadao = [];
    this.profissional = null;
    this.ocupacao = null;
    this.cidadado_selecionado = null;
    this.horario_selecionado = null;
    $("#modalDetalhesAgendamento").toggleClass("show");
    $("#modalDetalhesAgendamento").css("display", "none");
  }

  confirmarPresenca() {
    var obj = { "cidadao_id": this.cidadado_selecionado.cidadao_id, "clinica_medica_id": this.presenca.clinica_medica_id, "profissional_id": this.presenca.profissional_id, "tipo_atendimento": "AGENDAMENTO", "agenda_hora_id": this.cidadado_selecionado.id }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`Atendimento`, obj).then(
      result => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Presença confirmada!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.obterAgendaDia();
              this.fecharModalDetalhesCalendario();
            }
          }
        );

      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  iniciarAtendimento() {
    // var obj = { "cidadao_id": this.cidadado_selecionado.cidadao_id, "clinica_medica_id": this.presenca.clinica_medica_id, "profissional_id": this.presenca.profissional_id, "tipo_atendimento": "AGENDAMENTO", "agenda_hora_id": this.cidadado_selecionado.id }
    Swal.fire('Iniciando Teleatendimento');
    Swal.showLoading();
    var obj = {
      "presenca_id": this.presenca.id,
      "cidadao_id": this.cidadado_selecionado.cidadao_id,
      "clinica_medica_id": this.presenca.clinica_medica_id,
      "profissional_id": this.presenca.profissional_id,
      "tipo_atendimento": "AGENDAMENTO",
      "agenda_hora_id": this.cidadado_selecionado.id,
      "iniciar_atendimento": true
    }
    this.service.Post(`Atendimento`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${res["id"]}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();

      if (res.error.messagem) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      }
    })
  }


  continuarAtendimento() {
    Swal.fire('Iniciando Teleatendimento');
    Swal.showLoading();
    var obj = {
      atendimento_id: this.cidadado_selecionado.atendimento_id,
      presenca_id: this.presenca.id
    }
    this.service.Put(`Atendimento/${this.cidadado_selecionado.atendimento_id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${this.cidadado_selecionado.atendimento_id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  obterClasse(h) {
    if (!h) {
      return ""
    }
    if (h.bloqueado) {
      return 'bloqueado'
    }

    if (h.atendimento_status == "ATENDIMENTO_ENCERRADO") {
      return 'encerrado'
    }
    if (h.atendido) {
      return 'atendido'
    }

    if (h.agenda_hora_id && !h.vago) {
      return 'agendado'
    }

    if (h.vago && h.local_atendimento == 'PRESENCIAL') {
      return 'presencial'
    }

    if (h.vago && h.local_atendimento == 'TELEATENDIMENTO') {
      return 'teleatendimento'
    }


  }

  prevDay() {
    var data_atual = moment(this.data_inicial);
    this.data_inicial = data_atual.subtract(1, 'days').format("YYYY-MM-DD");
    this.obterAgendaDia();
  }

  nextDay() {
    var data_atual = moment(this.data_inicial);
    this.data_inicial = data_atual.add(1, 'days').format("YYYY-MM-DD");
    this.obterAgendaDia();
  }
}
