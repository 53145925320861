import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-procedure-aba',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css']
})
export class ProcedureComponent implements OnInit {
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() listaProcedimento: any = [];
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() atendimento: any = {};

  seeList: boolean = true;
  visualizar_prontuario: any = false;
  constructor(private authService: AuthService,) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  goBack = () => {
    this.seeList = true;
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }

}
