import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ApiService } from '../../service/apiServices';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from '../../global'

@Component({
  selector: 'app-modal-auth-forwarding',
  templateUrl: './modal-auth-forwarding.component.html',
  styleUrls: ['./modal-auth-forwarding.component.css']
})
export class ModalAuthForwardingComponent implements OnInit {

  @Input() consulta: any = {};
  @Input() cidadao: any = {};
  @Output() resultPostModalAuth: EventEmitter<any> = new EventEmitter();
  criticidade: any = [{ 'id': 1, 'nome': 'ELETIVO' }, { 'id': 2, 'nome': 'PRIORIDADE' }, { 'id': 3, 'nome': 'URGENCIA' }, { 'id': 4, 'nome': 'EMERGENCIA' }];
  encaminhamento: any = {};
  currentUser: any = JSON.parse(localStorage.getItem('@DigtVentry:currentUser'));
  global_: any
  constructor(
    public service: ApiService,
    private alerts: NgxAlertsService,
    private global: Global
  ) {
    this.global_ = this.global
  }

  ngOnInit() {
    this.consulta.criticidade_ = 0;
    this.consulta.Ocupacao = {};
    this.consulta.Cidadao = {};
    this.consulta.Profissional = {};
    this.consulta.EstabelecimentoSolicitante = {};
    this.consulta.Cid = {};
  }

  ngOnChanges() {
    this.obterListaCid(this.consulta.cid_subcategoria_id)
  }

  fecharModal = () => {
    this.resultPostModalAuth.emit();
    $("#modalAutorizarEncaminhamento").toggleClass("show");
    $("#modalAutorizarEncaminhamento").css("display", "none");
  }

  obterListaCid = (cid) => {
    if (cid == 0 || cid == undefined || cid == null) {
      this.consulta.Cid = 'CID não informado'
      return;
    }
    this.service.Get(`CidSubCategoria/${cid}`).then(
      result => {
        this.consulta.Cid = result['codigo'] + ' - ' + result['nome']
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  salvarStatusRegulacao = (status) => {
    let obj = {
      "id": this.consulta.id,
      "estabelecimento_id_solicitante": this.consulta.estabelecimento_id_solicitante,
      "profissional_id": this.consulta.profissional_id,
      "ocupacao_id": this.consulta.ocupacao_id,
      "solicitado_em": this.consulta.solicitado_em,
      "observacao": this.consulta.observacao,
      "cidadao_id": this.consulta.cidadao_id,
      "usuario_id": this.currentUser.usuario_id,
      "criado_em": this.consulta.criado_em,
      "atualizado_em": this.consulta.atualizado_em,
      "ativo": this.consulta.ativo,
      "criticidade": this.consulta.criticidade,
      "status": status,
      "justificativa": this.consulta.justificativa,
      "motivo": this.consulta.motivo,
      "cid_subcategoria_id": this.consulta.cid_subcategoria_id,
      "equipe_ine": this.consulta.equipe_ine,
      "equipe_nome": this.consulta.equipe_nome,
      "acs_nome": this.consulta.acs_nome
    }

    if (this.consulta.cid_subcategoria_id == 0) {
      obj.cid_subcategoria_id = null
    }

    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`RegulacaoConsulta/${obj.id}`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.fecharModal();
              this.resultPostModalAuth.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

}
