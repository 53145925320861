import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-schedule-configuration',
  templateUrl: './schedule-configuration.component.html',
  styleUrls: ['./schedule-configuration.component.css']
})
export class ScheduleConfigurationComponent implements OnInit {

  configAgenda: any = {};
  profissionais: any = [];
  estabelecimentos: any = [];
  ocupacoes: any = [];
  listaTempoAtendimento: any = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  uid: any = 0;
  loading: boolean = false;
  editando: boolean = false;
  listaLocalAtendimento: any = [];

  constructor(
    public service: ApiService,
    private alerts: NgxAlertsService,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService
  ) { }

  async init() {
    this.configAgenda.profissional_id = 0;
    this.configAgenda.ocupacao_id = 0;
    this.configAgenda.estabelecimento_id = 0;
    this.configAgenda.local_atendimento = 1;
    this.obterListaProfissionais();
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterConfiguracaoAgenda(uid);
    } else {
      this.configAgenda.tipo = '1';
      this.configAgenda.segunda_feira = false;
      this.configAgenda.terca_feira = false;
      this.configAgenda.quarta_feira = false;
      this.configAgenda.quinta_feira = false;
      this.configAgenda.sexta_feira = false;
      this.configAgenda.sabado = false;
      this.configAgenda.domingo = false;
      this.configAgenda.tempo_atendimento = 0;

    }
    this.obterListaLocalAtendimento();
  }
  ngOnInit() {
    this.init();
  }

  salvarConfigAgendamento = () => {
    if (!this.validaPostConfigAgendamento())
      return;
    this.configAgenda.hora_inicio = this.configAgenda.hora_inicio[0] + this.configAgenda.hora_inicio[1] + ":" + this.configAgenda.hora_inicio[2] + this.configAgenda.hora_inicio[3];
    this.configAgenda.hora_fim = this.configAgenda.hora_fim[0] + this.configAgenda.hora_fim[1] + ":" + this.configAgenda.hora_fim[2] + this.configAgenda.hora_fim[3];
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Post(`AgendaConfiguracao`, this.configAgenda).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Configuração de agenda salva com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  editarConfigAgendamento = () => {
    if (!this.validaPostConfigAgendamento())
      return;
    this.configAgenda.hora_inicio = this.configAgenda.hora_inicio[0] + this.configAgenda.hora_inicio[1] + ":" + this.configAgenda.hora_inicio[2] + this.configAgenda.hora_inicio[3];
    this.configAgenda.hora_fim = this.configAgenda.hora_fim[0] + this.configAgenda.hora_fim[1] + ":" + this.configAgenda.hora_fim[2] + this.configAgenda.hora_fim[3];
    //this.loading = true;
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`AgendaConfiguracao/${this.configAgenda.id}`, this.configAgenda).then(res => {
      this.loading = false;
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  goBack() {
    this.location.back();
  }

  validaPostConfigAgendamento = () => {
    if (this.configAgenda.nome == null || this.configAgenda.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome da agenda", 'error');
      return false;
    }
    if (this.configAgenda.estabelecimento_id == 0) {
      Swal.fire('Erro', "Favor selecionar o estabelecimento", 'error');
      return false;
    }
    if (this.configAgenda.ocupacao_id == 0) {
      Swal.fire('Erro', "Favor selecionar a ocupação", 'error');
      return false;
    }
    if (!this.configAgenda.domingo && !this.configAgenda.segunda_feira && !this.configAgenda.terca_feira && !this.configAgenda.quarta_feira &&
      !this.configAgenda.quinta_feira && !this.configAgenda.sexta_feira && !this.configAgenda.sabado) {
      Swal.fire('Erro', "Favor selecionar pelo menos um dia da semana", 'error');
      return false;
    }
    if (this.configAgenda.hora_inicio == null || this.configAgenda.hora_inicio.length != 4) {
      Swal.fire('Erro', "Favor informar uma hora de inicio válida", 'error');
      return false;
    }
    if (this.configAgenda.hora_fim == null || this.configAgenda.hora_fim.length != 4) {
      Swal.fire('Erro', "Favor informar uma hora fim válida", 'error');
      return false;
    }

    var hi = Number(this.configAgenda.hora_inicio[0] + this.configAgenda.hora_inicio[1]);
    var mi = Number(this.configAgenda.hora_inicio[2] + this.configAgenda.hora_inicio[3]);

    if (hi > 23 || mi > 59) {
      Swal.fire('Erro', "Favor informar uma hora de inicio válida", 'error');
      return false;
    }

    var hf = Number(this.configAgenda.hora_fim[0] + this.configAgenda.hora_fim[1]);
    var mf = Number(this.configAgenda.hora_fim[2] + this.configAgenda.hora_fim[3]);

    if (hf > 23 || mf > 59) {
      Swal.fire('Erro', "Favor informar uma hora final válida", 'error');
      return false;
    }

    if (this.configAgenda.tempo_atendimento == 0) {
      Swal.fire('Erro', "Favor informar o tempo de atendimento", 'error');
      return false;
    }
    if (this.configAgenda.profissional_id == 0)
      delete this.configAgenda.profissional_id;

    return true;
  }


  obterListaProfissionais = () => {

    this.service.Get(`Profissional`).then(res => {
      this.profissionais = res;
      this.obterListaEstabelecimentos();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterOcupacaoPorProfissional = () => {
    if (!this.configAgenda.profissional_id || this.configAgenda.profissional_id == 0) {
      return;
    }

    if (!this.configAgenda.estabelecimento_id || this.configAgenda.estabelecimento_id == 0) {
      return;
    }

    this.service.Get(`Estabelecimento/${this.configAgenda.estabelecimento_id}/Ocupacao?profissional_id=${this.configAgenda.profissional_id}`).then(res => {
      this.ocupacoes = res;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaEstabelecimentos = () => {
    this.service.Get(`Estabelecimento`).then(res => {
      this.estabelecimentos = res;
      // this.obterListaOcupacao();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaOcupacao = () => {
    this.service.Get(`Ocupacao`).then(res => {
      this.ocupacoes = res;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterConfiguracaoAgenda = (id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`AgendaConfiguracao/${id}`).then(res => {
      Swal.close();
      this.configAgenda = res;
      if (this.configAgenda.tipo == "ATENDIMENTO")
        this.configAgenda.tipo = '1';
      else
        this.configAgenda.tipo = '2';

      if (this.configAgenda.local_atendimento == "TELEATENDIMENTO")
        this.configAgenda.local_atendimento = '2';
      else
        this.configAgenda.local_atendimento = '1';
      this.editando = true;

    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  FormataHora = (inicioFim) => {
    if (inicioFim == 1) {
      if (this.configAgenda.hora_inicio != null) {
        if (this.configAgenda.hora_inicio.length == 1)
          this.configAgenda.hora_inicio = `0${this.configAgenda.hora_inicio}00`;
        if (this.configAgenda.hora_inicio.length == 2)
          this.configAgenda.hora_inicio = `${this.configAgenda.hora_inicio}00`;
        if (this.configAgenda.hora_inicio.length == 3)
          this.configAgenda.hora_inicio = `${this.configAgenda.hora_inicio}0`;
      }
    } else if (inicioFim == 2) {
      if (this.configAgenda.hora_fim != null) {
        if (this.configAgenda.hora_fim.length == 1)
          this.configAgenda.hora_fim = `0${this.configAgenda.hora_fim}00`;
        if (this.configAgenda.hora_fim.length == 2)
          this.configAgenda.hora_fim = `${this.configAgenda.hora_fim}00`;
        if (this.configAgenda.hora_fim.length == 3)
          this.configAgenda.hora_fim = `${this.configAgenda.hora_fim}0`;
      }
    }
  }


  obterListaLocalAtendimento = () => {
    this.service.Get(`AgendaConfiguracao/LocalAtendimento`).then(res => {
      Swal.close();
      this.listaLocalAtendimento = res;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

}
