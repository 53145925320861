import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var Simditor: any;
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';

@Component({
  selector: 'app-emergency-prescription-registration',
  templateUrl: './emergency-prescription-registration.component.html',
  styleUrls: ['./emergency-prescription-registration.component.css']
})
export class EmergencyPrescriptionRegistrationComponent implements OnInit {

  prescricao: any = {};
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Output() goBack = new EventEmitter();
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();

  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]
  usuario: any = {};
  listFavoriteText: any = [];

  constructor(private authService: AuthService,
    public service: ApiService) {
    Simditor.locale = 'en-US';
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
  }

  ngOnInit() {
    var that = this;
    setTimeout(() => {
      var descricao = new Simditor({
        textarea: $('#descricaoPrescricaoUrgencia'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
    }, 1000);

  }

  salvarPrescricao = () => {
    if (!this.validaPostPrescricao())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    var p = {
      "texto": $('#descricaoPrescricaoUrgencia').val(),
      "presencaId": this.presenca.id
    }
    this.service.Post(`Prontuario/${this.prontuario.id}/PrescricaoUrgencia`, p).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Prescrição salva com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.voltar();
              this.retornoPost.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostPrescricao = () => {
    if (this.prontuario.id == null) {
      Swal.fire('Erro', "Prontuario não encontrado.", 'error');
      return false;
    }
    var texto = $('#descricaoPrescricaoUrgencia').val();
    if (texto == null || texto == "") {
      Swal.fire('Erro', "Favor informar um texto de prescrição.", 'error');
      return false;
    }

    return true;
  }

  voltar = () => {
    this.prescricao = {};
    $('#descricaoPrescricaoUrgencia').val("");
    this.goBack.emit();
  }

  openFavoritesList = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Get(`TextoFavorito?UsuarioId=${this.usuario.usuario_id}&TipoTextoFavorito=PRESCRICAO`).then(
      result => {
        this.listFavoriteText = result;
        Swal.close()
        $("#modalListaTextosPrescricaoFavoritos").toggleClass("show");
        $("#modalListaTextosPrescricaoFavoritos").css("display", "block");
      },
      error => {
        Swal.fire('Error', error.error.messagem, 'error')
      }
    )

  }

  utilizarTextoSelecionado = (item) => {
    this.prescricao = item
    var $texto = item.texto
    var editor = new Simditor({
      textarea: $('#descricaoPrescricaoUrgencia')
    })
    editor.setValue(item.texto);
    this.closeFavorites();
  }

  closeFavorites = () => {
    $("#modalListaTextosPrescricaoFavoritos").toggleClass("show");
    $("#modalListaTextosPrescricaoFavoritos").css("display", "none");
  }
  


}
