import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { BsLocaleService, BsDatepickerConfig  } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);
import * as $ from "jquery";

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css']
})
export class DateRangeComponent implements OnInit {
  currentDate = new Date();
  locale = 'pt-br';
  colorTheme = 'theme-dark-blue';
  bsConfig?: Partial<BsDatepickerConfig>;

  periodo: Date[];

  myForm?: FormGroup;

  @Input() tipoPeriodo: string;
  @Output() emitePeriodo = new EventEmitter<any>();  
  @Input() limparPeriodo: any = {};

  inputPeriodo: any = '';

  constructor(
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      date: null,
      range: null
    });
    this.applyConfig();
  }

  ngOnChanges() {
    
    this.periodo = this.limparPeriodo
    
    $('#periodo').val('')
  }

  applyConfig = () => {
    this.bsConfig = { 
      containerClass: this.colorTheme, 
      dateInputFormat: 'DD/MM/YYYY',
      isAnimated: true,
      adaptivePosition: true,
      customTodayClass: 'custom-today-class',
      selectFromOtherMonth: true,
      showWeekNumbers: false
    };
    this.localeService.use(this.locale);
  }

  setPeriodo = (event:any) => {
    this.periodo = [];
    if (event != null){
      for (let item of event){
        item = item.toLocaleDateString()
        this.periodo.push(item)
      }
    }
    this.emitePeriodo.emit(this.periodo)
  }

  typeDate = (event: any) => {
  }


}