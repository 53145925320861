import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.css']
})
export class StorageComponent implements OnInit {
  menus: any = [];
  constructor(private authService: AuthService,private router: Router) {
    this.authService.menus.subscribe((res) => {
      if (res) {        
        this.menus = res.filter(x=>x.id == 9)[0];
      }
    })
  }

  ngOnInit() {
  }

  goToLink(url){
    this.router.navigate([`/${url}`]);
  }

}
