import { Component, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-modal-escala-glasgow',
  templateUrl: './modal-escala-glasgow.component.html',
  styleUrls: ['./modal-escala-glasgow.component.css']
})
export class ModalEscalaGlasgow implements OnChanges {

  @Output() resultEmiter: EventEmitter<any> = new EventEmitter();

  escala: any = {
    abertura_ocular: 0,
    resposta_verbal: 0,
    resposta_motora: 0,
    escala_glasgow: 0
  };
  constructor() { }

  ngOnChanges() {

  }

  // close modal 
  fecharModal = () => {
    $("#modalEscalaGlasgow").toggleClass("show");
    $("#modalEscalaGlasgow").css("display", "none");
  }

  checkStatus() {
    this.escala.escala_glasgow = parseInt(this.escala.abertura_ocular) + parseInt(this.escala.resposta_verbal) + parseInt(this.escala.resposta_motora);
    this.resultEmiter.emit(this.escala.escala_glasgow);
  }


}
