import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../service/apiServices';

import { catchError } from 'rxjs/operators';
import { Endereco, CEPError, CEPErrorCode, NgxViacepService } from '@brunoc/ngx-viacep';

import * as $ from "jquery";
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import Swal from 'sweetalert2';
import { Global } from '../../global';

import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-modal-citizen-registration',
  templateUrl: './modal-citizen-registration.component.html',
  styleUrls: ['./modal-citizen-registration.component.css']
})
// export class ModalCitizenRegistrationComponent implements OnChanges {
export class ModalCitizenRegistrationComponent implements OnInit {
  @Input() cidadao: any = {};
  @Output() retornaCidadao: EventEmitter<any> = new EventEmitter();
  @Output() cancelaCadastro: EventEmitter<any> = new EventEmitter();

  buscandoPorCep: boolean = false;
  listaEstadosCarregada: boolean = false;
  estados: any = [];
  municipios: any = [];
  loading: boolean = false;
  existe_cidadao: boolean = false;
  generos: any = [];

  @Input() tipoRegulacao: any;

  busca: any;
  @Input() dadoBusca: any;
  regulacao: any;

  solicitacao: boolean = false;
  cidadaoCriado: any;

  @Input() fechaModal: boolean;

  constructor(public service: ApiService,
    private global: Global,
    private alerts: NgxAlertsService,
    private viacep: NgxViacepService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.regulacao = this.tipoRegulacao;
    this.busca = this.dadoBusca
    if (this.busca) {
      if (this.busca.length == 14) {
        this.cidadao.cpf = this.busca
      }
      if (this.busca.length == 18) {
        this.cidadao.cns = this.busca
      }
    }
    if (this.fechaModal) {
      this.limparCampos()
      this.obterListaEstados();
    }
    this.obterListaEstados();
  }

  fecharModal = () => {
    this.cidadao = {};
    this.cidadao.estado_id = 0;
    this.cidadao.municipio_id = 0;
    $("#modalCadCidadao").toggleClass("show");
    $("#modalCadCidadao").css("display", "none");
  }

  obterListaEstados = () => {
    if (this.listaEstadosCarregada)
      return
    this.cidadao.estado_id = 0;
    this.cidadao.municipio_id = 0;
    this.service.Get(`Estado`).then(res => {
      this.estados = res;
      this.listaEstadosCarregada = true;
      this.obterListaGenero();
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }


  obterListaMunicipios = (estado_id, cadsus = false) => {
    this.cidadao.municipio_id = 0;
    if (this.cidadao.municipio_id_ != null)
      this.cidadao.municipio_id = this.cidadao.municipio_id_;
    this.service.Get(`Estado/${estado_id}/Municipios`).then(res => {
      this.municipios = res;
      if (this.buscandoPorCep) {
        let municipioCidadao = this.cidadao.localidade;
        let municipio = this.municipios.filter(function (item) {
          return item.nome == municipioCidadao;
        });
        if (municipio != null && municipio.length > 0)
          this.cidadao.municipio_id = municipio[0].id;
        this.buscandoPorCep = false;
      }
      if (cadsus) {
        if (this.cidadao.endereco_cidade_nome) {
          var fil_m = this.municipios.filter(x => x.nome == this.cidadao.endereco_cidade_nome.toUpperCase());
          if (fil_m.length > 0) {
            this.cidadao.municipio_id = fil_m[0].id;
          }
        }
      }

    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }


  buscarCep = () => {
    if (this.cidadao.cep == null || this.cidadao.cep.length != 8) {
      this.alerts.notifyError({
        body: "Favor informar um CEP válido",
        title: 'Erro'
      });
      return;
    }
    this.viacep
      .buscarPorCep(this.cidadao.cep)
      .pipe(
        catchError((error: CEPError) => {
          // Ocorreu algum erro :/
          return [{ 'erro': error.message }];
        })
      )
      .subscribe((endereco: Endereco) => {
        if (endereco['erro'] != null) {
          this.alerts.notifyError({
            body: endereco['erro'],
            title: 'Erro'
          });
          return
        }
        // Endereço retornado :)
        let estado = this.estados.filter(function (item) {
          return item.sigla == endereco.uf;
        });
        if (estado != null && estado.length > 0) {
          this.cidadao.estado_id = estado[0].id;
          this.cidadao.localidade = endereco.localidade.toUpperCase();
          this.buscandoPorCep = true;
          this.obterListaMunicipios(estado[0].id);
        }
        this.cidadao.bairro = endereco.bairro;
        this.cidadao.logradouro = endereco.logradouro;

      });
  }

  salvarCidadao = () => {
    this.cidadao.sexo = this.cidadao.sexo_id;
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.validaPostCidadao(this.cidadao).then(
      result => {
        var cidadao: any = {}
        cidadao = result;
        //cidadao.Endereco = {};
        //cidadao.Endereco = {
        //  "logradouro": this.cidadao.logradouro,
        //  "bairro": cidadao.bairro,
        //  "numero": cidadao.numero,
        //  "cep": cidadao.cep,
        //  "complemento": cidadao.complemento,
        //  "estado_id": cidadao.estado_id,
        //  "municipio_id": cidadao.municipio_id
        //};
        this.service.Post(`Cidadao`, cidadao).then(
          result => {
            this.fecharCadastro(result)
          },
          error => {
            this.emitError(error);
          }
        )
      },
      error => {
        this.emitError(error);
      }
    )
  }

  validaPostCidadao = (cidadao) => {
    return new Promise((resolve, reject) => {
      if (cidadao.nome == null || cidadao.nome == "") {
        reject("Favor preencher o nome do paciente")
      }
      if (cidadao.nome_mae == null || cidadao.nome_mae == "") {
        reject("Favor preencher o nome da mãe")
      }
      if (cidadao.nascido_em_ == null || cidadao.nascido_em_.length != 10) {
        reject("Favor informar uma data de nascimento válida")
      }
      if (cidadao.nascido_em_) {
        cidadao.nascido_em = this.global.dateServerMask(cidadao.nascido_em_)
      }
      if (cidadao.estado_id == 0) {
        reject("Favor informar o estado")
      }
      if (cidadao.municipio_id == 0) {
        reject("Favor informar o município")
      }
      if (cidadao.bairro == null || cidadao.bairro == "") {
        reject("Favor informar o bairro")
      }
      if (cidadao.logradouro == null || cidadao.logradouro == "") {
        reject("Favor informar o logradouro")
      }
      if (this.cidadao.sexo_id == null || this.cidadao.sexo_id == 0) {
        this.alerts.notifyError({
          body: "Favor preencher o gênero",
          title: 'Erro'
        });
        return false;
      }
      //if (cidadao.telefone == null && cidadao.telefone.length == 0) {
      //// if (cidadao.telefone_numero != null && cidadao.telefone_numero.length > 0 && cidadao.telefone_numero.length != 13) {
      //  reject("Favor informar um telefone válido")
      //}
      //if (cidadao.celular == null && cidadao.celular.length == 0) {
      //// if (cidadao.celular_numero != null && cidadao.celular_numero.length > 0 && cidadao.celular_numero.length != 14) {
      //  reject("Favor informar um celular válido")
      //}
      //if (cidadao.telefone_numero != null || cidadao.celular_numero != null) {
      //  cidadao.telefones = [];
      //  if (cidadao.telefone_numero != null && cidadao.telefone_numero.length != 0) {
      //    let t = cidadao.telefone_numero;
      //    cidadao.telefones.push({
      //      "tipo": "FIXO",
      //      "codigo_pais": '55',
      //      "codigo_area": t[0] + t[1],
      //      "numero": t[2] + t[3] + t[4] + t[5] + t[6] + t[7] + t[8] + t[9]
      //    });
      //  }
      //  if (cidadao.celular_numero != null && cidadao.celular_numero.length != 0) {
      //    let c = cidadao.celular_numero;
      //    cidadao.telefones.push({
      //      "tipo": "CELULAR",
      //      "codigo_pais": '55',
      //      "codigo_area": c[0] + c[1],
      //      "numero": c[2] + c[3] + c[4] + c[5] + c[6] + c[7] + c[8] + c[9] + c[10]
      //    });
      //  }
      //}
      //if (cidadao.telefones != null && cidadao.telefones.length == 0){
      //  delete cidadao.telefones;
      //}
      resolve(cidadao)
    })
  }

  limparCampos = () => {
    this.fecharModal();
  }

  emitError = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  fecharCadastro = (cidadao) => {
    this.cidadao = {};
    this.cidadao.estado_id = 0;
    this.cidadao.municipio_id = 0;
    this.retornaCidadao.emit(cidadao)
  }

  cancelarCadastro = () => {
    this.cancelaCadastro.emit()
  }

  obterListaGenero = () => {
    this.service.Get(`Cidadao/Genero`).then(res => {
      this.generos = res;
      this.cidadao.sexo_id = 0;
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }


  searchCadSus = (dado, tipo) => {
    var url;

    if (tipo == 1) {
      url = `Cidadao/Buscar?cpf=${dado}`;
    } else {
      url = `Cidadao/Buscar?cns=${dado}`;
    }
    this.service.GetAnotherPath(`https://cadsus.digt.app.br/`, url).then(res => {
      if (res[0]) {
        this.cidadao = res[0];
        this.cidadao.nascido_em_ = this.global.dateMask(res[0]['nascimento_em']);
        if (this.cidadao.genero) {
          var fil_g = this.generos.filter(x => x.nome == this.cidadao.genero.toUpperCase());
          if (fil_g.length > 0) {
            this.cidadao.sexo_id = fil_g[0].id;
          }
        }
        if (this.cidadao.endereco_cep) {
          this.cidadao.cep = this.cidadao.endereco_cep;
        }

        if (this.cidadao.endereco_cep) {
          this.cidadao.cep = this.cidadao.endereco_cep;
        }
        if (this.cidadao.endereco_estado_sigla) {
          var fil_es = this.estados.filter(x => x.sigla == this.cidadao.endereco_estado_sigla.toUpperCase());
          if (fil_es.length > 0) {
            this.cidadao.estado_id = fil_es[0].id;
            this.obterListaMunicipios(this.cidadao.estado_id, true);
          }
        }
        if (this.cidadao.endereco_bairro) {
          this.cidadao.bairro = this.cidadao.endereco_bairro;
        }
        if (this.cidadao.endereco_numero) {
          this.cidadao.numero = this.cidadao.endereco_numero;
        }
        if (this.cidadao.endereco_logradouro) {
          this.cidadao.logradouro = this.cidadao.endereco_logradouro;
        }
        if (res[0].telefone) {          

          var fone: any;

          fone = res[0].telefone.replace(/\D/g, '');
          

          if (fone.length == 13) {
            this.cidadao.telefone = ''
            this.cidadao.celular = fone.slice(-11)
          }
          else {
            this.cidadao.celular = ''
            this.cidadao.telefone = fone.slice(-10)
          }
        }
      }


    }).catch(res => {

      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      } else if (res.status == 523 || res.error.status == 523) {
        Swal.close()
        Swal.fire('Erro', `Sistema de busca está offline, favor tentar novamente mais tarde`, 'error');
      } else {
        Swal.fire('Erro', `${res.error}`, 'error');
      }
    });
  }

}
