import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var Simditor: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prescricao',
  templateUrl: './prescricao.component.html',
  styleUrls: ['./prescricao.component.css']
})
export class PrescricaoComponent implements OnInit {
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() listaPrescricao: any = [];
  seeList: boolean = true;
  listaFavoritos: any = [];
  prescricao: any = {};
  usuario: any = {};
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]
  visualizar_prontuario: any = false;
  constructor(private authService: AuthService,
    public service: ApiService) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
  }

  goBack = () => {
    this.seeList = true;
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }

  ObterListaFavorito() {

  }
}
