import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  menus: any = [];
  constructor(private authService: AuthService, private router: Router) {
    this.authService.menus.subscribe((res) => {
      if (res) {
        this.menus = res.filter(x => x.id == 8)[0];
      }
    })
  }

  ngOnInit() {
  }

  goToLink(url) {
   
    var rota = `/${url}`
    this.router.navigate([rota]);
  }
}
