import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
@Component({
  selector: 'app-referral-to-specialist-list',
  templateUrl: './referral-to-specialist-list.component.html',
  styleUrls: ['./referral-to-specialist-list.component.css']
})
export class ReferralToSpecialistListComponent implements OnInit {
  @Input() listaEncaminhamentos: any = [];
  @Input() prontuario: any = {};
  @Input() atendimento: any = {};
  dadosImpresso: any;
  global_: any;
  encaminhamento: any;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario: any;
  constructor(private global: Global,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService) {
    this.global_ = global;
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  visualizarItem = (item) => {
    this.encaminhamento = item;
    $("#modalVisualizarEncaminhamento").toggleClass("show");
    $("#modalVisualizarEncaminhamento").css("display", "block");
  }


  fecharModal = () => {
    $("#modalVisualizarEncaminhamento").toggleClass("show");
    $("#modalVisualizarEncaminhamento").css("display", "none");
  }

  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(encaminhamento_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Encaminhamento/${encaminhamento_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('encaminhamento_impresso');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('encaminhamento_impresso');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
      }
    });
  }


  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <tr>
  <td>
      <p>${element.cid_subcategoria_codigo}</p>
  </td>
  <td>
      <p>${element.cid_subcategoria_nome}</p>
  </td>  
  </tr>
  `
    });
    return td;
  }


  prepararImpressoHtml(dadosImpresso) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "ENCAMINHAMENTO")}
        <table>
        <tr colspan="10">
            <td class="cabecalho">
                <h4>JUSTIFICATIVA DA REFERÊNCIA</h4>
            </td>
        </tr>
    </table>
    <table>
    <tr>        
        <td>
            <b> Código </b>
        </td>
        <td>
            <b> CID </b>
        </td>       
    </tr>
    <tr>
        ${this.gerarTabela(dadosImpresso.conteudo.cids)}
    </tr>
</table>
        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>MOTIVO ENCAMINHAMENTO</h4>
                </td>
            </tr>
        </table>
        <table>                     
             <tr>   
             <td>
                ${dadosImpresso.conteudo.motivo_encaminhamento}
             </td>              
            </tr>
        </table>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

  obterlistaEncaminhamentos = () => {
    if (!this.atendimento) {
      return;
    }
    this.service.Get(`Atendimento/${this.atendimento.id}/Encaminhamento`).then(
      result => {
        this.listaEncaminhamentos = result;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }


  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse encaminhamento?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Encaminhamento/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterlistaEncaminhamentos();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

}
