import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
declare var Simditor: any;

@Component({
  selector: 'app-attendance-aba',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  @Input() prontuario: any = {};
  @Input() listaCondutas: any = [];
  @Input() condutaSelecionada: any = {};
  @Input() anamnese: any = {};
  @Input() listaCid: any = [];
  @Input() listaCondutaSelecionada: any = [];
  @Output() obterAnamnese: EventEmitter<any> = new EventEmitter();
  @Input() abaAnamneseSelecionada: any = true;

  @Input() classificacao: any = {};
  @Input() classificacaoEditando: any = {}
  @Input() cidadao: any = {};
  @Input() usuario: any;
  @Input() presenca: any;
  @Input() atendimento: any;
  visualizar_prontuario: any = false;
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  constructor(private authService: AuthService,
    public service: ApiService) {
    Simditor.locale = 'en-US';
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
    var that = this;
    setTimeout(() => {
      var descricao = new Simditor({
        textarea: $('#queixaPrincipalAnamnese'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
    }, 1000);
  }

  sendGlasglow = (glasglow: any) => {    
    this.classificacaoEditando.escala_glasgow = glasglow;
  }

  sendEscalaDor = (escala_dor: any) => {    
    this.classificacaoEditando.escala_dor = escala_dor;
  }

  openModalGlasgow = () => {
    $("#modalEscalaGlasgow").toggleClass("show");
    $("#modalEscalaGlasgow").css("display", "block");
  }

  openModalClassificacaoDor = () => {
    $("#modalEscalaDor").toggleClass("show");
    $("#modalEscalaDor").css("display", "block");
  }

  openModalClassificaoRisco = () => {
    $("#modalClassificacaoRisco").toggleClass("show");
    $("#modalClassificacaoRisco").css("display", "block");
  }

  adicionarCid(item) {
    if (!item) return;
    if (this.listaCid.length == 0) {
      item.cid_subcategoria_id = item.id;
      item.cid_subcategoria_codigo = item.codigo;
      item.cid_subcategoria_nome = item.nome;
      item.principal = false;
      item.diagnostico_confirmado = false;
      item.doenca_cronica = false;
      item.novo = true;
      this.listaCid.push(item);
    }
    else {
      var repetido = false;
      this.listaCid.forEach((s) => {
        if (s.id == item.id)
          repetido = true;
      });
      if (!repetido) {
        item.cid_subcategoria_id = item.id;
        item.cid_subcategoria_codigo = item.codigo;
        item.cid_subcategoria_nome = item.nome;
        item.principal = false;
        item.diagnostico_confirmado = false;
        item.doenca_cronica = false;
        item.novo = true;
        this.listaCid.push(item);
      }
    }
  }

  selecionaCidPrincipal(index, selecionado) {
    if (!selecionado)
      return
    this.listaCid.forEach((item) => {
      item.principal = false;
    });
    this.listaCid[index].principal = true;
  }

  removerCid(i) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      html: 'A operação só será efetivada após clicar no botão de salvar!',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      result => {
        if (result.isConfirmed) {
          this.listaCid.splice(i, 1);
        }
      }
    );
  }

  adicionarConduta(item) {
    if (!item) return;
    if (item.id == 0) return;
    if (this.listaCondutaSelecionada.length == 0) {
      item.conduta_encaminhamento_id = item.id;
      item.conduta_encaminhamento_nome = item.nome;
      this.listaCondutaSelecionada.push(item);
    }
    else {
      var repetido = false;
      this.listaCondutaSelecionada.forEach((s) => {
        if (s.id == item.id)
          repetido = true;
      });
      if (!repetido) {
        item.conduta_encaminhamento_id = item.id;
        item.conduta_encaminhamento_nome = item.nome;
        this.listaCondutaSelecionada.push(item);
      }
    }
  }

  removerConduta(i) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      html: 'A operação só será efetivada após clicar no botão de salvar!',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      result => {
        if (result.isConfirmed) {
          this.listaCondutaSelecionada.splice(i, 1);
        }
      }
    );
  }

  salvarAnamnese = () => {
    if (!this.validaPostAnamnese())
      return;

    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.anamnese.queixa = $('#queixaPrincipalAnamnese').val();
    if (!this.anamnese.id) {
      this.anamnese.cids = [];
      this.anamnese.condutas = [];
      this.listaCid.forEach((item) => {
        this.anamnese.cids.push({
          "cid_subcategoria_id": item.cid_subcategoria_id,
          "principal": (item.principal != true ? false : item.principal),
          "doenca_cronica": (item.doenca_cronica != true ? false : item.doenca_cronica),
          "diagnostico_confirmado": (item.diagnostico_confirmado != true ? false : item.diagnostico_confirmado)
        });
      });
      this.listaCondutaSelecionada.forEach((item) => {
        this.anamnese.condutas.push({ 'conduta_encaminhamento_id': item.conduta_encaminhamento_id });
      });
      this.service.Post(`Prontuario/${this.prontuario.id}/Anamnese`, this.anamnese).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Anamnese salva com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.obterAnamnese.emit(this.prontuario.id);
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    } else
      this.atualizarAnamnese();
  }

  atualizarAnamnese(deleteCidConduta = false) {
    this.anamnese.prontuarioid = this.prontuario.id;
    var anamnesePut = Object.assign({}, this.anamnese);
    anamnesePut.cids = [];
    anamnesePut.condutas = [];
    this.listaCid.forEach((item) => {
      if (item.novo)
        anamnesePut.cids.push({
          "cid_subcategoria_id": item.cid_subcategoria_id,
          "principal": (item.principal != true ? false : item.principal),
          "doenca_cronica": (item.doenca_cronica != true ? false : item.doenca_cronica),
          "diagnostico_confirmado": (item.diagnostico_confirmado != true ? false : item.diagnostico_confirmado)
        });
      else {
        item.cid_subcategoria_id = item.cid_subcategoria_id;
        delete item.cid_subcategoria_nome;
        delete item.cid_subcategoria_codigo;
        anamnesePut.cids.push(item);
      }
    });

    this.listaCondutaSelecionada.forEach((item) => {
      if (!item.id)
        anamnesePut.condutas.push({ 'conduta_encaminhamento_id': item.conduta_encaminhamento_id });
      else
        anamnesePut.condutas.push(item);
    });
    this.service.Put(`Anamnese/${anamnesePut.id}`, anamnesePut).then(res => {
      setTimeout(() => {
        if (deleteCidConduta) {
          Swal.close();
          this.obterAnamnese.emit(this.prontuario.id);
        }

        //Swal.fire({
        //  icon: 'success',
        //  title: 'Sucesso!',
        //  html: 'Anamnese atualizado com sucesso!'
        //}).then(
        //  result => {
        //    if (result.isConfirmed) {
        //      //this.obterAnamnese.emit(this.prontuario.id);
        //    }
        //  }
        //);
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    });
  }

  validaPostAnamnese = () => {
    if (this.prontuario.id == null) {
      Swal.fire('Erro', "Prontuario não encontrado.", 'error');
      return false;
    }

    return true;
  }

  validarClassificacao = () => {

    if (!this.cidadao.id) {
      Swal.fire('Erro', "Paciente não encontrado, favor realizar login novamente e repetir o processo.", 'error');
      return;
    }

    if (!this.atendimento.id) {
      Swal.fire('Erro', "Atendimento não encontrado, favor realizar login novamente e repetir o processo.", 'error');
      return;
    }

    if (!this.presenca.id) {
      Swal.fire('Erro', "Presença não encontrada, favor realizar login novamente e repetir o processo.", 'error');
      return;
    }

    var obj = Object.assign({}, this.classificacaoEditando);
    var c = {
      "cidadao_id": this.cidadao.id,
      "atendimento_id": this.atendimento.id,
      "peso": obj.peso ? parseFloat(obj.peso) : null,
      "altura": obj.altura ? parseFloat(obj.altura) : null,
      "frequencia_cardiaca": obj.frequencia_cardiaca ? parseInt(obj.frequencia_cardiaca) : null,
      "saturacao_oxigenio": obj.saturacao_oxigenio ? parseInt(obj.saturacao_oxigenio) : null,
      "classificacao_prioridade": obj.classificacao_prioridade,
      "tipo_prioridade": obj.tipo_prioridade ? parseInt(obj.tipo_prioridade) : null,
      "sistole": obj.sistole ? parseFloat(obj.sistole) : null,
      "diastole": obj.diastole ? parseFloat(obj.diastole) : null,
      "temperatura": obj.temperatura ? parseFloat(obj.temperatura) : null,
      "circunferencia_abdominal": obj.circunferencia_abdominal ? parseInt(obj.circunferencia_abdominal) : null,
      "observacao": obj.observacao,
      "frequencia_respiratoria": obj.frequencia_respiratoria ? parseInt(obj.frequencia_respiratoria) : null,
      "prioridade": obj.prioridade,
      "circunferencia_cefalica": obj.circunferencia_cefalica ? parseInt(obj.circunferencia_cefalica) : null,
      "glicemia_capilar": obj.glicemia_capilar ? parseInt(obj.glicemia_capilar) : null,
      "escala_glasgow": obj.escala_glasgow ? parseInt(obj.escala_glasgow) : null,
      "escala_dor": obj.escala_dor ? parseInt(obj.escala_dor) : null,
      "queixa": obj.queixa,
      "profissional_id": this.usuario.usuario_profissional_id,
      "presenca_id": this.presenca.id
    }

    if (c.frequencia_cardiaca == this.classificacao.frequencia_cardiaca && c.frequencia_respiratoria == this.classificacao.frequencia_respiratoria &&
      c.saturacao_oxigenio == this.classificacao.saturacao_oxigenio && c.sistole == this.classificacao.sistole && c.diastole == this.classificacao.diastole &&
      c.temperatura == this.classificacao.temperatura && c.glicemia_capilar == this.classificacao.glicemia_capilar && c.peso == this.classificacao.peso && c.altura == this.classificacao.altura &&
      c.escala_glasgow == this.classificacao.escala_glasgow) {
      Swal.close()
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: `Operação realizada com sucesso.`,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(
        result => {
          if (result.isConfirmed) {
            this.obterAnamnese.emit(this.prontuario.id);
          }
        }
      );
    }
    else this.sendClassificacao(c);

  }

  sendClassificacao = (obj) => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`ClassificacaoRisco`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: `Operação realizada com sucesso.`,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.classificacao = res;
              this.obterAnamnese.emit(this.prontuario.id);
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  salvarAnamneseClassificacao() {
    this.salvarAnamnese();
    this.validarClassificacao();
  }

  finalizarAtendimento() { }

}
