import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-modal-view-vacancies',
  templateUrl: './modal-view-vacancies.component.html',
  styleUrls: ['./modal-view-vacancies.component.css']
})
export class ModalViewVacanciesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // close modal 
fecharModal = () => {
  $("#modalVisualizarVagas").toggleClass("show");
  $("#modalVisualizarVagas").css("display","none");
}

}
