import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../../../service/apiServices';
import { AuthService } from '../../../../../service/auth/auth.service';
import { Global } from '../../../../../global';


import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit {

  listaReceituariosPaciente: any = [];

  usuario: any = {};
  presenca: any;
  cidadao_id: any;
  atendimento_id: any;
  uid: any;
  receituarioDados: any = {};
  global_: any;
  dadosImpresso: any;
  @Input() listaReceituarios: any = [];
  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario: any = false;
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
  ) {
    this.global_ = global;
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.listaReceituariosPaciente = this.listaReceituarios
  }

  obterlistaSolicitacaoReceituarios = () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    this.service.Get(`Atendimento/${uid}/Receituario`).then(
      result => {
        this.listaReceituariosPaciente = result;
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }


  visualizar = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Receituario/${id}`).then((res) => {
      Swal.close();
      this.receituarioDados = res;
      this.visualizarItem();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse receituário?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Receituario/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterlistaSolicitacaoReceituarios();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  visualizarItem = () => {
    $("#modalReceituario").toggleClass("show");
    $("#modalReceituario").css("display", "block");
  }


  fecharModal = () => {
    $("#modalReceituario").toggleClass("show");
    $("#modalReceituario").css("display", "none");
  }


  imprimirDado(item: any) {
    this.novoImpressoReceituario(item.id);
  }

  novoImpressoReceituario(id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.PostImpresso(`Documento/impresso-pdf/RECEITUARIO_CONTROLE_ESPECIAL.frx`, [
      {
        "nome": "id",
        "valor": id.toString()
      }
    ]).then(res => {
      if (res) {
        var link: any = res;
        Swal.close();
        window.open(link, '_blank');
      }
    });
  }

  carregarDadosImpresso(item: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Receituario/${item.id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }
  prepararImpressoHtml(dadosImpresso) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "RECEITUÁRIO")}

        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>RECEITUÁRIO</h4>
                </td>
            </tr>
        </table>
        <table>          
            <tr>
             <td>
                ${dadosImpresso.conteudo.texto}
             </td>  
             </tr>
             <tr>   
             <td>
                ${dadosImpresso.conteudo.observacao}
             </td>              
            </tr>
        </table>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

  enviarWhatsApp(item: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Receituario/${item.id}/Impresso/Whatsapp`).then(res => {
      if (res) {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      }
    });
  }

}
