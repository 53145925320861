import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import Swal from 'sweetalert2';
declare var Simditor: any;
import * as $ from "jquery";
import * as moment from 'moment';

@Component({
  selector: 'app-declaration-registration',
  templateUrl: './declaration-registration.component.html',
  styleUrls: ['./declaration-registration.component.css']
})
export class DeclarationRegistrationComponent implements OnInit {

  @Output() obterListaDeclaracao: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Output() goBack = new EventEmitter();
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  declaracao: any = {};

  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  constructor(private authService: AuthService,
    public service: ApiService) {
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    this.declaracao.tipo = 1;
    this.declaracao.tipo_declaracao = "LIVRE";
    var that = this;
    setTimeout(() => {
      var descricao = new Simditor({
        textarea: $('#descricaoDeclaracao'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
      });
    }, 1000);

  }

  salvarDeclaracao = () => {
    if (!this.validaPostDeclaracao())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    var declaracaoLivre = {
      "prontuario_id": this.prontuario.id,
      "presenca_id": this.presenca.id,
      "tipo_declaracao": this.declaracao.tipo_declaracao,
      "texto": $('#descricaoDeclaracao').val()
    }
    var hf = this.declaracao.data_fim;

    var declaracaoPadrao = {};
    if (hf) {
      debugger
      declaracaoPadrao = {
        "prontuario_id": this.prontuario.id,
        "presenca_id": this.presenca.id,
        "tipo_declaracao": this.declaracao.tipo_declaracao,
        "data_hora_inicio": moment(this.declaracao.data_inicio, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'),
        "data_hora_fim": moment(this.declaracao.data_fim, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'),
        "nome_acompanhante": this.declaracao.nome_acompanhante
      }
    }
    var declaracao = {};
    if (this.declaracao.tipo == 1) {
      declaracao = declaracaoLivre;
    }
    else {
      declaracao = declaracaoPadrao;
    }
    this.service.Post(`Prontuario/${this.prontuario.id}/ProntuarioDeclaracao`, declaracao).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Declaração salva com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.voltar();
              this.obterListaDeclaracao.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostDeclaracao = () => {
    if (this.prontuario.id == null) {
      Swal.fire('Erro', "Prontuario não encontrado.", 'error');
      return false;
    }
    if (this.presenca.id == null) {
      Swal.fire('Erro', "Presença não encontrado.", 'error');
      return false;
    }
    var texto = $('#descricaoDeclaracao').val();
    if ((texto == null || texto == "") && this.declaracao.tipo == 1) {
      Swal.fire('Erro', "Favor informar um texto da declaração.", 'error');
      return false;
    }

    return true;
  }

  voltar = () => {
    this.declaracao = {};
    $('#descricaoDeclaracao').val("");
    this.goBack.emit();
  }

  selecionarTipoDeclaracao(tipo) {
    this.declaracao.tipo = tipo;
    if (this.declaracao.tipo == 1) {
      this.declaracao.tipo_declaracao = "LIVRE";
      this.declaracao.nome_acompanhante = "";
      var that = this;
      setTimeout(() => {
        var descricao = new Simditor({
          textarea: $('#descricaoDeclaracao'),
          upload: false,
          tabIndent: true,
          toolbar: that.toolbar,
          toolbarFloat: true
        });
      }, 1000);
      $('#descricaoDeclaracao').val("");
    } else {
      this.declaracao.tipo_declaracao = "PADRAO";
      var hi = this.prontuario.atendido_em;
      var hf = new Date();
      this.declaracao.data_inicio = moment(hi).format("DD/MM/YYYY HH:mm");
      this.declaracao.data_fim = moment().format("DD/MM/YYYY HH:mm");
      // this.declaracao.data_inicio = hi.split('T')[0].split('-')[2] + hi.split('T')[0].split('-')[1] + hi.split('T')[0].split('-')[0] + hi.split('T')[1].split(':')[0] + hi.split('T')[1].split(':')[1];
      // this.declaracao.data_fim = hf.getDate().toString() + ((hf.getMonth() + 1).toString().length == 1 ? '0' + (hf.getMonth() + 1).toString() : hf.getMonth() + 1).toString() + hf.getFullYear().toString() + (hf.getHours().toString().length == 1 ? '0' + hf.getHours().toString() : hf.getHours()) + (hf.getMinutes().toString().length == 1 ? '0' + hf.getMinutes().toString() : hf.getMinutes());
    }


  }

}
