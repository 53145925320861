import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';

@Component({
  selector: 'app-medical-admission-list',
  templateUrl: './medical-admission-list.component.html',
  styleUrls: ['./medical-admission-list.component.css']
})
export class MedicalAdmissionlistComponent implements OnInit {

  @Input() prontuario: any = {};
  @Input() listaAdmissaoMed: any = [];
  @Output() resultEmiter: EventEmitter<any> = new EventEmitter();
  admissaoMed: any = {};
  dadosImpresso: any;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario: any = false;
  constructor(public global: Global,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService,) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  visualizar = (admissao_id) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`AdmissaoMedica/${admissao_id}`).then(res => {
      this.admissaoMed = res;
      $("#modalVisualizarAdmissaoMed").toggleClass("show");
      $("#modalVisualizarAdmissaoMed").css("display", "block");
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  fecharModal = () => {
    $("#modalVisualizarAdmissaoMed").toggleClass("show");
    $("#modalVisualizarAdmissaoMed").css("display", "none");
  }

  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(admissao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`AdmissaoMedica/${admissao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('admissao_impresso');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
      }
    });
  }



  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <tr>
  <td>
      <p>${element.cid_subcategoria_codigo}</p>
  </td>
  <td>
      <p>${element.cid_subcategoria_nome}</p>
  </td>
  <td>
      <p>${element.diagnostico_confirmado ? 'SIM' : 'NÃO'}</p>
  </td>
  <td>
      <p>${element.principal ? 'SIM' : 'NÃO'}</p>
  </td>
  <td>
     <p>${element.doenca_cronica ? 'SIM' : 'NÃO'}</p>
  </td>
  </tr>
  `
    });
    return td;
  }

  prepararImpressoHtml(dadosImpresso, apac = false) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}
      <body>
        <div>
        ${this.global.cabecalhoImpresso(dadosImpresso, "ADMISSÃO MÉDICA")}
        <tr colspan="10">
          <td class="cabecalho">
            <h4>HDA (HISTÓRICO DA DOENÇA ATUAL):</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.historico_doenca_atual}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>DIAGNÓSTICOS</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <b> CÓDIGO </b>
          </td>
          <td>
            <b> CID </b>
          </td>
          <td>
            <b> DIAGNÓSTICO CONFIRMADO </b>
          </td>
          <td>
          <b> PRINCIPAL </b>
        </td>
        <td>
        <b> DOENÇA CRÔNICA </b>
      </td>
        </tr>
        <tr> ${this.gerarTabela(dadosImpresso.conteudo.cids)} </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>ANTECEDENTES FAMILIARES</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.antecedentes_familiares}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>ANTECEDENTES PESSOAIS CLÍNICOS E CIRÚRGICOS</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.antecedentes_pessoais}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>IMPRESSÃO GERAL</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.impressao_geral}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>EXAME NEUROLÓGICO/CABEÇA/PESCOÇO</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.exame_neurologico}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>EXAME CARDIOLÓGICO</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.exame_cardiologico}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>EXAME RESPIRATÓRIO</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.exame_respiratorio}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>EXAME ABDOMINAL</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.exame_abdominal}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>EXAME DAS EXTREMIDADES</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.exame_extremidades}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>EXAME COMPLEMENTAR</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.exame_complementar}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>PROCEDIMENTO</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.sigtap_nome}</h1>
          </td>
        </tr>
      </table>
      <table>
        <tr colspan="10">
          <td class="cabecalho">
            <h4>INFORMAÇÕES ADICIONAIS</h4>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <h1>${dadosImpresso.conteudo.informacoes_adicionais}</h1>
          </td>
        </tr>
      </table>
      ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
  </body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }



  ///IMPRESSO AIH
  imprimirDadoAIH(item: any) {
    this.novoImpressoAIH(item.id);
  }

  carregarDadosImpressoAIH(admissao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`AdmissaoMedica/${admissao_id}/ImpressoAih`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' });
        this.prepararImpressoHtmlAIH(res);
      }
    });
  }

  novoImpressoAIH(admissao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.PostImpresso(`Documento/impresso-pdf/LAUDO_PARA_SOLICITACAO_DE_AUTORIZACAO.frx`, [
      {
        "nome": "id",
        "valor": admissao_id.toString()
      }
    ]).then(res => {
      if (res) {
        var link: any = res;
        Swal.close();
        window.open(link, '_blank');
      }
    });
  }


  prepararImpressoHtmlAIH(dadosImpresso) {
    var html = `
    <!DOCTYPE html>
    <html lang="pt">
    
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Certificado de Autorização de Internação Hospitalar</title>
        <style>
            body {
                font-family: Arial, sans-serif;
            }
    
            table {
                border-collapse: collapse;
                width: 100%;
            }
    
            th,
            td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
                vertical-align: top;
                font-size: 10px;
            }
    
            th {
                background-color: #f2f2f2;
                font-size: 10px;
            }
    
            .title {
                font-size: 10px;
                text-align: center;
            }
    
            .logo {
                width: 90px;
            }
    
            .logo-info {       
              border: none;
          }
  
          .logo-info p {
              margin: 0px;
              text-align: center;
              font-size: 10px;
          }
          table {
            margin:3px 0px;
          }
        </style>
    </head>
    
    <body>
        <table>
            <tr style="border: 1px solid;">
            <td class="logo-info">
                <img class="logo"
                src="${dadosImpresso.cabecalho.relatorio_caminho_logo}" />
            </td>
            <td class="logo-info">
                <p>SISTEMA <br /> UNICO DE <br /> SAUDE</p>
            </td>
            <td class="logo-info">
                <p>MINISTÉRIO <br /> DA <br />SAÚDE</p>
            </td>   
            <td>
                <h1 class="title">LAUDO PARA SOLICITAÇÃO DE AUTORIZAÇÃO DE INTERNACAO HOSPITALAR</h1>
            </td>
        </tr>
        </table>
        
        <table>
            <tr>
                <th colspan="2" style="text-align: center;">IDENTIFICAÇÃO DO ESTABELECIMENTO DE SAÚDE</th>
            </tr>
            <tr>
                <td>
                    <span > 1- NOME DO ESTABELECIMENTO SOLICITANTE</span>
                    <br />
                    <span>${dadosImpresso.cabecalho.estabelecimento_nome}</span>
                </td>
                <td>
                    <span > 2- CNES</span>
                    <br />
                    <span> ${dadosImpresso.cabecalho.estabelecimento_cnes}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span > 3- NOME DO ESTABELECIMENTO EXECUTANTE</span>
                    <br />
                    <span>${dadosImpresso.local_atendimento.estabelecimento_nome}</span>
                </td>
                <td>
                    <span > 4- CNES</span>
                    <br />
                    <span> ${dadosImpresso.local_atendimento.estabelecimento_cnes}</span>
                </td>
            </tr>
        </table>
        
        <table>
            <tr>
                <th colspan="4" style="text-align: center;">IDENTIFICAÇÃO DO PACIENTE</th>
            </tr>
            <tr>
                <td colspan="3">
                    <span > 5- NOME DO PACIENTE</span>
                    <br />
                    <span>${dadosImpresso.cidadao.nome} </span>
                </td>
                <td>
                    <span > 6- N DO PRONTUÁRIO</span>
                    <br />
                    <span> ${dadosImpresso.atendimento.protocolo}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span > 7- CARTÃO NACIONAL DE SAÚDE (CNS)</span>
                    <br />
                    <span>${dadosImpresso.cidadao.cns}</span>
                </td>
                <td>
                    <span > 8- DATA DE NASCIMENTO</span>
                    <br />
                    <span>${dadosImpresso.cidadao.data_nascimento}</span>
                </td>
                <td>
                    <span > 9- SEXO</span>
                    <br />
                    <span> ${dadosImpresso.cidadao.sexo}</span>
                </td>
                <td>
                    <span > 10- RAÇA / COR</span>
                    <br />
                    <span>&nbsp;</span>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <span > 11- NOME DA MÃE</span>
                    <br />
                    <span>${dadosImpresso.cidadao.nome_mae}</span>
                </td>
                <td>
                    <span > 12- TELEFONE DE CONTATO</span>
                    <br />
                    <span> ${dadosImpresso.cidadao.telefone}</span>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <span > 13- NOME DO RESPONSÁVEL</span>
                    <br />
                    <span>
                    &nbsp;
                    </span>
                </td>
                <td>
                    <span > 14- TELEFONE DE CONTATO</span>
                    <br />
                    <span>
                    &nbsp;
                    </span>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <span > 15 - ENDEREÇO (RUA, No, BAIRRO)</span>
                    <br />
                    <span>
                    ${dadosImpresso.cidadao.endereco_completo}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <span > 16- MUNICÍPIO DE RESIDÊNCIA</span>
                    <br />
                    <span>
                    ${dadosImpresso.cidadao.municipio}
                    </span>
                </td>
                <td>
                    <span > 17 - CÓD. IBGE MUNICÍPIO</span>
                    <br />
                    <span>${dadosImpresso.cidadao.municipio_co_ibge}</span>
                </td>
                <td>
                    <span > 18 - UF</span>
                    <br />
                    <span>
                    ${dadosImpresso.cidadao.estado_sigla}
                    </span>
                </td>
                <td>
                    <span > 19 - CEP</span>
                    <br />
                    <span>
                    ${dadosImpresso.cidadao.cep}
                    </span>
                </td>
            </tr>
        </table>
        
        <table>
            <tr>
                <th colspan="4" style="text-align: center;">JUSTIFICATIVA DA INTERNAÇÃO</th>
            </tr>
            <tr>
                <td colspan="4">
                    <span >20- PRINCIPAIS SINAIS E SINTOMAS CLÍNICOS</span>
                    <br />
                    <span>
                    ${dadosImpresso.conteudo.sintomas_clinicos}
                    </span>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <span >21 - CONDIÇÕES QUE JUSTIFICAM A INTERNAÇÃO</span>
                    <br />
                    <span>
                    ${dadosImpresso.conteudo.justificativa_internacao}
                    </span>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <span >22- PRINCIPAIS RESULTADOS DE PROVAS DIAGNÓSTICAS (RESULTADOS DE EXAMES
                        REALIZADOS)</span>
                    <br />
                    <span>
                    ${dadosImpresso.conteudo.provas_diagnosticas}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <span >23 - DIAGNÓSTICO INICIAL</span>
                    <br />
                    <span>
                    ${dadosImpresso.conteudo.diagnostico_inicial}
                    </span>
                </td>
                <td>
                    <span >24 - CID 10 PRINCIPAL</span>
                    <br />
                    <span>${dadosImpresso.conteudo.cid_principal} </span>
                </td>
                <td>
                    <span >25 - CID 10 SECUNDÁRIO</span>
                    <br />
                    <span>${dadosImpresso.conteudo.cid_secundario} </span>
                </td>
                <td>
                    <span >26 - CID 10 CAUSAS ASSOCIADAS</span>
                    <br />
                    <span>  ${dadosImpresso.conteudo.cid_causa_associada} </span>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <th colspan="4" style="text-align: center;">PROCEDIMENTO SOLICITADO</th>
            </tr>
            <tr>
                <td colspan="3">
                    <span >27 - DESCRIÇÃO DO PROCEDIMENTO</span>
                    <br />
                    <span>
                    ${dadosImpresso.conteudo.sigtap_descricao}
                    </span>
                </td>
                <td>
                    <span >28 - CÓDIGO DO PROCEDIMENTO</span>
                    <br />
                    <span> ${dadosImpresso.conteudo.sigtap_codigo} </span>
                </td>
            </tr>
            <tr>
                <td>
                    <span >29 - CLÍNICA</span>
                    <br />
                    <span>${dadosImpresso.conteudo.sigtap_clinica_solicitante} </span>
                </td>
                <td>
                    <span >30 - CARÁTER DA INTERNAÇÃO</span>
                    <br />
                    <span>${dadosImpresso.conteudo.sigtap_carater_internacao} </span>
                </td>
                <td>
                    <span >31 - DOCUMENTO</span>
                    <br />
                    <span>${dadosImpresso.conteudo.sigtap_solicitante_tipo_documento}  </span>
                </td>
                <td>
                    <span >32 - CPF/CNS DO PROFISSIONAL SOLICITANTE/ ASSISTENTE</span>
                    <br />
                    <span>${dadosImpresso.conteudo.sigtap_solicitante_documento_numero} </span>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <span >33 - NOME DO PROFISSIONAL SOLICITANTE/ ASSISTENTE</span>
                    <br />
                    <span>${dadosImpresso.conteudo.sigtap_solicitante_nome} </span>
                </td>
                <td>
                    <span >34 - DATA DE NASCIMENTO</span>
                    <br />
                    <span> 
                    ${dadosImpresso.conteudo.sigtap_solicitante_data_nascimento}
                    </span>
                </td>
                <td>
                    <span >35 - ASSINATURA E CARIMBO (N DO REGISTRO DO CONSELHO</span>
                    <br />
                    <span>
                    &nbsp;
                    </span>
                </td>
            </tr>    
        </table>
        <table>
            <tr>
                <th colspan="4" style="text-align: center;">PREENCHER EM CASO DE CAUSAS EXTERNAS (ACIDENTES OU VIOLÊNCIA)
                </th>
            </tr>
    
            <tr>
                <td rowspan="4">
                    <span >36 - ( ) ACIDENTE DE TRÂNSITO</span>
                    <br />
                    <span >37 - ( ) ACIDENTE DE TRABALHO TÍPICO</span>
                    <br />
                    <span >38 - ( ) ACIDENTE DE TRABALHO TRAJETO</span>
                </td>
                <td>
                    <span >39 - CNPJ DA SEGURADORA</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >40 - N DO BILHETE</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >41 - SÉRIE</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span >42 - CNPJ DA EMPRESA</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >43 - CNAE DA EMPRESA</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >44 - CBOR</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <span > 45 - VÍNCULO</span>
                    <br />
                    <span>
                        ( ) EMPREGADO ( ) EMPREGADOR ( ) AUTONOMO ( ) DESEMPREGADO ( ) APOSENTADO ( ) NÃO SEGURADO
                    </span>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <th colspan="4" style="text-align: center;">AUTORIZAÇÃO
                </th>
            </tr>
    
            <tr>
                <td>
                    <span >46 - NOME DO PROFISSIONAL AUTORIZADOR</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >47 - CÓD ÓRGÃO EMISSOR</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td rowspan="4">
                    <span >52 - N AUTORIZADOR DA INTERNAÇÃO HOSPITALAR</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
            </tr>
    
            <tr>
                <td>
                    <span >48 - DOCUMENTO (CNS / CPF)</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >49 - N DOCUMENTO DO PROFISSIONAL AUTORIZADOR</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
            </tr>
    
            <tr>
                <td>
                    <span >50 - DATA DA AUTORIZAÇÃO</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
                <td>
                    <span >51 - ASSINATURA E CARIMBO (N DO REGISTRO DO CONSELHO)</span>
                    <br />
                    <span> &nbsp;</span>
                </td>
    
            </tr> 
        </table>
    </body>
    
    </html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFileAIH(html, dadosImpresso);
  }

  createHtmlFileAIH(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }
}
