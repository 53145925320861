import { Component, OnInit, Directive } from '@angular/core';
import * as $ from "jquery";
// declare var $: any;
import Swal from 'sweetalert2';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import * as moment from 'moment';
import { Location } from '@angular/common';

import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

declare var Simditor: any;

@Component({
  selector: 'app-register-risk-rating',
  templateUrl: './register-risk-rating.component.html',
  styleUrls: ['./register-risk-rating.component.css']
})
export class RegisterRiskRatingComponent implements OnInit {

  client: any = {};
  status: boolean = true;
  uid: any = '';
  lockBTN: boolean = false;
  cidadao: any = {};
  atendimento: any = {};
  global_: any;
  classificacao: any = {};
  listaTipoPrioridade: any = [
    {
      id: 1,
      nome: `Gestante`
    },
    {
      id: 2,
      nome: `Idoso`
    },
    {
      id: 3,
      nome: `Necessidades Especiais`
    },
    {
      id: 4,
      nome: `Outros`
    },
  ];
  usuario: any;
  presenca: any;
  loading: any = false;
  Atendimentos: any = [];
  listaClinicas:any = [];

  /// editor de texto
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private location: Location,
    public global: Global,
    private authService: AuthService
  ) {

    this.global_ = global;
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    /// configuração do editor de texto
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.init();
  }


  init = async () => {
    this.obterListaClinicas()
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.getAtendimento(uid);
      await this.getClassificacaoRisco(uid)
    }
    setTimeout(() => {
      var that = this;
      var descricao = new Simditor({
        textarea: $('#queixa'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options

      });
      Swal.close()
    }, 500);
  }

  obterListaClinicas = () => {
    return new Promise(resolve => {
      this.service.Get(`Bloco/${this.presenca.bloco_id}/ClinicaMedica?ativo=true`).then(
        result => {
          this.listaClinicas = result
          this.classificacao.destino_interno = this.presenca.clinica_medica_id;
          resolve('ok')
        },
        error => {
          if (error.status == 401 || error.error.status == 401) {
            Swal.close()
            this.authService.logoutUser()
          }
          Swal.close();
          this.loading = false;
          Swal.fire('Erro', `${error.error.error.messagem}`, 'error').then(
            result => {
              resolve('ok')
            }
          )
        }
      )
    })
  }

  goBack() {
    this.location.back();
  }

  emitError = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  //FUNCOES
  getAtendimento = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Atendimento/${id}`).then(res => {
      this.atendimento = res;
      this.getCidadao(res['cidadao_id'])
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }


  getCidadao = (id) => {
    this.service.Get(`Cidadao/${id}`).then(res => {
      this.cidadao = res;
      Swal.close();
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }

  openModalalergia = () => {
    $("#modalAlergiasCidadao").toggleClass("show");
    $("#modalAlergiasCidadao").css("display", "block");
  }

  openModalGlasgow = () => {
    $("#modalEscalaGlasgow").toggleClass("show");
    $("#modalEscalaGlasgow").css("display", "block");
  }

  openModalClassificacaoDor = () => {
    $("#modalEscalaDor").toggleClass("show");
    $("#modalEscalaDor").css("display", "block");
  }

  openModalClassificaoRisco = () => {
    $("#modalClassificacaoRisco").toggleClass("show");
    $("#modalClassificacaoRisco").css("display", "block");
  }

  sendGlasglow = (glasglow: any) => {
    this.classificacao.escala_glasgow = glasglow;
  }

  sendEscalaDor = (escala_dor: any) => {
    this.classificacao.escala_dor = escala_dor;
  }

  sendClassificacaoPrioridade = (classificacao_prioridade: any) => {
    this.classificacao.classificacao_prioridade = classificacao_prioridade.classificacao_prioridade ? parseInt(classificacao_prioridade.classificacao_prioridade) : 0;
    this.classificacao.prioridade = classificacao_prioridade.prioridade;
    this.classificacao.tipo_prioridade = classificacao_prioridade.tipo_prioridade;
  }

  getTipoPrioridade = (tipo_prioridade: any) => {
    var filter = this.listaTipoPrioridade.filter(x => x.id == tipo_prioridade);
    if (filter.length > 0) {
      return filter[0].nome;
    }
  }

  validarClassificacao = () => {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (!this.cidadao.id) {
      this.alerts.notifyError({
        body: `Paciente não encontrado, favor realizar login novamente e repetir o processo.`,
        title: 'Erro'
      });
      this.loading = false;
      return
    }

    if (!this.atendimento.id) {
      this.alerts.notifyError({
        body: `Atendimento não encontrado, favor realizar login novamente e repetir o processo.`,
        title: 'Erro'
      });
      this.loading = false;
      return
    }

    if (!this.presenca.id) {
      this.alerts.notifyError({
        body: `Presença não encontrada, favor realizar login novamente e repetir o processo.`,
        title: 'Erro'
      });
      this.loading = false;
      return
    }

    if (this.classificacao.classificacao_prioridade == 0 || this.classificacao.classificacao_prioridade == undefined || this.classificacao.classificacao_prioridade == null) {
      Swal.fire('Atenção', 'É preciso indicar uma classificação de risco', 'warning').then(
        result => {
          this.loading = false;
        }
      )
      return
    }

    var obj = Object.assign({}, this.classificacao);
    var c = {
      "cidadao_id": this.cidadao.id,
      "atendimento_id": this.atendimento.id,
      "peso": obj.peso ? parseFloat(obj.peso) : null,
      "altura": obj.altura ? parseFloat(obj.altura) : null,
      "frequencia_cardiaca": obj.frequencia_cardiaca ? parseInt(obj.frequencia_cardiaca) : null,
      "saturacao_oxigenio": obj.saturacao_oxigenio ? parseInt(obj.saturacao_oxigenio) : null,
      "classificacao_prioridade": obj.classificacao_prioridade,
      "tipo_prioridade": obj.tipo_prioridade ? parseInt(obj.tipo_prioridade) : null,
      "sistole": obj.sistole ? parseFloat(obj.sistole) : null,
      "diastole": obj.diastole ? parseFloat(obj.diastole) : null,
      "temperatura": obj.temperatura ? parseFloat(obj.temperatura) : null,
      "circunferencia_abdominal": obj.circunferencia_abdominal ? parseInt(obj.circunferencia_abdominal) : null,
      "observacao": obj.observacao,
      "frequencia_respiratoria": obj.frequencia_respiratoria ? parseInt(obj.frequencia_respiratoria) : null,
      "prioridade": obj.prioridade,
      "circunferencia_cefalica": obj.circunferencia_cefalica ? parseInt(obj.circunferencia_cefalica) : null,
      "glicemia_capilar": obj.glicemia_capilar ? parseInt(obj.glicemia_capilar) : null,
      "escala_glasgow": obj.escala_glasgow ? parseInt(obj.escala_glasgow) : null,
      "escala_dor": obj.escala_dor ? parseInt(obj.escala_dor) : null,
      "queixa": $('#queixa').val().replace(/&NBSP;/g, " ").replace(/&nbsp;/g, " "),
      "profissional_id": this.usuario.usuario_profissional_id,
      "presenca_id": this.presenca.id,
      "clinica_id": this.classificacao.destino_interno
    }
    
    this.sendClassificacao(c);
  }

  sendClassificacao = (obj) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`ClassificacaoRisco`, obj).then(res => {
      let that = this;
      setTimeout(() => {
        that.alerts.notifySuccess({
          body: `Classificação realizada com sucesso para o paciente ${that.atendimento.cidadao_nome}`,
          title: 'Sucesso'
        });
      }, 1000);
      this.loading = false;
      Swal.close();
      this.goBack();
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      this.loading = false;
      Swal.close();
    })
  }

  getClassificacaoRisco = (id) => {
    this.service.Get(`Atendimento/${id}/ClassificacaoRisco`).then(res => {
      this.Atendimentos = res;
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }

  retirarEspaco(text) {
    var textoSemNBSP = text.replace(/&NBSP;/g, " ").replace(/&nbsp;/g, " ");
    return textoSemNBSP;
  }
}
