import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service'
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from "jquery";
import { Global } from '../../../global';

import { ModalAddAppointmentComponent } from '../../../modals/modal-add-appointment/modal-add-appointment.component'
import { ModalAddProcedureComponent } from '../../../modals/modal-add-procedure/modal-add-procedure.component'

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  plano: any = {};
  addValores: boolean = false;

  //procedimento
  listaProcedimentos: any = []

  //consulta
  listaOcupacoes: any = []

  //paginação procedimentos
  paginaProcedimentos: any = 1;
  totalPaginasProcedimentos: any = 1;
  //paginação consultas
  paginaConsultas: any = 1;
  totalPaginasConsultas: any = 1;
  totalPorPagina: any = 25;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private global: Global,
    private service: ApiService
  ) { }

  ngOnInit() {
    var uid = this.route.snapshot.paramMap.get('uuid');
    this.plano.ativo = true;
    if (uid != null) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterPlano(uid).then(
        result => {
          this.plano = result;
          if (this.plano.id){
            this.addValores = true;
          }
          Swal.close()
        },
        error => {
          console.log(error)
          Swal.close()
        }
      )
    }
  }

  obterPlano = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`Plano/${uid}`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }
  
  mudaStatusPlano = () => {
    this.plano.ativo != this.plano.ativo;
  }

  salvarPlano = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    if (!this.plano.nome || this.plano.nome == '') {
      Swal.fire('Erro', 'É preciso informar o nome para o plano', 'error');
      return;
    }
    var obj = {
      ativo: this.plano.ativo,
      nome: this.plano.nome,
    }
    this.service.Post(`Plano`, obj).then(
      result => {
        this.plano = result;
        if (this.plano.id){
          this.addValores = true;
        }
        Swal.fire('Sucesso', 'Plano cadastrado com sucesso', 'success')
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  goBack() {
    this.location.back();
  }

  editarPlano = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading
    this.service.Put(`Plano/${this.plano.id}`, this.plano).then(
      result => {
        Swal.fire('Sucesso', 'Plano alterado com sucesso', 'success')
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  obterProcedimentos = () => {
    this.listaProcedimentos = []
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`PlanoSigTap?Skip=0&Take=${this.totalPorPagina}&Ativo=true`).then(
      result => {
        var lista = result['conteudo']
        for (let item of lista){
          if (item.plano_id == this.plano.id){
            this.listaProcedimentos.push(item)            
          }
        }
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  obterConsultas = () => {
    this.listaOcupacoes = []
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`PlanoOcupacao?Skip=0&Take=${this.totalPorPagina}&Ativo=true`).then(
      result => {
        var lista = result['conteudo']
        for (let item of lista){
          if (item.plano_id == this.plano.id){
            this.listaOcupacoes.push(item)            
          }
        }
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  addNovoProcedimento = () => {
    $("#modalAddProcedimento").addClass("show");
    $("#modalAddProcedimento").css("display", "block");
  }

  addNovaConsulta = () => {
    $("#modalAddConsulta").addClass("show");
    $("#modalAddConsulta").css("display", "block");
  }

  atualizaListaProcedimentos = (item: boolean) => {
    if (item){
      this.obterProcedimentos()
    }
  }
  
  atualizaListaEspecialidades = (item: boolean) => {
    if (item){
      this.obterConsultas()
    }
  }

  validaDados = (item) => {
    return new Promise((resolve, reject) => {
      if (!item.novoValor || item.novoValor <= 0 || item.novoValor == '') {
        reject('É preciso informar um valor para o procedimento')
        return
      }
      if (item.novoValor){
        if (item.novoValor.includes(','))
          item.novoValor = item.novoValor.replace(',', '.')
        item.novoValor = parseFloat(item.novoValor)
      }
      resolve(item)
    })
  }

  editarValorProcedimento = (item) => {
    item.editar = true;
    item.novoValor = item.valor
  }

  salvarEditarProcedimento = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.validaDados(item).then(
      result => {
        var obj: any = {};
        Object.assign(obj, result)
        obj.valor = obj.novoValor
        delete obj.editar
        delete obj.novoValor
        this.service.Put(`PlanoSigTap/${obj.id}`,obj).then(
          result => {
            Swal.fire('Sucesso', 'Procedimento editado com sucesso', 'success').then(
              result => {
                this.atualizaListaProcedimentos(true)
              }
            )
          },
          error => {
            Swal.fire('Erro', error.messagem, 'error')
          }
        )
      },
      error => {
        Swal.fire('Erro', error, 'error')
      }
    )    
  }
  
  editarValorEspecialidade = (item) => {
    item.editar = true;
    item.novoValor = item.valor
  }

  salvarEditarEspecialidade = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.validaDados(item).then(
      result => {
        var obj: any = {};
        Object.assign(obj, result)
        obj.valor = obj.novoValor
        delete obj.editar
        delete obj.novoValor
        obj.ocupacao_id = result['sigtap_id']
        this.service.Put(`PlanoOcupacao/${obj.id}`,obj).then(
          result => {
            Swal.fire('Sucesso', 'Especialidade editada com sucesso', 'success').then(
              result => {
                this.atualizaListaEspecialidades(true)
              }
            )
          },
          error => {
            Swal.fire('Erro', error.messagem, 'error')
          }
        )
      },
      error => {
        Swal.fire('Erro', error, 'error')
      }
    )    
  }

  cancelarEditar = (item) => {
    delete item.editar
  }

  obterListaProcedimentosPaginada = (pagina) => {
    this.listaOcupacoes = []
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`PlanoSigTap?Skip=${pagina}&Take=${this.totalPorPagina}&Ativo=true`).then(
      result => {
        var lista = result['conteudo']
        for (let item of lista){
          if (item.plano_id == this.plano.id){
            this.listaOcupacoes.push(item)            
          }
        }
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  obterListaEspecialidadesPaginada = (pagina) => {
    this.listaOcupacoes = []
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`PlanoOcupacao?Skip=${pagina}&Take=${this.totalPorPagina}&Ativo=true`).then(
      result => {
        var lista = result['conteudo']
        for (let item of lista){
          if (item.plano_id == this.plano.id){
            this.listaOcupacoes.push(item)            
          }
        }
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

}
