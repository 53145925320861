import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
@Component({
  selector: 'app-evolution-list',
  templateUrl: './evolution-list.component.html',
  styleUrls: ['./evolution-list.component.css']
})
export class EvolutionListComponent implements OnChanges {

  @Input() listaEvolucao: any = [];
  @Input() prontuario: any = {};
  listaOcupacao: any = [];
  dadosImpresso: any;
  listaAux: any = [];
  historico_evolucao: any = {};
  selectAll: any = false;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  constructor(private global: Global,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService) {
  }

  ngOnInit() {
    this.historico_evolucao.cargo = 0;
    this.listaAux = this.listaEvolucao;
    this.obterListaOcupacao();
  }

  ngOnChanges() {
    this.listaAux = this.listaEvolucao;
  }

  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(evolucao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`ProntuarioEvolucao/Impresso?Id=${evolucao_id}`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('evolucao_unica');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
      }
    });
  }

  changeSelectAll(selectAll) {
    if (selectAll) {
      this.listaEvolucao.forEach(element => {
        element.selecionado = true;
      });
    } else {
      this.listaEvolucao.forEach(element => {
        element.selecionado = false;
      });
    }
  }

  changeSelectFilter(ocupacao_id) {
    if (!ocupacao_id) {
      return
    }

    if (ocupacao_id == 0) {
      this.listaEvolucao = this.listaAux;
    } else {
      this.listaEvolucao = this.listaAux.filter(x => x.ocupacao_id == ocupacao_id);
    }
  }

  verificarSelecionado() {
    if (this.listaAux.filter(x => x.selecionado).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  verificarTodosSelecionado() {
    if (this.listaAux.filter(x => x.selecionado == null || x.selecionado == false).length > 0) {
      this.selectAll = false;
    } else {
      this.selectAll = true;
    }
  }

  imprimirSelecionados() {
    if (this.listaAux.filter(x => x.selecionado).length == 0) {
      return;
    }
    var idImpresso = this.listaAux.filter(x => x.selecionado).map((item) => {
      return `Id=${item.id}`
    });

    var objSend = idImpresso.join(`&`);
    this.prepararImpressoSelecionados(objSend);
  }

  prepararImpressoSelecionados(parametos: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`ProntuarioEvolucao/Impresso?${parametos}`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('evolucao_multiplo');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
      }
    });
  }

  imprimirTodos() {
    var idImpresso = this.listaAux.map((item) => {
      return `Id=${item.id}`
    });

    var objSend = idImpresso.join(`&`);
    this.prepararImpressoSelecionados(objSend);
  }

  obterListaOcupacao = () => {
    this.service.Get(`Ocupacao?tipo_ocupacao=OCUPACAO`).then(
      result => {
        var objLista: any = result;
        objLista.unshift({
          "nome": "Todos",
          "codigo": "0",
          "id_esus": "0",
          "id": 0,
          "ativo": true
        })
        this.listaOcupacao = result;
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }


  carregarDados(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
            <tr>
                <td >
                    <p>${this.global.formatDateTime(element.evoluido_em)}</p>
                    <p class="p-2"></p>
                </td>
                <td>
                    ${element.texto}
                </td>
                <td >
                    <div class="line-assinatura" style="width: 200px;"></div>
                    <p style="margin: 0px;text-align: center;"> ${element.assinatura.nome_assinatura}</p>
                    <p style="margin: 0px;text-align: center;">${element.assinatura.linha_2}</p>                        
                </td>
            </tr>
  `
    });
    return td;
  }

  prepararImpressoHtml(dadosImpresso, apac = false) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "EVOLUÇÃO / ANOTAÇÃO")}
       
        <table class="apac">
        <thead>
        </thead>
        <tbody>
            <caption>
                <h4>EVOLUÇÃO / ANOTAÇÃO</h4>
            </caption>
            ${this.carregarDados(dadosImpresso.conteudo)}           
        </tbody>
        <tfoot></tfoot>
    </table>     
       
    </div>
</body>

</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();  
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }
}
