import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import * as moment from "moment";
@Component({
  selector: 'app-attendances',
  templateUrl: './attendances.component.html',
  styleUrls: ['./attendances.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AttendancesComponent implements OnInit {

  ListaAtendimento: any = [];
  filtroAguardandoAtendimento: any = '';
  filtroAtendido: any = '';
  dados = JSON.parse(localStorage.getItem('dadosPresencaUser'));
  usuario: any = {};
  presenca: any;
  statusSelecionado: any = 'AGUARDANDO_ATENDIMENTO,AGUARDANDO_CLASSIFICACAO_RISCO,INTERNADO'

  listaClinicas: any = [];
  filtro: string = "";
  loading: boolean = false;
  ListaTeleAtendimento: any = [];
  filtroAguardandoTeleAtendimento: any = '';
  showBtn: any = true;
  filtroDataAtendimento: any = "";

  constructor(public service: ApiService,
    private global: Global,
    private router: Router,
    private authService: AuthService) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
        
      }
    });

    if (!this.dados) {
      this.authService.logoutUser();
    }
  }

  ngOnInit() {
    this.obterListaClinicas().then(
      result => {
        this.filtro = this.presenca.clinica_medica_id
        this.getListaAtendimento('AGUARDANDO_ATENDIMENTO,AGUARDANDO_CLASSIFICACAO_RISCO', this.filtro)
      }
    )
  }

  obterListaClinicas = () => {
    return new Promise(resolve => {
      this.service.Get(`Bloco/${this.presenca.bloco_id}/ClinicaMedica?ativo=true`).then(
        result => {
          this.listaClinicas = result
          this.filtro = this.presenca.clinica_medica_id;
          resolve('ok')
        },
        error => {
          if (error.status == 401 || error.error.status == 401) {
            Swal.close()
            this.authService.logoutUser()
          }
          Swal.close();
          this.loading = false;
          Swal.fire('Erro', `${error.error.error.messagem}`, 'error').then(
            result => {
              resolve('ok')
            }
          )
        }
      )
    })
  }

  getListaAtendimento(status, clinica) {
    this.statusSelecionado = status;
    this.filtro = clinica
    this.showBtn = true;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var url = `Atendimento?clinica_medica_id=${clinica}`
    if (status.includes(",")) {
      var split = status.split(",");
      split.forEach(element => {
        url += `&status_atendimento=${element}`
      });
    } else {
      url += `&status_atendimento=${status}`
    }
    url += `&ordenacao=true`;

    if (status.includes("AGUARDANDO_ATENDIMENTO")) {
      url += `&local_atendimento=PRESENCIAL`;
    } else 
    if (status.includes("ATENDIMENTO_ENCERRADO")) {
      if (this.filtroDataAtendimento) {        
        var data_atendimento: any = this.filtroDataAtendimento[4] + this.filtroDataAtendimento[5] + this.filtroDataAtendimento[6] + this.filtroDataAtendimento[7] + "-" + this.filtroDataAtendimento[2] + this.filtroDataAtendimento[3] + "-" + this.filtroDataAtendimento[0] + this.filtroDataAtendimento[1];
        // var dataFormatada = data_atendimento;
        var dataFormatada = new Date(data_atendimento);

        // Validar se a data é válida
        if (!isNaN(dataFormatada.getTime())) {
          // A data é válida, agora vamos comparar com a data atual
          var hoje = new Date();
          // Zera as horas para comparar apenas as datas
          hoje.setHours(0, 0, 0, 0);

          if (dataFormatada < hoje) {
            url += `&encerrado_em=${data_atendimento}`;
          } else {
            
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: "A data não pode ser maior que hoje"
            }).then(
              result => {
                if (result.isConfirmed) {
                  return
                }
              }
            );
            return
          }
        } else {
          
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: "Data inválida"
          }).then(
            result => {
              if (result.isConfirmed) {
                return
              }
            }
          );
          return
        }
      }
    }

    url += `&profissional_id=${this.usuario.usuario_profissional_id}`;

    this.service.Get(url).then(res => {
      this.ListaAtendimento = res;
      for (let item of this.ListaAtendimento) {
        this.global.applyMask(item)
        if (item.cidadao_idade_extenso.split('Anos ')) {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Anos ')[0] + 'Anos\n' + item.cidadao_idade_extenso.split('Anos ')[1]
        }
        else {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Ano ')[0] + 'Ano\n' + item.cidadao_idade_extenso.split('Ano ')[1]
        }
        if (item.profissional_nome) {
          var temp_profissional = item.profissional_nome.split(' ');
          var mid_index = Math.ceil(item.profissional_nome.split(' ').length / 2);
          temp_profissional[mid_index] = '\n' + temp_profissional[mid_index]
          item.profissional_nome = temp_profissional.join(' ')
        }
      }
      Swal.close();
    }).catch(res => {
      Swal.close();
      if (res.status == 401 || res.error && res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }


  getListaAguardandoAtendimento(status, clinica) {
    this.statusSelecionado = status;
    this.filtro = clinica
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Atendimento?clinica_medica_id=${clinica}&status_atendimento=AGUARDANDO_ATENDIMENTO&status_atendimento=AGUARDANDO_CLASSIFICACAO_RISCO`).then(res => {
      this.ListaAtendimento = res;
      for (let item of this.ListaAtendimento) {
        this.global.applyMask(item)
        if (item.cidadao_idade_extenso.split('Anos ')) {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Anos ')[0] + 'Anos\n' + item.cidadao_idade_extenso.split('Anos ')[1]
        }
        else {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Ano ')[0] + 'Ano\n' + item.cidadao_idade_extenso.split('Ano ')[1]
        }
        if (item.profissional_nome) {
          var temp_profissional = item.profissional_nome.split(' ');
          var mid_index = Math.ceil(item.profissional_nome.split(' ').length / 2);
          temp_profissional[mid_index] = '\n' + temp_profissional[mid_index]
          item.profissional_nome = temp_profissional.join(' ')
        }
      }
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  filtroListaAguardandoAtendimento() {
    if (this.ListaAtendimento.length === 0) {
      return this.ListaAtendimento;
    }
    // Suponha que 'this.ListaAtendimento' seja a sua lista original.
    // const listaDuplicada = [];

    // this.ListaAtendimento.forEach(item => {
    //   for (let i = 0; i < 10; i++) {
    //     listaDuplicada.push({ ...item });
    //   }
    // });
    // const listaFiltrada = listaDuplicada.filter(
    //   a => a.cidadao_nome.toLowerCase().includes(this.filtroAguardandoAtendimento.toLowerCase()) ||
    //     a.profissional_nome.toLowerCase().includes(this.filtroAguardandoAtendimento.toLowerCase())
    // );
    // return listaFiltrada;
    return this.ListaAtendimento.filter(
      a => a.cidadao_nome.toLocaleLowerCase().includes(this.filtroAguardandoAtendimento.toLocaleLowerCase()) ||
        a.profissional_nome.toLocaleLowerCase().includes(this.filtroAguardandoAtendimento.toLocaleLowerCase())
    );
  }

  filtroListaAtendido() {
    if (this.ListaAtendimento.length === 0) {
      return this.ListaAtendimento;
    }
    return this.ListaAtendimento.filter(
      a => a.cidadao_nome.toLocaleLowerCase().includes(this.filtroAtendido.toLocaleLowerCase()) ||
        a.profissional_nome.toLocaleLowerCase().includes(this.filtroAtendido.toLocaleLowerCase())
    );
  }

  getListaTeleAtendimento(establecimento_id) {
    this.showBtn = false;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var url = `AgendaHora?agenda_dia_estabelecimento_id=${establecimento_id}`
    url += `&agenda_dia_profissional_id=${this.presenca.profissional_id}`

    url += `&vago=false`;
    url += `&ativo=true`;
    url += `&agenda_dia_local_atendimento=TELEATENDIMENTO`;
    url += `&agenda_dia_agenda_em=${moment().format("YYYY-MM-DD")}`;

    this.service.Get(url).then(res => {
      this.ListaTeleAtendimento = res;
      for (let item of this.ListaTeleAtendimento) {
        this.global.applyMask(item)
        if (item.cidadao_idade_extenso.split('Anos ')) {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Anos ')[0] + 'Anos\n' + item.cidadao_idade_extenso.split('Anos ')[1]
        }
        else {
          item.cidadao_idade_extenso = item.cidadao_idade_extenso.split('Ano ')[0] + 'Ano\n' + item.cidadao_idade_extenso.split('Ano ')[1]
        }
        if (item.profissional_nome) {
          var temp_profissional = item.profissional_nome.split(' ');
          var mid_index = Math.ceil(item.profissional_nome.split(' ').length / 2);
          temp_profissional[mid_index] = '\n' + temp_profissional[mid_index]
          item.profissional_nome = temp_profissional.join(' ')
        }
      }
      Swal.close();
    }).catch(res => {
      Swal.close();
      if (res.status == 401 || res.error && res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }


  filtroListaAguardandoTeleAtendimento() {
    if (this.ListaTeleAtendimento.length === 0) {
      return this.ListaTeleAtendimento;
    }
    return this.ListaTeleAtendimento.filter(
      a => a.cidadao_nome.toLocaleLowerCase().includes(this.filtroAguardandoTeleAtendimento.toLocaleLowerCase())
    );
  }

  iniciarAtendimento(item: any) {
    Swal.fire('Iniciando atendimento');
    Swal.showLoading();
    var obj = {
      presenca_id: this.presenca.id
    }
    this.service.Post(`Atendimento/${item.id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${item.id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  iniciarTeleAtendimento(item: any) {
    Swal.fire('Iniciando Teleatendimento');
    Swal.showLoading();


    var obj = {
      "presenca_id": this.presenca.id,
      "cidadao_id": item.cidadao_id,
      "clinica_medica_id": this.presenca.clinica_medica_id,
      "profissional_id": this.presenca.profissional_id,
      "tipo_atendimento": "AGENDAMENTO",
      "agenda_hora_id": item.id,
      "iniciar_atendimento": true
    }
    this.service.Post(`Atendimento`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${res["id"]}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();

      if (res.error.messagem) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      }
    })
  }

  continuarAtendimento(item: any) {
    Swal.fire('Iniciando atendimento');
    Swal.showLoading();
    var obj = {
      atendimento_id: item.id,
      presenca_id: this.presenca.id
    }
    this.service.Put(`Atendimento/${item.id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${item.id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  chamarPaciente(item: any) {
    Swal.fire('Chamando paciente');
    Swal.showLoading();
    var obj = {}
    this.service.Post(`Atendimento/${item.id}/ChamadaPainel`, this.presenca.id).then(res => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Atendimento',
        html: `Paciente ${res['paciente_nome']} chamado com sucesso!`
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  retirarEspaco(text) {
    var textoSemNBSP = text.replace(/&NBSP;/g, " ").replace(/&nbsp;/g, " ");
    return textoSemNBSP;
  }

  visualizarAtendimento(item: any) {
    
    this.router.navigate([`prontuario/${item.id}`]);
  }
}
