import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() toggleSidebar: boolean;
  menus: any = [];
  constructor(private authService: AuthService,private router: Router) {

    this.authService.menus.subscribe((res) => {
      if (res) {
        this.menus = res;
      }
    })
  }

  ngOnInit() {
  }

  goToLink(url){
    this.router.navigate([`/${url}`]);
  }

}
