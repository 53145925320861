import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../service/apiServices';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Global } from '../../global';
import { Location } from '@angular/common';
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-imprimir-agendamento',
  templateUrl: './schedule-print.component.html',
  styleUrls: ['./schedule-print.component.css']
})
export class SchedulePrintComponent implements OnInit {

  @Input() agendamento: string;
  @Input() path: string;

  dadosAgendamento: any = {};

  global_: any

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.global_ = this.global;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.dadosAgendamento = {}
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    if (this.agendamento) {
      this.service.Get(`${this.path}?Id=${this.agendamento}`).then(
        result => {
          this.dadosAgendamento = result;
          this.printAgendamento()
        },
        error => {
          if (error.status == 401 || error.error.status == 401) {
            Swal.close()
            this.authService.logoutUser()
          }
          Swal.fire('Erro', error.error.messagem, 'error')
        }
      )
    }
  }

  printAgendamento = () => {
    if (this.dadosAgendamento.nomeEspecialidadeAgendamento) {
      this.dadosAgendamento.tipo = 1
    }
    else if (this.dadosAgendamento.nomeProcedimentoAgendamento) {
      this.dadosAgendamento.tipo = 2
    }
    if (this.dadosAgendamento.cnsCidadao) {
      if (this.dadosAgendamento.cnsCidadao.toLowerCase() != 'não informado') {
        this.dadosAgendamento.cnsCidadao = this.global_.cnsMask(this.dadosAgendamento.cnsCidadao)
      }
    }
    else this.dadosAgendamento.cnsCidadao = 'não informado'
    if (this.dadosAgendamento.cpfCidadao) {
      if (this.dadosAgendamento.cpfCidadao.toLowerCase() != 'não informado') {
        this.dadosAgendamento.cpfCidadao = this.global_.cpfMask(this.dadosAgendamento.cpfCidadao)
      }
    }
    else this.dadosAgendamento.cpfCidadao = 'não informado'
    if (this.dadosAgendamento.cnsProfissionalSolicitante) {
      if (this.dadosAgendamento.cnsProfissionalSolicitante.toLowerCase() != 'não informado') {
        this.dadosAgendamento.cnsProfissionalSolicitante = this.global_.cnsMask(this.dadosAgendamento.cnsProfissionalSolicitante)
      }
    }
    else this.dadosAgendamento.cnsProfissionalSolicitante = 'não informado'
    if (this.dadosAgendamento.telefonePaciente) {
      this.dadosAgendamento.telefonePaciente = this.global_.telMask(this.dadosAgendamento.telefonePaciente)
    }

    //if (this.dadosAgendamento.dataHoraAgendamento) {
    //  this.dadosAgendamento.dataHoraAgendamento = this.dadosAgendamento.dataHoraAgendamento.replace('às', '')
    //  this.dadosAgendamento.dataHoraAgendamento = this.dadosAgendamento.dataHoraAgendamento.replace(' ', ' às ')
    //}
    $('#imprimir_agendamento').addClass("only-print")
    setTimeout(() => {
      window.print()
    }, 500)
    setTimeout(() => {
      $('#imprimir_agendamento').removeClass("only-print")
      this.agendamento = '';
      this.dadosAgendamento = {};
      Swal.close()
    }, 750)
    Swal.close()
  }

}
