import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './screens/login/login.component';
import { HomeComponent } from './screens/home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { Global } from './global';
import { ClientComponent } from './screens/register/client/client.component';
import { ProfessionalComponent } from './screens/register/professional/professional.component';
import { EstablishmentComponent } from './screens/register/establishment/establishment.component';
import { RegisterComponent } from './screens/register/register/register.component';

import { ValidateMaskDirective } from './validate-mask.directive'
import { ListProfessionalComponent } from './screens/register/list-professional/list-professional.component';
import { ApiService } from './service/apiServices';
import { AppConfig } from './service/app.config';
import { HttpModule } from '@angular/http';
import { ListEstablishmentComponent } from './screens/register/list-establishment/list-establishment.component';
import { ListClientComponent } from './screens/register/list-client/list-client.component';
import { NgxAlertsModule, NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { Location } from '@angular/common';

import { RegulationComponent } from './screens/regulation/regulation/regulation.component';
import { ForwardingComponent } from './screens/regulation/forwarding/forwarding.component';
import { ExamsComponent } from './screens/regulation/exams/exams.component';
import { CitizenComponent } from './screens/register/citizen/citizen.component';
import { ListCitizenComponent } from './screens/register/list-citizen/list-citizen.component';

// Modals
import { ModalAuthExamsComponent } from './modals/modal-auth-exams/modal-auth-exams.component';
import { ModalScheduleProcedureComponent } from './modals/modal-schedule-procedure/modal-schedule-procedure.component';
import { ModalReportComponent } from './modals/modal-report/modal-report.component';
import { ModalViewVacanciesComponent } from './modals/modal-view-vacancies/modal-view-vacancies.component';
import { NgxViacepModule, NgxViacepService } from '@brunoc/ngx-viacep';
import { ListScheduleConfigurationComponent } from './screens/register/list-schedule-configuration/list-schedule-configuration.component';
import { ScheduleConfigurationComponent } from './screens/register/schedule-configuration/schedule-configuration.component';

import { ScheduleComponent } from './components/schedule/schedule.component';
import { ModalScheduleForwardingComponent } from './modals/modal-schedule-forwarding/modal-schedule-forwarding.component';
import { ModalAuthForwardingComponent } from './modals/modal-auth-forwarding/modal-auth-forwarding.component';
import { ModalRealizationForwardingComponent } from './modals/modal-realization-forwarding/modal-realization-forwarding.component';
import { ModalCitizenRegistrationComponent } from './modals/modal-citizen-registration/modal-citizen-registration.component';
import { ModalManualRegulationComponent } from './modals/modal-manual-regulation/modal-manual-regulation.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './screens/register/profile/profile.component';
import { ListProfileComponent } from './screens/register/list-profile/list-profile.component';
import { ListUserComponent } from './screens/register/list-user/list-user.component';
import { UserComponent } from './screens/register/user/user.component';
import { ManualRegulationComponent } from './components/manual-regulation/manual-regulation.component';
import { PacerecerLaudoComponent } from './components/pacerecer-laudo/pacerecer-laudo.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { RegulationHistoricComponent } from './modals/regulation-historic/regulation-historic.component';
import { GeneralConfigurationComponent } from './screens/register/general-configuration/general-configuration.component';
import { HtmlPrintComponent } from './components/html-print/html-print.component';
import { SchedulePrintComponent } from './print/schedule-print/schedule-print.component';
import { ExamRequestComponent } from './print/exam-request/exam-request.component';
import { SingleScaleComponent } from './screens/register/single-scale/single-scale.component';
import { CalendarComponent } from './components/calendar/calendar.component';

import localePt from '@angular/common/locales/pt';
import { ChangeStatusComponent } from './modals/change-status/change-status.component';
import { ListPreparationComponent } from './screens/register/list-preparation/list-preparation.component';
import { PreparationComponent } from './screens/register/preparation/preparation.component';
import { PlanComponent } from './screens/register/plan/plan.component';
import { ListPlansComponent } from './screens/register/list-plans/list-plans.component';
import { ModalAddProcedureComponent } from './modals/modal-add-procedure/modal-add-procedure.component';
import { ModalAddAppointmentComponent } from './modals/modal-add-appointment/modal-add-appointment.component';
import { ListArrivalTimeSettingComponent } from './screens/register/list-arrival-time-setting/list-arrival-time-setting.component';
import { ArrivalTimeSettingComponent } from './screens/register/arrival-time-setting/arrival-time-setting.component';
import { SpendCeilComponent } from './screens/register/spend-ceil/spend-ceil.component';
import { EstablishmentDataComponent } from './components/establishment-data/establishment-data.component';
import { BlockComponent } from './components/block/block.component';
import { MedicalClinicComponent } from './components/medical-clinic/medical-clinic.component';
import { RoomsComponent } from './components/rooms/rooms.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ReceptionComponent } from './screens/reception/reception/reception.component';
import { SpontaneousDemandComponent } from './screens/reception/spontaneous-demand/spontaneous-demand.component';
import { RiskRatingComponent } from './screens/risk-rating/risk-rating.component';
import { RegisterRiskRatingComponent } from './screens/risk-rating/register-risk-rating/register-risk-rating.component';
import { ModalAlergiasomponent } from './modals/modal-alergias/modal-alergias.component';
import { ModalEscalaGlasgow } from './modals/modal-escala-glasgow/modal-escala-glasgow.component';
import { ModalEscalaDor } from './modals/modal-escala-dor/modal-escala-dor.component';
import { ModalClassificacaoRisco } from './modals/modal-classificacao-risco/modal-classificacao-risco.component';
import { AttendancesComponent } from './screens/attendances/attendances.component';
import { ProntuarioComponent } from './screens/prontuario/prontuario.component';
import { MedicalAdmissionComponent } from './components/prontuario-abas/medical-admission/medical-admission.component';
import { MedicalAdmissionRegistrationComponent } from './components/prontuario-abas/medical-admission/medical-admission-registration/medical-admission-registration.component';
import { MedicalAdmissionlistComponent } from './components/prontuario-abas/medical-admission/medical-admission-list/medical-admission-list.component';
import { PrescriptionComponent } from './components/prontuario-abas/prescription/prescription/prescription.component';
import { PrescriptionListComponent } from './components/prontuario-abas/prescription/prescription/prescription-list/prescription-list.component';
import { PrescriptionRegistrationComponent } from './components/prontuario-abas/prescription/prescription/prescription-registration/prescription-registration.component';
import { EvolutionComponent } from './components/prontuario-abas/evolution/evolution.component';
import { ExamsAttendanceComponent } from './components/prontuario-abas/exams/exams.component';
import { ExamsListComponent } from './components/prontuario-abas/exams/exams-list/exams-list.component';
import { ExamsRegistrationComponent } from './components/prontuario-abas/exams/exams-registration/exams-registration.component';
import { ReferralToSpecialistComponent } from './components/prontuario-abas/referral-to-specialist/referral-to-specialist.component';
import { AttendanceComponent } from './components/prontuario-abas/attendance/attendance.component';
import { EmergencyPrescriptionComponent } from './components/prontuario-abas/emergency-prescription/emergency-prescription.component';
import { EmergencyPrescriptionListComponent } from './components/prontuario-abas/emergency-prescription/emergency-prescription-list/emergency-prescription-list.component';
import { EmergencyPrescriptionRegistrationComponent } from './components/prontuario-abas/emergency-prescription/emergency-prescription-registration/emergency-prescription-registration.component';
import { ProcedureComponent } from './components/prontuario-abas/procedure/procedure.component';
import { ProcedureListComponent } from './components/prontuario-abas/procedure/procedure-list/procedure-list.component';
import { ProcedureRegistrationComponent } from './components/prontuario-abas/procedure/procedure-registration/procedure-registration.component';
import { CertificateComponent } from './components/prontuario-abas/certificate/certificate.component';
import { CertificateListComponent } from './components/prontuario-abas/certificate/certificate-list/certificate-list.component';
import { CertificateRegistrationComponent } from './components/prontuario-abas/certificate/certificate-registration/certificate-registration.component';
import { ModalEscalaRasey } from './modals/modal-escala-ramsey/modal-escala-ramsey.component';
import { TransferComponent } from './components/prontuario-abas/transfer/transfer.component';
import { TransferRegistrationComponent } from './components/prontuario-abas/transfer/transfer-registration/transfer-registration.component';
import { TransferListComponent } from './components/prontuario-abas/transfer/transfer-list/transfer-list.component';
import { DeclarationListComponent } from './components/prontuario-abas/certificate/declaration-list/declaration-list.component';
import { DeclarationRegistrationComponent } from './components/prontuario-abas/certificate/declaration-registration/declaration-registration.component';
import { FinalizeServiceComponent } from './components/prontuario-abas/finalize-service/finalize-service.component';
import { ComponenteClassificacaoRiscoComponent } from './components/componente-classificacao-risco/componente-classificacao-risco.component';
import { EmergencyPrescriptionPrintComponent } from './print/emergency-prescription-print/emergency-prescription-print.component';
import { EvolutionPrintComponent } from './print/evolution-print/evolution-print.component';
import { EvolutionRegistrationComponent } from './components/prontuario-abas/evolution/evolution-registration/evolution-registration.component';
import { EvolutionListComponent } from './components/prontuario-abas/evolution/evolution-list/evolution-list.component';
import { ReferralToSpecialistListComponent } from './components/prontuario-abas/referral-to-specialist/referral-to-specialist-list/referral-to-specialist-list.component';
import { ReferralToSpecialistRegistrationComponent } from './components/prontuario-abas/referral-to-specialist/referral-to-specialist-registration/referral-to-specialist-registration.component';
import { CertificatePrintComponent } from './print/certificate-print/certificate-print.component';
import { DeclarationPrintComponent } from './print/declaration-print/declaration-print.component';
import { ProcedurePrintComponent } from './print/procedure-print/procedure-print.component';
import { ReferralToSpecilistPrintComponent } from './print/referral-to-specilist-print/referral-to-specilist-print.component';
import { EvolutionMultiplePrintComponent } from './print/evolution-print-multiple/evolution-print-multiple.component';
import { NursingStationComponent } from './components/nursing-station/nursing-station.component';
import { HospitalBedComponent } from './components/hospital-bed/hospital-bed.component';
import { ListExamsGroupComponent } from './screens/register/exams-group/list-exams-group/list-exams-group.component';
import { AddExamsGroupComponent } from './screens/register/exams-group/add-exams-group/add-exams-group.component';
import { HistoryOfServiceComponent } from './components/prontuario-abas/history-of-service/history-of-service.component';
import { AddClosingReasonComponent } from './screens/register/closing-reason/add-closing-reason/add-closing-reason.component';
import { ListClosingReasonComponent } from './screens/register/closing-reason/list-closing-reason/list-closing-reason.component';
import { ExamsHistoryComponent } from './components/prontuario-abas/exams/exams-history/exams-history.component';
import { SettingsComponent } from './screens/settings/settings/settings.component';
import { ExamsSettingComponent } from './screens/settings/exams/exams.component';
import { ExamSettingComponent } from './screens/settings/exams/exam/exam.component';
import { StorageComponent } from './screens/storage/storage/storage.component';
import { AddMakerComponent } from './screens/storage/maker/add-maker/add-maker.component';
import { ListMakerComponent } from './screens/storage/maker/list-maker/list-maker.component';
import { ListSuplierComponent } from './screens/storage/suplier/list-suplier/list-suplier.component';
import { AddSuplierComponent } from './screens/storage/suplier/add-suplier/add-suplier.component';
import { InternmentComponent } from './screens/internment/internment.component';
import { ExamPrintComponent } from './print/exam-print/exam-print.component';
import { MedicalAdmissionPrintComponent } from './print/medical-admission-print/medical-admission-print.component';
import { StocksComponent } from './screens/storage/stocks/stocks.component';
import { StockComponent } from './screens/storage/stocks/stock/stock.component';
import { FaaPrintComponent } from './print/faa-print/faa-print.component';
import { PrescricaoComponent } from './components/prontuario-abas/prescricao/prescricao.component';
import { PrescricaoListComponent } from './components/prontuario-abas/prescricao/prescricao-list/prescricao-list.component';
import { PrescricaoCreateComponent } from './components/prontuario-abas/prescricao/prescricao-create/prescricao-create.component';
import { DashBoardComponent } from './screens/register/dashboard/dashboard-registration/dashboard-registration.component';
import { ListDashBoardComponent } from './screens/register/dashboard/list-dashboard/list-dashboard.component';
import { ListProductModelComponent } from './screens/storage/product-model/list-product-model/list-product-model.component';
import { ProductModelRegistrationComponent } from './screens/storage/product-model/product-model-registration/product-model-registration.component';
import { DashBoardsComponent } from './screens/dashboards/dashboards.component';
import { TeleatendimentoAgendarComponent } from './screens/reception/teleatendimento-agendar/teleatendimento-agendar.component';
import { TeleAtendimentoComponent } from './components/prontuario-abas/teleatendimento/teleatendimento.component';
import { ModalAgendamentoComponent } from './modals/modal-agendamento/modal-agendamento.component';
import { ReceituarioPrintComponent } from './print/receituario-print/receituario-print.component';
import { ExamSolicitaionComponent } from './print/exam-solicitacao/exam-solicitacao.component';
import { PrintComponent } from './screens/print/print.component';
import { FileUploadService } from './service/fileService';
import { ResultadosExameComponent } from './screens/resultados-exames/resultados-exames.component';
import { CriarResultadoComponent } from './screens/resultados-exames/criar-resultado/criar-resultado.component';
import { AutocompleteSigtapComponent } from './components/autocomplete-sigtap/autocomplete-sigtap.component';
import { GestaoEscalasComponent } from './screens/gestao-escalas/gestao-escalas.component';
import { appRoutes } from './routes';
import { GestaoEscalasAdmComponent } from './screens/gestao-escalas-adm/gestao-escalas-adm.component';
import { ListPerfilUsuarioComponent } from './screens/register/list-perfil-usuario/list-perfil-usuario.component';
import { PerfilComponent } from './screens/register/perfil/perfil.component';
import { PerfilUsuarioComponent } from './screens/register/perfil-usuario/perfil-usuario.component';
import { PsicologiaComponent } from './components/prontuario-abas/psicologia/psicologia.component';
import { ListExameImagemComponent } from './screens/exame-imagem/list-exame-imagem/list-exame-imagem.component';
import { AddExameImagemComponent } from './screens/exame-imagem/add-exame-imagem/add-exame-imagem.component';
import { DicomComponent } from './screens/exame-imagem/dicom/dicom.component';
import { LaboratorioComponent } from './screens/laboratorio/laboratorio.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

var telas = [
  LoginComponent,
  HomeComponent,
  ListProfessionalComponent,
  ClientComponent,
  ProfessionalComponent,
  EstablishmentComponent,
  RegisterComponent,
  ListEstablishmentComponent,
  ListClientComponent,
  RegulationComponent,
  ForwardingComponent,
  ExamsComponent,
  CitizenComponent,
  ListCitizenComponent,
  ListScheduleConfigurationComponent,
  ScheduleConfigurationComponent,
  ProfileComponent,
  ListProfileComponent,
  ListPerfilUsuarioComponent,
  PerfilComponent,
  ListUserComponent,
  UserComponent,
  ManualRegulationComponent,
  PacerecerLaudoComponent,
  AutocompleteComponent,
  GeneralConfigurationComponent,
  ListPreparationComponent,
  PreparationComponent,
  PlanComponent,
  ListPlansComponent,
  ListArrivalTimeSettingComponent,
  ArrivalTimeSettingComponent,
  ReceptionComponent,
  SpontaneousDemandComponent,
  TeleatendimentoAgendarComponent,
  RiskRatingComponent,
  RegisterRiskRatingComponent,
  AttendancesComponent,
  ProntuarioComponent,
  SettingsComponent,
  ExamsSettingComponent,
  ExamSettingComponent,
  ListExamsGroupComponent,
  AddExamsGroupComponent,
  AddMakerComponent,
  ListMakerComponent,
  StocksComponent,
  StockComponent,
  ListSuplierComponent,
  AddSuplierComponent,
  DashBoardComponent,
  ListDashBoardComponent,
  ListProductModelComponent,
  ProductModelRegistrationComponent,
  DashBoardsComponent,
  AutocompleteSigtapComponent,
  PerfilUsuarioComponent,
  ListExameImagemComponent,
  AddExameImagemComponent,
  DicomComponent
];

var componentes = [
  HeaderComponent,
  FooterComponent,
  SidebarComponent,
  ScheduleComponent,
  DateRangeComponent,
  MultiselectComponent,
  RegulationHistoricComponent,
  BlockComponent,
  MedicalClinicComponent,
  RoomsComponent,
  MedicalAdmissionComponent,
  AttendanceComponent,
  MedicalAdmissionRegistrationComponent,
  MedicalAdmissionlistComponent,
  ReferralToSpecialistComponent,
  EmergencyPrescriptionComponent,
  EmergencyPrescriptionListComponent,
  EmergencyPrescriptionRegistrationComponent,
  ProcedureComponent,
  ProcedureListComponent,
  ProcedureRegistrationComponent,
  CertificateComponent,
  CertificateListComponent,
  CertificateRegistrationComponent,
  TransferComponent,
  TransferRegistrationComponent,
  TransferListComponent,
  DeclarationListComponent,
  DeclarationRegistrationComponent,
  FinalizeServiceComponent,
  ComponenteClassificacaoRiscoComponent,
  EvolutionRegistrationComponent,
  EvolutionListComponent,
  ReferralToSpecialistListComponent,
  ReferralToSpecialistRegistrationComponent,
  NursingStationComponent,
  HospitalBedComponent,
  HistoryOfServiceComponent,
  ExamsHistoryComponent,
  PrescricaoComponent,
  PrescricaoListComponent,
  PrescricaoCreateComponent,
  TeleAtendimentoComponent,
  PsicologiaComponent
]

var directives = [
  ValidateMaskDirective
]

var modals = [
  ModalAuthExamsComponent,
  ModalScheduleProcedureComponent,
  ModalReportComponent,
  ModalViewVacanciesComponent,
  ModalScheduleForwardingComponent,
  ModalAuthForwardingComponent,
  ModalRealizationForwardingComponent,
  ModalCitizenRegistrationComponent,
  ModalManualRegulationComponent,
  ModalAddProcedureComponent,
  ModalAddAppointmentComponent,
  ModalAlergiasomponent,
  ModalEscalaGlasgow,
  ModalEscalaDor,
  ModalClassificacaoRisco,
  ModalEscalaRasey,
  ModalAgendamentoComponent
]

@NgModule({
  declarations: [
    AppComponent,
    telas,
    componentes,
    directives,
    modals,
    HtmlPrintComponent,
    SchedulePrintComponent,
    ExamRequestComponent,
    SingleScaleComponent,
    CalendarComponent,
    ChangeStatusComponent,
    SpendCeilComponent,
    EstablishmentDataComponent,
    RegistrationComponent,
    PrescriptionComponent,
    PrescriptionListComponent,
    PrescriptionRegistrationComponent,
    EvolutionComponent,
    ExamsAttendanceComponent,
    ExamsListComponent,
    ExamsRegistrationComponent,
    ComponenteClassificacaoRiscoComponent,
    ForwardingComponent,
    EmergencyPrescriptionPrintComponent,
    EvolutionPrintComponent,
    CertificatePrintComponent,
    DeclarationPrintComponent,
    ProcedurePrintComponent,
    ReferralToSpecilistPrintComponent,
    EvolutionMultiplePrintComponent,
    ListClosingReasonComponent,
    AddClosingReasonComponent,
    StorageComponent,
    ExamPrintComponent,
    MedicalAdmissionPrintComponent,
    InternmentComponent,
    FaaPrintComponent,
    ReceituarioPrintComponent,
    ExamSolicitaionComponent,
    PrintComponent,
    ResultadosExameComponent,
    CriarResultadoComponent,
    GestaoEscalasComponent,
    GestaoEscalasAdmComponent,
    LaboratorioComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgxAlertsModule.forRoot(),
    NgxMaskModule.forRoot(),
    SnotifyModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#2196f3',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    NgxViacepModule,
    RouterModule.forRoot(appRoutes, { useHash: false }),    
    BsDatepickerModule.forRoot()
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    NgxViacepService,
    NgxAlertsService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    Location,
    AuthGuard,
    Global,
    ApiService,
    FileUploadService,
    {
      provide: LOCALE_ID,
      useValue: "pt"
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    modals
  ],
})
export class AppModule { }
