import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import Swal from 'sweetalert2';
import { AuthService } from '../../../service/auth/auth.service';

@Component({
  selector: 'app-certificate-aba',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() ListaAbaAtestado: any = [];
  seeList: boolean = true;

  visualizarListaDeclaracao: boolean = true;
  listaAbaDeclaracao: any = [];
  visualizar_prontuario: any = false;
  constructor(public service: ApiService,
    private authService: AuthService) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  goBack = () => {
    this.seeList = true;
  }

  goBackDeclaration = () => {
    this.visualizarListaDeclaracao = true;
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }

  carregarAbaDeclaracao() {
    this.visualizarListaDeclaracao = true;
    this.obterListaDeclaracao();
  }

  obterListaDeclaracao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/ProntuarioDeclaracao`).then(
      result => {
        this.listaAbaDeclaracao = result
        Swal.close()
      }
    ).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    });
  }

}
