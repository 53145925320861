import { Component, Input, OnInit } from '@angular/core';
import { Global } from '../../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { AuthService } from '../../../../service/auth/auth.service';
import { ApiService } from '../../../../service/apiServices';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.css']
})
export class TransferListComponent implements OnInit {

  @Input() listaAbaTransferencia: any = [];
  @Input() cidadao: any = {};
  @Input() prontuario: any = {};
  transferenciaDados: any = {};
  dataHoje: any = ''
  fileUrl: SafeUrl;
  dadosImpresso: any;
  constructor(private global: Global,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService) { }

  ngOnInit() {
  }

  visualizar = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Prontuario/Transferencia/${id}`).then((res) => {
      Swal.close();
      this.transferenciaDados = res;
      this.visualizarItem();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

  visualizarItem = () => {
    $("#modalTransferenciaVisualizar").toggleClass("show");
    $("#modalTransferenciaVisualizar").css("display", "block");
  }

  fecharModal = () => {
    $("#modalTransferenciaVisualizar").toggleClass("show");
    $("#modalTransferenciaVisualizar").css("display", "none");
  }


  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(transferenci_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Transferencia/${transferenci_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }


  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <tr>
  <td>
      <p>${element.protocolo}</p>
  </td>
  <td>
      <p>${element.sigtap_codigo}</p>
  </td>
  <td>
      <p>${element.sigtap_nome}</p>
  </td>
  <td>
      <p>${element.criticidade}</p>
  </td>
  <td>
     <p>${element.status}</p>
  </td>
  </tr>
  `
    });
    return td;
  }

  prepararImpressoHtml(dadosImpresso, apac = false) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
     ${this.global.cabecalhoImpresso(dadosImpresso, "TRANSFÊRENCIA")}
     <table>
     <tr colspan="10">
       <td class="cabecalho">
         <h4>TRANSFÊRENCIA</h4>
       </td>
     </tr>
   </table>
   <table>
     <tr>
       <td>
         <b> Data da Solicitação: </b> ${this.global.formatDateTime(dadosImpresso.conteudo.solicitado_em)}
       </td>
     </tr>
     <tr>
       <td>
         <b> Exame Realizado: </b> ${dadosImpresso.conteudo.exame_realizado}
       </td>
     </tr>
     <tr>
       <td>
         <b> Justificativa: </b> ${dadosImpresso.conteudo.justificativa}
       </td>
     </tr>
     <tr>
       <td>
         <b> Observação: </b> ${dadosImpresso.conteudo.observacao}
       </td>
     </tr>
     <tr>
       <td>
         <b> Referência: </b> ${dadosImpresso.conteudo.referencia}
       </td>
     </tr>
     <tr>
       <td>
         <b> Terapêutica utilizada: </b> ${dadosImpresso.conteudo.terapeutica_utilizada}
       </td>
     </tr>
     <tr>
       <td>
         <b> Criticidade: </b>${dadosImpresso.conteudo.prioridade_transferencia}
       </td>
     </tr>    
   </table>
    ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

}
