import { Component, OnInit } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import Swal from 'sweetalert2';
import { AuthService } from '../../../service/auth/auth.service'
import { Global } from '../../../global'
import * as $ from "jquery";
@Component({
  selector: 'app-list-schedule-configuration',
  templateUrl: './list-schedule-configuration.component.html',
  styleUrls: ['./list-schedule-configuration.component.css']
})
export class ListScheduleConfigurationComponent implements OnInit {

  configAgendaList: any = [];
  filtro: string = "";
  paginaAvulsa: any = 1;
  totalPorPaginaAvulsa: any = 100;
  totalPaginaAvulsa: any = 1;

  listaAgendaAvulsa: any = [];
  filtroAgendaAvulsa: string = "";

  global_: any;

  listaProfissionais: any = null;
  profissionalSelecionado: any = {};
  listaHorarios: any = [];
  listaLocalAtendimento: any = [];

  pagina: any = 1;
  profissional_nome: any = "";
  listaTempoAtendimento: any = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  exibirBtns: any = true;
  constructor(
    public service: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private global: Global
  ) {
    this.global_ = this.global
  }

  ngOnInit() {
    // $("#modalHorarios").toggleClass("show");
    // $("#modalHorarios").css("display", "block");
    this.obterListaLocalAtendimento();
    this.obterListaAgenda().then(
      result => {
        this.obterAgendaAvulsa();
        Swal.close()
      },
      error => {
        this.obterAgendaAvulsa();
        Swal.close()
      }
    )
  }

  obterListaAgenda = () => {
    return new Promise((resolve, reject) => {
      Swal.fire('Favor aguarde');
      Swal.showLoading();
      this.service.Get(`AgendaConfiguracao`).then(res => {
        Swal.close();
        this.configAgendaList = res;
        resolve('ok')
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close();
        this.alerts.notifyError({
          body: `${res.erro.erros[0].messagem}`,
          title: 'Erro'
        });
      })
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} essa Configuração de agenda?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`AgendaConfiguracao/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaAgenda();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.configAgendaList.length === 0) {
      return this.configAgendaList;
    }
    return this.configAgendaList.filter(
      c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()) || c.tipo.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase())
    );
  }

  filtroListaAvulsa() {
    if (this.listaAgendaAvulsa.length === 0) {
      return this.listaAgendaAvulsa;
    }
    return this.listaAgendaAvulsa.filter(
      c => c.Ocupacao.nome.toLocaleLowerCase().includes(this.filtroAgendaAvulsa.toLocaleLowerCase())
      // c => c.Ocupacao.nome.toLocaleLowerCase().includes(this.filtroAgendaAvulsa.toLocaleLowerCase()) || c.Profissional.nome.toLocaleLowerCase().includes(this.filtroAgendaAvulsa.toLocaleLowerCase())
    );
  }

  obterAgendaAvulsa = () => {
    this.service.Get(`AgendaDia/Avulsa?Skip=${this.paginaAvulsa}&Take=${this.totalPorPaginaAvulsa}`).then(
      result => {
        var res: any = result['conteudo'];

        this.totalPaginaAvulsa = Math.ceil(result['quantidade_total'] / this.totalPorPaginaAvulsa)

        this.listaAgendaAvulsa = res.filter(x => x.ativo == true);
        for (let item of this.listaAgendaAvulsa) {
          if (item.agenda_em) {
            item.data_agenda = item.agenda_em.split('T')[0]
          }
        }
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  voltarPaginaAvulsa = () => {
    this.paginaAvulsa = this.paginaAvulsa - 1;
    this.obterAgendaAvulsa()
  }

  removerAgenda = (obj) => {
    if (!obj.liberada) {
      Swal.fire('Erro', 'Essa escala já possui agendamentos ocupados', 'error')
      return
    }
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Patch_body(`AgendaDia/${obj.id}`, { ativo: false }).then(
      result => {
        this.obterAgendaAvulsa()
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  pesquisarProfissional = () => {
    this.pagina = 1;
    this.loadingProfissionaisConfig(this.pagina);
  }

  loadingProfissionaisConfig = (page = 1) => {
    this.exibirBtns = false;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var url = `AgendaConfiguracao/Lotacao?pagina=${page}&quantidade=10`;
    if (this.profissional_nome) {
      url = url += `&busca=${this.profissional_nome}`;
    }
    this.service.Get(url).then(
      result => {
        
        this.listaProfissionais = result;
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  editarAgenda = (item) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.profissionalSelecionado = item;
    this.service.Get(`AgendaConfiguracao/Lotacao/${item.id}/Horario`).then(
      result => {
        
        this.listaHorarios = result;
        $("#modalHorarios").toggleClass("show");
        $("#modalHorarios").css("display", "block");
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  fecharModal = () => {
    $("#modalHorarios").toggleClass("show");
    $("#modalHorarios").css("display", "none");
  }

  obterListaLocalAtendimento = () => {
    this.service.Get(`AgendaConfiguracao/LocalAtendimento`).then(res => {
      Swal.close();
      this.listaLocalAtendimento = res;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  nextPage() {
    this.pagina = this.pagina + 1;
    this.loadingProfissionaisConfig(this.pagina)
  }

  prevPage() {
    this.pagina = this.pagina - 1;
    this.loadingProfissionaisConfig(this.pagina)
  }

  FormataHora = (inicioFim, hora) => {
    if (inicioFim == 1) {
      if (hora.hora_inicio != null) {
        if (hora.hora_inicio.length == 1)
          hora.hora_inicio = `0${hora.hora_inicio}00`;
        if (hora.hora_inicio.length == 2)
          hora.hora_inicio = `${hora.hora_inicio}00`;
        if (hora.hora_inicio.length == 3)
          hora.hora_inicio = `${hora.hora_inicio}0`;
      }
    } else if (inicioFim == 2) {
      if (hora.hora_fim != null) {
        if (hora.hora_fim.length == 1)
          hora.hora_fim = `0${hora.hora_fim}00`;
        if (hora.hora_fim.length == 2)
          hora.hora_fim = `${hora.hora_fim}00`;
        if (hora.hora_fim.length == 3)
          hora.hora_fim = `${hora.hora_fim}0`;
      }
    }
  }

  validarHoras(horaInicio, horaFim) {
    const formatoHora = /^([01]\d|2[0-3]):([0-5]\d)$/; // Expressão regular para validar o formato "hh:mm"

    if (formatoHora.test(horaInicio) && formatoHora.test(horaFim)) {
      // As horas estão no formato correto
      const horaInicioObj = new Date(`1970-01-01T${horaInicio}:00`);
      const horaFimObj = new Date(`1970-01-01T${horaFim}:00`);

      // Verificar se a hora de início não é menor que a hora de fim
      if (horaInicioObj < horaFimObj) {
        return true;
      }
    }

    return false;
  }

  addNovoHorario = (item) => {
    var obj = {
      "ativo": true,
      "tipo_agenda": "ATENDIMENTO",
      "estabelecimento_id": this.profissionalSelecionado.estabelecimento_id,
      "ocupacao_id": this.profissionalSelecionado.ocupacao_id,
      "segunda_feira": false,
      "terca_feira": false,
      "quarta_feira": false,
      "quinta_feira": false,
      "sexta_feira": false,
      "sabado": false,
      "domingo": false,
      "hora_inicio": "",
      "hora_fim": "",
      "tempo_atendimento": 0,
      "profissional_id": this.profissionalSelecionado.profissional_id,
      "local_atendimento": 0,
      "uuid": this.global.generateUUID()
    }

    switch (item.dia) {
      case "DOMINGO":
        obj.domingo = true;
        break;
      case "SEGUNDA":
        obj.segunda_feira = true;
        break;
      case "TERCA":
        obj.terca_feira = true;
        break;
      case "QUARTA":
        obj.quarta_feira = true;
        break;
      case "QUINTA":
        obj.quinta_feira = true;
        break;
      case "SEXTA":
        obj.sexta_feira = true;
        break;
      case "SABADO":
        obj.sabado = true;
        break;
    }

    item.horarios.push(obj);
  }


  removerItem = (hora, item) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Deseja realmente remover esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Remover!'
    }).then((result) => {
      if (result.isConfirmed) {
        if (hora.id) {
          this.deleteAgendaHora(hora, item)
        } else {
          const indexToRemove = item.horarios.findIndex(item => item.uuid === hora.uuid);
          if (indexToRemove !== -1) {
            item.horarios.splice(indexToRemove, 1);
          }
        }
      }
    });

  }

  onCheckboxChange(item) {
    // Se o estado do checkbox estiver prestes a ser alterado    
    if (!item.ativo && item.horarios.length > 0) {
      // Exibe o swal para confirmar a alteração
      Swal.fire({
        title: 'Tem certeza?',
        text: 'Deseja realmente alterar esses horários?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, alterar!',
        allowOutsideClick: false, // Impede clique fora do swal
        allowEscapeKey: false // Impede o uso da tecla "Esc"
      }).then((result) => {
        // Se o usuário confirmar, atualize o status do checkbox
        if (result.isConfirmed) {
          // Atualiza o estado do checkbox
          item.ativo = false;
          this.apagarDadosPorLista(item)
        } else {
          // Se o usuário cancelar, mantenha o estado original do checkbox
          item.ativo = true;
        }
      });
    }
  }
  apagarDadosPorLista = (item) => {
    Swal.fire('Apagando horários');
    Swal.showLoading();
    this.deleteAgendaHoraRecursiva(item);
  }

  ajustarItem = (hora) => {
    if (!hora.hora_inicio) {
      Swal.fire('Erro', "Favor informar uma hora de inicio válida", 'error');
      return false;
    }
    if (!hora.hora_fim) {
      Swal.fire('Erro', "Favor informar uma hora fim válida", 'error');
      return false;
    }

    if (!this.validarHoras(hora.hora_inicio, hora.hora_fim)) {
      Swal.fire('Erro', "Favor informar uma hora inicio e fim válidas", 'error');
      return
    }


    if (hora.local_atendimento == 0) {
      Swal.fire('Erro', "Favor informar o local de atendimento", 'error');
      return false;
    }

    if (hora.tempo_atendimento == 0) {
      Swal.fire('Erro', "Favor informar o tempo de atendimento", 'error');
      return false;
    }

    if (hora.id) {
      this.putAgendaHora(hora);
    } else {
      this.postAgendaHora(hora);
    }
  }

  postAgendaHora = (obj) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`AgendaConfiguracao/Horario`, obj).then(
      result => {
        
        obj.id = result["id"];
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  putAgendaHora = (obj) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Put(`AgendaConfiguracao/Horario/${obj.id}`, obj).then(
      result => {
        
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  deleteAgendaHoraRecursiva = (lista) => {
    // Se há horários na lista
    if (lista.horarios.length > 0) {
      const horario = lista.horarios[0]; // Pega o primeiro horário
      // Chama o serviço para excluir o horário
      if (horario.id) {
        this.service.Delete(`AgendaConfiguracao/Horario/${horario.id}`).then(
          result => {
            
            // Remove o horário da lista
            lista.horarios.shift();
            // Se não houver mais horários na lista
            if (lista.horarios.length === 0) {
              lista.ativo = false; // Desativa a lista se não houver mais horários
            }
            // Chama a função recursivamente com a lista atualizada
            this.deleteAgendaHoraRecursiva(lista);
            // Fecha o swal          
          },
          error => {
            // Tratamento de erro
            if (error.status == 401 || error.error.status == 401) {
              Swal.close();
              this.authService.logoutUser();
            }
            Swal.fire('Erro', error.error.messagem, 'error');
          }
        );
      } else {
        const indexToRemove = lista.horarios.findIndex(item => item.uuid === horario.uuid);
        if (indexToRemove !== -1) {
          lista.horarios.splice(indexToRemove, 1);
        }
        this.deleteAgendaHoraRecursiva(lista);
      }
    } else {
      Swal.close();
    }
  };

  deleteAgendaHora = (obj, lista) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Delete(`AgendaConfiguracao/Horario/${obj.id}`).then(
      result => {
        
        const indexToRemove = lista.horarios.findIndex(item => item.id === obj.id);
        if (indexToRemove !== -1) {
          lista.horarios.splice(indexToRemove, 1);
        }
        if (lista.horarios.length == 0) {
          lista.ativo = false;
        }
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }
}
