import { Component, OnInit, Directive } from '@angular/core';
declare var jquery: any;
declare var $: any;
import Swal from 'sweetalert2';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import * as moment from 'moment';
import { Location } from '@angular/common';

import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  client: any = {};
  status: boolean = true;
  uid: any = '';
  lockBTN: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private location: Location,
    public global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.init()
    this.client.ativo = this.status;
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.getClient(uid);
    }
  }

  saveClient = () => {
    this.compareData(this.client).then(
      result => {
          var Obj = {
            "ativo": result['ativo'],
            "nome": result['nome'],
            "nome_empresarial": result['nome_empresarial'],
            "cnpj": result['clientCnpj'],
            "telefone": this.global.removeMask(result['clientPhone']),
            "email": result['email']
          }
        if(this.client.id){
          this.putClient(Obj, this.client.id)
        }
        else {
          this.postClient(Obj)
        }
      },
      error => {
        this.emitError(error)
      }
    )
  }

  uploadLogo = () => {
    var x = document.getElementById('inputFile');
    x.click();
  }

  compareData = (data) => {
    return new Promise((resolve, reject) => {
      if (data.nome == '' || data.nome == null) {
        reject('É preciso informar o nome do cliente')
        return
      }
      if (data.nome_empresarial == '' || data.nome_empresarial == null) {
        reject(`É preciso informar o nome fantaisa`)
        return
      }
      if (data.cnpj == '' || data.cnpj == null) {
        reject(`É preciso informar o CNPJ do cliente`)
        return
      }
      if (data.cnpj.length > 0) {
        data.clientCnpj = data.cnpj.replace(/\D/g, '');
      }
      if (data.clientCnpj.length < 14) {
        reject(`É preciso informar um CNPJ válido para o cliente`)
        return
      }
      if (data.telefone == '' || data.telefone == null) {
        reject(`É preciso informar o telefone do cliente`)
        return
      }
      if (data.telefone.length > 0) {
        data.clientPhone = data.telefone.replace(/\D/g, '');
      }
      if (data.clientPhone.length < 10) {
        reject(`É preciso informar um número de telefone válido para o cliente`)
        return
      }
      if (!data.email) {
        reject(`É preciso informar um e-mail válido para o cliente`)
        return
      }
      if (data.email){
        if (!this.global.validaEmail(data.email)){
          reject('Favor digitar um e-mail válido')
        }
      }
      resolve(data)
    })
    
  }

  changeStatus = () => {
    this.status = !this.status;
    this.client.ativo = this.status;
  }

  goBack() {
    this.location.back();
  }

  getClient = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Cliente/${id}`).then(res => {
      this.client = res;
      this.global.applyMask(this.client);
      this.status = this.client.ativo;
      Swal.close();
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }

  postClient = (Obj) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.lockBTN = true;
    this.service.Post(`Cliente`, Obj).then(
      result => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Cliente salvo com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.lockBTN = false;
              this.goBack();
            }
          }
        );
      },
      error => {
        this.emitError(error.error[0].messagem)
      }
    )
  }

  putClient = (Obj, id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Put(`Cliente/${id}`, Obj).then(result => {
      Swal.close()
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Cliente atualizado com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
    this.lockBTN = false;
    this.goBack();
          }
        }
      );
    }).catch(result => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: result.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  emitError = (error) => {
    if (typeof error == 'string'){
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401){
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }


}
