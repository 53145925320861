import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { Global } from '../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

declare var Simditor: any;

@Component({
  selector: 'app-evolution',
  templateUrl: './evolution.component.html',
  styleUrls: ['./evolution.component.css']
})
export class EvolutionComponent implements OnInit {
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() listaEvolucao: any = [];
  seeList: boolean = true;
  visualizar_prontuario: any = false;
  constructor(public service: ApiService, private authService: AuthService) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  goBack = () => {
    this.seeList = true;
    this.obterListaEvolucao();
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }


  obterListaEvolucao = () => {
    if (!this.prontuario) {
      return;
    }
    this.service.Get(`Prontuario/${this.prontuario.id}/ProntuarioEvolucao`).then(
      result => {
        this.listaEvolucao = result
      },
      error => {
        if (error.codigo == 'NotFound') {
          Swal.fire('Atenção', error.error.messagem, 'warning')
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

}
