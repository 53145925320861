import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var jquery: any;
declare var $: any;
declare var FullCalendar: any;
import Swal from 'sweetalert2';
import * as moment from "moment";
@Component({
  selector: 'app-gestao-escalas',
  templateUrl: './gestao-escalas.component.html',
  styleUrls: ['./gestao-escalas.component.css']
})
export class GestaoEscalasComponent implements OnInit {
  usuario: any = {};
  presenca: any = {};
  listaProfissional: any = [];
  profissionalSelecionado: any = 0;
  ocupacaoSelecionada: any = 0;
  eventos: any = [];
  listaOcupacoes: any = [];
  estabelecimento_id: any = 0;
  data_inicial: any;
  data_final: any;
  calendar: any;
  calendar_: any;


  filtro: any = '';
  resultCidadao: any = [];
  pagina: any = 1;
  totalPorPagina: any = 300;
  totalPagina: any = 1;

  cidadado_selecionado: any = null;
  horario_selecionado: any = null;
  profissional: any = null;
  ocupacao: any = null;

  bloquearAgendas: any = false;
  listaHorariosBloquear: any = [];

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnInit() {
    this.ocupacaoSelecionada = this.presenca.ocupacao_id;
    this.getOcupacao();
  }

  changeBloquearAgendas() {

  }


  salvarListaHorariosBloqueados() {
    if (this.listaHorariosBloquear.length > 0) {
      var lista = [];

      this.listaHorariosBloquear.forEach((item) => {
        lista.push({
          "agenda_hora_id": item.agenda_hora_id,
          "agenda_configuracao_id": item.agenda_configuracao_id,
          "agenda_inicio": item.data_inicial,
          "bloqueado": !item.bloqueado,
        })
      });
      this.bloquearAgendaDia(lista);
    }
  }



  bloquearAgendaDia = (obj) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`AgendaDia/Bloquear`, obj).then(
      result => {
        this.listaHorariosBloquear = [];
        this.getAgendaHora();
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }
  startCalendar() {
    let that = this;
    var calendarEl = document.getElementById('calendar_gestao');
    this.calendar = new FullCalendar.Calendar(calendarEl, {
      locale: 'pt',
      aspectRatio: 1,
      expandRows: true,
      slotMinTime: '06:00',
      slotMaxTime: '23:59',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,dayGridMonth,timeGridDay'
      },
      columnFormat: {
        month: 'ddd',
        week: 'ddd d',
        day: 'd'
      },
      buttonText: {
        today: "Hoje",
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      initialView: 'timeGridWeek',
      initialDate: this.data_inicial,
      nowIndicator: true,
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true, // allow "more" link when too many events   
      events: this.eventos,
      timeZone: 'America/Sao_Paulo',
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short' // Adiciona AM/PM
      },
      datesSet: function (info) {
        var startOfWeek = info.startStr; // Início da semana
        var endOfWeek = info.endStr; // Fim da semana
        if (info.view.type == 'dayGridMonth') {

        } else
          if (info.view.type == 'timeGridWeek') {
            that.data_inicial = startOfWeek;
            that.data_final = endOfWeek;
          } else
            if (info.view.type == 'timeGridDay') {
              that.data_inicial = startOfWeek;
              that.data_final = startOfWeek;
            }
      },
      dateClick: function (info) {

      },
      eventClick: function (event) {

        console.log(event.event._def.extendedProps);
        if (!that.bloquearAgendas) {
          if (event.event.extendedProps.bloqueado) {
            return;
          }
          if (event.event._def.extendedProps.vago) {
            that.horario_selecionado = event.event._def.extendedProps;
            that.cidadado_selecionado = null;
            that.resultCidadao = [];
            that.filtro = "";
            that.profissional = that.listaProfissional.filter(x => x.id == that.profissionalSelecionado)[0];
            that.ocupacao = that.listaOcupacoes.filter(x => x.id == that.ocupacaoSelecionada)[0];
            $("#modalAgendamentoCalendario").toggleClass("show");
            $("#modalAgendamentoCalendario").css("display", "block");
          } else {
            that.horario_selecionado = event.event._def.extendedProps;
            that.cidadado_selecionado = null;
            that.profissional = that.listaProfissional.filter(x => x.id == that.profissionalSelecionado)[0];
            that.ocupacao = that.listaOcupacoes.filter(x => x.id == that.ocupacaoSelecionada)[0];
            $("#modalDetalhesAgendamento").toggleClass("show");
            $("#modalDetalhesAgendamento").css("display", "block");
            that.obterDetalhes();
          }
        } else {
          if (event.event.extendedProps.bloqueado) {
            var horario = event.event._def.extendedProps;
            var filter = that.listaHorariosBloquear.filter(x => x === horario);
            if (filter.length > 0) {
              that.listaHorariosBloquear.forEach((element, index) => {
                if (element == horario) {
                  that.listaHorariosBloquear.splice(index, 1);
                }
              });
              //voltar para a cor original
              if (event.event.extendedProps.local_atendimento == "PRESENCIAL") {
                event.el.style.backgroundColor = '#007bff';
              } else {
                event.el.style.backgroundColor = '#6d00ff';
              }
            } else {
              event.el.style.backgroundColor = '#27de85';
              that.listaHorariosBloquear.push(horario);
            }
          } else
            if (event.event._def.extendedProps.vago) {
              var horario = event.event._def.extendedProps;
              var filter = that.listaHorariosBloquear.filter(x => x === horario);
              if (filter.length > 0) {
                that.listaHorariosBloquear.forEach((element, index) => {
                  if (element == horario) {
                    that.listaHorariosBloquear.splice(index, 1);
                  }
                });
                //voltar para a cor original
                if (event.event.extendedProps.local_atendimento == "PRESENCIAL") {
                  event.el.style.backgroundColor = '#007bff';
                } else {
                  event.el.style.backgroundColor = '#6d00ff';
                }
              } else {
                event.el.style.backgroundColor = '#27de85';
                that.listaHorariosBloquear.push(horario);
              }
            }
        }
      },
      eventDidMount: function (info) {
        if (info.event.extendedProps.bloqueado) {
          info.el.style.backgroundColor = '#8d8a83';
          return;
        }
        if (info.event.extendedProps.vago) {
          if (info.event.extendedProps.bloqueado) {
            info.el.style.backgroundColor = '#8d8a83';
          } else
            if (info.event.extendedProps.local_atendimento == "PRESENCIAL") {
              info.el.style.backgroundColor = '#007bff';
            } else {
              info.el.style.backgroundColor = '#6d00ff';
            }
        } else {
          info.el.style.backgroundColor = '#e9479a';
          if (info.event.extendedProps.atendido) {
            info.el.style.backgroundColor = '#229b23';
          } else
            if (info.event.extendedProps.atendimento_status == "ATENDIMENTO_ENCERRADO") {
              info.el.style.backgroundColor = '#ffc107';
            } else
              if (info.event.extendedProps.bloqueado) {
                info.el.style.backgroundColor = '#8d8a83';
              }
        }
      },
      eventContent: function (info) {
        var props = info.event._def.extendedProps;
        return {
          html: `
          <div>
            <span class="fc-time">${info.timeText}</span>             
          </div>
          `,
        };
      }
    });

    this.calendar.render();
    this.calendar.setOption('locale', 'pt');
    $('#calendar_gestao').on('click', '.fc-prev-button', function () {
      if (that.calendar.currentData.currentViewType == 'dayGridMonth') {
        var mesInicio = moment(that.calendar.currentData.currentDate).add(1, 'months').clone().startOf('month').format('YYYY-MM-DD');
        var mesFim = moment(that.calendar.currentData.currentDate).add(1, 'months').clone().endOf('month').format('YYYY-MM-DD');
        that.data_inicial = mesInicio;
        that.data_final = mesFim;
      }
      that.listaHorariosBloquear = [];
      that.getAgendaHora();
    });

    $('#calendar_gestao').on('click', '.fc-next-button', function () {
      if (that.calendar.currentData.currentViewType == 'dayGridMonth') {
        var mesInicio = moment(that.calendar.currentData.currentDate).add(1, 'months').clone().startOf('month').format('YYYY-MM-DD');
        var mesFim = moment(that.calendar.currentData.currentDate).add(1, 'months').clone().endOf('month').format('YYYY-MM-DD');
        that.data_inicial = mesInicio;
        that.data_final = mesFim;
      }
      that.listaHorariosBloquear = [];
      that.getAgendaHora();
    });

    $('#calendar_gestao').on('click', '.fc-dayGridMonth-button', function () {
      var mesInicio = moment(that.calendar.currentData.currentDate).clone().startOf('month').format('YYYY-MM-DD');
      var mesFim = moment(that.calendar.currentData.currentDate).clone().endOf('month').format('YYYY-MM-DD');
      that.data_inicial = mesInicio;
      that.data_final = mesFim;
      that.listaHorariosBloquear = [];
      that.getAgendaHora();
    });

    $('#calendar_gestao').on('click', '.fc-timeGridWeek-button', function () {
      that.listaHorariosBloquear = [];
      that.getAgendaHora();
    });

    $('#calendar_gestao').on('click', '.fc-timeGridDay-button', function () {
      that.listaHorariosBloquear = [];
      that.getAgendaHora();
    });

    $(".fc-today-button").click(function () {
      that.listaHorariosBloquear = [];
      if (that.calendar.currentData.currentViewType == 'dayGridMonth') {
        const dataAtual = moment();
        var mesInicio = dataAtual.clone().startOf('month').format('YYYY-MM-DD');
        var mesFim = dataAtual.clone().endOf('month').format('YYYY-MM-DD');
        that.data_inicial = mesInicio;
        that.data_final = mesFim;
      } else {
        const dataAtual = moment();
        // Obtenha o primeiro dia da semana
        const primeiroDiaSemana = dataAtual.clone().startOf('week');
        // Obtenha o último dia da semana
        const ultimoDiaSemana = dataAtual.clone().endOf('week');
        this.data_inicial = primeiroDiaSemana.format('YYYY-MM-DD');
        this.data_final = ultimoDiaSemana.format('YYYY-MM-DD');
      }
      that.getAgendaHora();
    });

  }

  startCalendarPick() {
    var calendarEl = document.getElementById('calendar_pick');

    this.calendar_ = new FullCalendar.Calendar(calendarEl, {
      locale: 'pt',
      aspectRatio: 1,
      headerToolbar: {
        left: 'prev,next',
        center: '',
        right: 'title'
      },
      titleFormat: { month: 'long' },
      initialDate: this.data_inicial,
      navLinks: false, // can click day/week names to navigate views
      businessHours: false, // display business hours
      editable: false,
      selectable: false,
      dateClick: function (info) {

      }
    });

    this.calendar_.render();
    this.calendar_.setOption('locale', 'pt');
  }

  getAgendaHora = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`AgendaDia/Calendario?data_inicio=${this.data_inicial}&data_final=${this.data_final}&estabelecimento_id=${this.presenca.estabelecimento_id}&ocupacao_id=${this.ocupacaoSelecionada}&profissional_id=${this.profissionalSelecionado}`).then(
      result => {
        this.eventos = result;

        this.eventos.forEach(element => {
          element.data_inicial = element.start;
          element.data_final = element.end;
        });

        if (!this.calendar) {
          this.startCalendar();
          // this.startCalendarPick();
        } else {
          this.calendar.removeAllEvents();
          this.calendar.addEventSource(this.eventos)
        }
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  getOcupacao = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento/${this.presenca.estabelecimento_id}/Ocupacao`).then(
      result => {
        this.listaOcupacoes = result;
        if (this.presenca.ocupacao_id) {
          this.getProfissionais(this.presenca.ocupacao_id, true);
        }
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  carregarProfissionais() {
    if (this.ocupacaoSelecionada) {
      this.getProfissionais(this.ocupacaoSelecionada);
    }
  }

  getProfissionais = (ocupacao_id, primeira = false) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Profissional?OcupacaoId=${ocupacao_id}&EstabelecimentoId=${this.presenca.estabelecimento_id}`).then(
      result => {
        this.listaProfissional = result;
        if (primeira) {
          this.profissionalSelecionado = this.usuario.usuario_profissional_id;
          this.pesquisarAgenda();
        }
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  pesquisarAgenda() {
    if (!this.ocupacaoSelecionada || this.ocupacaoSelecionada == 0) {
      Swal.fire('Alerta', "Favor selecionar uma ocupação", 'warning');
      return;
    }

    if (!this.profissionalSelecionado || this.profissionalSelecionado == 0) {
      Swal.fire('Alerta', "Favor selecionar um profissional", 'warning');
      return;
    }


    const dataAtual = moment();

    // Obtenha o primeiro dia da semana
    const primeiroDiaSemana = dataAtual.clone().startOf('week');
    // Obtenha o último dia da semana
    const ultimoDiaSemana = dataAtual.clone().endOf('week');

    this.data_inicial = primeiroDiaSemana.format('YYYY-MM-DD');
    this.data_final = ultimoDiaSemana.format('YYYY-MM-DD');

    this.getAgendaHora();
  }

  fecharModalCalendario() {
    this.resultCidadao = [];
    this.profissional = null;
    this.ocupacao = null;
    this.cidadado_selecionado = null;
    this.horario_selecionado = null;
    this.filtro = "";
    $("#modalAgendamentoCalendario").toggleClass("show");
    $("#modalAgendamentoCalendario").css("display", "none");
  }

  obterListaCidadaos = () => {
    if (this.filtro.length == 0) {
      Swal.fire('Erro', 'Favor digitar um filtro.', 'error');
      return;
    }

    var busca = this.filtro;
    if (!isNaN(parseInt(this.filtro[0])))
      var busca = this.global.removeMask(this.filtro);

    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Cidadao?Skip=${this.pagina}&Take=${this.totalPorPagina}&Busca=${busca}`).then(res => {
      this.resultCidadao = res['conteudo'];
      if (this.resultCidadao.length > 0) {
        this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
      }
      for (let cidadao of this.resultCidadao) {
        this.global.applyMask(cidadao)
      }
      Swal.close();
      if (this.resultCidadao.length == 0)
        Swal.fire({
          title: 'Atenção',
          text: `Esse paciente não foi encontrado em nosso sistema. Deseja cadastra-lo agora?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#dc3545'
        }).then(
          result => {
            if (result.isConfirmed) {
              $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
              $("#modalNovoCidadaoDemandaEspontanea").css("display", "block");
            }
          }
        );
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  fecharModal = () => {
    $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
    $("#modalNovoCidadaoDemandaEspontanea").css("display", "none");
  }

  retornoNovoCidadao = (cidadao) => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      html: 'Operação realizada com sucesso!'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.filtro = cidadao.nome;
          this.fecharModal();
          this.obterListaCidadaos();
        }
      }
    );
  }

  selecionarCidadao(cidadao) {
    this.cidadado_selecionado = cidadao;
  }

  confirmarAgenda() {
    var obj = {
      "cidadao_id": this.cidadado_selecionado.id,
      "agenda_hora_id": this.horario_selecionado.agenda_hora_id,
      "agenda_configuracao_id": this.horario_selecionado.agenda_configuracao_id,
      "agenda_inicio": this.horario_selecionado.data_inicial
    }

    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`AgendaDia/Agendar`, obj).then(
      result => {
        this.fecharModalCalendario();
        this.getAgendaHora();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  voltarModalAgendamento() {
    this.cidadado_selecionado = null;
  }

  removerAgenda() {
    Swal.fire({
      title: 'Atenção',
      text: `Deseja realmente cancelar esse agendamento?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.deletarAgenda();
        }
      }
    );
  }


  deletarAgenda = () => {
    // agenda_hora_id
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Delete(`AgendaHora/${this.cidadado_selecionado.id}`).then(
      result => {
        this.fecharModalDetalhesCalendario();
        this.getAgendaHora();
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }


  obterDetalhes = () => {
    // agenda_hora_id
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`AgendaHora/${this.horario_selecionado.agenda_hora_id}`).then(
      result => {
        this.cidadado_selecionado = result;
        Swal.close();
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }


  fecharModalDetalhesCalendario() {
    this.resultCidadao = [];
    this.profissional = null;
    this.ocupacao = null;
    this.cidadado_selecionado = null;
    this.horario_selecionado = null;
    $("#modalDetalhesAgendamento").toggleClass("show");
    $("#modalDetalhesAgendamento").css("display", "none");
  }

  confirmarPresenca() {
    var obj = { "cidadao_id": this.cidadado_selecionado.cidadao_id, "clinica_medica_id": this.presenca.clinica_medica_id, "profissional_id": this.presenca.profissional_id, "tipo_atendimento": "AGENDAMENTO", "agenda_hora_id": this.cidadado_selecionado.id }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`Atendimento`, obj).then(
      result => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Presença confirmada!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.getAgendaHora();
              this.fecharModalDetalhesCalendario();
            }
          }
        );

      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }


  iniciarTeleAtendimento() {
    Swal.fire('Iniciando Teleatendimento');
    Swal.showLoading();
    var obj = {
      "presenca_id": this.presenca.id,
      "cidadao_id": this.cidadado_selecionado.cidadao_id,
      "clinica_medica_id": this.presenca.clinica_medica_id,
      "profissional_id": this.presenca.profissional_id,
      "tipo_atendimento": "AGENDAMENTO",
      "agenda_hora_id": this.cidadado_selecionado.id,
      "iniciar_atendimento": true
    }
    this.service.Post(`Atendimento`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${res["id"]}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();

      if (res.error.messagem) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      }
    })
  }

  iniciarAtendimento() {
    Swal.fire('Iniciando atendimento');
    Swal.showLoading();
    var obj = {
      presenca_id: this.presenca.id
    }
    this.service.Post(`Atendimento/${this.cidadado_selecionado.atendimento_id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${this.cidadado_selecionado.atendimento_id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  continuarAtendimento() {
    Swal.fire('Iniciando Teleatendimento');
    Swal.showLoading();
    var obj = {
      atendimento_id: this.cidadado_selecionado.atendimento_id,
      presenca_id: this.presenca.id
    }
    this.service.Put(`Atendimento/${this.cidadado_selecionado.atendimento_id}/Prontuario`, obj).then(res => {
      Swal.close();
      this.router.navigate([`prontuario/${this.cidadado_selecionado.atendimento_id}`]);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  historicoAtendimento() {
    this.router.navigate([`prontuario/${this.cidadado_selecionado.atendimento_id}`]);
  }
}
