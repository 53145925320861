import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-exams-group',
  templateUrl: './add-exams-group.component.html',
  styleUrls: ['./add-exams-group.component.css']
})
export class AddExamsGroupComponent implements OnInit {

  grupoExame: any = [];
  exame: any = [];
  estabelecimento: any = '';
  clinica: any = [];
  listaExames: any = [];
  listaClinicas: any = [];
  clinicasSelecionadas: any = [];

  presencaUser: any = [];
  rotaClinicas: any = ''
  uid: any = ''

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.grupoExame.ativo = true;

    this.presencaUser = JSON.parse(localStorage.getItem('@DigtVentry:presencaUser'));
    if (this.presencaUser) {
      this.rotaClinicas = `Estabelecimento/${this.presencaUser.estabelecimento_id}/ClinicaMedica`
    }

    this.uid = this.route.snapshot.paramMap.get('uuid');
    if (this.uid != null) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterGrupoExame(this.uid).then(
        result => {
          this.grupoExame = result;
          this.obterExames(this.uid).then(
            result => {
              this.listaExames = result;
              this.obterClinicas(this.uid).then(
                result => {
                  this.clinicasSelecionadas = result;
                  Swal.close()
                },
                error => {
                  Swal.fire('Erro!', error.error.messagem, 'error')
                }
              )
            },
            error => {
              Swal.fire('Erro!', error.error.messagem, 'error')
            }
          )
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error')
        }
      )
    }
  }

  goBack() {
    this.location.back();
  }

  mudarStatus = () => {
    this.grupoExame.ativo = !this.grupoExame.ativo
  }

  obterGrupoExame = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`GrupoSigtap/${uid}`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }

  obterExames = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`GrupoSigtap/${uid}/Sigtap`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }

  obterClinicas = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`GrupoSigtap/${uid}/ClinicaMedica`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }

  selecionarSigtap = (item: any) => {
    var i
    for (i = 0; i < this.listaExames.length; i++) {
      if (this.listaExames[i].id == item.id) {
        Swal.fire('Atenção', 'O exame já foi selecionado', 'warning')
        return
      }
    }
    if (item.codigo) {
      this.listaExames.push(item)
    }
    else {
      return
    }
  }

  removerSigtap = (exame: any) => {
    var i
    for (i = 0; i < this.listaExames.length; i++) {
      if (this.listaExames[i].id === exame.id) {
        this.listaExames.splice(i, 1)
      }
    }
  }

  selecionarEstabelecimento = (item: any) => {
    if (item != undefined) {
      this.estabelecimento = item;
      this.listarClinicas()
    }
    else {
      this.estabelecimento = '';
    }
  }

  listarClinicas = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Get(`Estabelecimento/${this.estabelecimento.id}/ClinicaMedica`).then(
      result => {
        this.listaClinicas = result;
        Swal.close()
      }
    )
  }

  selecionarClinica = (item: any) => {
    item.Estabelecimento = this.estabelecimento;
    var i
    for (i = 0; i < this.clinicasSelecionadas.length; i++) {
      if (this.clinicasSelecionadas[i].id == item.id) {
        Swal.fire('Atenção', 'A clínica já foi selecionada', 'warning')
        return
      }
    }
    if (this.clinicasSelecionadas.length == 0) {
      this.clinicasSelecionadas.push(item)
    }
    else {
      this.clinicasSelecionadas.push(item)
    }
  }

  removerClinica = (clinica: any) => {
    var i
    for (i = 0; i < this.clinicasSelecionadas.length; i++) {
      if (this.clinicasSelecionadas[i].id === clinica.id) {
        this.clinicasSelecionadas.splice(i, 1)
      }
    }
  }

  salvarGrupoExames = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    if (!this.grupoExame.nome || this.grupoExame.nome == undefined) {
      Swal.fire('Erro!', 'É preciso informar um nome para este grupo de exames', 'error')
      return
    }

    if (this.listaExames.length == 0) {
      Swal.fire('Erro!', 'É preciso informar pelo menos um exame para este grupo de exames', 'error')
      return
    }

    if (this.listaClinicas.length == 0) {
      Swal.fire('Erro!', 'É preciso informar pelo menos uma clínica para este grupo de exames', 'error')
      return
    }

    var listaClinicas = [];
    this.clinicasSelecionadas.forEach(clinica => {
      listaClinicas.push({ clinica_medica_id: clinica.id })
    })
    var listaSigtap = [];
    this.listaExames.forEach(exame => {
      listaSigtap.push({ sigtap_id: exame.id })
    })

    var Obj = {
      nome: this.grupoExame.nome,
      ativo: this.grupoExame.ativo,
      clinica_medica: listaClinicas,
      sigtap: listaSigtap
    }

    this.service.Post(`GrupoSigtap`, Obj).then(
      result => {
        Swal.fire('Sucesso!', 'Grupo de exames criado com sucesso', 'success').then(
          result => {
            this.location.back()
          }
        )
      },
      error => {
        Swal.fire('Erro!', error.error.messagem, 'error');
      }
    )
  }

  atualizarGrupoExames = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    var listaClinicas = [];
    this.clinicasSelecionadas.forEach(clinica => {
      listaClinicas.push({ clinica_medica_id: clinica.id })
    })
    var listaSigtap = [];
    this.listaExames.forEach(exame => {
      listaSigtap.push({ sigtap_id: exame.id })
    })

    var Obj = {
      nome: this.grupoExame.nome,
      ativo: this.grupoExame.ativo,
      clinica_medica: listaClinicas,
      sigtap: listaSigtap
    }

    this.service.Put(`GrupoSigtap/${this.uid}`, Obj).then(
      result => {
        Swal.fire('Sucesso!', 'Atualização realizada com sucesso', 'success').then(
          result => {
            this.location.back()
          }
        )
      },
      error => {
        // if (error.status == 405) {
        //   Swal.fire('Erro!', 'HTTP 405', 'error');
        // }
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error');
      }
    )
  }

}
