import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-closing-reason',
  templateUrl: './add-closing-reason.component.html',
  styleUrls: ['./add-closing-reason.component.css']
})
export class AddClosingReasonComponent implements OnInit {
  motivo: any = {};
  presencaUser: any = [];
  rotaClinicas: any = ''
  uid: any = ''

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('uuid');
    if (this.uid != null) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterMotivo(this.uid).then(
        result => {
          this.motivo = result;
          Swal.close();
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error')
        }
      )
    }
  }

  goBack() {
    this.location.back();
  }

  obterMotivo = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`MotivoEncerramento/${uid}`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }


  salvarMotivo = () => {
    if (this.uid != null) {
      this.atualizarMotivos();
    } else {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      var Obj = {
        nome: this.motivo.nome,
        ativo: this.motivo.ativo,
        cid_obrigatorio: this.motivo.cid_obrigatorio
      }

      this.service.Post(`MotivoEncerramento`, Obj).then(
        result => {
          Swal.fire('Sucesso!', 'Motivo de encerramento criado com sucesso', 'success').then(
            result => {
              this.location.back()
            }
          )
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error');
        }
      )
    }
  }

  atualizarMotivos = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    var Obj = {
      nome: this.motivo.nome,
      ativo: this.motivo.ativo,
      cid_obrigatorio: this.motivo.cid_obrigatorio
    }

    this.service.Put(`MotivoEncerramento/${this.uid}`, Obj).then(
      result => {
        Swal.fire('Sucesso!', 'Atualização realizada com sucesso', 'success').then(
          result => {
            this.location.back()
          }
        )
      },
      error => {
        Swal.fire('Erro!', error.error.messagem, 'error');
      }
    )
  }

}
