import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import { Global } from '../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { AuthService } from '../../service/auth/auth.service'
@Component({
  selector: 'app-manual-regulation',
  templateUrl: './manual-regulation.component.html',
  styleUrls: ['./manual-regulation.component.css']
})
// export class ManualRegulationComponent implements OnInit {
export class ManualRegulationComponent implements OnChanges {

  usuario: any;
  cidadao: any = [];

  cadCidadao: boolean = true;
  cadRegulacao: any = {};

  listaEstabelecimentos: any = [];
  listaProfissionais: any = [];
  listaCriticidade: any = [];
  listaSigtap: any = [];
  listaProcedimentos: any = [];
  listaEspecialidades: any = [];

  listaItensRegulacao: any = [];
  listaCid: any = [];
  listaCidSub: any = [];

  busca: any = 'busca';

  @Input() regulaCidadao: any;
  @Input() tipoRegulacao: any;
  regulacao: any;

  @Input() salvaRegulacao: boolean;
  solicitacao: boolean;

  @Output() cancelaRegulacao: EventEmitter<any> = new EventEmitter();
  @Output() fecharModalPai: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.cadRegulacao.data_hora_solicitacao_mask = this.defineHoje()
    this.listaItensRegulacao = []
    this.cadRegulacao.cidsub = 0;
    var currentUser = localStorage.getItem('@DigtVentry:currentUser');
    this.usuario = JSON.parse(currentUser);
  }

  ngOnChanges() {
    this.listaItensRegulacao = []
    this.cidadao = this.regulaCidadao;
    this.regulacao = this.tipoRegulacao;
    if (this.cidadao.id) {
      Swal.close()
      this.init()
    }
  }

  defineHoje = () => {
    var today = new Date();
    var hoje = ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear();
    return hoje
  }
  defineHora = () => {
    return this.global.formateTime(new Date());
  }

  init = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.getEstabelecimentos().then(
      result => {
        this.listaEstabelecimentos = result;
        this.listaEstabelecimentos.unshift({ id: 0, nome: 'Selecione..' });
        this.cadRegulacao.estabelecimento_solicitante_id = this.listaEstabelecimentos[0].id
        this.getProfissionais().then(
          result => {
            this.listaProfissionais = result;
            this.listaProfissionais.unshift({ id: 0, nome: 'Selecione..' })
            this.cadRegulacao.profissional_solicitante_id = this.listaProfissionais[0].id
            this.getCriticidade().then(
              result => {
                this.listaCriticidade = result;
                this.listaCriticidade.unshift({ id: 5, nome: 'Selecione..' });
                this.cadRegulacao.criticidade = this.listaCriticidade[0].id

                if (this.regulacao === 1) {
                  this.getEspecialidades().then(
                    result => {
                      this.listaEspecialidades = result;
                      this.listaEspecialidades.unshift({ id: 0, nome: 'Selecione..' });
                      this.cadRegulacao.especialidade = this.listaEspecialidades[0].id
                      Swal.close()
                    },
                    error => {
                      this.emitErro(error)
                    }
                  )
                }
                if (this.regulacao === 2) {
                  //   this.getSigtap().then(
                  //     result => {
                  //       this.listaSigtap = result;
                  //       this.listaSigtap.unshift({ id: 0, nome: 'Selecione..' });
                  //       this.cadRegulacao.sigtap_id = this.listaSigtap[0].id
                  //     },
                  //     error => {
                  //       this.emitErro(error)
                  Swal.close()
                }
              },
              error => {
                this.emitErro(error)
              }
            )
          },
          error => {
            this.emitErro(error)
          }
        )
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  // obter dados para cadastro regulação

  getEstabelecimentos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getProfissionais = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Profissional`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getCriticidade = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`RegulacaoExame/Criticidade`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getProcedimentos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Profissional`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getEspecialidades = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Ocupacao`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  addProcedimento = () => {
    for (let item of this.listaSigtap) {
      if (parseInt(this.cadRegulacao.sigtap_id) === item.id) {
        this.cadRegulacao.procedimento_solicitado = item.sub_grupo;
      }
    }
  }

  // add itens solicitação

  addItemSolicitacao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.validaCadRegulacao(this.cadRegulacao).then(
      result => {
        var regulacao = result;
        this.salvarRegulacao(regulacao).then(
          result => {
            this.cadRegulacao = {}
            this.cadRegulacao.data_hora_solicitacao_mask = this.defineHoje();
            this.cadRegulacao.estabelecimento_solicitante_id = 0
            this.cadRegulacao.profissional_solicitante_id = 0
            this.cadRegulacao.criticidade = 0
            this.cadRegulacao.cid = 0
            this.cadRegulacao.cidsub = 0
            this.cadRegulacao.sigtap_id = 0
            this.cadRegulacao.procedimento_solicitado = ''
            this.cadRegulacao.especialidade = 0
            this.cadRegulacao.justificativa = ''
            this.cadRegulacao.observacao = ''
            Swal.close()
            this.retornoVisual()
          },
          error => {
            this.emitErro(error)
          }
        )

      },
      error => {
        this.emitErro(error)
      }
    )
  }

  validaCadRegulacao = (regulacao) => {
    return new Promise((resolve, reject) => {
      if (!regulacao.estabelecimento_solicitante_id) {
        reject('É preciso informar o estabelecimento solicitante')
      }
      if (!regulacao.data_hora_solicitacao_mask) {
        reject('É preciso informar a data de solicitação')
      }

      if (regulacao.data_hora_solicitacao_mask) {
        var date = regulacao.data_hora_solicitacao_mask.split("/")[2] + "-" + regulacao.data_hora_solicitacao_mask.split("/")[1] + "-" + regulacao.data_hora_solicitacao_mask.split("/")[0];
        var validDate = new Date(date).toString();
        if (validDate === 'Invalid Date') {
          reject('É preciso informar uma data válida')
        }
        var dateFuture = new Date(date);
        if (dateFuture > new Date()) {
          reject('A data de solicitação não pode ser uma data futura')
        }
        regulacao.data_hora_solicitacao = date;
      }
      if (!regulacao.profissional_solicitante_id) {
        reject('É preciso informar o profissional solicitante')
        return
      }
      if (regulacao.profissional_solicitante_id) {
        for (let item of this.listaProfissionais) {
          if (item.id == regulacao.profissional_solicitante_id) {
            regulacao.profissional_nome = item.nome
          }
        }
      }
      if (!regulacao.criticidade || parseInt(regulacao.criticidade) == 5) {
        reject('É preciso informar a criticidade')
        return
      }
      // if (!regulacao.cidsub) {
      //   reject('É preciso informar um CID')
      //   return
      // }
      if (!regulacao.justificativa && !regulacao.motivo) {
        reject('É preciso informar uma justificativa para a solicitação')
        return
      }
      if (!regulacao.observacao) {
        reject('É preciso adicionar uma observação para a solicitação')
        return
      }
      if (regulacao.criticidade) {
        for (let item of this.listaCriticidade) {
          if (item.id == regulacao.criticidade) {
            regulacao.criticidade_nome = item.nome
          }
        }
      }
      if (this.regulacao === 1) {
        if (!regulacao.especialidade) {
          reject('É preciso informar a especialidade')
          return
        }
        if (regulacao.especialidade) {
          for (let item of this.listaEspecialidades) {
            if (item.id == regulacao.especialidade) {
              regulacao.especialidade_nome = item.nome
            }
          }
        }
      }
      if (this.regulacao === 2) {
        if (!regulacao.sigtap) {
          reject('É preciso informar o SIGTAP')
          return
        }
        // if (regulacao.sigtap_id) {
        //   for (let item of this.listaSigtap) {
        //     if (item.id == regulacao.sigtap_id) {
        //       regulacao.sigtap_id_nome = item.nome
        //     }
        //   }
        // }
        // if (!regulacao.procedimento_solicitado) {
        //   reject('É preciso informar o procedimento')
        //   return
        // }
      }
      regulacao.status = true;
      resolve(regulacao)
    })
  }

  salvarRegulacao = (regulacao) => {
    return new Promise((resolve, reject) => {
      if (this.regulacao == 1) {
        var obj = {
          cidadao_id: parseInt(this.cidadao.id),
          status: 'PENDENTE',
          estabelecimento_id_solicitante: parseInt(regulacao.estabelecimento_solicitante_id),
          equipe_ine: regulacao.equipe_ine,
          equipe_nome: regulacao.equipe_nome,
          acs_nome: regulacao.acs_nome,
          solicitado_em: regulacao.data_hora_solicitacao,
          profissional_id: parseInt(regulacao.profissional_solicitante_id),
          criticidade: parseInt(regulacao.criticidade),
          ocupacao_id: parseInt(regulacao.especialidade),
          motivo: regulacao.motivo,
          observacao: regulacao.observacao,
          usuario_id: parseInt(this.usuario.usuario_id)
        }
        if (regulacao.cidsub) {
          obj['cid_subcategoria_id'] = parseInt(regulacao.cidsub)
        }
        else {
          obj['cid_subcategoria_id'] = null
        }
        this.listaItensRegulacao.push(obj)
        this.postRegulacaoConsulta(obj).then(
          result => {
            resolve(result);
          },
          error => {
            reject(error)
          }
        );
      }
      if (this.regulacao == 2) {
        var objeto = {
          observacao: regulacao.observacao,
          status: "PENDENTE",
          criticidade: parseInt(regulacao.criticidade),
          profissional_solicitante_id: parseInt(regulacao.profissional_solicitante_id),
          cidadao_id: parseInt(this.cidadao.id),
          estabelecimento_solicitante_id: parseInt(regulacao.estabelecimento_solicitante_id),
          estabelecimento_executor_id: parseInt(regulacao.estabelecimento_solicitante_id),
          autorizado: false,
          usuario_id: parseInt(this.usuario.usuario_id),
          periodo_validade_apac: "",
          justificativa: regulacao.justificativa,
          agenda_hora_id: 0,
          sigtap_id: regulacao.sigtap,
          laudo_exame: "",
          data_hora_solicitacao: regulacao.data_hora_solicitacao + 'T' + this.defineHora(),
          ine_equipe: regulacao.ine_equipe,
          nome_equipe: regulacao.nome_equipe,
          nome_acs: regulacao.nome_acs,
        }
        if (regulacao.cidsub) {
          objeto['cid_sub_categoria_id'] = parseInt(regulacao.cidsub)
        }
        else {
          objeto['cid_sub_categoria_id'] = null
        }

        this.postRegulacaoExame(objeto).then(
          result => {
            resolve(result)
          },
          error => {
            reject(error)
          }
        )
      }
    })
  }

  postRegulacaoConsulta = (regulacao) => {
    return new Promise((resolve, reject) => {
      this.service.Post(`RegulacaoConsulta`, regulacao).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  postRegulacaoExame = (regulacao) => {
    return new Promise((resolve, reject) => {
      this.service.Post(`RegulacaoExame`, regulacao).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  removeSolicitacao = (i) => {
    this.listaItensRegulacao.splice(i, 1)
  }

  retornoVisual = () => {
    Swal.fire('Sucesso', 'A regulação foi cadastrada com sucesso', 'success').then(
      result => {
        if (result.isConfirmed) {
          if (this.regulacao == 1) {
            this.cancelarRegulacao();
            this.fecharModalPai.emit();
            return
          }
          if (this.regulacao == 2) {
            this.getListaSolicitacoes(this.cidadao.cpf);
            this.fecharModalPai.emit();
            return
          }
        }
      }
    )
  }

  cancelarRegulacao = () => {
    this.listaItensRegulacao = []
    this.cancelaRegulacao.emit()
  }

  getListaSolicitacoes = (cpf_cidadao) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    if (this.regulacao == 1) {
      Swal.close()
    }
    if (this.regulacao == 2) {
      this.service.Get(`RegulacaoExame?Skip=1&Take=100&busca_paciente=${cpf_cidadao}`).then(
        result => {
          this.listaItensRegulacao = result;
          Swal.close();
        },
        error => {
          this.emitErro(error)
        }
      )
    }
  }

  showProfissionalNome = (id) => {
    for (let item of this.listaProfissionais) {
      if (item.id === id) {
        return item.nome
      }
    }
  }

  showEspecialidadeNome = (id) => {
    for (let item of this.listaEspecialidades) {
      if (item.id === id) {
        return item.nome
      }
    }
  }

  selecionarCid(item) {
    if (item.id != null)
      this.cadRegulacao.cidsub = item.id;
    else
      this.cadRegulacao.cidsub = null;
  }

  selecionarSigtap(item) {
    this.cadRegulacao.sigtap = ''
    if (item.id) {
      this.cadRegulacao.sigtap = item.id
    }
    this.cadRegulacao.procedimento = item.nome
    this.cadRegulacao.grupo_sigtap = item.sub_grupo
  }

  selecionarGrupoSigtap(item) {
    if (typeof item == 'string') {
      this.cadRegulacao.grupo_sigtap = item
      this.busca = `ListarSubGrupo?nome_subGrupo=${this.cadRegulacao.grupo_sigtap}&busca`
    }
    else {
      this.busca = `busca`
      this.cadRegulacao.sigtap_id = '';
    }
  }

}
