import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
declare var Simditor: any;
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
@Component({
  selector: 'app-medical-admission-registration',
  templateUrl: './medical-admission-registration.component.html',
  styleUrls: ['./medical-admission-registration.component.css']
})
export class MedicalAdmissionRegistrationComponent implements OnInit {
  admissao: any = {};
  abaSelected: any = 1;
  listaCid: any = [];
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];
  @Input() prontuario: any = {};
  @Input() abaAdmissaoSelecionada: boolean = false;
  @Output() resultEmiter: EventEmitter<any> = new EventEmitter();

  @Input() presenca: any = {};
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();

  listaProcedimentosCid: any = [];

  constructor(private authService: AuthService,
    public service: ApiService,
    private route: ActivatedRoute) {
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    this.admissao.sedacao_aplica = true;
    this.admissao.impressao_geral = "";
    this.admissao.exame_neurologico = "";
    this.admissao.exame_cardiologico = "";
    this.admissao.exame_respiratorio = "";
    this.admissao.exame_abdominal = "";
    this.admissao.exame_extremidades = "";

    var that = this;
    setTimeout(() => {
      var hd = new Simditor({
        textarea: $('#hda'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
      var antecedentes_familiares = new Simditor({
        textarea: $('#antecedentes_familiares'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
      var antecedentes_pessoais = new Simditor({
        textarea: $('#antecedentes_pessoais'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
      var informacoes_adicionais = new Simditor({
        textarea: $('#informacoes_adicionais'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
      var exame_complementar = new Simditor({
        textarea: $('#exame_complementar'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
      var impressao_geral = new Simditor({
        textarea: $('#impressao_geral'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
      var exame_neurologico = new Simditor({
        textarea: $('#exame_neurologico'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
    }, 1000);
  }

  goBack() {
    this.resultEmiter.emit('voltar');
  }

  armazenaStringAbas() {
    var ig = $("#impressao_geral").val();
    var en = $("#exame_neurologico").val();
    var ec = $("#exame_cardiologico").val();
    var er = $("#exame_respiratorio").val();
    var ea = $("#exame_abdominal").val();
    var ee = $("#exame_extremidades").val();

    if (ig != null)
      this.admissao.impressao_geral = ig;

    if (en != null)
      this.admissao.exame_neurologico = en;

    if (ec != null)
      this.admissao.exame_cardiologico = ec;

    if (er != null)
      this.admissao.exame_respiratorio = er;

    if (ea != null)
      this.admissao.exame_abdominal = ea;

    if (ee != null)
      this.admissao.exame_extremidades = ee;
  }

  setActvieAba(aba: any) {
    this.abaSelected = aba;
    var that = this;
    this.armazenaStringAbas();
    switch (aba) {
      case 1:
        setTimeout(() => {
          var impressao_geral = new Simditor({
            textarea: $('#impressao_geral'),
            upload: false,
            tabIndent: true,
            toolbar: that.toolbar,
            toolbarFloat: true,
            //optional options
          });
          impressao_geral.setValue(this.admissao.impressao_geral);
          var exame_neurologico = new Simditor({
            textarea: $('#exame_neurologico'),
            upload: false,
            tabIndent: true,
            toolbar: that.toolbar,
            toolbarFloat: true,
            //optional options
          });
          exame_neurologico.setValue(this.admissao.exame_neurologico);
        }, 1000);
        break;
      case 2:
        setTimeout(() => {
          var exame_cardiologico = new Simditor({
            textarea: $('#exame_cardiologico'),
            upload: false,
            tabIndent: true,
            toolbar: that.toolbar,
            toolbarFloat: true,
            //optional options
          });
          exame_cardiologico.setValue(this.admissao.exame_cardiologico);
          var exame_respiratorio = new Simditor({
            textarea: $('#exame_respiratorio'),
            upload: false,
            tabIndent: true,
            toolbar: that.toolbar,
            toolbarFloat: true,
            //optional options
          });
          exame_respiratorio.setValue(this.admissao.exame_respiratorio);
        }, 1000);
        break;
      case 3:
        setTimeout(() => {
          var exame_abdominal = new Simditor({
            textarea: $('#exame_abdominal'),
            upload: false,
            tabIndent: true,
            toolbar: that.toolbar,
            toolbarFloat: true,
            //optional options
          });
          exame_abdominal.setValue(this.admissao.exame_abdominal);
        }, 1000);
        break;
      case 4:
        setTimeout(() => {
          var exame_extremidades = new Simditor({
            textarea: $('#exame_extremidades'),
            upload: false,
            tabIndent: true,
            toolbar: that.toolbar,
            toolbarFloat: true,
            //optional options
          });
          exame_extremidades.setValue(this.admissao.exame_extremidades);
        }, 1000);
        break;

      default:
        break;
    }

  }

  openModalGlasgow = () => {
    $("#modalEscalaGlasgow").toggleClass("show");
    $("#modalEscalaGlasgow").css("display", "block");
  }

  openEscalaRamsey = () => {
    $("#modalEscalaRamsey").toggleClass("show");
    $("#modalEscalaRamsey").css("display", "block");
  }

  sendGlasglow = (glasglow: any) => {
    this.admissao.escala_glasgow = glasglow;
  }

  sendRamsey = (ramsey: any) => {
    this.admissao.escala_sedacao = ramsey;
  }

  adicionarCid(item) {
    if (!item) return;
    if (this.listaCid.length == 0) {
      item.cid_subcategoria_id = item.id;
      item.cid_subcategoria_codigo = item.codigo;
      item.cid_subcategoria_nome = item.nome;
      item.principal = false;
      item.diagnostico_confirmado = false;
      item.doenca_cronica = false;
      item.novo = true;
      this.listaCid.push(item);
    }
    else {
      var repetido = false;
      this.listaCid.forEach((s) => {
        if (s.id == item.id)
          repetido = true;
      });
      if (!repetido) {
        item.cid_subcategoria_id = item.id;
        item.cid_subcategoria_codigo = item.codigo;
        item.cid_subcategoria_nome = item.nome;
        item.principal = false;
        item.diagnostico_confirmado = false;
        item.doenca_cronica = false;
        item.novo = true;
        this.listaCid.push(item);
      }
    }
  }

  selecionaCidPrincipal(index, selecionado) {
    if (!selecionado)
      return
    this.listaCid.forEach((item) => {
      item.principal = false;
    });
    this.listaCid[index].principal = true;
  }

  removerCid(i) {
    this.listaCid.splice(i, 1);
  }

  selecionarSigtap(item) {    
    this.admissao.sigtap_id = '';
    if (item != null && item.id)
      this.admissao.sigtap_id = item.id;
  }

  sendMedicalAdmission = () => {
    this.armazenaStringAbas();
    var obj = Object.assign({}, this.admissao);
    obj.historico_doenca_atual = $("#hda").val();
    obj.antecedentes_familiares = $("#antecedentes_familiares").val();
    obj.antecedentes_pessoais = $("#antecedentes_pessoais").val();
    obj.exame_complementar = $("#exame_complementar").val();
    obj.informacoes_adicionais = $("#informacoes_adicionais").val();
    obj.presenca_id = this.presenca.id;
    obj.prontuario_id = this.prontuario.id;
    obj.cids = [];
    this.listaCid.forEach((item) => {
      obj.cids.push({
        "cid_subcategoria_id": item.cid_subcategoria_id,
        "principal": (item.principal != true ? false : item.principal),
        "doenca_cronica": (item.doenca_cronica != true ? false : item.doenca_cronica),
        "diagnostico_confirmado": (item.diagnostico_confirmado != true ? false : item.diagnostico_confirmado)
      });
    });

    this.postAdmission(obj);
  }

  postAdmission = (obj) => {
    if (!this.validaPostAdmission())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`Prontuario/${this.prontuario.id}/AdmissaoMedica`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Admissão salva com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
              this.retornoPost.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostAdmission = () => {
    if (this.admissao.sigtap_id == null || this.admissao.sigtap_id == '') {
      Swal.fire('Erro', "Favor informar um procedimento", 'error');
      return false;
    }
    if (!this.admissao.sedacao_aplica)
      delete this.admissao.escala_sedacao;
    return true;
  }

  visualizarProcedimentosCid = (cid) => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Sigtap?cid=${cid.id}`).then(res => {
      this.listaProcedimentosCid = res;
      if (this.listaProcedimentosCid.length > 0) {
        $("#modalProcedimentosCid").toggleClass("show");
        $("#modalProcedimentosCid").css("display", "block");
      } else {
        Swal.fire('Atenção', `Não há procedimento para o cid selecionado.`, 'warning');
        return;
      }
      Swal.close();
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    })
  }

  fecharModal = () => {
    $("#modalProcedimentosCid").toggleClass("show");
    $("#modalProcedimentosCid").css("display", "none");
  }

  selecionarProcedimento = (procedimento) => {    
    this.selecionarSigtap(procedimento);
    $("#campoAutocomplete1").val(procedimento.nome);
    $("#modalProcedimentosCid").toggleClass("show");
    $("#modalProcedimentosCid").css("display", "none");
  }

  prepararParametrosSigtap() {
    if (this.listaCid.length > 0) {
      return this.listaCid.map((item) => {
        return `cid=${item.id}`
      }).join("&") + "&busca"
    } else {
      return 'busca'
    }
  }
}
