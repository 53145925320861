import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
import Swal from 'sweetalert2';
declare var jquery: any;
declare var $: any;
declare var document: any;
declare var simpleDatatables: any;
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-list-professional',
  templateUrl: './list-professional.component.html',
  styleUrls: ['./list-professional.component.css']
})

export class ListProfessionalComponent implements OnInit {

  listProfessional: any = [];
  filtro: string = "";

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getListProfessional();
  }

  getListProfessional() {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Profissional`).then(res => {
      this.listProfessional = res;
      for (let item of this.listProfessional) {
        this.global.applyMask(item)
      }
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401){
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse profissional?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Profissional/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.getListProfessional();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401){
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.listProfessional.length === 0) {
      return this.listProfessional;
    }
    return this.listProfessional.filter(
      p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()) ||
        (p.cpf != null ? p.cpf.replace(".", "").replace(".", "").replace("-", "").includes(this.filtro.replace(".", "").replace(".", "").replace("-", "")) : "") ||
        (p.cns != null ? p.cns.replace(" ", "").replace(" ", "").replace(" ", "").includes(this.filtro.replace(" ", "").replace(" ", "").replace(" ", "")) : "")
    );
  }

};
