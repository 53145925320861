import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { config } from 'process';

@Component({
  selector: 'app-general-configuration',
  templateUrl: './general-configuration.component.html',
  styleUrls: ['./general-configuration.component.css']
})
export class GeneralConfigurationComponent implements OnInit {

  configuracao: any = {};
  abaselecionada: any = 1;

  constructor(public service: ApiService,
    private location: Location,
    private alerts: NgxAlertsService,) { }

  ngOnInit() {
    this.configuracao.relatorio_caminho_logo = "";
    this.configuracao.relatorio_estado = "";
    this.configuracao.relatorio_municipio = "";
    this.obterConfiguracao();
  }

  goBack() {
    this.location.back();
  }

  salvarConfig = () => {
    if (!this.validaPostConfig())
      return;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    if (this.configuracao.id == null)
      this.service.Post(`Configuracao`, this.configuracao).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'configuração salva com sucesso!'
          }).then(
            result => {
              if (result.isConfirmed) {
                //this.goBack();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    else
      this.service.Put(`Configuracao/${this.configuracao.id}`, this.configuracao).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Configuração atualizada com sucesso!'
          }).then(
            result => {
              if (result.isConfirmed) {
                //this.goBack();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      })
  }

  validaPostConfig = () => {
    
    if ((this.configuracao.url_mensagem_whatsapp == null || this.configuracao.url_mensagem_whatsapp == "") && this.abaselecionada == 1) {
      this.alerts.notifyError({
        body: "Favor preencher o link do whatsApp",
        title: 'Erro'
      });
      return false;
    }
    if (!(this.configuracao.relatorio_caminho_logo == "" && this.configuracao.relatorio_estado == "" && this.configuracao.relatorio_municipio == "")
      && !(this.configuracao.relatorio_caminho_logo != "" && this.configuracao.relatorio_estado != "" && this.configuracao.relatorio_municipio != "")) {
      this.alerts.notifyError({
        body: "Favor preencher todos os campos da configuração do relatório ",
        title: 'Erro'
      });
      return false;
    }
    return true;
  }

  obterConfiguracao = () => {
    this.service.Get(`Configuracao`).then(res => {
      if (res != null)
        this.configuracao = res;
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  abaAtual = (aba) => {
    this.abaselecionada = aba;
  }

  validaCampoRelatorio = () => {
    let result = !(!(this.configuracao.relatorio_caminho_logo == "" && this.configuracao.relatorio_estado == "" && this.configuracao.relatorio_municipio == "")
      && !(this.configuracao.relatorio_caminho_logo != "" && this.configuracao.relatorio_estado != "" && this.configuracao.relatorio_municipio != ""));
    return result;
  }

}
