import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
declare var Simditor: any;
import * as $ from "jquery";

@Component({
  selector: 'app-procedure-registration',
  templateUrl: './procedure-registration.component.html',
  styleUrls: ['./procedure-registration.component.css']
})
export class ProcedureRegistrationComponent implements OnInit {
  atendimento_id: any = 0;
  presenca: any = {};
  procedimento: any = {};
  sigtapSelecionado: any = {};
  @Output() goBack = new EventEmitter();
  listaCriticidade: any = [{ nome: 'Selecione..', id: 0 }, { nome: 'Eletivo', id: 1 }, { nome: 'Prioridade', id: 2 }, { nome: 'Urgência', id: 3 }, { nome: 'Emergência', id: 4 }];
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();



  /// editor de texto
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  constructor(private authService: AuthService,
    public service: ApiService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.procedimento.criticidade_id = 0;
    Simditor.locale = 'en-US';
    this.init();
  }
  init = () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    if (uid != null)
      this.atendimento_id = uid;

    setTimeout(() => {
      var that = this;
      var descricao = new Simditor({
        textarea: $('#texto_procedimento'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options

      });
      Swal.close()
    }, 500);
  }

  selecionarSigtap(item) {
    this.procedimento.sigtap_id = '';
    if (item != null && item.id)
      this.procedimento.sigtap_id = item.id;
  }

  postProcedimento = () => {
    if (!this.validaPostProcedimento())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.procedimento.tipo_procedimento = "PROCEDIMENTO";
    this.procedimento.presenca_id = this.presenca.id;


    var obj =
    {
      "tipo_solicitacao": "PROCEDIMENTO",
      "presenca_id": this.presenca.id,
      "prescricao": this.presenca.prescricao,
      "complemento": $('#texto_procedimento').val(),
      "criticidade": this.procedimento.criticidade_id,
      "procedimentos": [
        {
          "sigtap_id": this.procedimento.sigtap_id,
          "complemento": $('#texto_procedimento').val(),
          "criticidade": this.procedimento.criticidade_id
        }
      ]
    }

    this.service.Post(`Atendimento/${this.atendimento_id}/Solicitacao`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Procedimento salvo com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.voltar();
              this.retornoPost.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostProcedimento = () => {
    if (this.procedimento.sigtap_id == null || this.procedimento.sigtap_id == '') {
      Swal.fire('Erro', "Favor informar um procedimento", 'error');
      return false;
    }

    if (this.procedimento.criticidade_id == 0) {
      Swal.fire('Erro', "Favor informar a criticidade", 'error');
      return false;
    }

    return true;
  }

  voltar = () => {
    this.procedimento = {};
    this.procedimento.criticidade_id = 0;
    this.goBack.emit();
  }

}
