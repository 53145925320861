import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from '../../../global';
import { AuthService } from "../../../service/auth/auth.service";

@Component({
  selector: 'app-forwarding',
  templateUrl: './forwarding.component.html',
  styleUrls: ['./forwarding.component.css']
})
export class ForwardingComponent implements OnInit {
  cadCidadao: any = {};
  consulta: any = {};
  cidadao: any = {};
  filtroAgendamento: any = {};
  filtro: any = {};
  consultas: any = [];
  estabelecimentos: any = [];
  profissionais: any = [];
  usuarios: any = [];
  criticidade: any = [{ 'id': 1, 'nome': 'ELETIVO' }, { 'id': 2, 'nome': 'PRIORIODADE' }, { 'id': 3, 'nome': 'URGENCIA' }, { 'id': 4, 'nome': 'EMERGENCIA' }];
  status: any = [{ 'id': 1, 'nome': 'PENDENTE' }, { 'id': 2, 'nome': 'AUTORIZADO' }, { 'id': 3, 'nome': 'AGENDADO' }, { 'id': 4, 'nome': 'REALIZADO' }, { 'id': 5, 'nome': 'CANCELADO' }];
  ocupacoes: any = [];
  ordenacao: any = [{ 'id': 1, 'nome': 'CRITICIDADE' }];
  pagina: any = 1;
  totalPorPagina: any = 100;
  totalPagina: any = 1;

  enviaTipoRegulacao: any;
  listaStatusVisualizacao: any = [];
  listaStatusSolicitacao: any = [];

  obterHistoricoRegulacaoId: any = '';
  tipoRegulacaoHistorico: any = '';

  visualizarAgendamento: any = '';
  path: any = 'RegulacaoConsulta/GerarCanhotoAgendamentoConsulta'

  global_: any;

  // alterar status
  alterarStatus: boolean = false;
  regulacoesAlterarStatus: any = [];
  pathStatus: any = 'RegulacaoConsulta/';

  constructor(
    public service: ApiService,
    private alerts: NgxAlertsService,
    private global: Global,
    private auth: AuthService
  ) {
    this.global_ = global;
  }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.obterListaStatusSolicitacao().then(
      result => {
        this.listaStatusSolicitacao = result
        for (let item of this.listaStatusSolicitacao) {
          if (item.nome.toLowerCase() == 'realizado' || item.nome.toLowerCase() == 'cancelado') {
            item.checked = false;
          }
          else {
            item.checked = true;
          }
        }
        this.filtro.listaStatusSolicitacao = this.listaStatusSolicitacao
        this.obterListaEstabelecimentos();
      },
      error => {
        if (typeof error == 'string') {
          Swal.fire('Erro', `${error}`, 'error');
          return
        }
        if (error.status == 401) {
          Swal.close()
          this.auth.logoutUser()
          return
        }
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
    this.filtro.estabelecimento_solicitante_id = 0;
    this.filtro.estabelecimento_executante_id = 0;
    this.filtro.profissional_id = 0;
    this.filtro.criticidade_id = 0;
    this.filtro.ocupacao_id = 0;
    this.filtro.status_id = 0;
    this.filtro.ordenacao_id = 0;
    this.filtro.SolicitadoEmInicial = "";
    this.filtro.SolicitadoEmFinal = "";
    this.filtro.AgendadoEmInicial = "";
    this.filtro.AgendadoEmFinal = "";
    this.filtro.RealizadoEmInicial = "";
    this.filtro.RealizadoEmFinal = "";
    this.filtro.buscaPaciente = "";
    this.filtro.UsuarioId = 0;
  }

  obterListaRegulacao = () => {
    var filtro: any;
    if (this.filtro.buscaPaciente) {
      filtro = this.filtro.buscaPaciente.toLowerCase()
      // filtro = filtro.replace(/[\W_]+/g,'')
    }
    var txt = `Skip=${this.pagina}&Take=100&EstabelecimentoIdSolicitante=${this.filtro.estabelecimento_solicitante_id}&EstabelecimentoIdExecutor=${this.filtro.estabelecimento_executante_id}&ProfissionalId=${this.filtro.profissional_id}&Criticidade=${this.filtro.criticidade_id == 0 ? "" : this.filtro.criticidade_id}&OcupacaoId=${this.filtro.ocupacao_id}&Ordenacao=${this.filtro.ordenacao_id == 0 ? 1 : this.filtro.ordenacao_id}&SolicitadoEmInicial=${this.filtro.SolicitadoEmInicial}&SolicitadoEmFinal=${this.filtro.SolicitadoEmFinal}&AgendadoEmInicial=${this.filtro.AgendadoEmInicial}&AgendadoEmFinal=${this.filtro.AgendadoEmFinal}&RealizadoEmInicial=${this.filtro.RealizadoEmInicial}&RealizadoEmFinal=${this.filtro.RealizadoEmFinal}&UsuarioId=${this.filtro.UsuarioId}`

    if (filtro) {
      if (txt != '') {
        txt += '&'
      }
      txt += `Busca=${filtro}`
    }
    if (this.filtro.listaStatusSolicitacao) {
      for (let item of this.filtro.listaStatusSolicitacao) {
        if (item.checked) {
          if (txt != '') {
            txt += '&'
          }
          txt += 'Status=' + item.nome
        }
      }
    }
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`RegulacaoConsulta?${txt}`).then(
      res => {
        this.consultas = res['conteudo'];
        if (this.consultas.length > 0) {
          this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
        }
        Swal.close();
      }
    ).catch(
      res => {
        Swal.close()
        if (res.status == 401) {
          Swal.close()
          this.auth.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      }
    )
  }

  obterListaStatusSolicitacao = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`RegulacaoExame/StatusSolicitacao`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  obterListaEstabelecimentos = () => {
    this.service.Get(`Estabelecimento`).then(res => {
      this.estabelecimentos = res;
      this.obterListaUsuarios();
    }).catch(
      res => {
        if (res.status == 401) {
          Swal.close()
          this.auth.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      })
  }

  obterListaUsuarios = () => {
    this.service.Get(`Usuario`).then(res => {
      this.usuarios = res;
      this.obterListaProfissionais();
    }).catch(
      res => {
        if (res.status == 401) {
          Swal.close()
          this.auth.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      })
  }

  obterListaProfissionais = () => {
    this.service.Get(`Profissional`).then(res => {
      this.profissionais = res;
      this.obterListaOcupacao();
    }).catch(
      res => {
        if (res.status == 401) {
          Swal.close()
          this.auth.logoutUser()
        }
        Swal.fire('Erro', `${res.error.messagem}`, 'error');
      })
  }

  obterListaOcupacao = () => {
    this.service.Get(`Ocupacao`).then(res => {
      this.ocupacoes = res;
      this.obterListaRegulacao();
    }).catch(res => {
      if (res.status == 401) {
        Swal.close()
        this.auth.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  abrirModalAgendarEncaminhamento = (consulta) => {
    var data = new Date();
    var dataAtual: any = (data.getDate().toString().length == 1 ? '0' + data.getDate().toString() : data.getDate().toString()) + ((data.getMonth() + 1).toString().length == 1 ? '0' + (data.getMonth() + 1).toString() : (data.getMonth() + 1).toString()) + data.getFullYear();
    this.filtroAgendamento = {}
    this.filtroAgendamento.estabelecimento_id = 0;
    this.filtroAgendamento.ocupacao_id = 0;
    this.filtroAgendamento.profissional_id = 0;
    this.filtroAgendamento.data_agenda_inicio = dataAtual;
    this.filtroAgendamento.data_agenda_fim = dataAtual;
    this.filtroAgendamento.visualisandoHorarios = false;
    this.filtroAgendamento.exibirAgenda = false;
    this.filtroAgendamento.agendas = [];

    this.obterRegulacao(consulta.idRegulacao);
    $("#modalAgendarEncaminhamento").toggleClass("show");
    $("#modalAgendarEncaminhamento").css("display", "block");
  }

  abrirModalAutorizarEncaminhamento = (consulta) => {
    this.obterRegulacao(consulta.idRegulacao);
    $("#modalAutorizarEncaminhamento").toggleClass("show");
    $("#modalAutorizarEncaminhamento").css("display", "block");
  }

  abrirModalRealizacaoEncaminhamento = (consulta) => {
    this.obterRegulacao(consulta.idRegulacao);
    $("#modalRealizacaoEncaminhamento").toggleClass("show");
    $("#modalRealizacaoEncaminhamento").css("display", "block");
  }

  abrirModalCadCidadao = () => {
    $("#modalCadCidadao").toggleClass("show");
    $("#modalCadCidadao").css("display", "block");
  }

  fecharModal = () => {
    this.cadCidadao = {};
    this.cadCidadao.estado_id = 0;
    this.cadCidadao.municipio_id = 0;
    $("#modalCadCidadao").toggleClass("show");
    $("#modalCadCidadao").css("display", "none");
  }

  retornoPostCidadao = (cidadao) => {

  }

  abrirModalRegulacaoManual = (tipoRegulacao: any) => {
    this.enviaTipoRegulacao = tipoRegulacao;
    $("#modalRegulacaoManual").toggleClass("show");
    $("#modalRegulacaoManual").css("display", "block");
  }

  fecharModalRegulacaoManual = () => {
    $("#modalRegulacaoManual").toggle("show");
    $("#modalRegulacaoManual").css("display", "none");
    this.obterListaRegulacao()
  }

  setPeriodoSolicitacao = (periodo: any) => {
    if (periodo.length == 2) {
      this.filtro.SolicitadoEmInicial = periodo[0].split("/")[2] + "-" + periodo[0].split("/")[1] + "-" + periodo[0].split("/")[0];
      this.filtro.SolicitadoEmFinal = periodo[1].split("/")[2] + "-" + periodo[1].split("/")[1] + "-" + periodo[1].split("/")[0];
    } else {
      this.filtro.SolicitadoEmInicial = "";
      this.filtro.SolicitadoEmFinal = "";
    }
  }

  setPeriodoAgendamento = (periodo: any) => {
    if (periodo.length == 2) {
      this.filtro.AgendadoEmInicial = periodo[0].split("/")[2] + "-" + periodo[0].split("/")[1] + "-" + periodo[0].split("/")[0];
      this.filtro.AgendadoEmFinal = periodo[1].split("/")[2] + "-" + periodo[1].split("/")[1] + "-" + periodo[1].split("/")[0];
    } else {
      this.filtro.AgendadoEmInicial = "";
      this.filtro.AgendadoEmFinal = "";
    }
  }

  setPeriodoRealizacao = (periodo: any) => {
    if (periodo.length == 2) {
      this.filtro.RealizadoEmInicial = periodo[0].split("/")[2] + "-" + periodo[0].split("/")[1] + "-" + periodo[0].split("/")[0];
      this.filtro.RealizadoEmFinal = periodo[1].split("/")[2] + "-" + periodo[1].split("/")[1] + "-" + periodo[1].split("/")[0];
    } else {
      this.filtro.RealizadoEmInicial = "";
      this.filtro.RealizadoEmFinal = "";
    }
  }

  obterRegulacao = (id) => {
    this.service.Get(`RegulacaoConsulta/${id}`).then(res => {
      this.consulta = res;
      // if (this.consulta.cid_subcategoria_id == null)
      //   this.consulta.cid_subcategoria_id = 0;
      if (this.consulta.estabelecimento_id_executor == null)
        this.consulta.estabelecimento_id_executor = 0;
      this.cidadao = this.consulta.Cidadao;
    }).catch(res => {
      if (res.status == 401) {
        Swal.close()
        this.auth.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  Excluir = (regulacaoConsulta_id) => {
    this.alerts.alertQuestion({
      title: 'Deseja realmente excluir esse dado?',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      cancelButtonClass: "btn-color-danger",
    }, (e) => {
      if (e.value) {
        this.service.Delete(`RegulacaoConsulta/${regulacaoConsulta_id}`).then((res) => {
          this.obterListaRegulacao();
        }).catch((err) => {
          if (err.status == 401) {
            Swal.close()
            this.auth.logoutUser()
          }
          Swal.fire('Erro', `${err.error.messagem}`, 'error');
        })
      }
    }, (e) => {

    })
  }

  resultPostModalAuth = () => {
    this.obterListaRegulacao();
  }

  resultPostModalAgendamento = () => {
    this.obterListaRegulacao();
  }

  resultPostModalRealization = () => {
    this.obterListaRegulacao();
  }

  displayListStatus = (newList: any) => {
    this.filtro.listaStatusSolicitacao = newList;
  }

  listaStatusRegulacao = () => {
    return new Promise((resolve) => {
      var lista = [
        { id: 1, nome: 'PENDENTE' },
        { id: 2, nome: 'AUTORIZADO' },
        { id: 3, nome: 'AGENDADO' },
        { id: 4, nome: 'REALIZADO' },
        { id: 5, nome: 'CANCELADO' }
      ]
      resolve(lista);
    })
  }

  visualizarHistoricoRegulacao = (item: any, tipoRegulacao: any) => {
    this.obterHistoricoRegulacaoId = item.idRegulacao;
    this.tipoRegulacaoHistorico = tipoRegulacao;
    $("#modalHistoricoRegulacao").toggleClass("show");
    $("#modalHistoricoRegulacao").css("display", "block");
  }

  limparVisualizarHistorico = () => {
    this.obterHistoricoRegulacaoId = '';
    this.tipoRegulacaoHistorico = '';
  }

  obterComprovanteAgendamento = (regulacao) => {
    this.visualizarAgendamento = regulacao.idRegulacao
    setTimeout(() => {
      this.visualizarAgendamento = ''
    }, 250)
  }

  // alterar status
  selectMultiple = (data) => {
    if (data.checked == undefined || !data.checked) {
      data.checked = false;
    }
    data.checked = !data.checked
    this.permitirEmBloco()
    return data
  }

  permitirEmBloco = () => {
    this.alterarStatus = false;
    var emBloco = 0;
    for (let item of this.consultas) {
      if (item.checked) {
        emBloco += 1;
        if (emBloco > 0) {
          this.alterarStatus = true;
        }
      }
    }
  }

  voltarStatus = () => {
    this.regulacoesAlterarStatus = []
    for (let item of this.consultas) {
      if (item.checked == true) {
        this.regulacoesAlterarStatus.push(item)
      }
    }
    if (this.regulacoesAlterarStatus.every((val, i, arr) => val.status === arr[0].status)) {
      this.abrirModalAlterarStatus()
    }
    else {
      Swal.fire('Erro', 'Os procedimentos selecionados não possuem o mesmo status. Por favor, verifique a seleção.', 'error')
      return
    }
  }

  abrirModalAlterarStatus = () => {
    $("#modalAlterarStatus").toggleClass("show");
    $("#modalAlterarStatus").css("display", "block");
  }

  fecharModalAlterarStatus = () => {
    $("#modalAlterarStatus").toggleClass("show");
    $("#modalAlterarStatus").css("display", "none");
    // Swal.fire('Favor aguarde');
    // Swal.showLoading();
    this.alterarStatus = false;
    this.obterListaRegulacao()
  }

}
