import { Component, OnInit } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../service/apiServices';
import { Global } from '../../global';
import Swal from 'sweetalert2';
import { AuthService } from '../../service/auth/auth.service'
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-risk-rating',
  templateUrl: './risk-rating.component.html',
  styleUrls: ['./risk-rating.component.css']
})
export class RiskRatingComponent implements OnInit {

  Atendimentos: any = [];
  loading: boolean = false;
  filtro: string = "";
  pagina: any = 1;
  totalPorPagina: any = 100;
  totalPagina: any = 1;

  params: string = `Skip=${this.pagina}&Take=10&Busca`;

  listaClinicas: any = []
  usuario: any;
  presenca: any;

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private global: Global,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }


  ngOnInit() {
    this.init();
  }

  init = async () => {
    this.obterListaClinicas().then(
      result => {
        this.obterListaAtendimentos(this.presenca.clinica_medica_id);
      }
    )
  }

  obterListaClinicas = () => {
    return new Promise(resolve => {
      this.service.Get(`Bloco/${this.presenca.bloco_id}/ClinicaMedica?ativo=true`).then(
        result => {
          this.listaClinicas = result
          this.filtro = this.presenca.clinica_medica_id;
          resolve('ok')
        },
        error => {
          if (error.status == 401 || error.error.status == 401) {
            Swal.close()
            this.authService.logoutUser()
          }
          Swal.close();
          this.loading = false;
          Swal.fire('Erro', `${error.error.error.messagem}`, 'error').then(
            result => {
              resolve('ok')
            }
          )
        }
      )
    })
  }

  obterListaAtendimentos = (clinica: any) => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Atendimento?clinica_medica_id=${clinica}&classificacao_risco=true&ordenacao=false`).then(res => {

      this.Atendimentos = res;
      if (this.Atendimentos.length > 0) {
        this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
      }
      for (let cidadao of this.Atendimentos) {
        this.global.applyMask(cidadao)
      }

      this.Atendimentos.forEach(atendimento => {
        if (atendimento.criado_em){ 
          atendimento.chegada_data = atendimento.criado_em.split('T')[0]
          atendimento.chegada_hora = atendimento.criado_em.split('T')[1]
          atendimento.tempo_espera_hora = this.global.countWaitingTimeHoursDays(atendimento.criado_em)
          atendimento.tempo_espera_minuto = this.global.countWaitingTimeMinuteSeconds(atendimento.criado_em)
        }

      })

      Swal.close();
      this.loading = false;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      this.loading = false;
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse paciente?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Favor aguarde',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
          Swal.showLoading();
          this.service.AtivarDesativar(`Cidadao/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaAtendimentos(this.presenca.clinica_medica_id);
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.Atendimentos.length === 0) {
      return this.Atendimentos;
    }
    return this.Atendimentos.filter(
      c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()) ||
        (c.cpf != null ? c.cpf.replace(".", "").replace(".", "").replace("-", "").includes(this.filtro.replace(".", "").replace(".", "").replace("-", "")) :
          c.cns != null ? c.cns.replace(" ", "").replace(" ", "").replace(" ", "").includes(this.filtro.replace(" ", "").replace(" ", "").replace(" ", "")) : '')
    );
  }

  resultBuscaCidadao = (result) => {
    if (result != null) {
      this.Atendimentos = result['conteudo'];
      if (this.Atendimentos.length > 0) {
        this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
      }
    }
    else {
      this.pagina = 1;
      this.service.Get(`Cidadao?Skip=${this.pagina}&Take=${this.totalPorPagina}`).then(res => {
        this.Atendimentos = res;
        if (this.Atendimentos.length > 0) {
          this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
        }
        for (let cidadao of this.Atendimentos) {
          this.global.applyMask(cidadao)
        }
        Swal.close();
        this.loading = false;
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close();
        this.loading = false;
        Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
      });
    }

  }

  voltarPagina = () => {
    this.pagina = this.pagina - 1;
    this.obterListaAtendimentos(this.presenca.clinica_medica_id)
  }


  chamarPaciente(item: any) {    
    Swal.showLoading();
    var obj = {}
    this.service.Post(`Atendimento/${item.id}/ChamadaPainel`, this.presenca.id).then(res => {
      Swal.close();      
      Swal.fire({
        icon: 'success',
        title: 'Atendimento',
        html: `Paciente ${res['paciente_nome']} chamado com sucesso!`
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }
}
