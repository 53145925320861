import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser = new BehaviorSubject(undefined);
  authToken = new BehaviorSubject(undefined);
  clientes = new BehaviorSubject(undefined);
  currentClient = new BehaviorSubject(undefined);
  currentPresenca = new BehaviorSubject(undefined);
  client = new BehaviorSubject(undefined);
  dadosImpresso = new BehaviorSubject(undefined);
  tipoImpresso = new BehaviorSubject(undefined);
  teleAtendimento = new BehaviorSubject(undefined);
  menus = new BehaviorSubject(undefined);
  visualizarProntuario = new BehaviorSubject(undefined);


  constructor(private router: Router) { }

  async loginUser(user, checkout = "") {
    await localStorage.setItem('@DigtVentry:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token)
    await this.currentUser.next(user);
    if (checkout != null && checkout != "") {
      this.router.navigate([checkout]);
    } else {
      this.router.navigate(['home']);
    }
  }

  async setTokenUser(user) {
    await this.authToken.next(user.access_token)
  }

  async setPresenca(presenca) {
    await localStorage.setItem('@DigtVentry:presencaUser', JSON.stringify(presenca));
    await this.currentPresenca.next(presenca)
  }


  async saveUser(user) {
    await localStorage.setItem('@DigtVentry:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token)
    await this.currentUser.next(user);
  }


  async logoutUser() {
    await localStorage.clear();
    await this.authToken.next(null);
    await this.currentUser.next(null);
    await this.currentPresenca.next(null);
    this.router.navigate(['login']);
  }

  async logoutUserCheckout() {
    await this.currentUser.next(null);
    this.router.navigate(['login']);
  }

  async saveClientes(dados) {
    await this.clientes.next(dados);
  }

  async setDadosImpresso(dados) {
    await this.dadosImpresso.next(dados);
  }

  async setTipoImpresso(dados) {
    await this.tipoImpresso.next(dados);
  }

  async setTeleatendimento(dados) {
    await this.teleAtendimento.next(dados);
  }

  async saveMenus(dados) {
    await this.menus.next(dados);
  }

  async setVisualizarProntuario(dados) {
    await this.visualizarProntuario.next(dados);
  }
}
