import { Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-modal-escala-dor',
  templateUrl: './modal-escala-dor.component.html',
  styleUrls: ['./modal-escala-dor.component.css']
})
export class ModalEscalaDor implements OnChanges {

  escala: any = {};
  @Output() resultEmiter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnChanges() {
  }

  // close modal 
  fecharModal = () => {
    $("#modalEscalaDor").toggleClass("show");
    $("#modalEscalaDor").css("display", "none");
  }

  selectEscalador = () => {
    this.resultEmiter.emit(this.escala.escala_dor);
  }
}
