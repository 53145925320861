import { Component, OnInit, OnChanges, Inject, Input, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import { Global } from '../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-modal-procedimento',
  templateUrl: './modal-add-procedure.component.html',
  styleUrls: ['./modal-add-procedure.component.css']
})
export class ModalAddProcedureComponent implements OnInit {

  procedimento: any = '';
  listaProcedimentos: any = [];

  @Input() plano = {};
  @Output() atualizaListaProcedimentos = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }

  buscarProcedimento = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`SigTap?busca=${this.procedimento}`).then(
      result => {
        this.listaProcedimentos = result;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.messagem, 'error')
      }
    )
  }

  fecharModal = () => {
    this.listaProcedimentos = []
    $("#modalAddProcedimento").removeClass("show");
    $("#modalAddProcedimento").css("display", "none");
    this.atualizaEfecha()
  }

  salvarProcedimento = (item) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    item.plano_id = this.plano
    this.validaDados(item).then(
      result => {
        var obj = result
        obj['sigtap_id'] = result['id'];
        delete result['id']
        this.service.Post(`PlanoSigTap`, obj).then(
          result => {
            Swal.fire('Sucesso', 'Procedimento cadastrado com sucesso', 'success').then(
              result => {
                this.buscarProcedimento()
              }
            )
          },
          error => {
            Swal.fire('Erro', error.messagem, 'error')
          }
        )
      },
      error => {
        Swal.fire('Erro', error, 'error');
      }
    )
  }

  validaDados = (item) => {
    return new Promise((resolve, reject) => {
      if (isNaN(item.valor)) {
        reject('É preciso informar um valor válido para o procedimento')
        return
      }
      if (!item.valor || item.valor <= 0 || item.valor == '') {
        reject('É preciso informar um valor para o procedimento')
        return
      }
      if (item.valor){
        if (item.valor.includes(','))
          item.valor = item.valor.replace(',', '.')
        item.valor = parseFloat(item.valor)
      }
      resolve(item)
    })
  }

  salvarVarios = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var obj: any = []
    for (let item of this.listaProcedimentos){
      if (isNaN(item.valor)) {
        delete item.valor
      }
      if (item.valor){
        if (item.valor.includes(','))
          item.valor = item.valor.replace(',', '.')
        item.valor = parseFloat(item.valor)
        obj.push(item)
      }
    }

    this.enviaRecursivo(obj, 0)
  }

  enviaRecursivo = (obj, ordem) => {
    if (ordem == obj.length){
      var sucesso = 0
      for (let item of obj) {
        if (item.sucesso){
          sucesso +=1;
        }
      }
      if (sucesso == obj.length){
        Swal.fire('Sucesso', 'Todos os procedimentos foram enviados com sucesso','success').then(
          result => {
            this.buscarProcedimento()
            return
          }
        )
      }
      if (sucesso < obj.length){
        Swal.fire('Atenção', 'Alguns dos procedimentos não foram enviados com sucesso. Verifique a lista e tente novamente','warning').then(
          result => {
            this.buscarProcedimento()
            return
          }
        )
      }
      if (sucesso == 0){
        Swal.fire('Erro', 'Os procedimentos não foram salvos. Verifique a lista e tente novamente','error').then(
          result => {
            this.buscarProcedimento()
            return
          }
        )
      }
      return
    }
    // obj[ordem].sucesso = true;
    // this.enviaRecursivo(obj, ordem+1)
    obj[ordem].plano_id = this.plano
    obj[ordem].sigtap_id = obj[ordem].id
    this.service.Post(`PlanoSigTap`, obj[ordem]).then(
      result => {
        obj[ordem].sucesso = true;
        this.enviaRecursivo(obj,ordem+1)
      },
      error => {
        this.enviaRecursivo(obj,ordem+1)
      }
    )
  }

  atualizaEfecha = () => {
    this.atualizaListaProcedimentos.emit(true)
  }

}
