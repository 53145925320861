import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { Global } from 'src/app/global';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
declare var Simditor: any;

@Component({
  selector: 'app-teleatendimento',
  templateUrl: './teleatendimento.component.html',
  styleUrls: ['./teleatendimento.component.css']
})
export class TeleAtendimentoComponent implements OnChanges {

  @Input() prontuario: any = {};
  @Input() atendimento: any = {};
  @Input() listaHistoricoAtendimentos: any = [];

  global_: any;
  dadosImpresso: any = null;
  teleatendimento: any = {};
  usuario: any;
  teleAtendimentoIniciado: any = false;
  configuracao: any = {};
  teleatendimentoTexto: any = "";
  loading: any = false;
  listaHistorico: any = [];
  visualizar_prontuario: any = false;
  constructor(private authService: AuthService,
    private global: Global,
    public service: ApiService,
    private alerts: NgxAlertsService,) {
    this.global_ = global;
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.teleAtendimento.subscribe(res => {
      if (res) {

      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
    this.obterConfiguracao();
  }

  ngOnChanges() {
    
  }
  enviarMsg() {
    if (!this.teleatendimentoTexto) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    var obj = {
      "mensagem_enviada": this.teleatendimentoTexto
    }
    this.service.Post(`Prontuario/${this.prontuario.id}/Mensageiro`, obj).then(res => {
      this.loading = false;
      if (res != null) {
        this.teleatendimentoTexto = "";
        this.obterHistoricoMassagem();
      }
    }).catch(res => {
      this.loading = false;

      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  iniciarTeleAtendimento() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (!this.configuracao.url_teleatendimento) {
      this.alerts.notifyError({
        body: `Favor configurar o link para o tele atendimento.`,
        title: 'Erro'
      });
      return;
    }
    this.teleatendimento.uuid = this.newGuid();
    var urlLink = `${this.configuracao.url_teleatendimento}${this.atendimento.protocolo}`;
    $("#loadingScreenTeleAtentimento").css("display", "block");
    var obj = {
      "link": urlLink
    }
    
    this.service.Post(`Prontuario/${this.prontuario.id}/IniciarTeleatendimento`, obj).then(res => {
      this.loading = false;
      this.obterHistoricoMassagem();
      if (res != null)
        this.authService.setTeleatendimento(this.teleatendimento);
      setTimeout(() => {
        $("#loadingScreenTeleAtentimento").css("display", "block");
        document.getElementById('AppFrame').setAttribute("src", urlLink);
        this.teleAtendimentoIniciado = true;
      }, 2000);
    }).catch(res => {
      this.loading = false;

      $("#loadingScreenTeleAtentimento").css("display", "none");
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  obterHistoricoMassagem = () => {
    this.service.Get(`Prontuario/${this.prontuario.id}/HistoricoTeleatendimento`).then(res => {
      if (res != null)
        this.listaHistorico = res;
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  obterConfiguracao = () => {
    this.service.Get(`Configuracao`).then(res => {
      if (res != null)
        this.configuracao = res;
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }


  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
