import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Global } from '../../global';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
declare var Simditor: any;

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {
  dadosImpresso: any;
  global_: any;
  uid: any;
  data: any;
  constructor(public service: ApiService,
    private global: Global,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alerts: NgxAlertsService,
    private router: Router,
  ) {
    this.global_ = this.global;
    $('#header').removeAttr('class');
    $("#header").hide();

    $('#sidebar').removeAttr('class');
    $("#sidebar").hide();

    $('#footer').removeAttr('class');
    $("#footer").hide();   
  }

  ngOnInit() {
    this.init();
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    this.data = this.route.snapshot.paramMap.get('data');
    if (uid != null) {
      await this.obterDados();
    }
  }

  obterDados() {
    this.service.Get(`Receituario/${this.data}/Receituario/${this.uid}/Impresso`).then(res => {
      if (res) {     
        this.dadosImpresso = res;
        Swal.close();      
      }
    });
  }
}
