import { Component, OnInit, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-modal-report',
  templateUrl: './modal-report.component.html',
  styleUrls: ['./modal-report.component.css']
})
export class ModalReportComponent implements OnChanges {
  // export class ModalReportComponent implements OnInit {

  @Input() dadosRegulacao: any = {};
  
  cidadao: any;
  usuarioSolicitante: any;
  regulacao: any;
  estabelecimentoSolicitante: any;
  listaProcedimentos: any;
  cid: any;
  limparDadosRegulacaoExame: boolean = false;

  @Output() fecharModalLaudo: EventEmitter<any> = new EventEmitter();
  global_ : any

  constructor(
    private global: Global,
    public service: ApiService,
    private authService: AuthService
  ) {
    this.global_ = this.global
   }

  ngOnInit() { }

  ngOnChanges() {
    this.regulacao = {}
    if (this.dadosRegulacao.id) {
      Swal.fire('Favor aguarde');
      Swal.showLoading()
      this.regulacao = this.dadosRegulacao;
      this.cidadao = this.dadosRegulacao.Cidadao;
      this.usuarioSolicitante = this.dadosRegulacao.Usuario;
      this.listaProcedimentos = this.dadosRegulacao.ListaSigTap;
      this.limparDadosRegulacaoExame = false;
      if (!this.regulacao.CidSubCategoria){
        this.getCid(this.regulacao.cid_sub_categoria_id).then(
          result => {
            this.dadosRegulacao.CidSubCategoria = result
          },
          error => {
            this.emitErro(error)
          }
        )
      }
      if (this.regulacao.estabelecimento_solicitante_id != null){
        this.getEstabelecimentoSolicitante(this.regulacao.estabelecimento_solicitante_id).then(
          result => {
            this.estabelecimentoSolicitante = result;
            Swal.close()
          },
          error => {
            this.emitErro(error)
          }
        )
      }
    }
  }

  getEstabelecimentoSolicitante = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento/${id}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getCid = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`CidSubCategoria/${id}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  emitErro = (error) => {
    if (typeof error == 'string'){
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401){
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  // close modal 
  fecharModal = () => {
    this.dadosRegulacao = {}
    this.limparDadosRegulacaoExame = true;
    this.fecharModalLaudo.emit()
    $("#modalLaudo").toggleClass("show");
    $("#modalLaudo").css("display", "none");
  }

}
