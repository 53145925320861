import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as $ from "jquery";
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-modal-alergias',
  templateUrl: './modal-alergias.component.html',
  styleUrls: ['./modal-alergias.component.css']
})
export class ModalAlergiasomponent implements OnChanges {

  alergias: any = [];
  @Input() alergia: any = {};
  @Input() cidadao_id: any;
  @Input() cidadao_nome: any;

  usuario: any;
  global_: any;

  listMedicamento: any = [];
  visualizar_prontuario: any = false;

  constructor(
    public service: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
  ) {
    this.global_ = global;
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;     
      }
    });
  }

  ngOnChanges() {
    if (this.cidadao_id) {
      this.getAlergia();
    }
  }

  // close modal 
  fecharModal = () => {
    $("#modalAlergiasCidadao").toggleClass("show");
    $("#modalAlergiasCidadao").css("display", "none");
  }

  limparCampos() {
    this.alergia.descricao = "";
  }

  salvarAlergia() {
    var obj = Object.assign({}, this.alergia);

    if (this.alergia.possui_alergia == null) {
      Swal.fire('Erro', "Favor informar se o paciente possui alguma alergia.", 'error');
      return;
    }

    if (this.alergia.possui_alergia == 'true') {
      if (!this.alergia.tipo_alergia) {
        Swal.fire('Erro', "Favor informar o tipo da alergia.", 'error');
        return;
      }

      if (this.alergia.tipo_alergia == "1") {
        if (!this.alergia.descricao) {
          Swal.fire('Erro', "Favor descrever as alergias.", 'error');
          return;
        }
      }
    }
    obj.possui_alergia == 'true' ? obj.possui_alergia = true : obj.possui_alergia = false;

    if (!obj.possui_alergia) {
      obj.tipo_alergia = null;
      obj.descricao = null;
      obj.medicamento_id = null;
    }

    obj.profissional_id = this.usuario.usuario_profissional_id;
    obj.cidadao_id = this.cidadao_id;

    if (obj.tipo_alergia == '1') {
      obj.medicamento_id = null;
    }

    var send = {
      "tipo_alergia": parseInt(obj.tipo_alergia),
      "descricao": obj.descricao,
      "cidadao_id": obj.cidadao_id,
      "profissional_id": obj.profissional_id,
      "possui_alergia": obj.possui_alergia,
      "medicamento_id": obj.medicamento_id

    }
    this.sendAlergia(send)
  }

  sendAlergia = (obj) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`Cidadao/${this.cidadao_id}/Alergia`, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: `Alergia salva com sucesso para o paciente ${this.cidadao_nome}.`,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.alergia = {};
              this.getAlergia();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.error && res.error.erros.length > 0) {
        Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
      } else {
        Swal.fire('Erro', `${res.messagem}`, 'error');
      }
      Swal.close();
    })
  }

  getAlergia = () => {
    this.service.Get(`Cidadao/${this.cidadao_id}/Alergia`).then(res => {
      this.alergias = res;
      Swal.close();
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }


  adicionarMedicamento(item) {
    if (!item) return;
    this.alergia.medicamento_id = item.id;
  }
}
