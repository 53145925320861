import { Routes } from "@angular/router";
import { AuthGuard } from "./_guards/auth.guard";
import { AttendancesComponent } from "./screens/attendances/attendances.component";
import { DashBoardsComponent } from "./screens/dashboards/dashboards.component";
import { HomeComponent } from "./screens/home/home.component";
import { InternmentComponent } from "./screens/internment/internment.component";
import { LoginComponent } from "./screens/login/login.component";
import { ProntuarioComponent } from "./screens/prontuario/prontuario.component";
import { ReceptionComponent } from "./screens/reception/reception/reception.component";
import { SpontaneousDemandComponent } from "./screens/reception/spontaneous-demand/spontaneous-demand.component";
import { TeleatendimentoAgendarComponent } from "./screens/reception/teleatendimento-agendar/teleatendimento-agendar.component";
import { ArrivalTimeSettingComponent } from "./screens/register/arrival-time-setting/arrival-time-setting.component";
import { CitizenComponent } from "./screens/register/citizen/citizen.component";
import { ClientComponent } from "./screens/register/client/client.component";
import { AddClosingReasonComponent } from "./screens/register/closing-reason/add-closing-reason/add-closing-reason.component";
import { ListClosingReasonComponent } from "./screens/register/closing-reason/list-closing-reason/list-closing-reason.component";
import { DashBoardComponent } from "./screens/register/dashboard/dashboard-registration/dashboard-registration.component";
import { ListDashBoardComponent } from "./screens/register/dashboard/list-dashboard/list-dashboard.component";
import { EstablishmentComponent } from "./screens/register/establishment/establishment.component";
import { AddExamsGroupComponent } from "./screens/register/exams-group/add-exams-group/add-exams-group.component";
import { ListExamsGroupComponent } from "./screens/register/exams-group/list-exams-group/list-exams-group.component";
import { GeneralConfigurationComponent } from "./screens/register/general-configuration/general-configuration.component";
import { ListArrivalTimeSettingComponent } from "./screens/register/list-arrival-time-setting/list-arrival-time-setting.component";
import { ListCitizenComponent } from "./screens/register/list-citizen/list-citizen.component";
import { ListClientComponent } from "./screens/register/list-client/list-client.component";
import { ListEstablishmentComponent } from "./screens/register/list-establishment/list-establishment.component";
import { ListPlansComponent } from "./screens/register/list-plans/list-plans.component";
import { ListPreparationComponent } from "./screens/register/list-preparation/list-preparation.component";
import { ListProfessionalComponent } from "./screens/register/list-professional/list-professional.component";
import { ListProfileComponent } from "./screens/register/list-profile/list-profile.component";
import { ListScheduleConfigurationComponent } from "./screens/register/list-schedule-configuration/list-schedule-configuration.component";
import { ListUserComponent } from "./screens/register/list-user/list-user.component";
import { PlanComponent } from "./screens/register/plan/plan.component";
import { PreparationComponent } from "./screens/register/preparation/preparation.component";
import { ProfessionalComponent } from "./screens/register/professional/professional.component";
import { ProfileComponent } from "./screens/register/profile/profile.component";
import { RegisterComponent } from "./screens/register/register/register.component";
import { ScheduleConfigurationComponent } from "./screens/register/schedule-configuration/schedule-configuration.component";
import { SingleScaleComponent } from "./screens/register/single-scale/single-scale.component";
import { SpendCeilComponent } from "./screens/register/spend-ceil/spend-ceil.component";
import { UserComponent } from "./screens/register/user/user.component";
import { ExamsComponent } from "./screens/regulation/exams/exams.component";
import { ForwardingComponent } from "./screens/regulation/forwarding/forwarding.component";
import { RegulationComponent } from "./screens/regulation/regulation/regulation.component";
import { CriarResultadoComponent } from "./screens/resultados-exames/criar-resultado/criar-resultado.component";
import { ResultadosExameComponent } from "./screens/resultados-exames/resultados-exames.component";
import { RegisterRiskRatingComponent } from "./screens/risk-rating/register-risk-rating/register-risk-rating.component";
import { RiskRatingComponent } from "./screens/risk-rating/risk-rating.component";
import { ExamSettingComponent } from "./screens/settings/exams/exam/exam.component";
import { ExamsSettingComponent } from "./screens/settings/exams/exams.component";
import { SettingsComponent } from "./screens/settings/settings/settings.component";
import { AddMakerComponent } from "./screens/storage/maker/add-maker/add-maker.component";
import { ListMakerComponent } from "./screens/storage/maker/list-maker/list-maker.component";
import { ListProductModelComponent } from "./screens/storage/product-model/list-product-model/list-product-model.component";
import { ProductModelRegistrationComponent } from "./screens/storage/product-model/product-model-registration/product-model-registration.component";
import { StockComponent } from "./screens/storage/stocks/stock/stock.component";
import { StocksComponent } from "./screens/storage/stocks/stocks.component";
import { StorageComponent } from "./screens/storage/storage/storage.component";
import { AddSuplierComponent } from "./screens/storage/suplier/add-suplier/add-suplier.component";
import { ListSuplierComponent } from "./screens/storage/suplier/list-suplier/list-suplier.component";
import { GestaoEscalasComponent } from "./screens/gestao-escalas/gestao-escalas.component";
import { GestaoEscalasAdmComponent } from "./screens/gestao-escalas-adm/gestao-escalas-adm.component";
import { ListPerfilUsuarioComponent } from "./screens/register/list-perfil-usuario/list-perfil-usuario.component";
import { PerfilComponent } from "./screens/register/perfil/perfil.component";
import { PerfilUsuarioComponent } from "./screens/register/perfil-usuario/perfil-usuario.component";
import { ListExameImagemComponent } from "./screens/exame-imagem/list-exame-imagem/list-exame-imagem.component";
import { AddExameImagemComponent } from "./screens/exame-imagem/add-exame-imagem/add-exame-imagem.component";
import { DicomComponent } from "./screens/exame-imagem/dicom/dicom.component";
import { LaboratorioComponent } from "./screens/laboratorio/laboratorio.component";

export const appRoutes: Routes = [

    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'login', component: LoginComponent, pathMatch: 'full' },

    { path: 'cadastro', component: RegisterComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/clientes', component: ListClientComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/clientes/cliente', component: ClientComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/clientes/cliente/:uuid', component: ClientComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/profissionais', component: ListProfessionalComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/profissionais/profissional', component: ProfessionalComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/profissionais/profissional/:uuid', component: ProfessionalComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/estabelecimentos', component: ListEstablishmentComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/estabelecimentos/estabelecimento', component: EstablishmentComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/estabelecimentos/estabelecimento/:uuid', component: EstablishmentComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/cidadaos', component: ListCitizenComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/cidadaos/cidadao', component: CitizenComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/cidadaos/cidadao/:uuid', component: CitizenComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/configuracoesAgendamento', component: ListScheduleConfigurationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/configuracoesAgendamento/configuracaoAgendamento', component: ScheduleConfigurationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/configuracoesAgendamento/configuracaoAgendamento/:uuid', component: ScheduleConfigurationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/configuracoesAgendamento/configuracaoAgendaAvulsa', component: SingleScaleComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/configuracoesAgendamento/configuracaoAgendaAvulsa/:uuid', component: SingleScaleComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/perfis', component: ListProfileComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/perfis/perfil', component: ProfileComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/perfis/perfil/:uuid', component: ProfileComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/usuarios', component: ListUserComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/usuarios/usuario', component: UserComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/usuarios/usuario/:uuid', component: UserComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'regulacao', component: RegulationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'regulacao/consultas', component: ForwardingComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'regulacao/exames', component: ExamsComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/configuracaoGeral', component: GeneralConfigurationComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/preparos', component: ListPreparationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/preparos/preparo', component: PreparationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/preparos/preparo/:uuid', component: PreparationComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/planos', component: ListPlansComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/planos/plano', component: PlanComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/planos/plano/:uuid', component: PlanComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/configuracoesHorarioChegada', component: ListArrivalTimeSettingComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/configuracoesHorarioChegada/configuracaoHorarioChegada', component: ArrivalTimeSettingComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/configuracoesHorarioChegada/configuracaoHorarioChegada/:uuid', component: ArrivalTimeSettingComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/tetoGastos', component: SpendCeilComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'recepcao', component: ReceptionComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'recepcao/demandaEspontanea', component: SpontaneousDemandComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'recepcao/agendamento', component: TeleatendimentoAgendarComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'classificacao', component: RiskRatingComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'classificacao/:uuid', component: RegisterRiskRatingComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'atendimento', component: AttendancesComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'prontuario/:uuid', component: ProntuarioComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/grupoExames', component: ListExamsGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/grupoExames/addGrupoExames', component: AddExamsGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/grupoExames/editarGrupoExames/:uuid', component: AddExamsGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/motivoEncerramento', component: ListClosingReasonComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/motivoEncerramento/motivo', component: AddClosingReasonComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/motivoEncerramento/motivo/:uuid', component: AddClosingReasonComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'configuracao', component: SettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'configuracao/exames', component: ExamsSettingComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    //{ path: 'configuracao/exames/exame', component: ExamComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'configuracao/exames/exame/:uuid', component: ExamSettingComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque', component: StorageComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'estoque/estoques', component: StocksComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/estoques/addEstoque', component: StockComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/estoques/editarEstoque/:uuid', component: StockComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'estoque/fabricantes', component: ListMakerComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/fabricantes/addFabricante', component: AddMakerComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/fabricantes/editarFabricante/:uuid', component: AddMakerComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'estoque/fornecedores', component: ListSuplierComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/fornecedores/addFornecedor', component: AddSuplierComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/fornecedores/editarFornecedor/:uuid', component: AddSuplierComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'internamento', component: InternmentComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/dashboard', component: ListDashBoardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/dashboard/criar', component: DashBoardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/dashboard/criar/:uuid', component: DashBoardComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'estoque/modeloProduto', component: ListProductModelComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/modeloProduto/criar', component: ProductModelRegistrationComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'estoque/modeloProduto/criar/:uuid', component: ProductModelRegistrationComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'dashboards', component: DashBoardsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'atendimento', component: AttendancesComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'resultados_exames', component: ResultadosExameComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'resultados_exames/criar', component: CriarResultadoComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'minhas_agendas', component: GestaoEscalasComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'gestao_escalas_adm', component: GestaoEscalasAdmComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'cadastro/perfil-usuario', component: ListPerfilUsuarioComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/perfil-usuario/criar', component: PerfilComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'cadastro/perfil-usuario/editar/:uuid', component: PerfilComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'perfil-usuario', component: PerfilUsuarioComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    { path: 'exame-imagem', component: ListExameImagemComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'exame-imagem/cadastro', component: AddExameImagemComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'exame-imagem/cadastro/:uuid', component: AddExameImagemComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'dicom', component: DicomComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'laboratorio', component: LaboratorioComponent, canActivate: [AuthGuard], pathMatch: 'full' },
];