import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { Location } from '@angular/common';

import * as $ from "jquery";

@Component({
  selector: 'app-teleatendimento-agendar',
  templateUrl: './teleatendimento-agendar.component.html',
  styleUrls: ['./teleatendimento-agendar.component.css']
})
export class TeleatendimentoAgendarComponent implements OnInit {

  filtro: any = '';
  resultCidadao: any = [];
  pagina: any = 1;
  totalPorPagina: any = 300;
  totalPagina: any = 1;
  listaClinicas: any = [];
  dados = JSON.parse(localStorage.getItem('dadosPresencaUser'));
  userLogado = JSON.parse(localStorage.getItem('@DigtVentry:currentUser'));
  presencaUser = JSON.parse(localStorage.getItem('@DigtVentry:presencaUser'));
  cidadao: any;
  usuario: any;
  constructor(public service: ApiService,
    private global: Global,
    private location: Location,
    private authService: AuthService) {

    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });

  }

  ngOnInit() {
    this.obterListaClinicas();
  }

  obterListaClinicas() {
    this.service.Get(`Estabelecimento/${this.presencaUser.estabelecimento_id}/ClinicaMedica?ativo=true`).then((res) => {
      this.listaClinicas = res;
    }).catch((err) => {
      Swal.fire('Erro', `Erro ao obter clinicas`, 'error');
    });
  }

  obterListaCidadaos = () => {
    if (this.filtro.length == 0) {
      Swal.fire('Erro', 'Favor digitar um filtro.', 'error');
      return;
    }

    var busca = this.filtro;
    if (!isNaN(parseInt(this.filtro[0])))
      var busca = this.global.removeMask(this.filtro);

    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Cidadao?Skip=${this.pagina}&Take=${this.totalPorPagina}&Busca=${busca}`).then(res => {
      this.resultCidadao = res['conteudo'];
      if (this.resultCidadao.length > 0) {
        this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
      }
      for (let cidadao of this.resultCidadao) {
        this.global.applyMask(cidadao)
      }
      Swal.close();
      if (this.resultCidadao.length == 0)
        Swal.fire({
          title: 'Atenção',
          text: `Esse paciente não foi encontrado em nosso sistema. Deseja cadastra-lo agora?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#dc3545'
        }).then(
          result => {
            if (result.isConfirmed) {
              $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
              $("#modalNovoCidadaoDemandaEspontanea").css("display", "block");
            }
          }
        );
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  fecharModal = () => {
    $("#modalNovoCidadaoDemandaEspontanea").toggleClass("show");
    $("#modalNovoCidadaoDemandaEspontanea").css("display", "none");
  }

  retornoNovoCidadao = (cidadao) => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      html: 'Operação realizada com sucesso!'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.filtro = cidadao.nome;
          this.fecharModal();
          this.obterListaCidadaos();
        }
      }
    );
  }

  encaminharCidadao = (cidadao_id, clinica_id) => {
    if (clinica_id == null) {
      Swal.fire('Erro', 'Favor informar a clinica médica.', 'error');
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    let encaminhamento = {
      "cidadao_id": cidadao_id,
      "clinica_medica_id": parseInt(clinica_id),
      "profissional_id": this.usuario.usuario_profissional_id
    };
    this.service.Post(`Atendimento`, encaminhamento).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Paciente encaminhado com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.location.back();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  abrirModalAgendamento(cidadao) {
    this.cidadao = cidadao;
    $("#modalAgendamento").toggleClass("show");
    $("#modalAgendamento").css("display", "block");
  }

  resultPostModalRealization() {
    this.cidadao = null;
  }
}
