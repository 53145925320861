import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  usuario: any = {};
  presenca: any;

  displaySidebar: boolean = true;
  @Output() toggleBody = new EventEmitter<boolean>();

  constructor(public router: Router,
    public apiService: ApiService,
    public global: Global,
    private authService: AuthService,) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnInit() {
  }

  logOut() {
    if (this.presenca != null && this.presenca.id != null) {
      this.apiService.Post(`Presenca/${this.presenca.id}/Logout`, {}).then(res => {
        this.authService.logoutUser();
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close();
        Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
      });
    } else {
      this.authService.logoutUser();
    }


  }

  perfil() {
    this.router.navigate(['perfil-usuario']);
  }

  displayMenu = () => {
    this.displaySidebar = !this.displaySidebar;
    this.toggleBody.emit(this.displaySidebar)
  }
}
