import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-model-registration',
  templateUrl: './product-model-registration.component.html',
  styleUrls: ['./product-model-registration.component.css']
})
export class ProductModelRegistrationComponent implements OnInit {
  presencaUser: any = [];
  uid: any = '';
  modelo: any = {};
  listaTipo: any = [];

  listaTipoMedida: any = []

  listaTiposVia: any = [
  ];

  listaTiposClasse: any = [
  ];

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.modelo.tipo = 0;
    this.modelo.tipo_medida_id = 0;
    this.modelo.via_administracao_id = 0;
    this.modelo.classe_terapeutica_id = 0;


    this.obterTipoProduto();
    this.obterTipoMedida();
    this.obterTipoViaAdministracao();
    this.obterTipoClasseTerapeutica();
    this.uid = this.route.snapshot.paramMap.get('uuid');
    if (this.uid != null) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterModeloProduto(this.uid).then(
        result => {
          this.modelo = result;
          Swal.close();
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error')
        }
      )
    }
  }

  goBack() {
    this.location.back();
  }

  obterModeloProduto = (uid) => {
    return new Promise((res, rej) => {
      this.service.Get(`ProdutoModelo/${uid}`).then(
        result => {
          res(result)
        },
        error => {
          rej(error)
        }
      )
    })
  }

  adicionarMedicamento(item) {
    if (!item) return;
    this.modelo.codigo_catmat = item.codigo_catmat;
    this.modelo.nome = item.principio_ativo;
    this.modelo.apresentacao = item.concentracao;
  }


  obterTipoClasseTerapeutica = () => {
    this.service.Get(`ClasseTerapeutica`).then(
      result => {
        this.listaTiposClasse = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }



  obterTipoViaAdministracao = () => {

    this.service.Get(`ViaAdministracao`).then(
      result => {
        this.listaTiposVia = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }

  obterTipoProduto = () => {

    this.service.Get(`ProdutoModelo/TipoProdutoModelo`).then(
      result => {
        this.listaTipo = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }

  obterTipoMedida = () => {
    this.service.Get(`TipoMedida`).then(
      result => {
        this.listaTipoMedida = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }



  salvarModeloProduto = () => {
    if (this.modelo.tipo == null || this.modelo.tipo == 0) {
      Swal.fire('Atenção', `Tipo obrigatório`, 'warning');
      return
    }

    if (this.modelo.codigo_catmat == null || this.modelo.codigo_catmat == "") {
      Swal.fire('Atenção', `Código catmat obrigatório`, 'warning');
      return
    }

    if (this.modelo.nome == null || this.modelo.nome == "") {
      Swal.fire('Atenção', `Nome obrigatório`, 'warning');
      return
    }


    if (this.modelo.apresentacao == null || this.modelo.apresentacao == "") {
      Swal.fire('Atenção', `Apresentação obrigatório`, 'warning');
      return
    }

    if (this.modelo.tipo_medida_id == null || this.modelo.tipo_medida_id == 0) {
      Swal.fire('Atenção', `Forma de apresentação obrigatório`, 'warning');
      return

    }

    if (this.modelo.tipo == 1) {
      if (this.modelo.via_administracao_id == null || this.modelo.via_administracao_id == 0) {
        Swal.fire('Atenção', `Via de administração obrigatório`, 'warning');
        return
      }

      if (this.modelo.classe_terapeutica_id == null || this.modelo.classe_terapeutica_id == 0) {
        Swal.fire('Atenção', `Classe terapêutica obrigatório`, 'warning');
        return
      }
    } else {
      this.modelo.via_administracao_id = 0;
      this.modelo.classe_terapeutica_id = 0;
    }


    if (this.uid != null) {
      this.atualizarModeloProdutos();
    } else {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      var Obj = {
        "nome": this.modelo.nome,
        "tipo": this.modelo.tipo,
        "codigo_catmat": this.modelo.codigo_catmat,
        "apresentacao": this.modelo.apresentacao,
        "via_administracao_id": this.modelo.via_administracao_id,
        "classe_terapeutica_id": this.modelo.classe_terapeutica_id,
        "tipo_medida_id": this.modelo.tipo_medida_id,
        "complemento": this.modelo.complemento,
        "ativo": this.modelo.ativo
      }

      this.service.Post(`ProdutoModelo`, Obj).then(
        result => {
          Swal.fire('Sucesso!', 'Modelo de produto criado com sucesso', 'success').then(
            result => {
              this.location.back()
            }
          )
        },
        error => {
          Swal.fire('Erro!', error.error.messagem, 'error');
        }
      )
    }
  }

  atualizarModeloProdutos = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    var Obj = {
      "nome": this.modelo.nome,
      "tipo": this.modelo.tipo,
      "codigo_catmat": this.modelo.codigo_catmat,
      "apresentacao": this.modelo.apresentacao,
      "via_administracao_id": this.modelo.via_administracao_id,
      "classe_terapeutica_id": this.modelo.classe_terapeutica_id,
      "tipo_medida_id": this.modelo.tipo_medida_id,
      "ativo": this.modelo.ativo,
      "complemento": this.modelo.complemento,
    }

    this.service.Put(`ProdutoModelo/${this.uid}`, Obj).then(
      result => {
        Swal.fire('Sucesso!', 'Atualização realizada com sucesso', 'success').then(
          result => {
            this.location.back()
          }
        )
      },
      error => {
        Swal.fire('Erro!', error.error.messagem, 'error');
      }
    )
  }

}
