import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Global } from '../../../global'
import { AuthService } from '../../../service/auth/auth.service';

@Component({
  selector: 'app-single-scale',
  templateUrl: './single-scale.component.html',
  styleUrls: ['./single-scale.component.css']
})
export class SingleScaleComponent implements OnInit {

  agendaAvulsa: any = {};
  profissionais: any = [];
  estabelecimentos: any = [];
  ocupacoes: any = [];
  listaTempoAtendimento: any = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  uid: any = 0;
  loading: boolean = false;
  editando: boolean = false;
  listaLocalAtendimento: any = [];

  constructor(
    public service: ApiService,
    private alerts: NgxAlertsService,
    private route: ActivatedRoute,
    private location: Location,
    private global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.agendaAvulsa.profissionalId = 0;
    this.agendaAvulsa.ocupacaoId = 0;
    this.agendaAvulsa.estabelecimentoId = 0;
    this.agendaAvulsa.horaInicio = '';
    this.agendaAvulsa.horaFim = '';
    this.agendaAvulsa.tempo = '';
    this.agendaAvulsa.listaDatas = [];
    this.agendaAvulsa.AgendaAvulsa = true;
    this.agendaAvulsa.localAtendimento = 1;

    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    this.agendaAvulsa.tipo = '1';
    this.agendaAvulsa.tempo = 0;

    this.obterListaProfissionais().then(
      result => {
        this.profissionais = result;
        this.obterListaEstabelecimentos().then(
          result => {
            this.estabelecimentos = result;
            // this.obterListaOcupacao().then(
            //   result => {
            //     this.ocupacoes = result;
            //   },
            //   error => {
            //     this.erro(error)
            //   }
            // )
          },
          error => {
            this.erro(error)
          }
        )
      },
      error => {
        this.erro(error)
      }
    )
    this.obterListaLocalAtendimento();
  }

  erro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  salvaragendaAvulsa = () => {
    if (!this.validaPostagendaAvulsa())
      return;
    this.agendaAvulsa.horaInicio = this.agendaAvulsa.horaInicio[0] + this.agendaAvulsa.horaInicio[1] + ":" + this.agendaAvulsa.horaInicio[2] + this.agendaAvulsa.horaInicio[3];
    this.agendaAvulsa.horaFim = this.agendaAvulsa.horaFim[0] + this.agendaAvulsa.horaFim[1] + ":" + this.agendaAvulsa.horaFim[2] + this.agendaAvulsa.horaFim[3];

    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Post(`AgendaDIa/Avulsa`, this.agendaAvulsa).then(
      res => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Configuração de agenda salva com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      },
      err => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: err.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      }
    )
  }

  editaragendaAvulsa = () => {
    if (!this.validaPostagendaAvulsa())
      return;
    this.agendaAvulsa.horaInicio = this.agendaAvulsa.horaInicio[0] + this.agendaAvulsa.horaInicio[1] + ":" + this.agendaAvulsa.horaInicio[2] + this.agendaAvulsa.horaInicio[3];
    this.agendaAvulsa.horaFim = this.agendaAvulsa.horaFim[0] + this.agendaAvulsa.horaFim[1] + ":" + this.agendaAvulsa.horaFim[2] + this.agendaAvulsa.horaFim[3];

    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Put(`AgendaConfiguracao/${this.agendaAvulsa.id}`, this.agendaAvulsa).then(res => {
      this.loading = false;
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              this.goBack();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  goBack() {
    this.location.back();
  }

  validaPostagendaAvulsa = () => {
    return new Promise((resolve, reject) => {
      // if (this.agendaAvulsa.nome == null || this.agendaAvulsa.nome == "") {
      //   reject("Favor preencher o nome da agenda")
      //   return;
      // }
      if (this.agendaAvulsa.estabelecimentoId == 0) {
        reject("Favor selecionar o estabelecimento")
        return
      }
      if (this.agendaAvulsa.ocupacao_id == 0) {
        reject("Favor selecionar a ocupação")
        return
      }
      if (this.agendaAvulsa.horaInicio == null || this.agendaAvulsa.horaInicio.length != 4) {
        reject("Favor informar uma hora de inicio válida")
        return
      }
      if (this.agendaAvulsa.horaFim == null || this.agendaAvulsa.horaFim.length != 4) {
        reject("Favor informar uma hora fim válida")
        return
      }

      var hi = Number(this.agendaAvulsa.horaInicio[0] + this.agendaAvulsa.horaInicio[1]);
      var mi = Number(this.agendaAvulsa.horaInicio[2] + this.agendaAvulsa.horaInicio[3]);

      if (hi > 23 || mi > 59) {
        reject("Favor informar uma hora de inicio válida")
        return
      }

      var hf = Number(this.agendaAvulsa.horaFim[0] + this.agendaAvulsa.horaFim[1]);
      var mf = Number(this.agendaAvulsa.horaFim[2] + this.agendaAvulsa.horaFim[3]);

      if (hf > 23 || mf > 59) {
        reject("Favor informar uma hora final válida")
        return
      }

      if (this.agendaAvulsa.tempo == 0) {
        reject("Favor informar o tempo de atendimento")
        return
      }
      if (this.agendaAvulsa.profissionalId == 0)
        delete this.agendaAvulsa.profissionalId;

      return true;
    })
  }

  obterListaProfissionais = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Profissional`).then(
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  obterListaEstabelecimentos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento`).then(
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  obterListaOcupacao = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Ocupacao`).then(
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  obterOcupacaoPorProfissional = () => {
    if (!this.agendaAvulsa.profissionalId || this.agendaAvulsa.profissional_id == 0) {
      return;
    }

    if (!this.agendaAvulsa.estabelecimentoId || this.agendaAvulsa.estabelecimentoId == 0) {
      return;
    }

    this.service.Get(`Estabelecimento/${this.agendaAvulsa.estabelecimentoId}/Ocupacao?profissional_id=${this.agendaAvulsa.profissionalId}`).then(res => {
      this.ocupacoes = res;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  // obterConfiguracaoAgenda = (id) => {
  //   Swal.fire('Favor aguarde')
  //   Swal.showLoading()
  //   this.service.Get(`AgendaConfiguracao/${id}`).then(res => {
  //     this.agendaAvulsa = res;
  //     if (this.agendaAvulsa.tipo == "ATENDIMENTO")
  //       this.agendaAvulsa.tipo = '1';
  //     else
  //       this.agendaAvulsa.tipo = '2';
  //     this.editando = true;
  //     Swal.close();
  //   }).catch(res => {
  //     Swal.close();
  //     this.alerts.notifyError({
  //       body: `${res.error.messagem}`,
  //       title: 'Erro'
  //     });
  //   })
  // }

  FormataHora = (inicioFim) => {
    if (inicioFim == 1) {
      if (this.agendaAvulsa.horaInicio != null) {
        if (this.agendaAvulsa.horaInicio.length == 1)
          this.agendaAvulsa.horaInicio = `0${this.agendaAvulsa.horaInicio}00`;
        if (this.agendaAvulsa.horaInicio.length == 2)
          this.agendaAvulsa.horaInicio = `${this.agendaAvulsa.horaInicio}00`;
        if (this.agendaAvulsa.horaInicio.length == 3)
          this.agendaAvulsa.horaInicio = `${this.agendaAvulsa.horaInicio}0`;
      }
    } else if (inicioFim == 2) {
      if (this.agendaAvulsa.horaFim != null) {
        if (this.agendaAvulsa.horaFim.length == 1)
          this.agendaAvulsa.horaFim = `0${this.agendaAvulsa.horaFim}00`;
        if (this.agendaAvulsa.horaFim.length == 2)
          this.agendaAvulsa.horaFim = `${this.agendaAvulsa.horaFim}00`;
        if (this.agendaAvulsa.horaFim.length == 3)
          this.agendaAvulsa.horaFim = `${this.agendaAvulsa.horaFim}0`;
      }
    }
  }

  recebeDatas = (event) => {
    this.agendaAvulsa.listaDatas = event
  }

  obterListaLocalAtendimento = () => {
    this.service.Get(`AgendaConfiguracao/LocalAtendimento`).then(res => {
      this.listaLocalAtendimento = res;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

}
