import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../service/apiServices';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-pacerecer-laudo',
  templateUrl: './pacerecer-laudo.component.html',
  styleUrls: ['./pacerecer-laudo.component.css']
})
export class PacerecerLaudoComponent implements OnInit {

  unidades: any = [];
  profissionais: any = [];
  cids: any = [];
  favoritos: any = [];
  @Input() regulacao: any = {};
  @Input() tipo: any = 0;
  currentUser: any = JSON.parse(localStorage.getItem('@DigtVentry:currentUser'));
  visualizandoFavorito: boolean = false;
  @Output() resultPostModalRealization: EventEmitter<any> = new EventEmitter();
  @Input() limparDadosRegulacaoExame: boolean;

  @Output() fecharModalLaudo: EventEmitter<any> = new EventEmitter();

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,) { }

  ngOnInit() {
    this.tipo;
  }

  ngOnChanges() {
    var regula = this.regulacao
    this.unidades = [];
    this.profissionais = [];
    this.cids = [];
    this.favoritos = [];
    this.regulacao.observacao_realizacao = '';
    //this.tipo = 0;
    this.visualizandoFavorito = false;
    if (!this.limparDadosRegulacaoExame) {
      if (regula.id) {
        this.regulacao = regula
        var today = new Date();
        this.regulacao.data_realizacao = ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear()
        this.regulacao.hora_realizacao = ('0' + today.getHours()).slice(-2) + ':' + ('0' + today.getMinutes()).slice(-2)
        this.obterListaUnidades();
      }
    }
    //if (this.limparDadosRegulacaoExame){
    //  this.fecharModal()
    //}
  }

  obterListaUnidades = () => {
    this.service.Get(`Estabelecimento`).then(res => {
      this.unidades = res;
      this.regulacao.unidade_id = 0;
      //this.obterListaCids();
      this.getListProfessional();
    }).catch(res => {
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterTextosFavoritosUsuario = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`TextoFavorito?UsuarioId=${this.currentUser.usuario_id}&TipoTextoFavorito=LAUDO`).then(res => {
      this.favoritos = res;
      this.visualizandoFavorito = true;
      Swal.close();
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.error.messagem}`,
        title: 'Erro'
      });
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse favorito?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`TextoFavorito/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterTextosFavoritosUsuario();
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  favoritarLaudo = () => {
    if (this.regulacao.laudo_exame == null || this.regulacao.laudo_exame == "") {
      this.alerts.notifyError({
        body: "Favor preencher o laudo",
        title: 'Erro'
      });
      return;
    }
    Swal.fire({
      title: 'Atenção',
      text: `Deseja favoritar esse laudo?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            text: `Qual nome deseja dar ao favorito?`,
            icon: 'question',
            input: 'text',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545'
          }).then(
            res => {
              if (res.isConfirmed) {
                var obj = {
                  "nome": res.value,
                  "texto": this.regulacao.laudo_exame,
                  "tipo": "LAUDO",
                  "usuario_id": this.currentUser.usuario_id
                }
                Swal.fire('Favor aguarde');
                Swal.showLoading();
                this.service.Post(`TextoFavorito`, obj).then(res => {
                  setTimeout(() => {
                    Swal.close();
                    Swal.fire({
                      icon: 'success',
                      title: 'Sucesso!',
                      html: 'Laudo favoritado com sucesso!'
                    }).then(
                      result => {
                        if (result.isConfirmed) {

                        }
                      }
                    );

                  }, 1000);
                }).catch((err) => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    html: err.error.erros[0].messagem
                  });
                })
              }
            }
          );


        }
      }
    );
  }

  selecionarFavorito = (texto_laudo) => {
    this.regulacao.laudo_exame = texto_laudo;
    this.visualizandoFavorito = false;
  }

  realizarRegulacaoConsultaExame = () => {
    if (this.regulacao.estabelecimento_id_executor == 0) {
      this.alerts.notifyError({
        body: "Favor informar a unidade executante.",
        title: 'Erro'
      });
      return false;
    }

    if (this.regulacao.data_realizacao == null || this.regulacao.data_realizacao.length != 8) {
      this.alerts.notifyError({
        body: "Favor informar data de realizacao valida.",
        title: 'Erro'
      });
      return false;
    }
    if (this.regulacao.hora_realizacao == null || this.regulacao.hora_realizacao.length != 4) {
      this.alerts.notifyError({
        body: "Favor informar a hora de realizacao.",
        title: 'Erro'
      });
      return false;
    }
    if (this.regulacao.profissional_id_realizacao == 0) {
      this.alerts.notifyError({
        body: "Favor informar o profissional executante.",
        title: 'Erro'
      });
      return false;
    }

    this.regulacao.profissional_id_realizacao = Number(this.regulacao.profissional_id_realizacao);
    var data_realizacao = this.regulacao.data_realizacao[4] + this.regulacao.data_realizacao[5] + this.regulacao.data_realizacao[6] + this.regulacao.data_realizacao[7] + "-" + this.regulacao.data_realizacao[2] + this.regulacao.data_realizacao[3] + "-" + this.regulacao.data_realizacao[0] + this.regulacao.data_realizacao[1];
    var hora_realizacao = this.regulacao.hora_realizacao[0] + this.regulacao.hora_realizacao[1] + ":" + this.regulacao.hora_realizacao[2] + this.regulacao.hora_realizacao[3] + ":00";
    this.regulacao.realizado_em = data_realizacao + "T" + hora_realizacao;
    delete this.regulacao.data_realizacao;
    delete this.regulacao.hora_realizacao;
    this.regulacao.status = "REALIZADO";
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var caminho = "";
    var obj = {};
    if (this.tipo == 1) {
      caminho = `RegulacaoConsulta/${this.regulacao.id}`;
      if (this.regulacao.cid_subcategoria_id == 0)
        delete this.regulacao.cid_subcategoria_id;
      obj = this.regulacao;
    }
    if (this.tipo == 2) {
      caminho = `RegulacaoExame/${this.regulacao.id}`;
      obj = {
        "status": "REALIZADO",
        "EstabelecimentoExecutorId": this.regulacao.estabelecimento_id_executor,
        "CidSubCategoriaIdRealizacao": this.regulacao.cid_subcategoria_id_realizacao,
        "dataHoraRealizado": this.regulacao.realizado_em,
        "observacao": this.regulacao.observacao_realizacao,
        "LaudoExame": this.regulacao.laudo_exame,
        "ProfissionalIdRealizacao": Number(this.regulacao.profissional_id_realizacao)
      }
      this.service.Patch_body(`${caminho}`, obj).then(
        result => {
          Swal.fire('Sucesso', 'Laudo enviado com sucesso', 'success').then(
            result => {
              this.regulacao = {};
              //this.tipo = 0;
              this.fecharModal();
            }
          )
          return
        },
        error => {
          Swal.fire('Error', error.error.messagem, 'error')
          return
        }
      )
      return
    }
    this.service.Put(caminho, obj).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {
              if (this.tipo == 1) {
                this.fecharModal();
                this.resultPostModalRealization.emit();
              }
              if (this.tipo == 2) {
                Swal.close();
                Swal.fire({
                  icon: 'success',
                  title: 'Sucesso!',
                  html: `Operação realizada com sucesso!`
                }).then(
                  result => {
                    if (result.isConfirmed) {
                      this.fecharModal();
                      this.resultPostModalRealization.emit();
                    }
                  }
                );
              }
            }
          }
        );
      }, 1000);
    }).catch(res => {
      Swal.close()
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  FormataHora = () => {
    if (this.regulacao.hora_realizacao != null) {
      if (this.regulacao.hora_realizacao.length == 1)
        this.regulacao.hora_realizacao = `0${this.regulacao.hora_realizacao}00`;
      if (this.regulacao.hora_realizacao.length == 2)
        this.regulacao.hora_realizacao = `${this.regulacao.hora_realizacao}00`;
      if (this.regulacao.hora_realizacao.length == 3)
        this.regulacao.hora_realizacao = `${this.regulacao.hora_realizacao}0`;
    }
  }

  fecharModal = () => {
    //this.unidades = [];
    //this.profissionais = [];
    //this.cids = [];
    //this.favoritos = [];
    //this.regulacao = {};
    //this.tipo = 0;
    //this.visualizandoFavorito = false;
    $("#modalRealizacaoEncaminhamento").toggleClass("show");
    $("#modalRealizacaoEncaminhamento").css("display", "none");
    //this.fecharModalLaudo.emit()
  }

  selecionarCid(item) {
    if (item != null && item.id != null)
      this.regulacao.cid_subcategoria_id_realizacao = item.id;
    else
      this.regulacao.cid_subcategoria_id_realizacao = 0;
  }

  getListProfessional() {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Profissional`).then(res => {
      this.profissionais = res;
      this.regulacao.profissional_id_realizacao = 0;
      //for (let item of this.listProfessional) {
      //  this.global.applyMask(item)
      //}
      Swal.close();
    }).catch(res => {
      Swal.close();
      alert(res)
    })
  }

}
