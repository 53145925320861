import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { Global } from 'src/app/global';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
declare var Simditor: any;
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';

@Component({
  selector: 'app-history-of-service',
  templateUrl: './history-of-service.component.html',
  styleUrls: ['./history-of-service.component.css']
})
export class HistoryOfServiceComponent implements OnChanges {

  @Input() prontuario: any = {};
  @Input() listaHistoricoAtendimentos: any = [];

  global_: any;
  dadosImpresso: any = null;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  constructor(private authService: AuthService,
    private global: Global,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService) {
    this.global_ = global;
  }

  ngOnChanges() {

  }

  imprimirFaa(item) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Prontuario/${item.id}/ImpressoEmergencia`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('impresso_faa');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
      }
    });
  }



  gerarTabelaAlegia(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <span>${element.tipo_alergia} - ${element.medicamento_codigo_catmat ? element.medicamento_codigo_catmat : '-'} - ${element.medicamento_principio_ativo ? element.medicamento_principio_ativo : '-'}</span>
        <br/>  
  `
    });
    return td;
  }

  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <span>${element.cid_subcategoria_codigo} - ${element.cid_subcategoria_nome}</span>
        <br/>     
  `
    });
    return td;
  }

  gerarTabelaSolicitacoes(lista) {
    var td = ``;
    lista.forEach(item => {
      item.procedimentos.forEach(element => {
        td +=
          `
        <tr>
          <td>
            <p>${element.protocolo}</p>
          </td>
          <td>
            <p>${element.sigtap_codigo}</p>
          </td>
          <td>
            <p>${element.sigtap_nome}</p>
          </td>
          <td>
            <p>${element.tipo_procedimento}</p>
          </td>
          <td>
            <p>${element.criticidade}</p>
          </td>
          <td>
            <p>${element.status}</p>
          </td>
        </tr>
  `
      })
    });
    return td;
  }

  gerarTabelaPrescricao(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <tr>
          <td>
            <p>${element.protocolo}</p>
          </td>
          <td>
            <p>${element.presenca_profissional_nome} ${element.presenca_profissional_conselho_classe_Sigla}-${element.presenca_profissional_conselho_estado_sigla} ${element.presenca_profissional_numero_registro}</p>
          </td>
          <td>
          <p>${this.global.formatDateTime(element.prescrito_em)}</p>
          </td>
          <td>
            <p>${element.texto}</p>
          </td>
          <td>
            <p></p>
          </td>
      </tr>
  `
    });
    return td;
  }

  prepararImpressoHtml(dadosImpresso, apac = false) {
    var html = `
    <html lang="en">
     ${this.global.headerImpresso(dadosImpresso)}
      <body>
        <div>
        ${this.global.cabecalhoImpresso(dadosImpresso, "ATENDIMENTO DE URGÊNCIA")}
          <table class="apac">
            <thead></thead>
            <tbody>
              <caption>
                <h4>CLASSIFICAÇÃO DE RISCO</h4>
              </caption>
              <tr rowspan="2">
                <td colspan="1">
                  <p>Risco: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.classificacao_prioridade : ''}</p>                  
                </td>
                <td colspan="4">
                  <p>Profissional: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.presenca_profissional_nome : ""}</p>                  
                </td>
                <td colspan="1">
                  <p>Data: ${dadosImpresso.conteudo.classificacao_risco ? this.global.formatDateTime(dadosImpresso.conteudo.classificacao_risco.classificado_em) : ""}</p>                  
                </td>
              </tr>
              <tr rowspan="2">
                <td colspan="1">
                  <p>FR: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.frequencia_respiratoria : ""} mrpm</p>                  
                </td>
                <td colspan="1">
                  <p>SatO2: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.saturacao_oxigenio : ""} %</p>                  
                </td>
                <td colspan="1">
                  <p>PA: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.sistole : ""} / ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.diastole : ""} mmHg</p>                  
                </td>
                <td colspan="1">
                 <p>FC: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.frequencia_cardiaca : ""} bpm</p>                  
                </td>
                <td colspan="1">
                  <p>T: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.temperatura : ""}ºC</p>                  
                </td>
                <td colspan="1">
                  <p>DX: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.glicemia_capilar : ""} mg/dl</p>                  
                </td>
              </tr>
             
              <tr>
                <td colspan="1">
                  <p>Dor: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.escala_dor ? dadosImpresso.conteudo.classificacao_risco.escala_dor + "/6" : "0/6" : ""}</p>                  
                </td>
                <td colspan="5">
                  <p>Prioridade: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.classificacao_prioridade : ""} ${dadosImpresso.conteudo.classificacao_risco && dadosImpresso.conteudo.classificacao_risco.prioridade ? dadosImpresso.conteudo.classificacao_risco.tipo_prioridade : ""}</p>                  
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
          <table>
            <tr colspan="10">
              <td class="title">
                <h4>ATENDIMENTO CLÍNICO</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr colspan="10">
              <td class="cabecalho">
                <h4>DADOS CLÍNICOS (ANAMNESE / EXAME CLÍNICO):</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <h1>${dadosImpresso.conteudo.anamnese.length > 0 ? dadosImpresso.conteudo.anamnese[0].queixa : ''}</h1>
              </td>
            </tr>
          </table>
          <table>
            <tr>
                <td>
                    <h4>Alergias</h4>
                    ${this.gerarTabelaAlegia(dadosImpresso.conteudo.alergia)}                 
                </td>
                <td>
                    <h4>Cids</h4>
                    ${this.gerarTabela(dadosImpresso.conteudo.cid_informado)}                    
                </td>
              
            </tr>         
        </table>         
          <table>
            <tr colspan="10">
              <td class="cabecalho">
                <h4>EXAMES / PROCEDIMENTOS:</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Protocolo </b>
              </td>   
              <td>
                <b>Código </b>
              </td>            
              <td>
                <b>Procedimento</b>
              </td>
              <td>
                <b>Solicitação </b>
              </td>
              <td>
                <b>Criticidade </b>
              </td>
              <td>
                <b>Status </b>
              </td>
            </tr>
            <tr> ${this.gerarTabelaSolicitacoes(dadosImpresso.conteudo.solicitacao)} </tr>
          </table>
          <table>
            <tr colspan="10">
              <td class="cabecalho">
                <h4>PRESCRIÇÃO:</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b> Protocolo </b>
              </td>
              <td>
                <b> Profissional </b>
              </td>
              <td>
                <b>Data da Prescrição </b>
              </td>
              <td>
                <b> Descrição </b>
              </td>
              <td>
                <b> Data da Administração </b>
              </td>
            </tr>
            <tr> ${this.gerarTabelaPrescricao(dadosImpresso.conteudo.prescricao_urgencia)} </tr>
          </table>
          ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
          <table>
            <tr colspan="10">
              <td class="title">
                  <h4>ANUÊNCIA DO USUÁRIO</h4>
              </td>
            </tr>
          </table>
     
          <table>
            <tr>
              <td class="border-top">                 
                  <p>Eu, ${dadosImpresso.cidadao.nome}, declaro que os dados cadastrais informados são a expressão da
                      verdade e fui atendido na data e hora registrada neste documento, bem como pelos profissionais que o
                      subscrevem.</p>
                      <div class="line-assinatura"></div>
                  <p>Assinatura do Paciente ou responsável </p>
              </td>
            </tr>
          </table>      
        </div>
      </body>
    </html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

}
