import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-exams-group',
  templateUrl: './list-exams-group.component.html',
  styleUrls: ['./list-exams-group.component.css']
})
export class ListExamsGroupComponent implements OnInit {

  listaGrupoExames: any = []
  filtro: string = "";  
  ativo: boolean = true;

  listaStatus: any = [
    {id: 1, status: true, desc: 'Ativo'},
    {id: 2, status: false, desc: 'Inativo'}
  ]

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.listExamsGroup('')
  }

  filtroLista() {
    if (this.listaGrupoExames.length === 0) {
      return this.listaGrupoExames;
    }
    return this.listaGrupoExames.filter(g => g.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  listExamsGroup = (txt:any) => {
    this.service.Get(`GrupoSigtap${txt}`).then(
      result => {
        this.listaGrupoExames = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }

  obterListaFiltros = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    var txt = ''

    if (this.filtro && txt == ''){
      txt += `?nome=${this.filtro}`
    }

    if (this.filtro && txt != ''){
      txt += `&ativo=${this.ativo}`
    }
    
    if (!this.filtro && txt == ''){
      txt += `?ativo=${this.ativo}`
    }

    this.listExamsGroup(txt)
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse grupo de exames?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`GrupoSigtap/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaFiltros();
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

}
