import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import Swal from 'sweetalert2';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { AuthService } from '../../../service/auth/auth.service';

@Component({
  selector: 'app-list-arrival-time-setting',
  templateUrl: './list-arrival-time-setting.component.html',
  styleUrls: ['./list-arrival-time-setting.component.css']
})
export class ListArrivalTimeSettingComponent implements OnInit {

  configAgendaList: any = [];

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService) { }

  ngOnInit() {
    this.obterListaAgenda();
  }

  obterListaAgenda = () => {
    return new Promise((resolve, reject) => {
      Swal.fire('Favor aguarde');
      Swal.showLoading();
      this.service.Get(`ConfiguracaoHorarioChegada`).then(res => {
        this.configAgendaList = res;
        resolve('ok');
        Swal.close();
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close();
        this.alerts.notifyError({
          body: `${res.erro.erros[0].messagem}`,
          title: 'Erro'
        });
      })
    })
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} essa Configuração de horario de chegada?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`ConfiguracaoHorarioChegada/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaAgenda();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  formataHora = (hora) => {
    if (hora == null && hora.length != 8)
      return;
    return hora.split(':')[0] + ':' + hora.split(':')[1];

  }

}
