import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { Global } from '../../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
declare var Simditor: any;
@Component({
  selector: 'app-exams-history',
  templateUrl: './exams-history.component.html',
  styleUrls: ['./exams-history.component.css']
})
export class ExamsHistoryComponent implements OnInit {

  historicoAtendimentos: any = [];
  listaSolicitacaoExames: any = [];
  usuario: any = {};
  presenca: any;
  cidadao_id: any;
  atendimento_id: any;
  uid: any;
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];
  exameSelecionado: any = {};
  solicitacaoExame: any = {};
  @Input() listaHistorico: any = [];
  @Input() atendimento: any = {};
  exame: any = {};

  fileToUpload: File | null = null;
  fileNameTOSave: any = "";
  tipoResultado: any = 1;
  resultadoExame: any = null;
  dadosImpresso: any;
  visualizar_prontuario: any = false;
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
    Simditor.locale = 'en-US';
    var that = this;
    setTimeout(() => {
      var motivo = new Simditor({
        textarea: $('#exame_coleta_historico'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
    }, 1000);
  }

  ngOnChanges() {
    this.historicoAtendimentos = this.listaHistorico
  }

  obterlistaSolicitacaoExames = () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    this.service.Get(`Atendimento/${uid}/Procedimento`).then(
      result => {
        this.listaHistorico = result;
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  visualizarExame = (item) => {
    this.solicitacaoExame = item;
    $("#modalHistoricoExame").toggleClass("show");
    $("#modalHistoricoExame").css("display", "block");
    $("#modalColetaHistoricoExame").css("display", "none");
    $("#modalAvaliarHistoricoExame").css("display", "none");
    $("#modalCancelarHistoricoExame").css("display", "none");
    $("#modalRealizarHistoricoExame").css("display", "none");
  }

  fecharModal = () => {
    this.solicitacaoExame = null;
    $("#modalHistoricoExame").toggleClass("show");
    $("#modalHistoricoExame").css("display", "none");
  }

  coleta = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#exame_coleta_historico')
    });
    editor.setValue('')
    $("#modalColetaHistoricoExame").toggleClass("show");
    $("#modalColetaHistoricoExame").css("display", "block");
  }

  fecharModalColeta = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_coleta_historico')
    });
    editor.setValue('');
    $("#modalColetaHistoricoExame").toggleClass("show");
    $("#modalColetaHistoricoExame").css("display", "none");
  }

  confirmarColeta = () => {
    if (!$('#exame_coleta_historico').val()) {
      return;
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = {
      "presenca_id": this.presenca.id,
      "texto": $('#exame_coleta_historico').val()
    }
    this.service.Post(`Procedimento/${this.exameSelecionado.id}/Coletado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.carregarHistorico();
            this.obterSolicitacao();
            this.fecharModalColeta();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  avaliar = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#exame_avaliar_historico')
    });
    editor.setValue('')
    $("#modalAvaliarHistoricoExame").toggleClass("show");
    $("#modalAvaliarHistoricoExame").css("display", "block");
  }

  fecharModalAvaliar = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_avaliar_historico')
    });
    editor.setValue('');
    $("#modalAvaliarHistoricoExame").toggleClass("show");
    $("#modalAvaliarHistoricoExame").css("display", "none");
  }

  confirmarAvaliar = () => {
    if (!$('#exame_avaliar_historico').val()) {
      return;
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = {
      "presenca_id": this.presenca.id,
      "texto": $('#exame_avaliar_historico').val()
    }
    this.service.Post(`Procedimento/${this.exameSelecionado.id}/Avaliado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.carregarHistorico();
            this.obterSolicitacao();
            this.fecharModalAvaliar();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  cancelar = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#exame_cancelamento_historico')
    });
    editor.setValue('');
    $("#modalCancelarHistoricoExame").toggleClass("show");
    $("#modalCancelarHistoricoExame").css("display", "block");
  }

  fecharModalCancelamento = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_cancelamento_historico')
    });
    editor.setValue('');
    $("#modalCancelarHistoricoExame").toggleClass("show");
    $("#modalCancelarHistoricoExame").css("display", "none");
  }

  confirmarCancelar = () => {
    if (!$('#exame_cancelamento_historico').val()) {
      return;
    }
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja cancelar esse exame?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();

          var obj = {
            "presenca_id": this.presenca.id,
            "texto": $('#exame_cancelamento_historico').val()
          }
          this.service.Post(`Procedimento/${this.exameSelecionado.id}/Cancelado`, obj).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.carregarHistorico();
                  this.obterSolicitacao();
                  this.fecharModalCancelamento();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.messagem
            });
          })
        }
      }
    );
  }

  realizar = (item) => {
    this.exameSelecionado = item;
    this.exame = {};
    var editor = new Simditor({
      textarea: $('#exame_resultado_historico')
    });
    editor.setValue('')
    $("#modalRealizarHistoricoExame").toggleClass("show");
    $("#modalRealizarHistoricoExame").css("display", "block");
  }

  fecharModalRealizar = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_resultado_historico')
    });
    editor.setValue('');
    $("#modalRealizarHistoricoExame").toggleClass("show");
    $("#modalRealizarHistoricoExame").css("display", "none");
  }


  confirmarResultado = () => {
    if (this.tipoResultado == 1) {
      if (!$('#exame_resultado_historico').val() && !this.exame.resultado_em) {
        return;
      }
    }

    if (!this.exame.resultado_em) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: 'Favor validar a data o resultado'
      });
      return
    }

    if (this.tipoResultado == 2) {
      if (!this.fileNameTOSave) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: 'Favor validar se o arquivo foi enviado'
        });
        return
      }
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = [
      {
        "presenca_id": this.presenca.id,
        "resultado": this.tipoResultado == 1 ? $('#exame_resultado_historico').val() : this.fileNameTOSave,
        "resultado_em": `${this.exame.resultado_em.split("/")[2]}-${this.exame.resultado_em.split("/")[1]}-${this.exame.resultado_em.split("/")[0]}`,
        "tipo_resultado": this.tipoResultado == 1 ? "TEXTO" : "ARQUIVO"
      }
    ]
    this.service.Post(`Procedimento/${this.exameSelecionado.id}/Resultado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.obterSolicitacao();
            this.fecharModalRealizar();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }


  cancelarSolicitacao = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#solicitacao_cancelamento_historico')
    });
    editor.setValue('')
    $("#modalCancelarHistoricoSolicitacao").toggleClass("show");
    $("#modalCancelarHistoricoSolicitacao").css("display", "block");
  }

  fecharModalCancelamentoSolictacao = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#solicitacao_cancelamento_historico')
    });
    editor.setValue('');
    $("#modalCancelarHistoricoSolicitacao").toggleClass("show");
    $("#modalCancelarHistoricoSolicitacao").css("display", "none");
  }

  confirmationAtivarDesativar = (id, ativo) => {
    if (!$('#solicitacao_cancelamento_historico').val()) {
      return;
    }
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja cancelar essa solicitacao?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();

          var obj = {
            "presenca_id": this.presenca.id,
            "texto": $('#solicitacao_cancelamento_historico').val()
          }
          this.service.Post(`Solicitacao/${this.exameSelecionado.id}/Cancelado`, obj).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.carregarHistorico();
                  this.fecharModalCancelamentoSolictacao();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.messagem
            });
          })
        }
      }
    );
  }

  obterListaExames = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.uid}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.historicoAtendimentos = result
        Swal.close()
      }
    )
  }

  carregarHistorico() {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Cidadao/${this.atendimento.cidadao_id}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.listaHistorico = result
        Swal.close()
      }
    )
  }

  obterSolicitacao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Solicitacao/${this.solicitacaoExame.id}`).then(
      result => {
        this.solicitacaoExame = result;
        Swal.close()
      }
    )
  }


  limparCampos(tipo) {
    this.tipoResultado = tipo;
    this.iniciarFileInput();
  }

  handleFileInput(files: FileList) {
    this.fileNameTOSave = null;
    this.fileToUpload = files.item(0);
  }

  enviarArquivo = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.postFile(`Arquivo/Solicitacao/Upload`, this.fileToUpload).then(
      result => {
        Swal.close();
        if (result) {
          var regex = /^"|"$/g;
          var nomeMidia = result["_body"].replace(regex, "");
          this.fileNameTOSave = nomeMidia;
          // // this.salvarMidia(obj);
          // this.solicitacaoExame = result;
        }
      }
    ).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  cancelarArquivo = () => {
    this.iniciarFileInput();
  }

  iniciarFileInput = () => {
    this.fileNameTOSave = null;
    this.fileToUpload = null;
    $("#fileIDHistorico").val('');
    setTimeout(() => {
      const dropArea_ = document.querySelector(".drop_box__history"),
        button = dropArea_.querySelector("button"),
        dragText = dropArea_.querySelector("header"),
        input = dropArea_.querySelector("input"),
        dropArea = document.querySelector(".drop_box_history");
      let file;
      var filename;

      button.onclick = () => {
        input.click();
      };
      dropArea.innerHTML = "";
      input.addEventListener("change", function (e: any) {
        var fileName = e.target.files[0].name;
        let filedata = `
    <form>
    <div class="form">
    <h4>${fileName}</h4>
    </div>
    </form>`;
        dropArea.innerHTML = filedata;
      });
    }, 500);
  }

  visualizarResultado(item) {
    this.exameSelecionado = item;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Procedimento/${this.exameSelecionado.id}/Resultado`).then((res) => {
      Swal.close();
      if (res[0]['tipo_resultado'] == 'ARQUIVO') {
        window.open(res[0]['resultado_link'], '_blank');
      } else {
        this.resultado(res[0])
      }

    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  resultado = (item) => {
    this.resultadoExame = item;
    $("#modalResultadoHistoricoExame").toggleClass("show");
    $("#modalResultadoHistoricoExame").css("display", "block");
  }

  fecharModalResultado = () => {
    this.exameSelecionado = null;
    $("#modalResultadoHistoricoExame").toggleClass("show");
    $("#modalResultadoHistoricoExame").css("display", "none");
  }

  imprimirDado(item: any) {
    this.novoImpressoExame(item.id);
  }

  novoImpressoExame(id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.PostImpresso(`Documento/impresso-pdf/SOLICITACAO_DE_EXAME.frx`, [
      {
        "nome": "id",
        "valor": id.toString()
      }
    ]).then(res => {
      if (res) {
        var link: any = res;
        Swal.close();
        window.open(link, '_blank');
      }
    });
  }


  carregarDadosImpresso(solicitacao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Solicitacao/${solicitacao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        this.authService.setDadosImpresso(this.dadosImpresso);
        this.authService.setTipoImpresso('solicitacao_impresso');
        Swal.close();
        setTimeout(() => {
          window.print()
        }, 750)
      }
    });
  }
}
