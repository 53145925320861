import { Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-modal-classificacao-risco',
  templateUrl: './modal-classificacao-risco.component.html',
  styleUrls: ['./modal-classificacao-risco.component.css']
})
export class ModalClassificacaoRisco implements OnChanges {

  @Output() resultEmiter: EventEmitter<any> = new EventEmitter();

  classificacao_r: any = {};
  listaTipoPrioridade: any = [
    {
      id: 1,
      nome: `Gestante`
    },
    {
      id: 2,
      nome: `Idoso`
    },
    {
      id: 3,
      nome: `Necessidades Especiais`
    },
    {
      id: 4,
      nome: `Outros`
    },
  ];
  constructor() { }

  ngOnChanges() {
    // this.classificacao_r.tipo_prioridade = 0;
  }

  // close modal 
  fecharModal = () => {
    $("#modalClassificacaoRisco").toggleClass("show");
    $("#modalClassificacaoRisco").css("display", "none");
  }

  changePrioridade() {
    this.resultEmiter.emit(this.classificacao_r);
  }

}
