import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.css']
})
export class RegulationComponent implements OnInit {
  menus: any = [];
  constructor(private authService: AuthService,private router: Router) {
    this.authService.menus.subscribe((res) => {
      if (res) {        
        this.menus = res.filter(x=>x.id == 3)[0];
      }
    })
  }

  ngOnInit() {
  }

  goToLink(url){
    this.router.navigate([`/${url}`]);
  }
}
