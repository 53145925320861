import { Component, OnInit, OnChanges, Inject, Input, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';

import { Global } from '../../global';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-modal-auth-exams',
  templateUrl: './modal-auth-exams.component.html',
  styleUrls: ['./modal-auth-exams.component.css']
})
// export class ModalAuthExamsComponent implements OnInit {
export class ModalAuthExamsComponent implements OnChanges {

  @Input() autorizarRegulacao: any;

  usuario: any = [];

  autorizar: any = [];
  dadosPaciente: any = {};
  dadosSolicitacao: any = [];
  dadosEstabelecimentoSolicitante: any = [];
  dadosCidadao: any = [];
  dadosUsuario: any = [];
  listaProcedimentos: any = [];

  listaCriticidade: any = [];
  listaConselhos: any = [];
  listaEstabelecimentos: any = [];
  listaCid: any = [];
  listaCidSub: any = [];
  listaStatus: any = [];
  dadosEquipe: any = [];

  autorizacao: any = [];
  errorLog: any = [];
  objRegulcao: any = [];

  @Output() reloadLista = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }

  async getUser() {
    var currentUser = await localStorage.getItem('@DigtVentry:currentUser');
    var user = JSON.parse(currentUser);
    if (user) {
      this.authService.saveUser(user);
    } else {
      this.authService.logoutUser();
    }
  }

  ngOnChanges() {
    //this.getUser()
    this.autorizacao = []
    var currentUser = localStorage.getItem('@DigtVentry:currentUser');
    this.usuario = JSON.parse(currentUser);
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.autorizar = this.autorizarRegulacao;
    this.dadosEquipe = this.autorizarRegulacao[0]
    Swal.close()
    if (this.autorizar.length > 0) {
      this.fillData()
    }
  }

  fillData = () => {
    this.dadosPaciente = this.autorizar[0].Cidadao
    this.dadosPaciente.nascido_em = this.global.dateMask(this.dadosPaciente.nascido_em)
    this.dadosSolicitacao = this.autorizar;
    for (let dado of this.dadosSolicitacao) {
      this.service.Get(`CidSubCategoria/${dado.cid_sub_categoria_id}`).then(
        result => {
          dado.CidSubCategoria = result
        }
      )
    }
    for (let dado of this.dadosSolicitacao) {
      this.service.Get(`SigTap/${dado.sigtap_id}`).then(
        result => {
          dado.SigTap = result
        }
      )
    }
    this.dadosEstabelecimentoSolicitante
    this.dadosUsuario = this.autorizar[0].ProfissionalSolicitante
    var autorizacao = []
    for (let item of this.autorizar) {
      autorizacao.push(item)
    }
    for (let item of autorizacao) {
      this.listaProcedimentos = item.ListaSigTap
    }
    this.getData().then(
      result => {
        this.aplicarMascaras().then(
          result => {
            this.obterInfoEstabelecimentoSolicitante().then(
              result => {
                this.dadosEstabelecimentoSolicitante = result
                Swal.close()
              }
            )
          }
        )
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  getData = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`RegulacaoExame/Criticidade`).then(
        result => {
          this.listaCriticidade = result;
          this.listaCriticidade.unshift({ id: 0, nome: 'Selecione..' })
          for (let criticidade of this.listaCriticidade) {
            if (criticidade.nome == this.autorizar[0].criticidade) {
              this.autorizacao.criticidade = criticidade.id
            }
          }
          this.service.Get(`ConselhoClasse`).then(
            result => {
              this.listaConselhos = result;
              this.listaConselhos.unshift({ id: 0, nome: 'Selecione..' })
              for (let conselho of this.listaConselhos) {
                if (conselho.id == this.autorizar[0].ProfissionalSolicitante.conselho_id) {
                  this.autorizacao.orgao_emissor = conselho.id
                  this.autorizacao.numero_orgao_emissor = this.autorizar[0].ProfissionalSolicitante.numero_registro
                }
              }
              this.service.Get(`Estabelecimento`).then(
                result => {
                  this.listaEstabelecimentos = result
                  this.listaEstabelecimentos.unshift({ id: 0, nome: 'Selecione..' })
                  this.autorizacao.estabelecimento_executante = 0;
                  this.service.Get(`RegulacaoExame/StatusSolicitacao`).then(
                    result => {
                      this.listaStatus = result;
                      this.listaStatus.unshift({ id: 0, nome: 'Selecione..' })
                      resolve('Todos os dados foram carregados')
                    },
                    error => {
                      reject(error)
                    }
                  )
                },
                error => {
                  reject(error)
                }
              )
            },
            error => {
              reject(error)
            }
          )
        },
        error => {
          reject(error)
        })
    })
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  ajustaInfoCid = () => {
    return new Promise((resolve) => {
      for (let item of this.listaCidSub) {
        if (item.id === this.dadosSolicitacao.cid_sub_categoria_id) {
          this.dadosSolicitacao.cid_sub_categoria_nome = item.nome
          this.dadosSolicitacao.cid_sub_categoria_nome50 = item.nome50
          this.dadosSolicitacao.cid_sub_categoria_codigo = item.codigo
        }
      }
      resolve('CID ajustado')
    })
  }

  aplicarMascaras = () => {
    return new Promise((resolve) => {
      if (this.dadosPaciente.cpf) {
        this.dadosPaciente.cpf = this.global.cpfMask(this.dadosPaciente.cpf)
      }
      if (this.dadosPaciente.cns) {
        this.dadosPaciente.cns = this.global.cnsMask(this.dadosPaciente.cns)
      }
      resolve('Máscaras aplicadas com sucesso')
    })
  }

  obterInfoEstabelecimentoSolicitante = () => {
    return new Promise((resolve) => {
      for (let item of this.listaEstabelecimentos) {
        if (item.id === this.autorizar[0].estabelecimento_solicitante_id) {
          resolve(item)
        }
      }
    })
  }

  updateRegulacao = (statusRegulacao: any) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading()
    this.objRegulcao = []
    this.validadeAPAC().then(
      result => {
        var date = new Date();
        var today = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        for (let item of this.autorizar) {
          var submit = {
            "ativo": true,
            "id": item.id,
            "observacao": item.observacao
          }
          if (statusRegulacao == 2) {
            submit['autorizado'] = true;
            submit['status'] = 'AUTORIZADO'
            submit['data_hora_autorizado'] = today;
            if (item.justificativa) {
              submit['justificativa_autorizacao'] = item.justificativa;
            }
          }
          if (statusRegulacao != 2) {
            submit['autorizado'] = false;
          }
          if (statusRegulacao == 1) {
            submit['status'] = 'PENDENTE'
          }
          if (statusRegulacao == 5) {
            submit['status'] = 'CANCELADO';
            submit['data_hora_cancelamento'] = today;
            if (item.justificativa) {
              submit['justificativa_cancelamento'] = item.justificativa;
            }
            //submit['ativo'] = false
          }
          if (this.autorizacao.criticidade && this.autorizacao.criticidade != 0) {
            submit['criticidade'] = this.autorizacao.criticidade
          }
          if (!this.autorizacao.criticidade) {
            submit['criticidade'] = item.criticidade
          }
          submit['data_hora_solicitacao'] = item.data_hora_solicitacao;
          submit['autorizado'] = true;
          submit['cidadao_id'] = item.Cidadao.id;
          submit['estabelecimento_solicitante_id'] = item.estabelecimento_solicitante_id;
          if (this.autorizacao.estabelecimento_executante) {
            submit['estabelecimento_executor_id'] = parseInt(this.autorizacao.estabelecimento_executante);
          }
          submit['usuario_id'] = parseInt(this.usuario.usuario_id);
          submit['periodo_validade_apac'] = this.autorizacao.validadeApac;
          submit['justificativa_solicitacao'] = item.justificativa_solicitacao;
          submit['cid_sub_categoria_id'] = item.cid_sub_categoria_id;
          //submit['agenda_hora_id'] = 0;
          submit['sigtap_id'] = item.sigtap_id;
          submit['laudo_exame'] = '';
          submit['profissional_solicitante_id'] = item.profissional_solicitante_id;

          submit['nome_equipe'] = item.nome_equipe;
          submit['ine_equipe'] = item.ine_equipe;
          submit['nome_acs'] = item.nome_acs;

          this.objRegulcao.push(submit)
        }
        if (this.objRegulcao.length === this.autorizar.length) {
          this.updateRecurse(0, this.objRegulcao)
        }
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  validadeAPAC = () => {
    return new Promise((resolve, reject) => {
      if (this.autorizacao.validadeApac) {
        var data = this.autorizacao.validadeApac.split('/')[2] + '-' + this.autorizacao.validadeApac.split('/')[1] + '-' + this.autorizacao.validadeApac.split('/')[0]
        var d = new Date(data)
        if (d.toDateString() == 'Invalid Date') {
          reject('A data de validade APAC informada está incorreta')
        }
        else {
          resolve(true)
        }
      }
      else {
        resolve(true)
      }
    })
  }

  listaItensCriticidade = (data) => {
    var nome = ''
    var lista = [
      { id: 1, nome: "ELETIVO" },
      { id: 2, nome: "PRIORIDADE" },
      { id: 3, nome: "URGENCIA" },
      { id: 4, nome: "EMERGENCIA" }
    ]
    for (let item of lista) {
      if (item.id == data) {
        return item.nome
      }
    }
  }

  // close modal 
  fecharModal = () => {
    this.objRegulcao = []
    this.autorizacao = []
    $("#modalAutorizarExame").toggleClass("show");
    $("#modalAutorizarExame").css("display", "none");
    this.reloadLista.emit();
    Swal.close()
    this.autorizacao = []
  }

  updateRecurse = (index, obj) => {
    try {
      if (index === obj.length) {
        this.exibeFinal()
        return
      }
      this.service.Put(`RegulacaoExame/${obj[index].id}`, obj[index]).then(
        result => {
          this.updateRecurse(index + 1, obj)
        },
        error => {
          this.errorLog = error.error.erros;
          this.updateRecurse(index + 1, obj)
        }
      )
    } catch (e) {
      this.errorLog = e.error.erros;
      this.updateRecurse(index + 1, obj)
    }
  }

  exibeFinal = () => {
    if (this.errorLog.length > 0) {
      var text: any = []
      for (let log of this.errorLog) {
        text.push('<p>' + log.messagem + '</p>')
      }
      Swal.fire({
        title: 'Atenção',
        html: text,
        icon: 'warning'
      })
      return
    }
    else {
      Swal.fire('Sucesso', 'Procedimentos alterados com sucesso.', 'success').then(
        result => {
          this.fecharModal();
        }
      )

    }
  }

}
