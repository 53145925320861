import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../../service/auth/auth.service'

import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-maker',
  templateUrl: './add-maker.component.html',
  styleUrls: ['./add-maker.component.css']
})
export class AddMakerComponent implements OnInit {

  fabricante: any = {};
  presencaUser: any = {};
  uid: string = '';

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.fabricante.internacional = false;
    this.fabricante.ativo = true;
    this.presencaUser = JSON.parse(localStorage.getItem('@DigtVentry:presencaUser'));

    this.uid = this.route.snapshot.paramMap.get('uuid');
    if (this.uid) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterDadoFabricante()
    }
  }

  obterDadoFabricante = () => {
    this.service.Get(`Fabricante/${this.uid}`).then(
      result => {
        this.fabricante = result;
        if (this.fabricante.telefone) {
          this.fabricante.telefone = this.global.telMask(this.fabricante.telefone)
        }
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error');
      }
    )
  }

  goBack() {
    this.location.back();
  }

  mudarNacionalidade = () => {
    this.fabricante.internacional = !this.fabricante.internacional;

    if (this.fabricante.internacional) {
      this.fabricante.pais = "Brasil";
    }
  }

  mudarStatus = () => {
    this.fabricante.ativo = !this.fabricante.ativo
  }

  salvarFabricante = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    if (!this.fabricante.nome || this.fabricante.nome == undefined) {
      Swal.fire('Erro', 'O nome do fabricante deve ser informado', 'error')
      return
    }

    if (!this.fabricante.cnpj || this.fabricante.cnpj == undefined) {
      Swal.fire('Erro', 'O CNPJ do fabricante deve ser informado', 'error')
      return
    }

    if (this.fabricante.cnpj) {
      this.fabricante.cnpj_unmask = this.fabricante.cnpj.replace(/\D/g, "")
    }

    if (!this.fabricante.nome_fantasia || this.fabricante.nome_fantasia == undefined) {
      this.fabricante.nome_fantasia = ''
    }

    if (!this.fabricante.telefone || this.fabricante.telefone == undefined) {
      this.fabricante.telefone = ''
      this.fabricante.telefone_unmask = ''
    }

    if (this.fabricante.telefone) {
      this.fabricante.telefone_unmask = this.fabricante.telefone.replace(/\D/g, "")
    }

    var Obj = {
      nome: this.fabricante.nome,
      cnpj: this.fabricante.cnpj_unmask,
      nome_fantasia: this.fabricante.nome_fantasia,
      internacional: this.fabricante.internacional,
      telefone: this.fabricante.telefone_unmask,
      ativo: this.fabricante.ativo,
      endereco: this.fabricante.endereco,
      pais: this.fabricante.pais,
      numero_registro: this.fabricante.numero_registro,
    }

    this.service.Post(`Fabricante`, Obj).then(
      result => {
        Swal.fire('Sucesso', 'Fabricante cadastrado com sucesso', 'success').then(
          result => {
            this.location.back()
            // this.fabricante = {}
            // this.ngOnInit()
          }
        )
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error');
      }
    )


  }

  atualizarFabricante = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    if (!this.fabricante.nome || this.fabricante.nome == undefined) {
      Swal.fire('Erro', 'O nome do fabricante deve ser informado', 'error')
      return
    }

    if (!this.fabricante.cnpj || this.fabricante.cnpj == undefined) {
      Swal.fire('Erro', 'O CNPJ do fabricante deve ser informado', 'error')
      return
    }

    if (this.fabricante.cnpj) {
      this.fabricante.cnpj_unmask = this.fabricante.cnpj.replace(/\D/g, "")
    }

    if (!this.fabricante.nome_fantasia || this.fabricante.nome_fantasia == undefined) {
      this.fabricante.nome_fantasia = ''
    }

    if (!this.fabricante.telefone || this.fabricante.telefone == undefined) {
      this.fabricante.telefone = ''
      this.fabricante.telefone_unmask = ''
    }

    if (this.fabricante.telefone) {
      this.fabricante.telefone_unmask = this.fabricante.telefone.replace(/\D/g, "")
    }

    var Obj = {
      nome: this.fabricante.nome,
      cnpj: this.fabricante.cnpj_unmask,
      nome_fantasia: this.fabricante.nome_fantasia,
      internacional: this.fabricante.internacional,
      telefone: this.fabricante.telefone_unmask,
      ativo: this.fabricante.ativo,
      endereco: this.fabricante.endereco,
      pais: this.fabricante.pais,
      numero_registro: this.fabricante.numero_registro,

    }

    this.service.Put(`Fabricante?Id=${this.uid}`, Obj).then(
      result => {
        Swal.fire('Sucesso', 'Fabricante atualizado com sucesso', 'success').then(
          result => {
            this.location.back()
            // Swal.fire('Favor aguarde')
            // Swal.showLoading()
            // this.obterDadoFabricante()
          }
        )
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error');
      }
    )


  }

}
