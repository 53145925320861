import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-prescricao-list',
  templateUrl: './prescricao-list.component.html',
  styleUrls: ['./prescricao-list.component.css']
})
export class PrescricaoListComponent implements OnInit {

  @Input() listaPrescricao: any = [];
  @Input() prontuario: any = {};
  prescricaoDados: any = {};
  dadosImpresso: any;
  global_: any;
  constructor(private global: Global,
    private authService: AuthService,
    public service: ApiService) {
    this.global_ = global;
  }

  ngOnInit() {
  }

  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(prescricao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/PrescricaoUrgencia/${prescricao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        this.authService.setDadosImpresso(this.dadosImpresso);
        this.authService.setTipoImpresso('prescricao_urgencia');
        Swal.close();
        setTimeout(() => {
          window.print()
        }, 750)
      }
    });
  }

  visualizar = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`PrescricaoUrgencia/${id}`).then((res) => {
      Swal.close();
      this.prescricaoDados = res;
      this.visualizarItem();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

  deletar = (id) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja deletar essa prescrição?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.Delete(`PrescricaoUrgencia/${id}`).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaAbaPrescricaoUrgencia();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  obterListaAbaPrescricaoUrgencia = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/PrescricaoUrgencia`).then(res => {
      this.listaPrescricao = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  visualizarItem = () => {
    $("#modalPrescricaoUrgencia").toggleClass("show");
    $("#modalPrescricaoUrgencia").css("display", "block");
  }


  fecharModal = () => {
    $("#modalPrescricaoUrgencia").toggleClass("show");
    $("#modalPrescricaoUrgencia").css("display", "none");
  }

}
