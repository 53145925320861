import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import Swal from 'sweetalert2';
import { Global } from '../../../../global';

@Component({
  selector: 'app-transfer-registration',
  templateUrl: './transfer-registration.component.html',
  styleUrls: ['./transfer-registration.component.css']
})
export class TransferRegistrationComponent implements OnInit {
  @Input() transferencia: any = {};
  @Input() presenca: any = {};
  @Input() cidadao: any = {};
  @Input() prontuario: any = {};
  @Output() goBack = new EventEmitter();
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() visualizando: boolean = false;

  generos: any = [{ 'nome': 'Masculino', 'id': 1 }, { 'nome': 'Feminino', 'id': 2 }, { 'nome': 'Não Informado', 'id': 3 }, { 'nome': 'Ambos', 'id': 4 }, { 'nome': 'Ignorado', 'id': 5 }];
  criticidadeList: any = [{ 'nome': 'Eletivo', 'id': 1 }, { 'nome': 'Prioridade', 'id': 2 }, { 'nome': 'Urgência', 'id': 3 }, { 'nome': 'Emergência', 'id': 4 }];

  @Input() clinicasPorBloco: any = [];
  listaCid: any = [];
  tipoTransferencia: any = 1;

  constructor(private authService: AuthService,
    public service: ApiService,
    private global: Global,) {
  }

  ngOnInit() {
    this.transferencia.tipo_transferencia = 'EXTERNA';
    this.transferencia.prioridade_transferencia = 0;
  }

  adicionarCid(item) {
    if (!item) return;
    if (this.listaCid.length == 0) {
      item.cid_subcategoria_id = item.id;
      item.cid_subcategoria_codigo = item.codigo;
      item.cid_subcategoria_nome = item.nome;
      item.principal = false;
      item.diagnostico_confirmado = false;
      item.doenca_cronica = false;
      item.novo = true;
      this.listaCid.push(item);
    }
    else {
      var repetido = false;
      this.listaCid.forEach((s) => {
        if (s.id == item.id)
          repetido = true;
      });
      if (!repetido) {
        item.cid_subcategoria_id = item.id;
        item.cid_subcategoria_codigo = item.codigo;
        item.cid_subcategoria_nome = item.nome;
        item.principal = false;
        item.diagnostico_confirmado = false;
        item.doenca_cronica = false;
        item.novo = true;
        this.listaCid.push(item);
      }
    }
  }

  selecionaCidPrincipal(index, selecionado) {
    if (!selecionado)
      return
    this.listaCid.forEach((item) => {
      item.principal = false;
    });
    this.listaCid[index].principal = true;
  }

  removerCid(i) {
    this.listaCid.splice(i, 1);
    //Swal.fire({
    //  icon: 'warning',
    //  title: 'Atenção!',
    //  html: 'A operação só será efetivada após clicar no botão de salvar!',
    //  allowOutsideClick: false,
    //  allowEscapeKey: false
    //}).then(
    //  result => {
    //    if (result.isConfirmed) {
    //      this.listaCid.splice(i, 1);
    //    }
    //  }
    //);
    //if (!this.atendimento.id || this.listaCid[i].novo)
    //  this.listaCid.splice(i, 1);
    //else {
    //  Swal.fire({
    //    title: 'Favor aguarde',
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //  Swal.showLoading();
    //  this.listaCid[i].ativo = false;
    //  this.atualizarAnamnese(true);
    //  //this.deleteCid(i);
    //}
  }

  salvarTransferencia = () => {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    var transferenciaExterna = {
      "presenca_id": this.presenca.id,
      "resumo_clinico": (this.transferencia.resumo_clinico == null ? '' : this.transferencia.resumo_clinico),
      "exame_realizado": (this.transferencia.exame_realizado == null ? '' : this.transferencia.exame_realizado),
      "terapeutica_utilizada": (this.transferencia.terapeutica_utilizada == null ? '' : this.transferencia.terapeutica_utilizada),
      "referencia": (this.transferencia.referencia == null ? '' : this.transferencia.referencia),
      "observacao": (this.transferencia.observacao == null ? '' : this.transferencia.observacao),
      "justificativa": (this.transferencia.justificativa == null ? '' : this.transferencia.justificativa),
      "status_transferencia": "SOLICITADO",
      "tipo_transferencia": "EXTERNA",
      "prioridade_transferencia": parseInt(this.transferencia.prioridade_transferencia)
    }
    //var transferenciaInterna = {
    //  "presenca_id": this.presenca.id,
    //  "clinica_medica_id": parseInt(this.transferencia.clinica_medica_id),
    //  "status_transferencia": "SOLICITADO",
    //  "tipo_transferencia": this.transferencia.tipo_transferencia,
    //  "prioridade_transferencia": parseInt(this.transferencia.prioridade_transferencia)
    //}
    //var transferencia = {};
    //if (this.transferencia.tipo_transferencia == 'EXTERNA')
    //  transferencia = transferenciaExterna;
    //if (this.transferencia.tipo_transferencia == 'INTERNA')
    //  transferencia = transferenciaInterna;
    this.service.Post(`Prontuario/${this.prontuario.id}/Transferencia`, transferenciaExterna).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Transferência salva com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.voltar();
              this.retornoPost.emit();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPost = () => {
    if (this.prontuario.id == null) {
      Swal.fire('Erro', "Prontuario não encontrado.", 'error');
      return false;
    }
    if (this.presenca.id == null) {
      Swal.fire('Erro', "Presença não encontrado.", 'error');
      return false;
    }
    //if (this.transferencia.tipo_transferencia == 'INTERNA' && this.transferencia.clinica_medica_id == 0) {
    //  Swal.fire('Erro', "Favor informar a clínica médica de destino.", 'error');
    //  return false;
    //}
    //if (this.presenca.clinica_medica_id == parseInt(this.transferencia.clinica_medica_id)) {
    //  Swal.fire('Erro', "A clínica de destino não pode ser a mesma de origem.", 'error');
    //  return false;
    //}

    //if (this.transferencia.resumo_clinico == null || this.transferencia.resumo_clinico == '') {
    //  Swal.fire('Erro', "Favor informar um resumo clínico.", 'error');
    //  return false;
    //}
    //if (this.transferencia.exame_realizado == null || this.transferencia.exame_realizado == '') {
    //  Swal.fire('Erro', "Favor especificar os exames realizados.", 'error');
    //  return false;
    //}
    //if (this.transferencia.terapeutica_utilizada == null || this.transferencia.terapeutica_utilizada == '') {
    //  Swal.fire('Erro', "Favor informar a terapeutica realizada.", 'error');
    //  return false;
    //}
    //if (this.transferencia.referencia == null || this.transferencia.referencia == '') {
    //  Swal.fire('Erro', "Favor informar uma referência de destino.", 'error');
    //  return false;
    //}
    if (this.transferencia.prioridade_transferencia == 0) {
      Swal.fire('Erro', "Favor informar a criticidade.", 'error');
      return false;
    }
    //if (this.transferencia.observacao == null || this.transferencia.observacao == '') {
    //  Swal.fire('Erro', "Favor informar uma observação complementar.", 'error');
    //  return false;
    //}
    //if (this.transferencia.justificativa == null || this.transferencia.justificativa == '') {
    //  Swal.fire('Erro', "Favor informar uma justificativa.", 'error');
    //  return false;
    //}

    return true;
  }

  selecionarTipoTransferencia(tipo) {
    this.transferencia.tipo_transferencia = tipo;
    this.transferencia.prioridade_transferencia = 0;
    if (tipo == 'INTERNA') {
      this.transferencia.clinica_medica_id = 0;
    }
  }

  voltar = () => {
    this.transferencia = {};
    this.goBack.emit();
  }

}
