import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { FileUploadService } from 'src/app/service/fileService';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-emergency-prescription-list',
  templateUrl: './emergency-prescription-list.component.html',
  styleUrls: ['./emergency-prescription-list.component.css']
})
export class EmergencyPrescriptionListComponent implements OnInit {

  @Input() listaPrescricao: any = [];
  @Input() prontuario: any = {};
  prescricaoDados: any = {};
  dadosImpresso: any;
  global_: any;

  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario: any = false;
  constructor(private global: Global,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService) {
    this.global_ = global;
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(prescricao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`PrescricaoUrgencia/${prescricao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }

  prepararImpressoHtml(dadosImpresso) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}
      <body>
        <div>
        ${this.global.cabecalhoImpresso(dadosImpresso, "PRESCRIÇÃO DE URGÊNCIA")}
        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>PRESCRIÇÃO DE URGÊNCIA</h4>
                </td>
            </tr>
        </table>
        <table>          
            <tr>
             <td>
                ${dadosImpresso.conteudo.texto}
             </td>               
            </tr>
        </table>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

  visualizar = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`PrescricaoUrgencia/${id}`).then((res) => {
      Swal.close();
      this.prescricaoDados = res;
      this.visualizarItem();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

  deletar = (id) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja deletar essa prescrição?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.Delete(`PrescricaoUrgencia/${id}`).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaAbaPrescricaoUrgencia();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  obterListaAbaPrescricaoUrgencia = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/PrescricaoUrgencia`).then(res => {
      this.listaPrescricao = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  visualizarItem = () => {
    $("#modalPrescricaoUrgencia").toggleClass("show");
    $("#modalPrescricaoUrgencia").css("display", "block");
  }


  fecharModal = () => {
    $("#modalPrescricaoUrgencia").toggleClass("show");
    $("#modalPrescricaoUrgencia").css("display", "none");
  }

}
