import { Component, OnInit } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../../service/apiServices';
import { Global } from '../../../global';
import Swal from 'sweetalert2';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-list-citizen',
  templateUrl: './list-citizen.component.html',
  styleUrls: ['./list-citizen.component.css']
})
export class ListCitizenComponent implements OnInit {

  cidadaos: any = [];
  loading: boolean = false;
  filtro: string = "";
  pagina: any = 1;
  totalPorPagina: any = 10;
  totalPagina: any = 1;

  params: string = `Skip=${this.pagina}&Take=10&Busca`;

  constructor(public service: ApiService,
    private alerts: NgxAlertsService,
    private global: Global,
    private authService: AuthService
  ) { }


  ngOnInit() {
    this.init();
  }

  init = async () => {
    this.obterListaCidadaos();
  }

  obterListaCidadaos = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Cidadao?Skip=${this.pagina}&Take=${this.totalPorPagina}`).then(res => {
      this.cidadaos = res['conteudo'];
      if (this.cidadaos.length > 0) {
        this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
      }
      for (let cidadao of this.cidadaos) {
        this.global.applyMask(cidadao)
      }
      Swal.close();
      this.loading = false;
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401){
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      this.loading = false;
      Swal.fire('Erro', `${res.error.erros[0].messagem}`,'error');
    });
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse paciente?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Favor aguarde',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
          Swal.showLoading();
          this.service.AtivarDesativar(`Cidadao/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaCidadaos();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401){
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.cidadaos.length === 0) {
      return this.cidadaos;
    }
    return this.cidadaos.filter(
      c => c.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()) ||
        (c.cpf != null ? c.cpf.replace(".", "").replace(".", "").replace("-", "").includes(this.filtro.replace(".", "").replace(".", "").replace("-", "")) :
          c.cns != null ? c.cns.replace(" ", "").replace(" ", "").replace(" ", "").includes(this.filtro.replace(" ", "").replace(" ", "").replace(" ", "")) : '')
    );
  }

  resultBuscaCidadao = (result) => {
    if (result != null) {
      this.cidadaos = result['conteudo'];
      if (this.cidadaos.length > 0) {
        this.totalPagina = Math.ceil(result['quantidade_total'] / this.totalPorPagina)
      }
    }
    else {
      this.pagina = 1;
      this.service.Get(`Cidadao?Skip=${this.pagina}&Take=${this.totalPorPagina}`).then(res => {
        this.cidadaos = res['conteudo'];
        if (this.cidadaos.length > 0) {
          this.totalPagina = Math.ceil(res['quantidade_total'] / this.totalPorPagina)
        }
        for (let cidadao of this.cidadaos) {
          this.global.applyMask(cidadao)
        }
        Swal.close();
        this.loading = false;
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401){
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close();
        this.loading = false;
        Swal.fire('Erro', `${res.error.erros[0].messagem}`,'error');
      });
    }

  }

  voltarPagina = () => {
    this.pagina = this.pagina - 1;
    this.obterListaCidadaos()
  }

}
