import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-list-preparation',
  templateUrl: './list-preparation.component.html',
  styleUrls: ['./list-preparation.component.css']
})
export class ListPreparationComponent implements OnInit {

  listaPreparos: any = [];
  filtro: string = "";

  constructor(
    public service: ApiService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getListPreparo();
  }

  getListPreparo = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Preparo`).then(
      result => {
        this.listaPreparos = result;
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  emitErro = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse preparo?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Preparo/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.getListPreparo();
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  filtroLista() {
    if (this.listaPreparos.length === 0) {
      return this.listaPreparos;
    }
    return this.listaPreparos.filter(c => c.descricao.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }


}
