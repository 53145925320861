import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var Simditor: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-emergency-prescription-aba',
  templateUrl: './emergency-prescription.component.html',
  styleUrls: ['./emergency-prescription.component.css']
})
export class EmergencyPrescriptionComponent implements OnInit {
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Input() listaPrescricao: any = [];
  seeList: boolean = true;
  listaFavoritos: any = [];
  prescricao: any = {};
  usuario: any = {};
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];
  visualizar_prontuario: any = false;
  constructor(private authService: AuthService,
    public service: ApiService) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    Simditor.locale = 'en-US';
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  goBack = () => {
    this.seeList = true;
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }

  ObterListaFavorito() {

  }

  openFavorites = () => {
    $("#modalListaFavoritosPrescricao").toggleClass("show");
    $("#modalListaFavoritosPrescricao").css("display", "block");
    var that = this;
    setTimeout(() => {
      var descricao = new Simditor({
        textarea: $('#descricaoPrescricaoUrgencia'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options
      });
    }, 1000);
  }

  closeFavorites = () => {
    this.listaFavoritos = [];
    this.prescricao = {};
    $("#modalListaFavoritosPrescricao").toggleClass("show");
    $("#modalListaFavoritosPrescricao").css("display", "none");
  }




  salvarPrescricaoFavorito = () => {
    if (!this.prescricao.nome) {
      Swal.fire('Erro', 'Favor preencher o nome da prescrição favorita', 'error')
      return;
    }

    var texto = $('#descricaoPrescricaoUrgencia').val();
    if (texto == null || texto == "") {
      Swal.fire('Erro', "Favor informar um texto de prescrição.", 'error');
      return;
    }

    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var Obj = {
      usuario_id: parseInt(this.usuario.usuario_id),
      texto: $('#descricaoPrescricaoUrgencia').val(),
      tipo: 'PRESCRICAO',
      nome: this.prescricao.nome,
    }

    this.service.Post(`TextoFavorito`, Obj).then(
      result => {
        Swal.fire('Sucesso', 'Prescrição adicionado aos favoritos com sucesso', 'success').then(() => {
          this.closeFavorites();
        });
      },
      error => {
        if (error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

}
