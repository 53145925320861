import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import Swal from 'sweetalert2';

import * as $ from "jquery";
import { Global } from '../../global';
import { ApiService } from '../../service/apiServices';

@Component({
  selector: 'app-modal-realization-forwarding',
  templateUrl: './modal-realization-forwarding.component.html',
  styleUrls: ['./modal-realization-forwarding.component.css']
})
export class ModalRealizationForwardingComponent implements OnInit {
  @Input() consulta: any = {};
  @Input() cidadao: any = {};
  @Input() encaminhamento: any = {};
  loading: boolean = false;
  @Output() resultPostModalRealization: EventEmitter<any> = new EventEmitter();
  global_: any
  constructor(
    private global: Global,
    public service: ApiService
  ) { 
    this.global_ = this.global
  }

  ngOnInit() {
  }

  ngOnChanges() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.obterCid(this.consulta.cid_subcategoria_id)
  }

  obterCid = (cid) => {
    if (cid == 0 || cid == undefined || cid == null) {
      this.consulta.Cid = 'CID não informado'
      return;
    }
    this.service.Get(`CidSubCategoria/${cid}`).then(
      result => {
        this.consulta.Cid = result['codigo'] + ' - ' + result['nome']
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  fecharModal = () => {
    $("#modalRealizacaoEncaminhamento").toggleClass("show");
    $("#modalRealizacaoEncaminhamento").css("display", "none");
  }

}
