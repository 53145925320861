import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
@Component({
  selector: 'app-medical-admission',
  templateUrl: './medical-admission.component.html',
  styleUrls: ['./medical-admission.component.css']
})
export class MedicalAdmissionComponent implements OnInit {

  @Output() retornoPost: EventEmitter<any> = new EventEmitter();
  @Input() prontuario: any = {};
  @Input() listaAdmissaoMed: any = [];
  seeList: any = true;
  @Input() abaAdmissaoSelecionada: boolean = false;
  visualizar_prontuario: any = false;
  constructor(public service: ApiService, private authService: AuthService,) {
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  setListView(event) {
    this.seeList = true;
  }

  retornoPostFilho = () => {
    this.retornoPost.emit();
  }

}
