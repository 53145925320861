import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-prescricao-create',
  templateUrl: './prescricao-create.component.html',
  styleUrls: ['./prescricao-create.component.css']
})
export class PrescricaoCreateComponent implements OnInit {

  @Input() listaPrescricao: any = [];
  @Input() prontuario: any = {};
  global_: any;
  prescricao: any = {};
  nutricao: any = [
    {
      tipo_nutricao: 0,
      nutricao: 0,
      prescricao: "",
      complemento: ""
    }
  ];

  listaTipoNutricao: any = [
    {
      id: 1,
      nome: 'DIETA ORAL'
    },
    {
      id: 2,
      nome: 'DIETA ENTERAL'
    },
    {
      id: 3,
      nome: 'DIETA ZERO'
    },
  ];
  constructor(private global: Global,
    private authService: AuthService,
    public service: ApiService) {
    this.global_ = global;
  }

  ngOnInit() {
  }

  salvarPrescricao() {

  }

}
