import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Location } from '@angular/common';
@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.css']
})
export class ReceptionComponent implements OnInit {

  dataHoje = new Date();

  atendimentosList: any = [];
  filtro: any = {};
  usuario: any;
  listaClinicas: any = [];
  presencaUser = JSON.parse(localStorage.getItem('@DigtVentry:presencaUser'));
  constructor(
    public service: ApiService,
    private global: Global,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alerts: NgxAlertsService,
    private router: Router,
    private location: Location,
  ) {

    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });

  }

  ngOnInit() {
    this.filtro.data = this.global.dateFormat(this.dataHoje);    
    this.pesquisar();
    this.obterListaClinicas();

  }

  obterListaClinicas() {
    this.service.Get(`Estabelecimento/${this.presencaUser.estabelecimento_id}/ClinicaMedica?ativo=true`).then((res) => {
      this.listaClinicas = res;
    }).catch((err) => {
      Swal.fire('Erro', `Erro ao obter clinicas`, 'error');
    });
  }

  pesquisar() {
    var obj = Object.assign({}, this.filtro);
    if (this.filtro.data) {
      obj.data = `${this.filtro.data.split("/")[2]}-${this.filtro.data.split("/")[1]}-${this.filtro.data.split("/")[0]}`;
    } else {
      Swal.fire('Erro', `Favor inserir uma data correta`, 'error');
      return
    }

    if (obj.data == "Invalid date") {
      Swal.fire('Erro', `Favor inserir uma data correta`, 'error');
      return
    }    
    this.carregarAgendas(obj);
  }

  carregarAgendas(obj: any) {
    Swal.fire('Favor aguarde');

    var url = `AgendaHora?agenda_dia_agenda_em=${obj.data}&vago=false`;

    if (obj.busca) {
      url += `&busca=${obj.busca.toUpperCase()}`
    }

    Swal.showLoading();
    this.service.Get(url).then(res => {
      if (res) {
        this.atendimentosList = res;
        this.atendimentosList.forEach((item) => {
          item.clinica_id = this.presencaUser.clinica_medica_id
        })
        Swal.close();
      }
    }).catch((res: any) => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  marcarPresencaCidadao(item: any) {
    if (!item.clinica_id) {
      Swal.fire('Erro', `Favor selecionar uma clinica`, 'error');
      return
    }

    Swal.fire({
      title: 'Atenção',
      text: `Deseja confirmar a presença do paciente?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          let encaminhamento = {
            "cidadao_id": item.cidadao_id,
            "clinica_medica_id": parseInt(item.clinica_id),
            "profissional_id": this.usuario.usuario_profissional_id,
            "tipo_atendimento": "AGENDAMENTO",
            "agenda_hora_id": item.id
          };
          this.service.Post(`Atendimento`, encaminhamento).then(res => {
            setTimeout(() => {
              Swal.close()
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Paciente encaminhado com sucesso!',
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(
                result => {
                  if (result.isConfirmed) {
                    this.pesquisar();
                  }
                }
              );
            }, 1000);
          }).catch(res => {
            if (res.status == 401 || res.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close()
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: res.error.messagem
            }).then(
              result => {
                if (result.isConfirmed) {

                }
              }
            );
          })
        }
      }
    );
  }
}
