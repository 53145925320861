import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from '../../service/apiServices';
import Swal from 'sweetalert2';

import { Global } from '../../global';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  @Input() consulta: any = {};
  @Input() cidadao: any = {};
  @Output() resultPostModalAgendamento: EventEmitter<any> = new EventEmitter();
  //visualisandoHorarios: boolean = false;
  //exibirAgenda: boolean = false;
  @Input() filtro: any = {};
  estabelecimentos: any = [];
  ocupacoes: any = [];
  profissionais: any = [];
  loading: boolean = false;
  //agendas: any = [];
  horariosList: any = [];
  agenda: any = {};
  cidadao_id: any = 0;
  @Input() tipo: any = 0;

  @Input() trocandoAgendamento: boolean = false;
  @Input() agendaHoraRemove: any = {};

  dataHoje = new Date();

  dataAtual: any = ('0' + this.dataHoje.getDate()).slice(-2) +
    ('0' + (this.dataHoje.getMonth() + 1)).slice(-2) + this.dataHoje.getFullYear();

  trintaDias = new Date(new Date().setDate(this.dataHoje.getDate() + 30));

  dataFuturo: any = ('0' + (this.trintaDias.getDate())).slice(-2) +
    ('0' + (this.trintaDias.getMonth() + 1)).slice(-2) + this.trintaDias.getFullYear();

  //@Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Input() regulacao: any = '';

  errorLog: any = [];
  @Input() limpaFiltro: boolean;

  usuario: any = {};

  constructor(
    public service: ApiService,
    private global: Global,
    private alerts: NgxAlertsService
  ) { }

  ngOnInit() {
    //this.filtro = {};
    //this.consulta = {};
    //this.cidadao = {};
    this.estabelecimentos = [];
    var currentUser = localStorage.getItem('@DigtVentry:currentUser');
    this.usuario = JSON.parse(currentUser);
  }

  ngOnChanges() {
    this.filtro = []
    this.estabelecimentos = []
    this.filtro.estabelecimento_id = 0;
    this.filtro.ocupacao_id = 0;
    this.filtro.profissional_id = 0;
    this.filtro.data_agenda_inicio = this.dataAtual;
    this.filtro.data_agenda_fim = this.dataFuturo;
    this.filtro.agendas = [];

    if (this.limpaFiltro) {
      this.limpaFiltroBuscaAgenda()
    }
    if (this.estabelecimentos.length == 0) {

      this.obterListaEstabelecimentos();
    }
    if (this.estabelecimentos.length > 0) {
      Swal.close();
    }

    if (this.consulta.id) {
      this.cidadao = this.consulta.Cidadao;
      if (this.tipo == 2) {
        this.consulta.Profissional = this.consulta.Usuario
      }
      //this.filtro.ocupacao_id = this.consulta.Usuario.ocupacao_id
    } else {
      try {
        this.cidadao = this.consulta.regulacoes[0].Cidadao;
        if (this.tipo == 2) {
          this.consulta.Profissional = this.consulta.regulacoes[0].Usuario
        }
      } catch (error) {

      }
    }
  }

  exibirEsconderHorarios = (agenda) => {
    //this.visualisandoHorarios = !this.visualisandoHorarios;
    this.filtro.visualisandoHorarios = !this.filtro.visualisandoHorarios;
    if (agenda == null) {
      this.pesquisarAgenda();
      return;
    }
    this.agenda = agenda;
    this.horariosList = [];
    agenda.horarios.forEach((item) => {
      if (item.vago) {
        item.nome_profissional = agenda.profissionalNome;
        this.horariosList.push(item);
      }
    });
  }

  //sendNotification() {
  //  this.notifyParent.emit();
  //}

  obterListaEstabelecimentos = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento`).then(res => {
      Swal.close();
      this.estabelecimentos = res;
      this.obterListaOcupacao();
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaOcupacao = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Ocupacao`).then(res => {
      this.ocupacoes = res;
      if (this.consulta.Ocupacao) {
        this.filtro.ocupacao_id = this.consulta.Ocupacao.id
        this.obterListaProfissionais(this.filtro.ocupacao_id)
      }
      Swal.close();
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaProfissionais = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    if (id == undefined) { return }
    this.service.Get(`Profissional?OcupacaoId=${id}`).then(res => {
      Swal.close();
      this.profissionais = res;
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  pesquisarAgenda = () => {
    if (this.tipo == 1) {
      for (let item of this.estabelecimentos) {
        if (item.id === parseInt(this.filtro.estabelecimento_id)) {
          this.consulta.EstabelecimentoExecutor = item;
        }
      }
    }
    if (this.tipo == 2) {
      for (let item of this.ocupacoes) {
        if (item.id === parseInt(this.filtro.ocupacao_id)) {
          this.consulta.Ocupacao = item;
        }
      }
      for (let item of this.estabelecimentos) {
        if (item.id === parseInt(this.filtro.estabelecimento_id)) {
          this.consulta.EstabelecimentoExecutor = item;
        }
      }
    }
    if (this.filtro.estabelecimento_id == 0) {
      Swal.fire('Erro', "Favor selecionar o estabelecimento", 'error');
      return false;
    }
    if (this.filtro.ocupacao_id == 0) {
      Swal.fire('Erro', "Favor selecionar a ocupação", 'error');
      return false;
    }

    var data_inicio = this.dataAtual[4] + this.dataAtual[5] + this.dataAtual[6] + this.dataAtual[7] + "-" + this.dataAtual[2] + this.dataAtual[3] + "-" + this.dataAtual[0] + this.dataAtual[1];
    var data_fim = this.dataAtual[4] + this.dataAtual[5] + this.dataAtual[6] + this.dataAtual[7] + "-" + this.dataAtual[2] + this.dataAtual[3] + "-" + this.dataAtual[0] + this.dataAtual[1];

    if (this.filtro.data_agenda_inicio.length == 8)
      data_inicio = this.filtro.data_agenda_inicio[4] + this.filtro.data_agenda_inicio[5] + this.filtro.data_agenda_inicio[6] + this.filtro.data_agenda_inicio[7] + "-" + this.filtro.data_agenda_inicio[2] + this.filtro.data_agenda_inicio[3] + "-" + this.filtro.data_agenda_inicio[0] + this.filtro.data_agenda_inicio[1];

    if (this.filtro.data_agenda_fim.length == 8)
      data_fim = this.filtro.data_agenda_fim[4] + this.filtro.data_agenda_fim[5] + this.filtro.data_agenda_fim[6] + this.filtro.data_agenda_fim[7] + "-" + this.filtro.data_agenda_fim[2] + this.filtro.data_agenda_fim[3] + "-" + this.filtro.data_agenda_fim[0] + this.filtro.data_agenda_fim[1];

    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`AgendaDia?EstabelecimentoId=${this.filtro.estabelecimento_id}&OcupacaoId=${this.filtro.ocupacao_id}&ProfissionalId=${this.filtro.profissional_id}&DataAgendaInicio=${data_inicio}&DataAgendaFim=${data_fim}`).then(res => {
      Swal.close();
      //this.exibirAgenda = true;
      this.filtro.exibirAgenda = true;
      this.filtro.agendas = res;
    }).catch(res => {
      //this.exibirAgenda = false;
      this.filtro.exibirAgenda = false;
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
      this.filtro.agendas = [];
    })
  }

  agendarPaciente = (hora, index) => {

    var h = hora.split(":")[0];
    var m = hora.split(":")[1];
    Swal.fire({
      title: 'Atenção',
      text: `Deseja agendar o paciente ${this.cidadao.nome} para o horário de ${h}:${m}?`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.agenda.horarios[index].cidadaoId = this.consulta.Cidadao.id;
          this.agenda.horarios[index].cidadao_id = this.consulta.Cidadao.id;
          this.agenda.horarios[index].vago = false;
          var obj = {
            "agenda_em": this.agenda.dataAgendaInicio,
            "estabelecimento_id": this.agenda.estabelecimentoId,
            "ocupacao_id": this.agenda.ocupacaoId,
            "profissional_id": this.agenda.profissionalId,
            "horarios": this.agenda.horarios
          }
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          if (this.agenda.horarios[index].id == null || this.agenda.horarios[index].id == 0)
            this.service.Post(`AgendaDia`, obj).then((res) => {
              Swal.close();
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Operação realizada com sucesso!'
              }).then(
                result => {
                  if (result.isConfirmed) {
                    setTimeout(() => {
                      Swal.close();
                      this.atualizarAgenda(res, index);
                    }, 1000);
                  }
                }
              );
            }).catch((err) => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                html: err.error.erros[0].messagem
              });
            });
          else
            this.service.Patch_body(`AgendaHora/${this.agenda.horarios[index].id}`, { "ativo": true, "cidadaoId": this.consulta.Cidadao.id, "UsuarioId": parseInt(this.usuario.usuario_id), "Tipo": this.tipo }).then((res) => {
              Swal.close();
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Operação realizada com sucesso!'
              }).then(
                result => {
                  if (result.isConfirmed) {
                    setTimeout(() => {
                      Swal.close();
                      this.atualizarAgenda(res, index);
                    }, 1000);
                  }
                }
              );
            }).catch((err) => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                html: err.error.erros[0].messagem
              });
            });
        }
      }
    );
  }

  atualizarAgenda = (agenda, index) => {
    var agenda_hora_id = agenda.id;
    if (agenda.Horarios) {
      agenda_hora_id = agenda.Horarios[index].id;
    }
    var obj =
    {
      "agendaHoraId": agenda_hora_id,
      "EstabelecimentoIdExecutor": Number(this.filtro.estabelecimento_id)
    };
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    var caminho = ""
    if (this.tipo == 1)
      caminho = `RegulacaoConsulta/${this.consulta.id}`;
    if (this.tipo == 2) {
      obj['ativo'] = true;
      obj['status'] = "AGENDADO";
      obj['estabelecimentoExecutorId'] = this.filtro.estabelecimento_id;
    }
    if (this.tipo == 1) {
      this.service.Patch_body(`${caminho}`, obj).then(
        res => {
          setTimeout(() => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: `O paciente ${this.cidadao.nome} foi agendado com sucesso!`
            }).then(
              result => {
                if (result.isConfirmed) {
                  if (this.trocandoAgendamento)
                    this.removerHorarioPacienteAgendado();
                  else
                    this.exibirEsconderHorarios(null);
                }
              }
            );
          }, 1000);
        }).catch(res => {
          Swal.close()
          Swal.fire('Erro', `${res.message}`, 'error');
        });
    }
    if (this.tipo == 2) {
      if (!this.consulta.id) {
        this.updateRecurse(agenda, 0, obj)
      }
      else {
        if (this.consulta) {
          this.updateExameAgendamento(agenda, obj)
        }
      }
    }
  }

  updateExameAgendamento = (agenda, obj) => {
    this.service.Patch_body(`RegulacaoExame/${this.regulacao}`, obj).then(
      result => {
        if (this.trocandoAgendamento)
          this.removerHorarioPacienteAgendado();
        else {
          Swal.fire('Sucesso', 'Procedimento agendado com sucesso.', 'success')
          return
        }
      },
      error => {
        Swal.fire('Erro', error, 'error')
      }
    )
  }

  updateRecurse = (agenda, index, obj) => {
    try {
      if (index === this.consulta.regulacoes.length) {
        this.exibeFinal()
        return
      }
      this.service.Patch_body(`RegulacaoExame/${this.consulta.regulacoes[index].id}`, obj).then(
        result => {
          this.updateRecurse(agenda, index + 1, obj)
        },
        error => {
          this.errorLog.push(error)
        }
      )
    } catch (e) {
      this.errorLog.push(e)
    }
  }

  exibeFinal = () => {
    if (this.errorLog.length > 0) {
      Swal.fire('Atenção', 'Alguns procedimentos não puderam ser agendados.', 'warning')
      return
    }
    else {
      Swal.fire('Sucesso', 'Procedimentos agendados com sucesso.', 'success')
      return
    }
  }

  limpaFiltroBuscaAgenda = () => {
    this.filtro.estabelecimento_id = 0;
    this.filtro.ocupacao_id = 0;
    this.filtro.profissional_id = 0;
    this.filtro.data_agenda_inicio = this.dataAtual;
    this.filtro.data_agenda_fim = this.dataAtual;
    this.filtro.agendas = [];
  }

  removerHorarioPacienteAgendado = () => {
    let obj = {
      "cidadaoId": 0
    }
    this.service.Patch_body(`AgendaHora/${this.agendaHoraRemove.id}`, obj).then(
      res => {
        setTimeout(() => {
          this.resultPostModalAgendamento.emit();
          Swal.close();

        }, 1000);
      }).catch(res => {
        Swal.close()
        Swal.fire('Erro', `${res.message}`, 'error');
      });
  }

}
