import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../service/apiServices';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Global } from '../../global';
import { Location } from '@angular/common';

import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import { AuthService } from '../../service/auth/auth.service'

@Component({
  selector: 'app-historico-regulacao',
  templateUrl: './regulation-historic.component.html',
  styleUrls: ['./regulation-historic.component.css']
})
export class RegulationHistoricComponent implements OnInit {

  @Input() regulacaoId: any = 0;
  @Input() tipoRegulacaoHistorico: any = 0;

  historico: any = {};
  listaUsuarios: any = [];
  dadosImpressoHistorico: any = {};
  alterandoAgenda: boolean = false;
  solicitacaoExame: any = {};

  cidadao: any = {};
  filtroAgendamento: any = {};
  consulta: any = {};
  agendaHoraTroca: any = {};

  @Output() limparVisualizarHistorico = new EventEmitter();

  global_: any

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.global_ = this.global
  }

  ngOnInit() {
  }

  ngOnChanges() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    if (this.tipoRegulacaoHistorico) {
      this.getProfissional().then(
        result => {
          this.listaUsuarios = result;
          if (this.tipoRegulacaoHistorico == 1) {
            this.obterRegulacaoConsulta(this.regulacaoId)
          }
          if (this.tipoRegulacaoHistorico == 2) {
            this.obterRegulacaoExame(this.regulacaoId)
          }
        },
        error => {
          this.showError(error)
        }
      )
    }
    else Swal.close()
  }

  fecharModal = () => {
    this.historico = {};
    this.limparVisualizarHistorico.emit();
    this.alterandoAgenda = false;
    $("#modalHistoricoRegulacao").toggleClass("show");
    $("#modalHistoricoRegulacao").css("display", "none");
    Swal.close();
  }

  obterRegulacaoExame = (id) => {
    this.service.Get(`RegulacaoExame/${id}`).then(
      result => {
        this.historico = result;
        this.consulta = result;
        this.cidadao = this.consulta.Cidadao;
        this.agendaHoraTroca = this.consulta.AgendaHora;

        if (this.consulta.AgendaHora) {
          this.getAgendaDia(this.historico.AgendaHora.agenda_dia_id).then(
            result => {
              var data = result['agenda_em'];
              this.historico.AgendaHora.agendamento = data.split('T')[0] + 'T' + this.historico.AgendaHora.hora;
            },
            error => {
              this.showError(error)
            }
          )
        }

        if (this.historico.CidSubCategoria) {
          this.historico.cid_subcategoria_texto = this.historico.CidSubCategoria.codigo + ' - ' + this.historico.CidSubCategoria.nome
        }
        if (this.historico.profissional_id_realizacao) {
          this.service.Get(`Profissional/${this.historico.profissional_id_realizacao}`).then(
            result => {
              this.historico.ProfissionalRealizacao = result;
              Swal.close()
            },
            error => {
              this.showError(error)
            }
          )
        }
        if (this.historico.CidSubCategoriaRealizacao) {
          this.historico.CidSubCategoriaRealizacao.texto = this.historico.CidSubCategoriaRealizacao.codigo + ' - ' + this.historico.CidSubCategoriaRealizacao.nome
        }
        Swal.close()
      },
      error => {
        this.showError(error)
      }
    )
  }

  obterRegulacaoConsulta = (id) => {
    this.service.Get(`RegulacaoConsulta/${id}`).then(
      result => {
        this.historico = result;

        this.consulta = result;
        if (this.consulta.estabelecimento_id_executor == null)
          this.consulta.estabelecimento_id_executor = 0;
        this.cidadao = this.consulta.Cidadao;
        if (this.consulta.AgendaHora != null)
          this.agendaHoraTroca = this.consulta.AgendaHora;

        for (let log of this.historico.Logs) {
          for (let usuario of this.listaUsuarios) {
            if (log.usuario_id == usuario.id) {
              log.usuario_nome = usuario.nome
            }
          }
        }
        if (!this.historico.cid_subcategoria_id) {
          // this.historico.cid_subcategoria_texto = 'NÃO INFORMADO'
          if (this.historico.AgendaHora) {
            this.getAgendaDia(this.historico.AgendaHora.agenda_dia_id).then(
              result => {
                var data = result['agenda_em']
                this.historico.AgendaHora.agendamento = data.split('T')[0] + 'T' + this.historico.AgendaHora.hora
                if (this.historico.estabelecimento_id_executor) {
                  this.getEstabelecimentos(this.historico.estabelecimento_id_executor).then(
                    result => {
                      this.historico.estabelecimento_executor_nome = result['nome'];
                      Swal.close()
                      return
                    },
                    error => {
                      this.showError(error)
                    }
                  )
                }
                else {
                  if (this.historico.AgendaHora) {
                    this.service.Get(`AgendaDia/${this.historico.AgendaHora.agenda_dia_id}`).then(
                      result => {
                        var agenda: any = {}
                        agenda = result;
                        this.service.Get(`Estabelecimento/${agenda.estabelecimento_id}`).then(
                          result => {
                            var estabelecimento: any = result;
                            this.historico.estabelecimento_executor_id = estabelecimento.id
                            this.historico.estabelecimento_executor_nome = estabelecimento.nome
                            Swal.close()
                            return
                          },
                          error => {
                            this.showError(error)
                          }
                        )
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  }
                }
              },
              error => {
                this.showError(error)
              }
            )
          }
          else {
            //this.historico.AgendaHora.agendamento = 'NÃO INFORMADO'
            Swal.close()
            return
          }
        }
        if (this.historico.cid_subcategoria_id) {
          this.getCid(this.historico.cid_subcategoria_id).then(
            result => {
              this.historico.cid_subcategoria_texto = result['codigo'] + ' ' + result['nome']
              if (this.historico.AgendaHora) {
                this.getAgendaDia(this.historico.AgendaHora.agenda_dia_id).then(
                  result => {
                    var data = result['agenda_em']
                    this.historico.AgendaHora.agendamento = data.split('T')[0] + 'T' + this.historico.AgendaHora.hora
                    if (this.historico.estabelecimento_id_executor) {
                      this.getEstabelecimentos(this.historico.estabelecimento_id_executor).then(
                        result => {
                          this.historico.estabelecimento_executor_nome = result['nome'];
                          Swal.close()
                          return
                        },
                        error => {
                          this.showError(error)
                        }
                      )
                    }
                    else {
                      if (this.historico.AgendaHora) {
                        this.service.Get(`AgendaDia/${this.historico.AgendaHora.agenda_dia_id}`).then(
                          result => {
                            var agenda: any = {}
                            agenda = result;
                            this.service.Get(`Estabelecimento/${agenda.estabelecimento_id}`).then(
                              result => {
                                var estabelecimento: any = result;
                                this.historico.estabelecimento_executor_id = estabelecimento.id
                                this.historico.estabelecimento_executor_nome = estabelecimento.nome
                                Swal.close()
                                return
                              },
                              error => {
                                this.showError(error)
                              }
                            )
                          },
                          error => {
                            this.showError(error)
                          }
                        )
                      }
                    }
                  },
                  error => {
                    this.showError(error)
                  }
                )
              }
              else {
                //this.historico.AgendaHora.agendamento = 'NÃO INFORMADO'
                Swal.close()
                return
              }
            }
          )
        }
        // if (this.historico.status == 'AGENDADO'){

        // }
        // if (this.historico.status == 'CANCELADO'){

        // }
        // if (this.historico.status == 'REALIZADO'){

        // }
        if (this.historico.cid_subcategoria_id_realizacao) {
          this.getCid(this.historico.cid_subcategoria_id_realizacao).then(
            result => {
              this.historico.cid_subcategoria_texto_realizacao = result['codigo'] + ' ' + result['nome']
            }
          )
        }
      },
      error => {
        this.showError(error)
      }
    )
  }

  showError = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  getEstabelecimentos = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento/${id}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getCid = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`CidSubCategoria/${id}`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getProfissional = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Usuario`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getAgendaDia = (id) => {
    return new Promise((resolve, reject) => {
      this.service.Get(`AgendaDia/${id}`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  obterDadosImpressoHistorico = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.service.Get(`Relatorios/${this.tipoRegulacaoHistorico == 1 ? 'HistoricoRegulacaoConsulta' : 'HistoricoRegulacaoExame'}?Id=${this.regulacaoId}`).then(
      res => {
        this.dadosImpressoHistorico = res;
        Swal.close();
        this.imprimirHistorico();
      }).catch(error => {
        Swal.close();
        Swal.fire('Erro', error.error.messagem, 'error');
      });
  }

  imprimirHistorico = () => {

    let doc = new jsPDF();

    let pageHeight = doc.internal.pageSize.height;
    let y = 0

    doc.setFontSize(8);
    doc.text("MINISTÉRIO DA SAÚDE", 10, 10);
    doc.text("ESTADO DO CEARÁ", 10, 14);
    doc.text("MUNICÍPIO DE NOVA RUSSAS", 10, 18);
    doc.text((this.dadosImpressoHistorico.estabelecimentoExecutor ? this.dadosImpressoHistorico.estabelecimentoExecutor : ''), 10, 22);

    doc.line(10, 23, 200, 23);

    //título
    doc.setFontSize(20);
    doc.text("Histórico de regulação", 10, 35);

    doc.setLineDashPattern([2, 35, 5000], 0);
    doc.setFontSize(10);

    doc.rect(10, 43, 190, 10);

    doc.text("Nome do paciente", 13, 44);

    doc.setFontSize(12);

    doc.text(this.dadosImpressoHistorico.nomeCidadao, 12, 51);

    doc.setFontSize(10);
    doc.text('CPF: ' + (this.dadosImpressoHistorico.cpfCidadao != null ? this.global.cpfMask(this.dadosImpressoHistorico.cpfCidadao) : '-'), 110, 51);
    doc.text('CNS: ' + (this.dadosImpressoHistorico.cnsCidadao != null ? this.global.cnsMask(this.dadosImpressoHistorico.cnsCidadao) : '-'), 148, 51);

    y = 60;
    let yBorda = y;
    var quantLinha = 0;
    var texto = '';

    y += 1;

    doc.setFontSize(12);
    doc.text("  SOLICITAÇÃO", 13, y).setFont(undefined, 'bold');
    doc.setFontSize(10);
    y += 6;

    if (this.tipoRegulacaoHistorico == 1) {
      doc.text("Especialidade:", 15, y).setFont(undefined, 'normal');
      doc.text(this.dadosImpressoHistorico.especialidade != null ? this.dadosImpressoHistorico.especialidade : '-', 41, y).setFont(undefined, 'bold');

      y += 4;

      doc.text("Justificativa:", 15, y).setFont(undefined, 'normal');
      doc.text(this.dadosImpressoHistorico.justificativa != null ? this.dadosImpressoHistorico.justificativa : '-', 38, y).setFont(undefined, 'bold');
      y += 4 + quantLinha;

    }

    if (this.tipoRegulacaoHistorico == 2) {
      doc.text("Procedimento/SIGTAP:", 15, y).setFont(undefined, 'normal');
      doc.text(this.dadosImpressoHistorico.sigTap != null ? this.dadosImpressoHistorico.sigTap : '-', 55, y).setFont(undefined, 'bold');

      y += 4;

      doc.text("Subgrupo SIGTAP:", 15, y).setFont(undefined, 'normal');
      doc.text(this.dadosImpressoHistorico.sigTapSubGrupo != null ? this.dadosImpressoHistorico.sigTapSubGrupo : '-', 48, y).setFont(undefined, 'bold');

      y += 4;
    }

    doc.text("CID/CIAP:", 15, y).setFont(undefined, 'normal');
    doc.text(this.dadosImpressoHistorico.cid != null ? this.dadosImpressoHistorico.cid : '-', 33, y).setFont(undefined, 'bold');

    y += 4;

    doc.text("Criticidade solicitação:", 15, y).setFont(undefined, 'normal');
    doc.text(this.dadosImpressoHistorico.classificacaoRisco != null ? this.dadosImpressoHistorico.classificacaoRisco : '-', 55, y).setFont(undefined, 'bold');

    y += 4;

    doc.text("Motivo solicitação:", 15, y).setFont(undefined, 'normal');
    texto = this.formatTextPdf(this.dadosImpressoHistorico.motivo, doc).texto;
    quantLinha = this.formatTextPdf(this.dadosImpressoHistorico.motivo, doc).quantLinha;

    doc.text(texto, 48, y).setFont(undefined, 'bold');

    y += quantLinha;

    doc.text("Data / Hora solicitação:", 15, y).setFont(undefined, 'normal');
    doc.text(this.dadosImpressoHistorico.solicitadoEm != null ? this.global.maskDataHora(this.dadosImpressoHistorico.solicitadoEm) : '-', 55, y).setFont(undefined, 'bold');

    doc.rect(10, yBorda, 190, 28 + quantLinha);

    let ylinha = 5;
    yBorda = 0;
    this.dadosImpressoHistorico.logs.forEach((item) => {
      if (item.status != "PENDENTE") {

        //se acabar a pagina
        if (y > 200) {
          doc.addPage();
          doc.setFontSize(8);
          doc.setFont(undefined, 'normal');
          doc.text("MINISTÉRIO DA SAÚDE", 10, 10);
          doc.text("ESTADO DO CEARÁ", 10, 14);
          doc.text("MUNICÍPIO DE NOVA RUSSAS", 10, 18);
          doc.text((this.dadosImpressoHistorico.estabelecimentoExecutor ? this.dadosImpressoHistorico.estabelecimentoExecutor : ''), 10, 22);
          doc.line(10, 23, 200, 23);
          y = 20;
          doc.setFontSize(12);
          doc.setLineDashPattern([2, 30, 5000], 0);
          doc.setFont(undefined, 'bold');
        }

        y += 12;
        yBorda = 1;
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        doc.text((item.status == 'AUTORIZADO' ? ' AUTORIZAÇÃO' : (item.status == 'AGENDADO' ? 'AGENDAMENTO' : 'REALIZAÇÃO')), 13, y).setFont(undefined, 'bold');
        doc.setFontSize(10);

        y += 6;
        yBorda += 6;
        ylinha += 4;
        doc.text("Em:", 15, y).setFont(undefined, 'normal');
        doc.text(item.atualizadoEm != null ? this.global.maskDataHora(item.atualizadoEm) : '-', 23, y).setFont(undefined, 'bold');


        y += 4;
        yBorda += 4;
        ylinha += 4;
        doc.text("Regulado por:", 15, y).setFont(undefined, 'normal');
        doc.text(item.usuario != null ? item.usuario : '-', 40, y).setFont(undefined, 'bold');

        if (item.status == "AUTORIZADO") {
          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Criticidade:", 15, y).setFont(undefined, 'normal');
          doc.text(item.criticidade != null ? item.criticidade : '-', 35, y).setFont(undefined, 'bold');
        }

        if (item.status == 'REALIZADO') {
          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Diagnóstico:", 15, y).setFont(undefined, 'normal');
          doc.text(this.dadosImpressoHistorico.cidRealizacao != null ? this.dadosImpressoHistorico.cidRealizacao : '-', 37, y).setFont(undefined, 'bold');

          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Unidade executante:", 15, y).setFont(undefined, 'normal');
          doc.text(this.dadosImpressoHistorico.estabelecimentoExecutor != null ? this.dadosImpressoHistorico.estabelecimentoExecutor : '-', 50, y).setFont(undefined, 'bold');

          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Data / hora realização:", 15, y).setFont(undefined, 'normal');
          doc.text(this.dadosImpressoHistorico.realizadoEm != null ? this.global.maskDataHora(this.dadosImpressoHistorico.realizadoEm) : '-', 54, y).setFont(undefined, 'bold');

          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Parecer / conduta especialidade:", 15, y).setFont(undefined, 'normal');

          texto = this.formatTextPdf(this.dadosImpressoHistorico.parecer, doc).texto;
          quantLinha = this.formatTextPdf(this.dadosImpressoHistorico.parecer, doc).quantLinha;

          doc.text(texto, 71, y).setFont(undefined, 'bold');

          y += quantLinha;
          yBorda += quantLinha;
          ylinha += quantLinha;

          //doc.text(this.dadosImpressoHistorico.parecer != null ? this.dadosImpressoHistorico.parecer : '-', 71, y).setFont(undefined, 'bold');
        }

        if (item.status == "AUTORIZADO" || item.status == "REALIZADO") {
          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Observação:", 15, y).setFont(undefined, 'normal');

          if (item.status == "AUTORIZADO") {
            texto = this.formatTextPdf(this.dadosImpressoHistorico.observacao, doc).texto;
            quantLinha = this.formatTextPdf(this.dadosImpressoHistorico.observacao, doc).quantLinha;
          }
          if (item.status == "REALIZADO") {
            texto = this.formatTextPdf(this.dadosImpressoHistorico.observacaoRealizacao, doc).texto;
            quantLinha = this.formatTextPdf(this.dadosImpressoHistorico.observacaoRealizacao, doc).quantLinha;
          }


          doc.text(texto, 37, y).setFont(undefined, 'bold');

          y += quantLinha;
          yBorda += quantLinha;
          ylinha += quantLinha;

        }

        if (item.status == "REALIZADO") {
          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Profissional executante / conselho:", 15, y).setFont(undefined, 'normal');
          texto = this.formatTextPdf(this.dadosImpressoHistorico.profissionalRealizacao, doc).texto;
          quantLinha = this.formatTextPdf(this.dadosImpressoHistorico.profissionalRealizacao, doc).quantLinha;
          doc.text(texto, 75, y).setFont(undefined, 'bold');

          y += quantLinha;

          yBorda += quantLinha;
          ylinha += quantLinha;

        }

        if (item.status == 'AGENDADO') {
          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Unidade agendada:", 15, y).setFont(undefined, 'normal');
          doc.text(this.dadosImpressoHistorico.estabelecimentoExecutor != null ? this.dadosImpressoHistorico.estabelecimentoExecutor : '-', 49, y).setFont(undefined, 'bold');

          y += 4;
          yBorda += 4;
          ylinha += 4;
          doc.text("Data / hora agendamento:", 15, y).setFont(undefined, 'normal');
          doc.text(this.dadosImpressoHistorico.agendadoEm != null ? this.dadosImpressoHistorico.agendadoEm : '-', 60, y).setFont(undefined, 'bold');

          y += 4;
          yBorda += 4;
          ylinha += 4;
        }


        doc.rect(10, y - yBorda, 190, ylinha);
        ylinha = 5;
        yBorda = 0;
      }

    })
    Swal.close();
    doc.save("impresso_historico_regulacao.pdf");

  }

  formatTextPdf(string, doc) {
    let quantLinha = 0;
    let txt = "";
    if (string != null && string != "") {
      if (string.split("\n").length > 1) {
        quantLinha += string.split("\n").length * 4;
        var linhas = string.split("\n");
        linhas.forEach((l) => {
          if (Math.ceil(l.length / 75) > 1)
            quantLinha += (Math.ceil(l.length / 75) - 1) * 4;
        })
      }
      else
        quantLinha = (Math.ceil(string.length / 75) * 4);
      txt = doc.splitTextToSize(string, 150);
    } else {
      quantLinha = 4;
      txt = "";
    }

    return { "quantLinha": quantLinha, "texto": txt }
  }

  imprimirSolicitacaoExame = (historico) => {
    this.solicitacaoExame = {}
    this.solicitacaoExame = historico
  }

  limparImpressos = () => {
    this.regulacaoId = ''
    this.solicitacaoExame = {}
    setTimeout(() => {
      Swal.close();
    }, 500)
  }

  alterarDataAgendamento = () => {
    var data = new Date();
    var dataAtual: any = (data.getDate().toString().length == 1 ? '0' + data.getDate().toString() : data.getDate().toString()) + ((data.getMonth() + 1).toString().length == 1 ? '0' + (data.getMonth() + 1).toString() : (data.getMonth() + 1).toString()) + data.getFullYear();
    this.filtroAgendamento = {}
    this.filtroAgendamento.estabelecimento_id = 0;
    this.filtroAgendamento.ocupacao_id = 0;
    this.filtroAgendamento.profissional_id = 0;
    this.filtroAgendamento.data_agenda_inicio = dataAtual;
    this.filtroAgendamento.data_agenda_fim = dataAtual;
    this.filtroAgendamento.visualisandoHorarios = false;
    this.filtroAgendamento.exibirAgenda = false;
    this.filtroAgendamento.agendas = [];
    this.alterandoAgenda = true;
  }

  goBack = () => {
    this.alterandoAgenda = false;
  }

  resultPostAgendamento = () => {
    if (this.tipoRegulacaoHistorico == 1)
      this.obterRegulacaoConsulta(this.consulta.id);
    if (this.tipoRegulacaoHistorico == 2)
      this.obterRegulacaoExame(this.consulta.id);
    this.alterandoAgenda = false;
  }

}
