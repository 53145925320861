import { Component, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { Global } from '../../global';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
declare var Simditor: any;
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';

@Component({
  selector: 'app-prontuario',
  templateUrl: './prontuario.component.html',
  styleUrls: ['./prontuario.component.css']
})
export class ProntuarioComponent implements OnInit, OnDestroy {
  cidadao: any = {};
  alergiaAbaAlergia: any = {};
  classificacao: any = {};
  classificacaoEditando: any = {};
  atendimento: any;
  global_: any;
  usuario: any;
  presenca: any;
  loading: any = false;
  clinicasPorBloco: any = [];

  abaAnamneseSelecionada: any = true;
  abaAdmissaoSelecionada: any = false;

  listaTipoPrioridade: any = [
    {
      id: 1,
      nome: `Gestante`
    },
    {
      id: 2,
      nome: `Idoso`
    },
    {
      id: 3,
      nome: `Necessidades Especiais`
    },
    {
      id: 4,
      nome: `Outros`
    },
  ];

  listaAbaProcedimento: any = [];
  listaCondutas: any = [];
  condutaSelecionada: any = {};
  uid: any;
  seeClassification: any = false;

  buscaListaExames: any = []
  buscaListaReceituarios: any = []
  listaAbaPrescricaoUrgencia: any = [];
  ListaAbaAtestado: any = []
  listaAbaTransferencia: any = [];
  listaAbaAdmissaoMed: any = [];

  prontuario: any = {};
  anamnese: any = {};
  listaCid: any = []
  listaConduta: any = [];
  listaEvolucao: any = [];

  listaEncaminhamentos: any = [];

  historicoAtendimentos: any = [];
  abaHistoricoAtendimentos: any = [];
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];

  tipo_impresso: any;
  classificacaoRisco: any = {};
  dadosImpresso: any = null;
  teleatendimento: any;
  atendimentoTeleAtendimento: any = false;

  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario: any = false;

  encerramentoProntario: any = {};

  listaPsicologia: any = [];
  constructor(public service: ApiService,
    private global: Global,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alerts: NgxAlertsService,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    private router: Router,
  ) {
    Simditor.locale = 'en-US';
    this.global_ = global;
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;

      }
    });
    this.authService.tipoImpresso.subscribe(res => {
      if (res) {
        this.tipo_impresso = res;
      }
    });
    this.authService.teleAtendimento.subscribe(res => {
      if (res) {
        this.teleatendimento = res;
      }
    });

    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnDestroy(): void {
    this.authService.setVisualizarProntuario(false);
  }

  ngOnInit() {
    this.init();
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (uid != null) {
      await this.obterAtendimento(uid);
    }
  }

  obterAtendimento(uuid: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Atendimento/${uuid}`).then(res => {
      Swal.close();
      this.teleatendimento = false;
      this.atendimento = res;
      
      this.atendimentoTeleAtendimento = res["agenda_hora_agenda_dia_local_atendimento"] == "PRESENCIAL" || res['tipo_atendimento'] == "DEMANDA_ESPONTANEA" ? false : true;
      if (this.atendimento.status_atendimento == 'ATENDIMENTO_ENCERRADO') {
        if (this.usuario.usuario_profissional_id != this.atendimento.profissional_id) {
          setTimeout(() => {
            Swal.fire('Alerta', 'Acesso ao prontuário negado', 'warning').then(
              result => {
                if (result.isConfirmed) {
                  this.router.navigate(['/atendimento']);
                }
              }
            )
          }, 1000);
        } else {
          this.iniciarTela(uuid, res['cidadao_id']);
          this.authService.setVisualizarProntuario(true);
        }
      } else {
        this.iniciarTela(uuid, res['cidadao_id']);
        this.authService.setVisualizarProntuario(false);
      }
    }).catch(res => {
      Swal.close();
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  iniciarTela(uuid, cidadao_id) {
    this.getProntuarioAtendimento(uuid);
    this.carregarAbaHistorico();
    this.getCidadao(cidadao_id);
    this.obterListaConduta();
  }

  getCidadao = (id) => {
    this.service.Get(`Cidadao/${id}`).then(res => {
      this.cidadao = res;
      this.cidadao.nascido_em_ = this.global.dateFormat(this.cidadao.nascido_em);
      this.cidadao.atendimento_clinica_medica_nome = this.atendimento.clinica_medica_nome;
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }

  getClassificacaoRisco = (id) => {
    this.service.Get(`Atendimento/${id}/ClassificacaoRisco`).then((res: any) => {
      if (res[`length`] > 0) {
        var arrayOrdenado = res.sort((a, b) => (a.criado_em < b.criado_em ? -1 : 1));
        this.classificacao = Object.assign({}, arrayOrdenado[arrayOrdenado.length - 1]);
        this.classificacaoEditando = Object.assign({}, arrayOrdenado[arrayOrdenado.length - 1]);
      }
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }

  getAnamnese = (prontuario_id) => {
    this.service.Get(`Prontuario/${prontuario_id}/Anamnese`).then(res => {
      if (res[`length`] > 0) {
        var anamneses: any = res;
        var lastArray = anamneses.pop();
        this.anamnese = lastArray;
        this.listaCid = [];
        this.popularQueixaAnamnese(this.anamnese.queixa);
        this.anamnese.cids.forEach((item) => {
          this.listaCid.push(item);
        });
        this.listaConduta = [];
        this.anamnese.condutas.forEach((item) => {
          this.listaConduta.push(item);
        });
      };
      this.getClassificacaoRisco(this.uid);
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }


  openModalalergia = () => {
    this.alergiaAbaAlergia = {};
    $("#modalAlergiasCidadao").toggleClass("show");
    $("#modalAlergiasCidadao").css("display", "block");
  }

  getTipoPrioridade = (tipo_prioridade: any) => {
    var filter = this.listaTipoPrioridade.filter(x => x.id == tipo_prioridade);
    if (filter.length > 0) {
      return filter[0].nome;
    }
  }

  obterListaConduta = () => {
    this.service.Get(`CondutaEncaminhamento`).then(res => {
      this.listaCondutas = res;
      this.listaCondutas.unshift({ id: 0, nome: 'Selecione..' });
      this.condutaSelecionada = this.listaCondutas[0];
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  retornoPostAbaProc = () => {
    this.obterListaAbaProcedimento();
  }

  retornoPostAbaPrescUrg = () => {
    this.obterListaAbaPrescricaoUrgencia();
  }

  obterListaAbaPrescricaoUrgencia = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/PrescricaoUrgencia`).then(res => {
      this.listaAbaPrescricaoUrgencia = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  obterListaAbaProcedimento = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.uid}/Solicitacao?tipo_solicitacao=PROCEDIMENTO`).then(res => {
      this.listaAbaProcedimento = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  getProntuarioAtendimento = (id) => {
    this.service.Get(`Atendimento/${id}/Prontuario`).then(res => {
      if (res[`length`] > 0) {
        var prontuarios: any = res;
        var lastArray = prontuarios.pop();
        this.prontuario = lastArray;
        this.getAnamnese(this.prontuario.id);
      }
    }).catch(res => {
      this.alerts.notifyError({
        body: `${res.mensagem}`,
        title: 'Erro'
      });
      Swal.close();
    })
  }

  obterListaExames = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.uid}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.buscaListaExames = result;
        this.carregarHistorico(this.atendimento.cidadao_id)
        Swal.close()
      }
    )
  }

  obterListaReceituarios = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.uid}/Receituario`).then(
      result => {
        this.buscaListaReceituarios = result
        Swal.close()
      }
    )
  }

  obterListaAtestado = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/ProntuarioAtestado`).then(
      result => {
        this.ListaAbaAtestado = result
        Swal.close()
      }
    )
  }

  obterListaTransferencia = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/Transferencia`).then(res => {
      this.listaAbaTransferencia = res;
      this.getClinicasPorBloco();
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  obterListaEvolucao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/ProntuarioEvolucao`).then(
      result => {
        this.listaEvolucao = result
        Swal.close();
      },
      error => {
        if (error.codigo == 'NotFound') {
          Swal.fire('Atenção', error.error.messagem, 'warning')
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  obterListaEncaminhamentos = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.atendimento.id}/Encaminhamento`).then(
      result => {
        this.listaEncaminhamentos = result
        Swal.close()
      }
    )
  }

  popularQueixaAnamnese(queixa) {
    var that = this;
    setTimeout(() => {
      var descricao = new Simditor({
        textarea: $('#queixaPrincipalAnamnese'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
      descricao.setValue(queixa)
    }, 1000);
  }

  retornoPostAbaAdmissaoMed = () => {
    this.obterListaAbaAdmissaoMed();
  }

  obterListaAbaAdmissaoMed = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.abaAnamneseSelecionada = false;
    this.abaAdmissaoSelecionada = true;
    this.service.Get(`Prontuario/${this.prontuario.id}/AdmissaoMedica`).then(res => {
      this.listaAbaAdmissaoMed = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.message}`, 'error');
    });
  }

  carregarAbaAnamnese() {
    this.abaAnamneseSelecionada = true;
    this.abaAdmissaoSelecionada = false;
  }

  getClinicasPorBloco = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Bloco/${this.presenca.bloco_id}/ClinicaMedica`).then(
      result => {
        this.clinicasPorBloco = result
        Swal.close()
      }
    )
  }

  carregarHistorico(cidadao_id) {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Cidadao/${cidadao_id}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.historicoAtendimentos = result
        Swal.close()
      }
    )
  }

  verMais() {

  }

  visualizarClassificacao = (item) => {
    this.classificacaoRisco = item;
    $("#modalClassificacao").toggleClass("show");
    $("#modalClassificacao").css("display", "block");
  }

  fecharModalClassificacaoRisco = () => {
    this.classificacaoRisco = null;
    $("#modalClassificacao").toggleClass("show");
    $("#modalClassificacao").css("display", "none");
  }

  carregarAbaHistorico() {
    this.service.Get(`Cidadao/${this.atendimento.cidadao_id}/Prontuario?status=FECHADO`).then(
      result => {
        this.abaHistoricoAtendimentos = result
      }
    )
  }


  imprimirFaa() {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Prontuario/${this.prontuario.id}/ImpressoEmergencia`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }

  gerarTabelaAlegia(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <span>${element.tipo_alergia} - ${element.medicamento_codigo_catmat ? element.medicamento_codigo_catmat : '-'} - ${element.medicamento_principio_ativo ? element.medicamento_principio_ativo : '-'}</span>
        <br/>  
  `
    });
    return td;
  }

  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <span>${element.cid_subcategoria_codigo} - ${element.cid_subcategoria_nome}</span>
        <br/>     
  `
    });
    return td;
  }

  gerarTabelaSolicitacoes(lista) {
    var td = ``;
    lista.forEach(item => {
      item.procedimentos.forEach(element => {
        td +=
          `
        <tr>
          <td>
            <p>${element.protocolo}</p>
          </td>
          <td>
            <p>${element.sigtap_codigo}</p>
          </td>
          <td>
            <p>${element.sigtap_nome}</p>
          </td>
          <td>
            <p>${element.tipo_procedimento}</p>
          </td>
          <td>
            <p>${element.criticidade}</p>
          </td>
          <td>
            <p>${element.status}</p>
          </td>
        </tr>
  `
      })
    });
    return td;
  }

  gerarTabelaPrescricao(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <tr>
          <td>
            <p>${element.protocolo}</p>
          </td>
          <td>
            <p>${element.presenca_profissional_nome} ${element.presenca_profissional_conselho_classe_Sigla}-${element.presenca_profissional_conselho_estado_sigla} ${element.presenca_profissional_numero_registro}</p>
          </td>
          <td>
          <p>${this.global.formatDateTime(element.prescrito_em)}</p>
          </td>
          <td>
            <p>${element.texto}</p>
          </td>
          <td>
            <p></p>
          </td>
      </tr>
  `
    });
    return td;
  }

  prepararImpressoHtml(dadosImpresso, apac = false) {
    var html = `
    <html lang="en">
     ${this.global.headerImpresso(dadosImpresso)}
      <body>
        <div>
        ${this.global.cabecalhoImpresso(dadosImpresso, "ATENDIMENTO DE URGÊNCIA")}
          <table class="apac">
            <thead></thead>
            <tbody>
              <caption>
                <h4>CLASSIFICAÇÃO DE RISCO</h4>
              </caption>
              <tr rowspan="2">
                <td colspan="1">
                  <p>Risco: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.classificacao_prioridade : ''}</p>                  
                </td>
                <td colspan="4">
                  <p>Profissional: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.presenca_profissional_nome : ""}</p>                  
                </td>
                <td colspan="1">
                  <p>Data: ${dadosImpresso.conteudo.classificacao_risco ? this.global.formatDateTime(dadosImpresso.conteudo.classificacao_risco.classificado_em) : ""}</p>                  
                </td>
              </tr>
              <tr rowspan="2">
                <td colspan="1">
                  <p>FR: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.frequencia_respiratoria : ""} mrpm</p>                  
                </td>
                <td colspan="1">
                  <p>SatO2: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.saturacao_oxigenio : ""} %</p>                  
                </td>
                <td colspan="1">
                  <p>PA: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.sistole : ""} / ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.diastole : ""} mmHg</p>                  
                </td>
                <td colspan="1">
                 <p>FC: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.frequencia_cardiaca : ""} bpm</p>                  
                </td>
                <td colspan="1">
                  <p>T: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.temperatura : ""}ºC</p>                  
                </td>
                <td colspan="1">
                  <p>DX: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.glicemia_capilar : ""} mg/dl</p>                  
                </td>
              </tr>
             
              <tr>
                <td colspan="1">
                  <p>Dor: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.escala_dor ? dadosImpresso.conteudo.classificacao_risco.escala_dor + "/6" : "0/6" : ""}</p>                  
                </td>
                <td colspan="5">
                  <p>Prioridade: ${dadosImpresso.conteudo.classificacao_risco ? dadosImpresso.conteudo.classificacao_risco.classificacao_prioridade : ""} ${dadosImpresso.conteudo.classificacao_risco && dadosImpresso.conteudo.classificacao_risco.prioridade ? dadosImpresso.conteudo.classificacao_risco.tipo_prioridade : ""}</p>                  
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
          <table>
            <tr colspan="10">
              <td class="title">
                <h4>ATENDIMENTO CLÍNICO</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr colspan="10">
              <td class="cabecalho">
                <h4>DADOS CLÍNICOS (ANAMNESE / EXAME CLÍNICO):</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <h1>${dadosImpresso.conteudo.anamnese.length > 0 ? dadosImpresso.conteudo.anamnese[0].queixa : ''}</h1>
              </td>
            </tr>
          </table>
          <table>
            <tr>
                <td>
                    <h4>Alergias</h4>
                    ${this.gerarTabelaAlegia(dadosImpresso.conteudo.alergia)}                 
                </td>
                <td>
                    <h4>Cids</h4>
                    ${this.gerarTabela(dadosImpresso.conteudo.cid_informado)}                    
                </td>
              
            </tr>         
        </table>         
          <table>
            <tr colspan="10">
              <td class="cabecalho">
                <h4>EXAMES / PROCEDIMENTOS:</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Protocolo </b>
              </td>   
              <td>
                <b>Código </b>
              </td>            
              <td>
                <b>Procedimento</b>
              </td>
              <td>
                <b>Solicitação </b>
              </td>
              <td>
                <b>Criticidade </b>
              </td>
              <td>
                <b>Status </b>
              </td>
            </tr>
            <tr> ${this.gerarTabelaSolicitacoes(dadosImpresso.conteudo.solicitacao)} </tr>
          </table>
          <table>
            <tr colspan="10">
              <td class="cabecalho">
                <h4>PRESCRIÇÃO:</h4>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b> Protocolo </b>
              </td>
              <td>
                <b> Profissional </b>
              </td>
              <td>
                <b>Data da Prescrição </b>
              </td>
              <td>
                <b> Descrição </b>
              </td>
              <td>
                <b> Data da Administração </b>
              </td>
            </tr>
            <tr> ${this.gerarTabelaPrescricao(dadosImpresso.conteudo.prescricao_urgencia)} </tr>
          </table>
          ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
          <table>
            <tr colspan="10">
              <td class="title">
                  <h4>ANUÊNCIA DO USUÁRIO</h4>
              </td>
            </tr>
          </table>
     
          <table>
            <tr>
              <td class="border-top">                 
                  <p>Eu, ${dadosImpresso.cidadao.nome}, declaro que os dados cadastrais informados são a expressão da
                      verdade e fui atendido na data e hora registrada neste documento, bem como pelos profissionais que o
                      subscrevem.</p>
                      <div class="line-assinatura"></div>
                  <p>Assinatura do Paciente ou responsável </p>
              </td>
            </tr>
          </table>      
        </div>
      </body>
    </html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

  reduzirCasaDecimal(valor) {
    if (valor == null)
      return '-';
    return parseFloat(valor.toFixed(2))
  }


  obterEncerramento = () => {
    if (this.visualizar_prontuario) {
      Swal.fire('Favor aguarde')
      Swal.showLoading();
      this.service.Get(`Prontuario/${this.prontuario.id}/Encerramento`).then(
        result => {
          this.encerramentoProntario = result
          Swal.close()
        }
      )
    }
  }

  obterListaPsicologia = () => {
    if (!this.prontuario) {
      return;
    }
    this.service.Get(`AtendimentoRestrito?cidadao_id=${this.atendimento.cidadao_id}`).then(
      result => {
        this.listaPsicologia = result
      },
      error => {
        if (error.codigo == 'NotFound') {
          Swal.fire('Atenção', error.error.messagem, 'warning')
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

}
