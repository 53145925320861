import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { Global } from '../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
declare var Simditor: any;

@Component({
  selector: 'app-finalize-service',
  templateUrl: './finalize-service.component.html',
  styleUrls: ['./finalize-service.component.css']
})
export class FinalizeServiceComponent implements OnInit, OnChanges {
  usuario: any = {};
  presenca: any;
  encerramento: any = {};
  @Input() prontuario: any = {};
  @Input() listaCondutaSelecionada: any = [];
  @Input() listaCondutas: any = [];
  @Input() encerramentoProntario: any = null;
  motivosEncerramentos: any = [];
  motivo_encerramento_id: any = 0;
  motivoSelecionado: any = null;

  /// editor de texto
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]
  visualizar_prontuario: any = false;

  constructor(
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    this.obterMotivosEncerramentos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {

      if (!this.visualizar_prontuario) {
        var descricao = new Simditor({
          textarea: $('#encerramentoObservacao'),
          upload: false,
          tabIndent: true,
          toolbar: this.toolbar,
          toolbarFloat: true,
        });
      }

      if (this.visualizar_prontuario) {
        this.motivo_encerramento_id = this.encerramentoProntario.motivo_encerramento_id
        this.encerramento.texto = this.encerramentoProntario.texto;
        this.listaCondutaSelecionada = this.encerramentoProntario.condutas;
        $("#observacaoTexto").html(this.encerramentoProntario.texto);
      }

    } catch (error) {

    }

  }


  obterEncerramento = () => {
    this.service.Get(`Prontuario/${this.prontuario.id}/Encerramento`).then(
      result => {
        this.encerramentoProntario = result;
        this.motivo_encerramento_id = this.encerramentoProntario.motivo_encerramento_id
        this.encerramento.texto = this.encerramentoProntario.texto;

      }
    )
  }

  adicionarConduta(item) {
    if (!item) return;
    if (item.id == 0) return;
    if (this.listaCondutaSelecionada.length == 0) {
      item.conduta_encaminhamento_id = item.id;
      item.conduta_encaminhamento_nome = item.nome;
      this.listaCondutaSelecionada.push(item);
    }
    else {
      var repetido = false;
      this.listaCondutaSelecionada.forEach((s) => {
        if (s.id == item.id)
          repetido = true;
      });
      if (!repetido) {
        item.conduta_encaminhamento_id = item.id;
        item.conduta_encaminhamento_nome = item.nome;
        this.listaCondutaSelecionada.push(item);
      }

    }

  }

  removerConduta(i) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      html: 'A operação só será efetivada após clicar no botão de salvar!',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      result => {
        if (result.isConfirmed) {
          this.listaCondutaSelecionada.splice(i, 1);
        }
      }
    );
  }

  finalizarAtendimento = () => {

    var obj: any = {
      presenca_id: this.presenca.id,
      motivo_encerramento_id: this.motivo_encerramento_id,
      texto: $("#encerramentoObservacao").val()
    }
    obj.condutas = [];
    this.listaCondutaSelecionada.forEach((item) => {
      obj.condutas.push({ conduta_encaminhamento_id: item.conduta_encaminhamento_id });
    });


    if (this.motivo_encerramento_id == 0) {
      Swal.fire('Erro', 'Favor inserir um motivo de encerramento', 'error');
      return
    }

    if (obj.condutas.length == 0) {
      Swal.fire('Erro', 'Favor inserir ao menos uma conduta', 'error');
      return
    }


    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Post(`Prontuario/${this.prontuario.id}/Encerramento`, obj).then(
      result => {
        Swal.fire('Sucesso', 'Atendimento finalizado com sucesso', 'success').then(
          result => {
            if (result.isConfirmed) {
              this.router.navigate(['/atendimento']);
            }
          }
        )
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error');
      }
    )
  }

  obterMotivosEncerramentos = () => {
    this.service.Get(`MotivoEncerramento`).then(
      result => {
        this.motivosEncerramentos = result;
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error');
      }
    )
  }

  selecionarMotivo(motivo_id) {
    if (motivo_id) {
      var fil = this.motivosEncerramentos.filter((x: any) => x.id == motivo_id)
      if (fil.length > 0) {
        this.motivoSelecionado = fil[0];
      }
    }
  }
}
