import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as $ from "jquery";
import { AuthService } from '../../service/auth/auth.service'
import { Global } from 'src/app/global';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/service/apiServices';
@Component({
  selector: 'app-modal-agendamento',
  templateUrl: './modal-agendamento.component.html',
  styleUrls: ['./modal-agendamento.component.css']
})
export class ModalAgendamentoComponent implements OnChanges {
  @Input() consulta: any = {};
  @Input() cidadao: any = {};
  @Output() resultPostModalAgendamento: EventEmitter<any> = new EventEmitter();
  @Input() regulacao: any = {};
  @Input() filtro: any = {};
  estabelecimentos: any = [];
  ocupacoes: any = [];
  profissionais: any = [];

  dataHoje = new Date();

  dataAtual: any = ('0' + this.dataHoje.getDate()).slice(-2) +
    ('0' + (this.dataHoje.getMonth() + 1)).slice(-2) + this.dataHoje.getFullYear();

  trintaDias = new Date(new Date().setDate(this.dataHoje.getDate() + 30));

  dataFuturo: any = ('0' + (this.trintaDias.getDate())).slice(-2) +
    ('0' + (this.trintaDias.getMonth() + 1)).slice(-2) + this.trintaDias.getFullYear();

  horariosList: any = [];
  agenda: any = {};
  presenca: any = {};
  usuario: any;
  constructor(
    private authService: AuthService,
    private global: Global,
    public service: ApiService,
  ) {

    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });

    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnChanges() {
    this.filtro = []
    this.estabelecimentos = []
    this.ocupacoes = [];
    this.profissionais = [];
    this.filtro.estabelecimento_id = 0;
    this.filtro.ocupacao_id = 0;
    this.filtro.profissional_id = 0;
    this.filtro.data_agenda_inicio = this.dataAtual;
    this.filtro.data_agenda_fim = this.dataFuturo;
    this.filtro.agendas = [];
    if (this.cidadao) {
      this.obterListaEstabelecimentos();
    }
  }

  fecharModal = () => {
    this.resultPostModalAgendamento.emit();
    $("#modalAgendamento").toggleClass("show");
    $("#modalAgendamento").css("display", "none");
  }

  carregarOcupacao = () => {
    if (this.filtro.estabelecimento_id != null && this.filtro.estabelecimento_id != 0) {
      this.obterListaOcupacao(this.filtro.estabelecimento_id);
    }
  }
  obterListaEstabelecimentos = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento`).then(res => {
      this.estabelecimentos = res;
      Swal.close()
      // this.obterListaOcupacao();
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }


  obterListaOcupacao = (estabelecimento_id: any) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento/${estabelecimento_id}/Ocupacao`).then(res => {
      this.ocupacoes = res;
      // this.obterListaProfissionais(this.filtro.ocupacao_id)
      Swal.close();
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }

  obterListaProfissionais = (id) => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    if (id == undefined) { return }
    this.service.Get(`Profissional?OcupacaoId=${id}`).then(res => {
      Swal.close();
      this.profissionais = res;
    }).catch(res => {
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
    })
  }


  pesquisarAgenda = () => {

    if (this.filtro.estabelecimento_id == 0) {
      Swal.fire('Erro', "Favor selecionar o estabelecimento", 'error');
      return false;
    }
    if (this.filtro.ocupacao_id == 0) {
      Swal.fire('Erro', "Favor selecionar a ocupação", 'error');
      return false;
    }

    var data_inicio = this.dataAtual[4] + this.dataAtual[5] + this.dataAtual[6] + this.dataAtual[7] + "-" + this.dataAtual[2] + this.dataAtual[3] + "-" + this.dataAtual[0] + this.dataAtual[1];
    var data_fim = this.dataAtual[4] + this.dataAtual[5] + this.dataAtual[6] + this.dataAtual[7] + "-" + this.dataAtual[2] + this.dataAtual[3] + "-" + this.dataAtual[0] + this.dataAtual[1];

    if (this.filtro.data_agenda_inicio.length == 8)
      data_inicio = this.filtro.data_agenda_inicio[4] + this.filtro.data_agenda_inicio[5] + this.filtro.data_agenda_inicio[6] + this.filtro.data_agenda_inicio[7] + "-" + this.filtro.data_agenda_inicio[2] + this.filtro.data_agenda_inicio[3] + "-" + this.filtro.data_agenda_inicio[0] + this.filtro.data_agenda_inicio[1];

    if (this.filtro.data_agenda_fim.length == 8)
      data_fim = this.filtro.data_agenda_fim[4] + this.filtro.data_agenda_fim[5] + this.filtro.data_agenda_fim[6] + this.filtro.data_agenda_fim[7] + "-" + this.filtro.data_agenda_fim[2] + this.filtro.data_agenda_fim[3] + "-" + this.filtro.data_agenda_fim[0] + this.filtro.data_agenda_fim[1];

    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`AgendaDia?EstabelecimentoId=${this.filtro.estabelecimento_id}&OcupacaoId=${this.filtro.ocupacao_id}&ProfissionalId=${this.filtro.profissional_id}&DataAgendaInicio=${data_inicio}&DataAgendaFim=${data_fim}`).then(res => {
      Swal.close();
      //this.exibirAgenda = true;
      this.filtro.exibirAgenda = true;
      this.filtro.agendas = res;
    }).catch(res => {
      //this.exibirAgenda = false;
      this.filtro.exibirAgenda = false;
      Swal.close();
      Swal.fire('Erro', `${res.error.messagem}`, 'error');
      this.filtro.agendas = [];
    })
  }

  exibirEsconderHorarios = (agenda) => {
    this.filtro.visualisandoHorarios = !this.filtro.visualisandoHorarios;
    if (agenda == null) {
      this.pesquisarAgenda();
      return;
    }
    this.agenda = agenda;
    this.horariosList = [];
    agenda.horarios.forEach((item) => {
      if (item.vago) {
        item.nome_profissional = agenda.profissionalNome;
        this.horariosList.push(item);
      }
    });
  }



  agendarPaciente = (hora, index) => {
    var h = hora.split(":")[0];
    var m = hora.split(":")[1];
    Swal.fire({
      title: 'Atenção',
      text: `Deseja agendar o paciente ${this.cidadao.nome} para o horário de ${h}:${m}?`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.agenda.horarios[index].cidadaoId = this.cidadao.id;
          this.agenda.horarios[index].cidadao_id = this.cidadao.id;
          this.agenda.horarios[index].vago = false;

          var obj = { "cidadao_id": this.cidadao.id, "agenda_hora_id": this.agenda.horarios[index].id,"agenda_configuracao_id":this.agenda.agendaConfiguracaoId, "agenda_inicio": `${this.agenda.dataAgendaInicio.split("T")[0]}T${this.agenda.horarios[index].hora}` }
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          if (this.agenda.horarios[index].id == null || this.agenda.horarios[index].id == 0)
            this.service.Post(`AgendaDia/Agendar`, obj).then((res) => {
              Swal.close();
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Operação realizada com sucesso!'
              }).then(
                result => {
                  if (result.isConfirmed) {
                    this.exibirEsconderHorarios(null);
                    setTimeout(() => {
                      Swal.close();
                    }, 1000);
                  }
                }
              );
            }).catch((err) => {
              if (err.status == 200) {
                Swal.close();
                Swal.fire({
                  icon: 'success',
                  title: 'Sucesso!',
                  html: 'Operação realizada com sucesso!'
                }).then(
                  result => {
                    if (result.isConfirmed) {
                      this.exibirEsconderHorarios(null);
                      setTimeout(() => {
                        Swal.close();
                      }, 1000);
                    }
                  }
                );
              } else {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Erro',
                  html: err.error.erros[0].messagem
                });
              }
            });

          else
            this.service.Patch_body(`AgendaHora/${this.agenda.horarios[index].id}`, { "ativo": true, "cidadaoId": this.cidadao.id, "UsuarioId": parseInt(this.usuario.usuario_id), "Tipo": 3 }).then((res) => {
              Swal.close();
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Operação realizada com sucesso!'
              }).then(
                result => {
                  if (result.isConfirmed) {
                    this.exibirEsconderHorarios(null);
                    setTimeout(() => {
                      Swal.close();
                    }, 1000);
                  }
                }
              );
            }).catch((err) => {
              if (err.status == 200) {
                Swal.close();
                Swal.fire({
                  icon: 'success',
                  title: 'Sucesso!',
                  html: 'Operação realizada com sucesso!'
                }).then(
                  result => {
                    if (result.isConfirmed) {
                      this.exibirEsconderHorarios(null);
                      setTimeout(() => {
                        Swal.close();
                      }, 1000);
                    }
                  }
                );
              } else {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Erro',
                  html: err.error.erros[0].messagem
                });
              }
            });
        }
      }
    );
  }

  atualizarAgenda = (agenda, index) => {

  }


}
