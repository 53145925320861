import { Component, Input, OnInit } from '@angular/core';
import { Global } from '../../../../global';
import * as $ from "jquery";
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
import * as moment from 'moment';
@Component({
  selector: 'app-declaration-list',
  templateUrl: './declaration-list.component.html',
  styleUrls: ['./declaration-list.component.css']
})
export class DeclarationListComponent implements OnInit {

  @Input() listaAbaDeclaracao: any = [];
  declaracao: any = {};
  @Input() prontuario: any = [];
  dadosImpresso: any;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  constructor(private global: Global,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    public service: ApiService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  visualizarDeclaracao(declaracao) {
    this.declaracao = declaracao;
    
    $("#modalVisualizarDeclaracao").toggleClass("show");
    $("#modalVisualizarDeclaracao").css("display", "block");
  }

  fecharModal = () => {
    $("#modalVisualizarDeclaracao").toggleClass("show");
    $("#modalVisualizarDeclaracao").css("display", "none");
  }

  imprimirDado(item: any) {
    this.carregarDadosImpresso(item.id);
  }

  carregarDadosImpresso(declaracao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`ProntuarioDeclaracao/${declaracao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }

  prepararImpressoHtml(dadosImpresso) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "DECLARAÇÃO")}

        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>DECLARAÇÃO</h4>
                </td>
            </tr>
        </table>
        <table>                     
             <tr>   
             <td>
                ${dadosImpresso.conteudo.texto}
             </td>              
            </tr>
        </table>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
    </div>
</body>
</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

}
