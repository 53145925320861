import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { Global } from '../../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})
export class PrescriptionComponent implements OnInit {

  seeList: any = true;
  listFavoriteText: any = []
  usuario: any = {};
  presenca: any;
  cidadao_id: any;
  atendimento_id: any;
  uid: any;
  textoFavorito: any;
  @Input() listaReceituarios: any = []
  visualizar_prontuario:any = false;
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {

  }

  openFavorites = () => {
    $("#modalListaTextosFavoritos").toggleClass("show");
    $("#modalListaTextosFavoritos").css("display", "block");
  }

  closeFavorites = () => {
    this.listFavoriteText = []
    $("#modalListaTextosFavoritos").toggleClass("show");
    $("#modalListaTextosFavoritos").css("display", "none");
  }

  openFavoritesList = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Get(`TextoFavorito?UsuarioId=${this.usuario.usuario_id}&TipoTextoFavorito=RECEITUARIO`).then(
      result => {
        this.listFavoriteText = result;
        Swal.close()
        this.openFavorites()
      },
      error => {
        Swal.fire('Error', error.error.messagem, 'error')
      }
    )

  }

  getListFavoriteText = () => {

  }

  obterlistaSolicitacaoReceituarios = (showLoading = true) => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (showLoading) {
      Swal.fire('Favor aguarde')
      Swal.showLoading();
    }
    this.service.Get(`Atendimento/${uid}/Receituario`).then(
      result => {
        this.listaReceituarios = result;
        
        if (showLoading) {
          this.seeList = !this.seeList;
        } else {
          this.seeList = true;
        }
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  utilizarTexto = (item: any) => {
    this.textoFavorito = item;
  }

  retornoPostFilho = ($event) => {
    this.closeFavorites();
    this.obterlistaSolicitacaoReceituarios(false);
  }

}
