import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../../../service/apiServices';
import { AuthService } from '../../../../../service/auth/auth.service';
import { Global } from '../../../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

declare var Simditor: any;

@Component({
  selector: 'app-prescription-registration',
  templateUrl: './prescription-registration.component.html',
  styleUrls: ['./prescription-registration.component.css']
})
export class PrescriptionRegistrationComponent implements OnInit {

  @Input() exibirModal = true;

  receituario: any = {}

  usuario: any = {};
  presenca: any;
  cidadao_id: any;
  atendimento_id: any;
  uid: any;

  @Input() textoSelecionado: any;
  @Output() fecharModalTextosFavoritos = new EventEmitter<boolean>();
  @Output() retornoPost = new EventEmitter<boolean>();



  /// editor de texto
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  constructor(private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    /// configuração do editor de texto
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    setTimeout(() => {
      var that = this;
      var descricao = new Simditor({
        textarea: $('#texto_receituario'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true,
        //optional options

      });
      Swal.close()
    }, 500);

  }

  ngOnChanges() {
    if (this.textoSelecionado != undefined && this.textoSelecionado != null) {
      this.utilizarTextoSelecionado()
    }
  }

  salvarReceituario = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();

    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;

    var Obj = {
      presenca_id: this.presenca.id,
      texto: this.receituario.nome,
      observacao: $('#texto_receituario').val()
    }

    this.service.Post(`Atendimento/${this.uid}/Receituario`, Obj).then(
      result => {
        this.receituario = {};
        this.retornoPost.emit(true);
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  salvarReceituarioFavorito = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var Obj = {
      usuario_id: parseInt(this.usuario.usuario_id),
      nome: this.receituario.nome,
      texto: $('#texto_receituario').val(),
      tipo: 'RECEITUARIO'
    }

    this.service.Post(`TextoFavorito`, Obj).then(
      result => {
        Swal.fire('Sucesso', 'Receituário adicionado aos favoritos com sucesso', 'success').then(() => {
          if (this.exibirModal) {
            this.receituario = {};
            this.retornoPost.emit(true);
          }
        });
      },
      error => {
        if (error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  utilizarTextoSelecionado = () => {
    this.receituario = this.textoSelecionado
    var $texto = this.textoSelecionado.texto
    var editor = new Simditor({
      textarea: $('#texto_receituario')
    })
    editor.setValue(this.textoSelecionado.texto)
    this.fecharModalTextosFavoritos.emit(true)
  }

}
