import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../service/auth/auth.service'

import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-list-exame-imagem',
  templateUrl: './list-exame-imagem.component.html',
  styleUrls: ['./list-exame-imagem.component.css']
})
export class ListExameImagemComponent implements OnInit {

  listaExamesImagem: any = []
  filtro: any = {};
  ativo: boolean = true;
  periodo: any = {};
  busca: any = 'busca';
  listaStatus: any = [
    { id: 1, status: true, desc: 'Ativo' },
    { id: 2, status: false, desc: 'Inativo' }
  ]

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.filtro.cidadao_busca = "";
    this.filtro.sigtap_busca = "";
    this.filtro.data_fim = moment().format("YYYY-MM-DD");
    this.filtro.data_inicial = moment().format("YYYY-MM-DD");
    this.listExamsImagem();
  }

  filtroLista() {
    if (this.listaExamesImagem.length === 0) {
      return this.listaExamesImagem;
    }
    return this.listaExamesImagem.filter(g => g.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  listExamsImagem = () => {
    var url = `cidadao_busca=${this.filtro.cidadao_busca}&sigtap_busca=${this.filtro.sigtap_busca}&data_inicial=${this.filtro.data_inicial}&data_fim=${this.filtro.data_fim}`;
    this.service.Get(`ExameImagem?${url}`).then(
      result => {
        this.listaExamesImagem = result;
        Swal.close()
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro!', error.error.messagem, 'error')
      }
    )
  }

  obterListaFiltros = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.listExamsImagem();
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse grupo de exames?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`GrupoSigtap/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaFiltros();
                }
              }
            );
          }).catch((err) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

  setPeriodo = (novoPeriodo: any) => {
    console.log(novoPeriodo)
    if (novoPeriodo.length > 0) {
      this.filtro.data_fim = this.global.dateServerMask(novoPeriodo[1])
      this.filtro.data_inicial = this.global.dateServerMask(novoPeriodo[0])
      return
    }
    this.filtro.data_fim = ''
    this.filtro.data_inicial = ''
  }

  limpaData = () => {
    this.periodo = {}
  }


  buscaSigtap = (item) => {
    
    // this.filtro.sigtap_busca = '';
    // this.filtro.sigtap_busca = item.id;
  }

  selecionarSigtap(item) {    
    this.filtro.sigtap_busca = '';    
    if (!item) return
    if (item.id) {
      this.filtro.sigtap_busca = item.id
    }
    // this.filtro.sigtap_busca = item.nome
  }



}
