import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ApiService } from 'src/app/service/apiServices';
declare var jquery: any;
declare var $: any;
import * as moment from 'moment';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  
  uid: any = 0;

  profile: any = {};
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private location: Location,
    private global: Global,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.init()
  }

  init = async () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    if (!this.uid){
      this.profile.ativo = true;
    }
    if (this.uid){
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.getProfile(this.uid)
    }
  }

  changeStatusProfile = () => {
    this.profile.ativo = !this.profile.ativo;
  }  

  goBack() {
    this.location.back();
  }

  saveProfile = () => {
    //this.loading = true;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.validateProfileData(this.profile).then(
      result => {
        var obj: any = {};
        obj = result;
        if (this.profile.id){
          this.service.Put(`Perfil/${obj.id}`, obj).then(
            result => {
              Swal.fire('Sucesso', 'Perfil atualizado com sucesso', 'success').then(
                result => {
                  if (result.isConfirmed){
                    this.loading = false;
                    this.goBack();
                    Swal.close();
                  }
                }
              )
            },
            error => {
              this.emitErro(error)
            }
          )
        }
        if (!this.profile.id){
          this.service.Post(`Perfil`, obj).then(
            result => {
              Swal.fire('Sucesso', 'Perfil criado com sucesso', 'success').then(
                result => {
                  if (result.isConfirmed){
                    this.loading = false;
                    Swal.close();
                    this.goBack();
                  }
                }
              )
            },
            error => {
              this.emitErro(error)
            }
          )
        }
      },
      error => {
        this.emitErro(error)
      }
    )
  }

  validateProfileData = (perfil) => {
    return new Promise((resolve, reject) => {
      if (!perfil.nome) {
        reject('É preciso informar um nome para o perfil')
        return
      }
      if (!perfil.pode_agendar_exame) {
        perfil.pode_agendar_exame = false
      }
      if (!perfil.pode_autorizar_exame) {
        perfil.pode_autorizar_exame = false
      }
      if (!perfil.pode_cancelar_exame) {
        perfil.pode_cancelar_exame = false
      }
      if (!perfil.pode_laudar_exame) {
        perfil.pode_laudar_exame = false
      }
      if (!perfil.pode_realizar_coletar_exame) {
        perfil.pode_realizar_coletar_exame = false
      }
      if (!perfil.pode_agendar_consulta) {
        perfil.pode_agendar_consulta = false
      }
      if (!perfil.pode_autorizar_consulta) {
        perfil.pode_autorizar_consulta = false
      }
      if (!perfil.pode_cancelar_consulta) {
        perfil.pode_cancelar_consulta = false
      }
      if (!perfil.pode_realizar_consulta) {
        perfil.pode_realizar_consulta = false
      }
      resolve(perfil)
    })
  }

  emitErro = (error) => {
    if (typeof error == 'string'){
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401){
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  getProfile = (uid) => {
    this.service.Get(`Perfil/${uid}`).then(
      result => {
        this.profile = result
        Swal.close()
      },
      error => {
        this.emitErro(error)
      }
    )
  }

}
