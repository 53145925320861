import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';
import { Global } from '../../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
declare var Simditor: any;
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/service/fileService';
@Component({
  selector: 'app-exams-attendance-list',
  templateUrl: './exams-list.component.html',
  styleUrls: ['./exams-list.component.css']
})
export class ExamsListComponent implements OnInit {
  listaSolicitacaoExames: any = []
  usuario: any = {};
  presenca: any;
  cidadao_id: any;
  atendimento_id: any;
  uid: any;
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ];
  exameSelecionado: any = {};
  solicitacaoExame: any = {};
  @Input() listaExames: any = []
  exame: any = {};

  fileToUpload: File | null = null;
  fileNameTOSave: any = "";
  tipoResultado: any = 1;
  resultadoExame: any = null;
  @Input() grupoExames: any = [];
  dadosImpresso: any;
  dataHoje: any = ''
  fileUrl: SafeUrl;
  visualizar_prontuario:any;
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
    Simditor.locale = 'en-US';
    var that = this;
    setTimeout(() => {
      var motivo = new Simditor({
        textarea: $('#exame_coleta'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
    }, 1000);
  }

  ngOnChanges() {
    this.listaSolicitacaoExames = this.listaExames;
  }

  obterlistaSolicitacaoExames = () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    this.service.Get(`Atendimento/${uid}/Procedimento`).then(
      result => {
        this.listaSolicitacaoExames = result;
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  visualizarExame = (item) => {
    this.solicitacaoExame = item;
    this.exame = {};
    $("#modalExame").toggleClass("show");
    $("#modalExame").css("display", "block");
    $("#modalColetaExame").css("display", "none");
    $("#modalAvaliarExame").css("display", "none");
    $("#modalCancelarExame").css("display", "none");
    $("#modalRealizarExame").css("display", "none");
  }

  fecharModal = () => {
    this.solicitacaoExame = null;
    $("#modalExame").toggleClass("show");
    $("#modalExame").css("display", "none");
  }

  coleta = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#exame_coleta')
    });
    editor.setValue('')
    $("#modalColetaExame").toggleClass("show");
    $("#modalColetaExame").css("display", "block");
  }

  fecharModalColeta = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_coleta')
    });
    editor.setValue('');
    $("#modalColetaExame").toggleClass("show");
    $("#modalColetaExame").css("display", "none");
  }

  confirmarColeta = () => {
    if (!$('#exame_coleta').val()) {
      return;
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = {
      "presenca_id": this.presenca.id,
      "texto": $('#exame_coleta').val()
    }
    this.service.Post(`Procedimento/${this.exameSelecionado.id}/Coletado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.obterSolicitacao();
            this.fecharModalColeta();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  avaliar = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#exame_avaliar')
    });
    editor.setValue('')
    $("#modalAvaliarExame").toggleClass("show");
    $("#modalAvaliarExame").css("display", "block");
  }

  fecharModalAvaliar = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_avaliar')
    });
    editor.setValue('');
    $("#modalAvaliarExame").toggleClass("show");
    $("#modalAvaliarExame").css("display", "none");
  }

  confirmarAvaliar = () => {
    if (!$('#exame_avaliar').val()) {
      return;
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = {
      "presenca_id": this.presenca.id,
      "texto": $('#exame_avaliar').val()
    }
    this.service.Post(`Procedimento/${this.exameSelecionado.id}/Avaliado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.obterSolicitacao();
            this.fecharModalAvaliar();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  cancelar = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#exame_cancelamento')
    });
    editor.setValue('')
    $("#modalCancelarExame").toggleClass("show");
    $("#modalCancelarExame").css("display", "block");
  }

  fecharModalCancelamento = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_cancelamento')
    });
    editor.setValue('');
    $("#modalCancelarExame").toggleClass("show");
    $("#modalCancelarExame").css("display", "none");
  }

  confirmarCancelar = () => {
    if (!$('#exame_cancelamento').val()) {
      return;
    }
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja cancelar esse exame?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();

          var obj = {
            "presenca_id": this.presenca.id,
            "texto": $('#exame_cancelamento').val()
          }
          this.service.Post(`Procedimento/${this.exameSelecionado.id}/Cancelado`, obj).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterSolicitacao();
                  this.fecharModalCancelamento();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.messagem
            });
          })
        }
      }
    );
  }

  realizar = (item) => {
    this.exameSelecionado = item;
    this.exame = {};
    $("#modalRealizarExame").toggleClass("show");
    $("#modalRealizarExame").css("display", "block");
    setTimeout(() => {
      var editor = new Simditor({
        textarea: $('#exame_resultado')
      });
      editor.setValue('');
    }, 500);
    this.limparCampos(1);
  }

  fecharModalRealizar = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#exame_resultado')
    });
    editor.setValue('');
    $("#modalRealizarExame").toggleClass("show");
    $("#modalRealizarExame").css("display", "none");
  }

  confirmarResultado = () => {
    if (this.tipoResultado == 1) {
      if (!$('#resultado_texto_procedimento').val() && !this.exame.resultado_em) {
        return;
      }
    }

    if (!this.exame.resultado_em) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: 'Favor validar a data o resultado'
      });
      return
    }
    if (this.tipoResultado == 2) {
      if (!this.fileNameTOSave) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: 'Favor validar se o arquivo foi enviado'
        });
        return
      }
    }

    Swal.fire('Favor aguarde');
    Swal.showLoading();

    var obj = [
      {
        "presenca_id": this.presenca.id,
        "resultado": this.tipoResultado == 1 ? $('#exame_resultado').val() : this.fileNameTOSave,
        "resultado_em": `${this.exame.resultado_em.split("/")[2]}-${this.exame.resultado_em.split("/")[1]}-${this.exame.resultado_em.split("/")[0]}`,
        "tipo_resultado": this.tipoResultado == 1 ? "TEXTO" : "ARQUIVO"
      }
    ]
    this.service.Post(`Procedimento/${this.exameSelecionado.id}/Resultado`, obj).then((res) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Operação realizada com sucesso!'
      }).then(
        result => {
          if (result.isConfirmed) {
            this.obterSolicitacao();
            this.fecharModalRealizar();
          }
        }
      );
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }


  cancelarSolicitacao = (item) => {
    this.exameSelecionado = item;
    var editor = new Simditor({
      textarea: $('#solicitacao_cancelamento')
    });
    editor.setValue('')
    $("#modalCancelarSolicitacao").toggleClass("show");
    $("#modalCancelarSolicitacao").css("display", "block");
  }

  fecharModalCancelamentoSolictacao = () => {
    this.exameSelecionado = null;
    var editor = new Simditor({
      textarea: $('#solicitacao_cancelamento')
    });
    editor.setValue('');
    $("#modalCancelarSolicitacao").toggleClass("show");
    $("#modalCancelarSolicitacao").css("display", "none");
  }

  confirmationAtivarDesativar = (id, ativo) => {
    if (!$('#solicitacao_cancelamento').val()) {
      return;
    }
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja cancelar essa solicitacao?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();

          var obj = {
            "presenca_id": this.presenca.id,
            "texto": $('#solicitacao_cancelamento').val()
          }
          this.service.Post(`Solicitacao/${this.exameSelecionado.id}/Cancelado`, obj).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaExames();
                  this.fecharModalCancelamentoSolictacao();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.messagem
            });
          })
        }
      }
    );
  }

  obterListaExames = () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.uid}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.listaSolicitacaoExames = result;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  obterSolicitacao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Solicitacao/${this.solicitacaoExame.id}`).then(
      result => {
        this.solicitacaoExame = result;
        Swal.close()
      }
    )
  }

  limparCampos(tipo) {
    this.tipoResultado = tipo;
    this.iniciarFileInput();
  }

  handleFileInput(files: FileList) {
    this.fileNameTOSave = null;
    this.fileToUpload = files.item(0);
  }

  enviarArquivo = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.postFile(`Arquivo/Solicitacao/Upload`, this.fileToUpload).then(
      result => {
        Swal.close();
        if (result) {
          var regex = /^"|"$/g;
          var nomeMidia = result["_body"].replace(regex, "");
          this.fileNameTOSave = nomeMidia;
          // // this.salvarMidia(obj);
          // this.solicitacaoExame = result;
        }
      }
    ).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  cancelarArquivo = () => {
    this.iniciarFileInput();
  }

  iniciarFileInput = () => {
    this.fileNameTOSave = null;
    this.fileToUpload = null;
    $("#fileID").val('');
    setTimeout(() => {
      const dropArea_ = document.querySelector(".drop_box_"),
        button = dropArea_.querySelector("button"),
        dragText = dropArea_.querySelector("header"),
        input = dropArea_.querySelector("input"),
        dropArea = document.querySelector(".drop_box");
      let file;
      var filename;

      button.onclick = () => {
        input.click();
      };
      dropArea.innerHTML = "";
      input.addEventListener("change", function (e: any) {
        var fileName = e.target.files[0].name;
        let filedata = `
    <form>
    <div class="form">
    <h4>${fileName}</h4>
    </div>
    </form>`;
        dropArea.innerHTML = filedata;
      });
    }, 500);
  }

  visualizarResultado(item) {
    this.exameSelecionado = item;
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Procedimento/${this.exameSelecionado.id}/Resultado`).then((res) => {
      Swal.close();
      if (res[0]['tipo_resultado'] == 'ARQUIVO') {
        window.open(res[0]['resultado_link'], '_blank');
      } else {
        this.resultado(res[0])
      }
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  resultado = (item) => {
    this.resultadoExame = item;
    $("#modalResultadoExame").toggleClass("show");
    $("#modalResultadoExame").css("display", "block");
  }

  fecharModalResultado = () => {
    this.exameSelecionado = null;
    $("#modalResultadoExame").toggleClass("show");
    $("#modalResultadoExame").css("display", "none");
  }

  imprimirDado(item: any) {
    this.novoImpressoExame(item.id);
  }

  novoImpressoExame(id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.PostImpresso(`Documento/impresso-pdf/SOLICITACAO_DE_EXAME.frx`, [
      {
        "nome": "id",
        "valor": id.toString()
      }
    ]).then(res => {
      if (res) {
        var link: any = res;
        Swal.close();
        window.open(link, '_blank');
      }
    });
  }

  imprimirSolicitacaoApac(item: any) {
    this.novoImpressoExameApac(item.id);
  }


  novoImpressoExameApac(id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.PostImpresso(`Documento/impresso-pdf/AUTORIZACAO_PROCEDIMENTO_AMBULATORIAL.frx`, [
      {
        "nome": "id",
        "valor": id.toString()
      }
    ]).then(res => {
      if (res) {
        var link: any = res;
        Swal.close();
        window.open(link, '_blank');
      }
    });
  }
  
  carregarDadosImpressoApac(solicitacao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Solicitacao/${solicitacao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        this.dadosImpresso.apac = true;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
      }
    });
  }

  carregarDadosImpresso(solicitacao_id: any) {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Solicitacao/${solicitacao_id}/Impresso`).then(res => {
      if (res) {
        this.dadosImpresso = res;
        var today = new Date();
        this.dataHoje = today.toLocaleDateString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })
        this.prepararImpressoHtml(res);
        // this.authService.setDadosImpresso(this.dadosImpresso);
        // this.authService.setTipoImpresso('exame_solicitacao');
        // Swal.close();
        // setTimeout(() => {
        //   window.print()
        // }, 750)
      }
    });
  }


  gerarTabela(lista) {
    var td = ``;
    lista.forEach(element => {
      td +=
        `
        <tr>
  <td>
      <p>${element.protocolo}</p>
  </td>
  <td>
      <p>${element.sigtap_codigo}</p>
  </td>
  <td>
      <p>${element.sigtap_nome}</p>
  </td>
  <td>
      <p>${element.criticidade}</p>
  </td>
  <td>
     <p>${element.status}</p>
  </td>
  </tr>
  `
    });
    return td;
  }

  gerarApac() {
    var apac = `
    <table class="apac">
    <thead>
    </thead>
    <tbody>
        <caption>
            <h4>AUTORIZAÇÃO</h4>
        </caption>
        <tr>
            <td colspan="2">
                <p>Nome do profissional autorizador</p>
                <p class="p-2"></p>
            </td>
            <td colspan="1">
                <p>Código órgão emissor</p>
                <p class="p-2"></p>
            </td>
        </tr>
        <tr>
            <td>
                <p>CNS</p>
                <p class="p-2"></p>
            </td>
            <td>
                <p>CPF</p>
                <p class="p-2"></p>
            </td>
            <td>
                <p>Data da autorização</p>
                <p class="p-2"></p>
            </td>
        </tr>
        <tr>
            <td>
                <p>Período de validade da APAC</p>
                <p class="p-2"></p>
            </td>
            <td>
                <p class="pb-3">Assinatura e carimbo (nº do registro do conselho)</p>
            </td>
            <td>
                <p>Nº da autorização (APAC)</p>
                <p class="p-2"></p>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="text-align: center;padding: 10px;">
                
                    <b>IDENTIFICAÇÃO DO ESTABELECIMENTO DE SAÚDE
                        (EXECUTANTE)</b>
                        
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <p>Nome fantasia do estabelecimento de saúde executante</p>
                <p class="p-2"></p>
            </td>
            <td colspan="1">
                <p>CNES</p>
                <p class="p-2"></p>
            </td>
        </tr>
    </tbody>
    <tfoot></tfoot>
</table>
    `

    return apac
  }

  prepararImpressoHtml(dadosImpresso, apac = false) {
    var html = `
    <html lang="en">
    ${this.global.headerImpresso(dadosImpresso)}

<body>
    <div>
    ${this.global.cabecalhoImpresso(dadosImpresso, "SOLICITAÇÃO DE EXAME")}

        <table>
            <tr colspan="10">
                <td class="cabecalho">
                    <h4>PROCEDIMENTO SOLICITADO</h4>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td>
                    <b> Protocolo </b>
                </td>
                <td>
                    <b> Cod. Sigtap </b>
                </td>
                <td>
                    <b> Procedimento </b>
                </td>
                <td>
                    <b> Criticidade </b>
                </td>            
                <td>
                    <b> Status </b>
                </td>
            </tr>           
                ${this.gerarTabela(dadosImpresso.conteudo.procedimentos)}        
        </table>
        ${this.global.assinaturaImpresso(dadosImpresso, this.dataHoje)}
        ${dadosImpresso.apac ? this.gerarApac() : ""}        
    </div>
</body>

</html>
    `;
    Swal.fire('Favor aguarde');
    Swal.showLoading();    
    this.createHtmlFile(html, dadosImpresso);
  }

  createHtmlFile(htmlContent, dadosImpresso) {

    const dataAtual = new Date();
    const fileName = `arquivo_${dataAtual.getTime()}.html`;
    const fileBlob = new Blob([htmlContent], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(fileBlob);
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(fileUrl);

    this.fileUploadService.uploadFile(`Documento/pdf-assinado?usuario_id=${dadosImpresso.assinatura.usuario_id}`, fileBlob, fileName).subscribe(
      () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Operação realizada com sucesso!'
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      },
      (error) => {
        Swal.close();
        if (error.status == 200) {
          window.open(error.error.text, '_blank');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            html: error.error.Message ? error.error.Message : 'Erro ao enviar arquivo'
          });
        }
      }
    );
  }

}
