// global.ts
import { Injectable } from "@angular/core";
import { AppConfig } from "./service/app.config";
import * as moment from "moment";
@Injectable()
export class Global {
  apiUrl = AppConfig.settings.apiServer.apiUrl;
  currentUser: any = {};
  token_acesso: any;

  regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/mgi;

  dateFormat(dataStr) {
    if (dataStr == null || dataStr == "") {
      return "--/--/--";
    }

    return moment(dataStr).format("DD/MM/YYYY");
  }

  dateTimeFormat(dataStr) {
    if (dataStr == null || dataStr == "") {
      return "--/--/--";
    }

    return moment(dataStr).format("DD/MM/YYYY HH:mm:ss");
  }

  /* calculando o md5 */

  formatApiPath(items: any = []) {
    let returnStr = "";
    items.forEach((element) => {
      returnStr += element + "/";
    });
    return returnStr.substring(0, returnStr.length - 1);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  mascara(t, mask) {
    var i = t.currentTarget.value;
    var saida = mask.substring(1, 0);
    var texto = mask.substring(i);
    if (texto.substring(0, 1) != saida) {
      t.currentTarget.value += texto.substring(0, 1);
    }
  }

  formatDateDay(value) {
    if (value) {
      return moment(value).format("DD");
    }
    return "--";
  }

  formatDateDayMonth(value) {
    if (value) {
      return moment(value).format("DD/MM");
    }
    return "--";
  }

  formatDateTosave(value) {
    if (value) {
      return moment(value).format("YYYY-DD-MMTHH:MM:SS");
    }
    return "--";
  }

  formatDate(value) {
    if (value) {
      return moment(value).format("YYYY-DD-MM");
    }
    return "--";
  }

  formateDateMonth(value) {
    var meses = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro ",
    ];
    if (value) {
      var monthIndex = moment(value).month();
      return meses[monthIndex];
    }
    return "--";
  }

  cutHtmlList(value) {
    if (value) {
      if (value.length > 100) {
        return `${value.substring(0, 190)}...`
      } else {
        return value;
      }

    }
    return "--";
  }

  formateDate(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY");
    }
    return "--";
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY [\nàs] HH:mm");
    }
    return "--";
  }

  formateDateDayWeek(value) {
    var week = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ];
    if (value) {
      var weekIndex = moment(value).day();
      return week[weekIndex].substring(0, 3);
    }
    return "--";
  }

  formateDateDayWeekComplete(value) {
    var week = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ];
    if (value) {
      var weekIndex = moment(value).day();
      return week[weekIndex]
    } else {
      var weekIndex = moment().day();
      return week[weekIndex];
    }
  }

  formatDateTimeComplete(value) {
    if (value) {
      
      var mes = this.formateDateMonth(value);
      var day = moment(value).date();
      var ano = moment(value).format('YYYY');
      // return moment(value).format(`DD de ${mes} de YYYY`);
      return `${day} de ${mes} de ${ano}`;
    } else {
      var mes = this.formateDateMonth(moment());
      var day = moment().date();
      var ano = moment().format('YYYY');
      return `${day} de ${mes} de ${ano}`;
    }
    return "--";
  }

  formateTime(value) {
    if (value) {
      return moment(value).format("HH:mm");
    }
    return "--";
  }

  headerImpresso(dadosImpresso) {
    var html = `
    <head>
    <meta charset="utf-8">
    <title>Ventry</title>
    <base href="/">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous"> -->
    <style>
      :host {
        ::ng-deep {
          all: initial;

          * {
            all: unset;
          }
        }
      }

      @page {
        width: 210mm;
        height: 297mm;
        margin: 2.5mm !important;
        margin-top: 0 !important;
        size: 8.5in 11in;
      }

      * {
        font-size: 9.5pt;
        text-transform: uppercase;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }

      th,
      td {
        padding: 5px;
        text-align: left;
      }

      th {}

      /* tr {
        background-color: #f5f5f5;
    } */
      .cabecalho {
        border-bottom: 1px solid #ddd;
      }

      .cabecalho-info {
        border-left: 1px solid #ddd;
      }

      td.logo {
        width: 120px;
    }

    td img {
        width: 120px;
    }

      .title {
        text-align: center;
        /* Centraliza o texto dentro da célula */
        width: 100%;
        /* Define a largura da célula para ocupar toda a largura da tabela */
      }

      .border-linha {
        border: 1px solid #ddd;
      }

      .border-linha>td>span {
        line-height: 17px;
      }

      .border-top {
        /* border-top: 1px solid #ddd; */
        text-align: center;
      }

      .line-assinatura {
        margin: 0 auto;
        width: 40%;
        height: 1px;
        background: #ddd;
        margin-top: 40px;
      }

      h4 {
        margin: 5px;
      }

      .p-2 {
        padding-bottom: 15px;
      }

      .pb-3 {
        padding-bottom: 50px;
      }

      .apac {
        margin-top: 15px;
        padding: 10px;
      }

      .apac td {
        border: 1px solid #ddd;
      }
      h1{
        font-weight: 300;
    }
    </style>
  </head>
    `;
    return html;
  }

  cabecalhoImpresso(dadosImpresso, titulo) {
    var html = `
    <table>
    <tr class="cabecalho">
      <td class="logo" >
        <img src="${dadosImpresso.cabecalho.relatorio_caminho_logo ? dadosImpresso.cabecalho.relatorio_caminho_logo : 'assets/logos/logo-esus-cor.png'}">
      </td>
      <td class="cabecalho-info">
        <h4>Ministério da Saúde</h4>
        <h4>${dadosImpresso.cabecalho.relatorio_estado}</h4>
        <h4>${dadosImpresso.cabecalho.relatorio_municipio}</h4>
        <h4>${dadosImpresso.cabecalho.estabelecimento_nome}</h4>
      </td>
    </tr>
  </table>
  <table>
    <tr colspan="10">
      <td class="title">
        <h4>${titulo}</h4>
      </td>
    </tr>
  </table>
  <table>
    <tr>
      <td>
        <b>IDENTIFICAÇÃO</b>
      </td>
    </tr>
  </table>
  <table>
    <tbody>
      <tr class="border-linha">
        <td>Nº do prontuário:
          <span> ${dadosImpresso.cidadao.id}</span>
        </td>
        <td >NOME:
          <span> ${dadosImpresso.cidadao.nome} </span>
        </td>
        <td class="border-linha">CNS:
          <span> ${dadosImpresso.cidadao.cns ? this.cnsMask(dadosImpresso.cidadao.cns) : 'CNS NÃO INFORMADO'} </span>
        </td>
        <td class="border-linha">CPF:
          <span> ${dadosImpresso.cidadao.cpf ? this.cpfMask(dadosImpresso.cidadao.cpf) : 'CPF NÃO INFORMADO'} </span>
        </td>
        <td class="border-linha">SEXO:
         <span> ${dadosImpresso.cidadao.sexo} </span>
        </td>
      </tr>
      <tr class="border-linha">
        <td class="border-linha">DATA DE NASC.:
          <span> ${dadosImpresso.cidadao.data_nascimento} </span>
        </td>
        <td class="border-linha">IDADE:
          <span> ${dadosImpresso.cidadao.idade} </span>
        </td>
        <td colspan="2" class="border-linha">NOME MÃE:
          <span> ${dadosImpresso.cidadao.nome_mae} </span>
        </td>
        <td class="border-linha">TELEFONE:
          <span> ${dadosImpresso.cidadao.telefone ? this.celMask(dadosImpresso.cidadao.telefone) : 'NÃO INFORMADO'} </span>
        </td>
      </tr>
      <tr class="border-linha ">
        <td colspan="4" class="border-linha">ENDEREÇO:
          <span> ${dadosImpresso.cidadao.endereco_completo ? dadosImpresso.cidadao.endereco_completo : 'ENDEREÇO NÃO INFORMADO'} </span>
        </td>
        <td >CÓDIGO DO MUNICÍPIO:
          <span> ${dadosImpresso.cidadao.municipio_co_ibge ? dadosImpresso.cidadao.municipio_co_ibge : 'NÃO INFORMADO'} </span>
        </td>
      </tr>
      <tr class="border-linha ">
        <td colspan="4" class="border-linha">UNIDADE SOLICITANTE:
          <span> ${dadosImpresso.local_atendimento.estabelecimento_nome} </span>
        </td>
        <td >CNES:
          <span> ${dadosImpresso.local_atendimento.estabelecimento_cnes} </span>
        </td>
      </tr>
      <tr class="border-linha ">
        <td colspan="4" class="border-linha">PROFISSIONAL SOLICITANTE:
          <span> ${dadosImpresso.atendimento.profissional_solicitante_nome} </span>
        </td>
        <td >CNS:
          <span> ${dadosImpresso.atendimento.profissional_solicitante_cns ? this.cnsMask(dadosImpresso.atendimento.profissional_solicitante_cns) : 'CNS NÃO INFORMADO'} </span>
        </td>
      </tr>
    </tbody>
  </table>
    `;
    return html;
  }

  assinaturaImpresso(dadosImpresso, dataHoje) {
    var html = `
    <table>
    <tr>
      <td class="border-top">
        <div class="line-assinatura"></div>
        <p>${dadosImpresso.assinatura.nome_assinatura}&nbsp;-&nbsp;${dadosImpresso.assinatura.linha_2}&nbsp;</p>
        <p>${dadosImpresso.cabecalho.estabelecimento_municipio}&nbsp;-&nbsp;${dadosImpresso.cabecalho.estabelecimento_estado_sigla},&nbsp;${dataHoje} </p>
      </td>
    </tr>
  </table>
`;
    return html;
  }

  formaterCurrency(value) {
    if (value) {
      if (value.toString().includes("R$")) {
        return value.replace("R$", "").trim();
      }
      return value;
    }
    return value;
  }

  MD5(d) {
    var result = this.M(this.V(this.Y(this.X(d), 8 * d.length)));
    return result.toLowerCase();
  }

  M(d) {
    for (var _, m = "0123456789ABCDEF", f = "", r = 0; r < d.length; r++)
      (_ = d.charCodeAt(r)), (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _));
    return f;
  }

  X(d) {
    for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0;
    for (m = 0; m < 8 * d.length; m += 8)
      _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32;
    return _;
  }

  V(d) {
    for (var _ = "", m = 0; m < 32 * d.length; m += 8)
      _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255);
    return _;
  }

  Y(d, _) {
    (d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _);
    for (
      var m = 1732584193, f = -271733879, r = -1732584194, i = 271733878, n = 0;
      n < d.length;
      n += 16
    ) {
      var h = m,
        t = f,
        g = r,
        e = i;
      (f = this.md5_ii(
        (f = this.md5_ii(
          (f = this.md5_ii(
            (f = this.md5_ii(
              (f = this.md5_hh(
                (f = this.md5_hh(
                  (f = this.md5_hh(
                    (f = this.md5_hh(
                      (f = this.md5_gg(
                        (f = this.md5_gg(
                          (f = this.md5_gg(
                            (f = this.md5_gg(
                              (f = this.md5_ff(
                                (f = this.md5_ff(
                                  (f = this.md5_ff(
                                    (f = this.md5_ff(
                                      f,
                                      (r = this.md5_ff(
                                        r,
                                        (i = this.md5_ff(
                                          i,
                                          (m = this.md5_ff(
                                            m,
                                            f,
                                            r,
                                            i,
                                            d[n + 0],
                                            7,
                                            -680876936
                                          )),
                                          f,
                                          r,
                                          d[n + 1],
                                          12,
                                          -389564586
                                        )),
                                        m,
                                        f,
                                        d[n + 2],
                                        17,
                                        606105819
                                      )),
                                      i,
                                      m,
                                      d[n + 3],
                                      22,
                                      -1044525330
                                    )),
                                    (r = this.md5_ff(
                                      r,
                                      (i = this.md5_ff(
                                        i,
                                        (m = this.md5_ff(
                                          m,
                                          f,
                                          r,
                                          i,
                                          d[n + 4],
                                          7,
                                          -176418897
                                        )),
                                        f,
                                        r,
                                        d[n + 5],
                                        12,
                                        1200080426
                                      )),
                                      m,
                                      f,
                                      d[n + 6],
                                      17,
                                      -1473231341
                                    )),
                                    i,
                                    m,
                                    d[n + 7],
                                    22,
                                    -45705983
                                  )),
                                  (r = this.md5_ff(
                                    r,
                                    (i = this.md5_ff(
                                      i,
                                      (m = this.md5_ff(
                                        m,
                                        f,
                                        r,
                                        i,
                                        d[n + 8],
                                        7,
                                        1770035416
                                      )),
                                      f,
                                      r,
                                      d[n + 9],
                                      12,
                                      -1958414417
                                    )),
                                    m,
                                    f,
                                    d[n + 10],
                                    17,
                                    -42063
                                  )),
                                  i,
                                  m,
                                  d[n + 11],
                                  22,
                                  -1990404162
                                )),
                                (r = this.md5_ff(
                                  r,
                                  (i = this.md5_ff(
                                    i,
                                    (m = this.md5_ff(
                                      m,
                                      f,
                                      r,
                                      i,
                                      d[n + 12],
                                      7,
                                      1804603682
                                    )),
                                    f,
                                    r,
                                    d[n + 13],
                                    12,
                                    -40341101
                                  )),
                                  m,
                                  f,
                                  d[n + 14],
                                  17,
                                  -1502002290
                                )),
                                i,
                                m,
                                d[n + 15],
                                22,
                                1236535329
                              )),
                              (r = this.md5_gg(
                                r,
                                (i = this.md5_gg(
                                  i,
                                  (m = this.md5_gg(
                                    m,
                                    f,
                                    r,
                                    i,
                                    d[n + 1],
                                    5,
                                    -165796510
                                  )),
                                  f,
                                  r,
                                  d[n + 6],
                                  9,
                                  -1069501632
                                )),
                                m,
                                f,
                                d[n + 11],
                                14,
                                643717713
                              )),
                              i,
                              m,
                              d[n + 0],
                              20,
                              -373897302
                            )),
                            (r = this.md5_gg(
                              r,
                              (i = this.md5_gg(
                                i,
                                (m = this.md5_gg(
                                  m,
                                  f,
                                  r,
                                  i,
                                  d[n + 5],
                                  5,
                                  -701558691
                                )),
                                f,
                                r,
                                d[n + 10],
                                9,
                                38016083
                              )),
                              m,
                              f,
                              d[n + 15],
                              14,
                              -660478335
                            )),
                            i,
                            m,
                            d[n + 4],
                            20,
                            -405537848
                          )),
                          (r = this.md5_gg(
                            r,
                            (i = this.md5_gg(
                              i,
                              (m = this.md5_gg(
                                m,
                                f,
                                r,
                                i,
                                d[n + 9],
                                5,
                                568446438
                              )),
                              f,
                              r,
                              d[n + 14],
                              9,
                              -1019803690
                            )),
                            m,
                            f,
                            d[n + 3],
                            14,
                            -187363961
                          )),
                          i,
                          m,
                          d[n + 8],
                          20,
                          1163531501
                        )),
                        (r = this.md5_gg(
                          r,
                          (i = this.md5_gg(
                            i,
                            (m = this.md5_gg(
                              m,
                              f,
                              r,
                              i,
                              d[n + 13],
                              5,
                              -1444681467
                            )),
                            f,
                            r,
                            d[n + 2],
                            9,
                            -51403784
                          )),
                          m,
                          f,
                          d[n + 7],
                          14,
                          1735328473
                        )),
                        i,
                        m,
                        d[n + 12],
                        20,
                        -1926607734
                      )),
                      (r = this.md5_hh(
                        r,
                        (i = this.md5_hh(
                          i,
                          (m = this.md5_hh(m, f, r, i, d[n + 5], 4, -378558)),
                          f,
                          r,
                          d[n + 8],
                          11,
                          -2022574463
                        )),
                        m,
                        f,
                        d[n + 11],
                        16,
                        1839030562
                      )),
                      i,
                      m,
                      d[n + 14],
                      23,
                      -35309556
                    )),
                    (r = this.md5_hh(
                      r,
                      (i = this.md5_hh(
                        i,
                        (m = this.md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)),
                        f,
                        r,
                        d[n + 4],
                        11,
                        1272893353
                      )),
                      m,
                      f,
                      d[n + 7],
                      16,
                      -155497632
                    )),
                    i,
                    m,
                    d[n + 10],
                    23,
                    -1094730640
                  )),
                  (r = this.md5_hh(
                    r,
                    (i = this.md5_hh(
                      i,
                      (m = this.md5_hh(m, f, r, i, d[n + 13], 4, 681279174)),
                      f,
                      r,
                      d[n + 0],
                      11,
                      -358537222
                    )),
                    m,
                    f,
                    d[n + 3],
                    16,
                    -722521979
                  )),
                  i,
                  m,
                  d[n + 6],
                  23,
                  76029189
                )),
                (r = this.md5_hh(
                  r,
                  (i = this.md5_hh(
                    i,
                    (m = this.md5_hh(m, f, r, i, d[n + 9], 4, -640364487)),
                    f,
                    r,
                    d[n + 12],
                    11,
                    -421815835
                  )),
                  m,
                  f,
                  d[n + 15],
                  16,
                  530742520
                )),
                i,
                m,
                d[n + 2],
                23,
                -995338651
              )),
              (r = this.md5_ii(
                r,
                (i = this.md5_ii(
                  i,
                  (m = this.md5_ii(m, f, r, i, d[n + 0], 6, -198630844)),
                  f,
                  r,
                  d[n + 7],
                  10,
                  1126891415
                )),
                m,
                f,
                d[n + 14],
                15,
                -1416354905
              )),
              i,
              m,
              d[n + 5],
              21,
              -57434055
            )),
            (r = this.md5_ii(
              r,
              (i = this.md5_ii(
                i,
                (m = this.md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)),
                f,
                r,
                d[n + 3],
                10,
                -1894986606
              )),
              m,
              f,
              d[n + 10],
              15,
              -1051523
            )),
            i,
            m,
            d[n + 1],
            21,
            -2054922799
          )),
          (r = this.md5_ii(
            r,
            (i = this.md5_ii(
              i,
              (m = this.md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)),
              f,
              r,
              d[n + 15],
              10,
              -30611744
            )),
            m,
            f,
            d[n + 6],
            15,
            -1560198380
          )),
          i,
          m,
          d[n + 13],
          21,
          1309151649
        )),
        (r = this.md5_ii(
          r,
          (i = this.md5_ii(
            i,
            (m = this.md5_ii(m, f, r, i, d[n + 4], 6, -145523070)),
            f,
            r,
            d[n + 11],
            10,
            -1120210379
          )),
          m,
          f,
          d[n + 2],
          15,
          718787259
        )),
        i,
        m,
        d[n + 9],
        21,
        -343485551
      )),
        (m = this.safe_add(m, h)),
        (f = this.safe_add(f, t)),
        (r = this.safe_add(r, g)),
        (i = this.safe_add(i, e));
    }
    return Array(m, f, r, i);
  }

  md5_cmn(d, _, m, f, r, i) {
    return this.safe_add(
      this.bit_rol(this.safe_add(this.safe_add(_, d), this.safe_add(f, i)), r),
      m
    );
  }

  md5_ff(d, _, m, f, r, i, n) {
    return this.md5_cmn((_ & m) | (~_ & f), d, _, r, i, n);
  }

  md5_gg(d, _, m, f, r, i, n) {
    return this.md5_cmn((_ & f) | (m & ~f), d, _, r, i, n);
  }

  md5_hh(d, _, m, f, r, i, n) {
    return this.md5_cmn(_ ^ m ^ f, d, _, r, i, n);
  }

  md5_ii(d, _, m, f, r, i, n) {
    return this.md5_cmn(m ^ (_ | ~f), d, _, r, i, n);
  }

  safe_add(d, _) {
    var m = (65535 & d) + (65535 & _);
    return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m);
  }

  bit_rol(d, _) {
    return (d << _) | (d >>> (32 - _));
  }

  /* necessário para o md5 */
  /** NORMAL words**/
  md5Normal(valor) {
    //value = 'test';
    var result = this.MD5(valor);
    //document.body.innerHTML = 'hash -  normal words: ' + result;
    return result;
    // value = 'מבחן'
  }

  /** NON ENGLISH words**/
  md5English(value) {
    //unescape() can be deprecated for the new browser versions
    //var result = this.MD5(unescape(encodeURIComponent(value)));
    //document.body.innerHTML += '<br><br>hash - non english words: ' + result;
    //return result;
  }

  /* fim das variaveis para md5 */

  applyMask = (value) => {
    if (value.cep) {
      value.cep = value.cep.replace(/^(\d{2})(\d{3})(\d{3})$/, "$1.$2-$3");
    }
    if (value.cnpj) {
      value.cnpj = value.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }
    if (value.phone) {
      value.phone = value.phone.replace(/^(\d{2})(\d)/g, "($1) $2");
      value.phone = value.phone.replace(/(\d)(\d{4})$/, "$1-$2");
    }
    if (value.telefone) {
      value.telefone = value.telefone.replace(/^(\d{2})(\d)/g, "($1) $2");
      value.telefone = value.telefone.replace(/(\d)(\d{4})$/, "$1-$2");
    }
    if (value.cel) {
      value.cel = value.cel.replace(/^(\d{2})(\d)/g, "($1) $2");
      value.cel = value.cel.replace(/(\d)(\d{4})$/, "$1-$2");
    }
    if (value.tel) {
      value.tel = value.tel.replace(/^(\d{2})(\d)/g, "($1) $2");
      value.tel = value.tel.replace(/(\d)(\d{4})$/, "$1-$2");
    }
    if (value.cpf) {
      value.cpf = value.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }
    if (value.cns) {
      value.cns = value.cns.replace(/(\d{3})(\d{4})(\d{4})(\d{4})$/, "$1 $2 $3 $4");
    }
  };

  cepMask = (value) => {
    value = value.replace(/^(\d{2})(\d{3})(\d{3})$/, "$1.$2-$3");
    return value
  }
  cnpjMask = (value) => {
    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    return value
  }
  phoneMask = (value) => {
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value
  }
  telefoneMask = (value) => {
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value
  }
  celMask = (value) => {
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value
  }
  telMask = (value) => {
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value
  }
  cpfMask = (value) => {
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    return value
  }
  cnsMask = (value) => {
    value = value.replace(/(\d{3})(\d{4})(\d{4})(\d{4})$/, "$1 $2 $3 $4");
    return value
  }

  dateMask = (date: any) => {
    date = date.split("T")[0];
    date = date.split("-").reverse().join("/")
    return date
  }

  dateServerMask = (date: any) => {
    date = date.split("/")[2] + "-" + date.split("/")[1] + "-" + date.split("/")[0];
    return date
  }

  validaEmail = (email) => {
    return this.regex.test(email);
  }

  removeMask = (data) => {
    data = data.replace(/\D/g, "");
    return data
  }

  applyPhoneMask = (data) => {
    var prefixo = ''
    var sufixo = ''
    prefixo = data.slice(0, -4)
    sufixo = data.slice(4)
    data = prefixo + ' ' + sufixo;
    return data
  }

  applyCelMask = (data) => {
    var prefixo = ''
    var sufixo = ''
    prefixo = data.slice(0, -4)
    sufixo = data.slice(5)
    data = prefixo + ' ' + sufixo;
    return data
  }

  maskDataHora = (data_hora) => {
    if (data_hora == null || data_hora.split("T").length < 2)
      return "";
    return data_hora.split('T')[0].split('-')[2] + "/" + data_hora.split('T')[0].split('-')[1] + "/" + data_hora.split('T')[0].split('-')[0] + " " + data_hora.split('T')[1].split(':')[0] + ":" + data_hora.split('T')[1].split(':')[1]
  }

  maskData = (data) => {
    if (data == null)
      return "";
    return data.split('T')[0].split('-')[2] + "/" + data.split('T')[0].split('-')[1] + "/" + data.split('T')[0].split('-')[0]
  }

  maskHora = (hora) => {
    if (hora == null)
      return "";
    return hora.split(':')[0] + ":" + hora.split(':')[1]
  }

  applyCurrency = (value) => {
    value = value.toString()
    if (value.includes('.')) {
      return value = 'R$ ' + value.split('.')[0] + ',' + ('00' + value.split('.')[1]).slice(-2)
    }
    return value = 'R$ ' + value + ',' + '00'
  }

  countWaitingTimeHoursDays = (value: any) => {
    var date = new Date(value)
    var entry = date.getTime();
    var now = Date.now();
    var ms = now - entry

    let seconds = `0${new Date(ms).getSeconds()}`.slice(-2);
    let minutes = `0${new Date(ms).getMinutes()}`.slice(-2);
    // let hours = `0${new Date(ms).getHours()}`.slice(-2);
    let hours: any = (ms / (1000 * 60 * 60)).toFixed(0);
    hours = parseInt(hours) % 24
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(0);

    let tempoEsperaDiaHora: any = '';
    let tempoEsperaMinutoSegundo: any = '';

    if (parseInt(days) > 0) {
      if (days == '1') {
        tempoEsperaDiaHora += `${days} dia, `
      }
      else {
        tempoEsperaDiaHora += `${days} dias, `
      }
    }
    if (parseInt(hours) > 0) {
      if (hours == '1') {
        tempoEsperaDiaHora += `${hours} hora, `
      }
      else {
        tempoEsperaDiaHora += `${hours} horas, `
      }
    }
    if (minutes) {
      if (minutes == '1') {
        tempoEsperaMinutoSegundo += `${minutes} minuto `
      }
      else {
        tempoEsperaMinutoSegundo += `${minutes} minutos `
      }
    }
    if (seconds) {
      if (tempoEsperaMinutoSegundo.length > 0) {
        tempoEsperaMinutoSegundo += `e `
      }
      if (seconds == '1') {
        tempoEsperaMinutoSegundo += `${seconds} segundo`
      }
      else {
        tempoEsperaMinutoSegundo += `${seconds} segundos`
      }
    }

    return tempoEsperaDiaHora
  }

  countWaitingTimeMinuteSeconds = (value: any) => {
    var date = new Date(value)
    var entry = date.getTime();
    var now = Date.now();
    var ms = now - entry

    let seconds = `0${new Date(ms).getSeconds()}`.slice(-2);
    let minutes = `0${new Date(ms).getMinutes()}`.slice(-2);
    // let hours = `0${new Date(ms).getHours()}`.slice(-2);
    let hours: any = (ms / (1000 * 60 * 60)).toFixed(0);
    hours = parseInt(hours) % 24
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(0);

    let tempoEsperaDiaHora: any = '';
    let tempoEsperaMinutoSegundo: any = '';

    if (parseInt(days) > 0) {
      if (days == '1') {
        tempoEsperaDiaHora += `${days} dia, `
      }
      else {
        tempoEsperaDiaHora += `${days} dias, `
      }
    }
    if (parseInt(hours) > 0) {
      if (hours == '1') {
        tempoEsperaDiaHora += `${hours} hora, `
      }
      else {
        tempoEsperaDiaHora += `${hours} horas, `
      }
    }
    if (minutes) {
      if (minutes == '1') {
        tempoEsperaMinutoSegundo += `${minutes} minuto `
      }
      else {
        tempoEsperaMinutoSegundo += `${minutes} minutos `
      }
    }
    if (seconds) {
      if (tempoEsperaMinutoSegundo.length > 0) {
        tempoEsperaMinutoSegundo += `e `
      }
      if (seconds == '1') {
        tempoEsperaMinutoSegundo += `${seconds} segundo`
      }
      else {
        tempoEsperaMinutoSegundo += `${seconds} segundos`
      }
    }

    return tempoEsperaMinutoSegundo
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
