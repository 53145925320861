import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../service/auth/auth.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-exame-imagem',
  templateUrl: './add-exame-imagem.component.html',
  styleUrls: ['./add-exame-imagem.component.css']
})
export class AddExameImagemComponent implements OnInit {

  grupoExame: any = [];
  exame: any = [];
  estabelecimento: any = '';
  clinica: any = [];
  listaExames: any = [];
  listaClinicas: any = [];
  clinicasSelecionadas: any = [];

  presencaUser: any = [];
  rotaClinicas: any = ''
  uid: any = ''
  pagina: any = 1;
  params: string = `Skip=${this.pagina}&Take=10&Busca`;
  listaCidadao: any = [];
  pacienteSelecionado: any;
  imagemExame: any = {};
  busca: any = 'busca';
  uuid: any;
  fileToUpload: File | null = null;
  fileNameTOSave: any = "";
  listaDCM: any = [];
  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.uuid = this.newGuid();
    this.imagemExame.arquivos_imagem = [];

    this.uid = this.route.snapshot.paramMap.get('uuid');
    if (this.uid != null) {
      Swal.fire('Favor aguarde')
      Swal.showLoading()
      this.obterExameImagem(this.uid);
    }
  }

  obterExameImagem = (uid) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()

    this.service.Get(`ExameImagem/${uid}`).then(
      result => {
        this.imagemExame = result;
        this.pacienteSelecionado = {
          nome: this.imagemExame.cidadao_nome,
          id: this.imagemExame.cidadao_id
        }
        // $("#fileIDHistorico").val(this.imagemExame.arquivo_laudo);
        Swal.close()
      }
    )
  }

  goBack() {
    this.location.back();
  }

  salvarGrupoExames = () => {

  }

  atualizarGrupoExames = () => {

  }


  selecionarPaciente(item: any) {
    this.pacienteSelecionado = item;
  }

  resultBuscaCidadao = (result) => {
    if (result != null) {
      this.listaCidadao = result['conteudo'];
    }
  }

  selecionarSigtap(item) {
    this.imagemExame.sigtap_busca = '';
    if (!item) return
    if (item.id) {
      this.imagemExame.sigtap_id = item.id
    }
  }

  removerPaciente() {
    this.pacienteSelecionado = null;
  }


  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  handleFileInput(files: FileList) {
    this.fileNameTOSave = null;
    this.fileToUpload = files.item(0);
    this.enviarArquivo();
  }

  enviarArquivo = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.postFile(`Arquivo/ExameImagem/Laudo/${this.uuid}`, this.fileToUpload).then(
      result => {
        Swal.close();
        if (result) {
          var regex = /^"|"$/g;
          var nomeMidia = result["_body"].replace(regex, "");
          this.fileNameTOSave = nomeMidia;
          this.imagemExame.arquivo_laudo = nomeMidia;
        }
      }
    ).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }




  handleFileInputDICOM(files: FileList) {
    this.fileNameTOSave = null;
    this.fileToUpload = files.item(0);
    this.enviarArquivoDICOM();
  }

  enviarArquivoDICOM = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.postFile(`Arquivo/ExameImagem/${this.uuid}`, this.fileToUpload).then(
      result => {
        Swal.close();
        if (result) {
          var regex = /^"|"$/g;
          var nomeMidia = result["_body"].replace(regex, "");
          $("#fileDCM").val("");
          this.imagemExame.arquivos_imagem.push({ nome_arquivo: nomeMidia, uuiLocal: this.newGuid() });
        }
      }
    ).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.messagem
      });
    })
  }

  removerItem = (item) => {
    this.imagemExame.arquivos_imagem.forEach(exame => {
      if (exame.uuiLocal === item.uuiLocal) {
        var index = this.imagemExame.arquivos_imagem.indexOf(exame)
        this.imagemExame.arquivos_imagem.splice(index, 1)
      }
    })
  }

  salvarExameImagem = () => {


    if (!this.pacienteSelecionado.id || this.pacienteSelecionado.id == undefined) {
      Swal.fire('Erro!', 'É preciso informar um paciente', 'error')
      return
    }

    if (!this.imagemExame.sigtap_id || this.imagemExame.sigtap_id == undefined) {
      Swal.fire('Erro!', 'É preciso informar um exame', 'error')
      return
    }


    var obj = {
      "uuid": this.uuid,
      "cidadao_id": this.pacienteSelecionado.id,
      "sigtap_id": this.imagemExame.sigtap_id,
      "arquivo_laudo": this.imagemExame.arquivo_laudo,
      "arquivos_imagem": this.imagemExame.arquivos_imagem
    }


    Swal.fire('Favor aguarde');
    Swal.showLoading();


    this.service.Post(`ExameImagem`, obj).then(
      result => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Salvo com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.location.back();
              }
            }
          );
        }, 1000);
      },
      error => {
        Swal.fire('Erro!', error.error.messagem, 'error');
      }
    )
  }

}
