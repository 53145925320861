import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";

import { Global } from '../../../global';
import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.css']
})

export class EstablishmentComponent implements OnInit {

  //estabelecimento: any = '';
  estabelecimento_id: any = 0;
  listaBloco: any = [];
  listaClinica: any = [];

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  recebeDadosEstabelecimento = (event) => {
    this.estabelecimento_id = event;
  }

  retornoListaBlocos = (event) => {
    this.listaBloco = event;
  }

  retornoListaClinica = (event) => {
    this.listaClinica = event;
  }

}
