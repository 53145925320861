import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import * as $ from "jquery";
import { Global } from '../../../global';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../../service/auth/auth.service'

@Component({
  selector: 'app-teto-gasto',
  templateUrl: './spend-ceil.component.html',
  styleUrls: ['./spend-ceil.component.css']
})
export class SpendCeilComponent implements OnInit {

  listaPlanos: any = []
  listaEstabelecimentos: any = []
  listaTetos: any = []
  plano: any = {}
  estabelecimento: any = {}
  valor: any = ''
  periodo: any = {}
  editarTeto: boolean = false;
  valorTetoEditado: any = {};

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private router: Router,
    private global: Global,
    private location: Location,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.obterPlanos().then(
      result => {
        this.listaPlanos = result
        this.listaPlanos.unshift({id:0,nome:'SELECIONE..'})
        this.plano == 0;
        this.obterEstabelecimentos().then(
          result => {
            this.listaEstabelecimentos = result
            this.listaEstabelecimentos.unshift({id:0,nome:'SELECIONE..'})
            this.estabelecimento == 0;
            Swal.close()
          },
          error => {
            this.mostraErro(error)
          }
        )
      },
      error => {
        this.mostraErro(error)
      }
    )
  }

  mostraErro = (erro) => {
    Swal.fire('Erro', erro, 'error')
  }
  
  obterPlanos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Plano`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }
  
  obterEstabelecimentos = () => {
    return new Promise((resolve, reject) => {
      this.service.Get(`Estabelecimento`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  setPeriodo = (novoPeriodo: any) => {
    if (novoPeriodo.length > 0) {
      this.periodo.data_fim_solicitacao = this.global.dateServerMask(novoPeriodo[1])
      this.periodo.data_inicio_solicitacao = this.global.dateServerMask(novoPeriodo[0])
      return
    }
    this.periodo.data_fim_solicitacao = ''
    this.periodo.data_inicio_solicitacao = ''
  }

  salvarTeto = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.validarDados(this.plano, this.estabelecimento, this.periodo, this.valor).then(
      result => {
        this.service.Post(`TetoGastoPlano`, result).then(
          result => {
            Swal.fire('Sucesso', 'Teto cadastrado com sucesso', 'success')
          },
          error => {
            this.mostraErro(error)
          }
        )
      },
      error => {
        this.mostraErro(error)
      }
    )
  }

  validarDados = (p,e,d,v) => {
    return new Promise((resolve, reject) => {
      if (Object.keys(p).length === 0) {
        reject('É preciso informar o plano')
        return
      }
      if (Object.keys(e).length === 0) {
        reject('É preciso informar o estabelecimento')
        return
      }
      if (!d.data_inicio_solicitacao) {
        reject('É preciso informar a data início')
        return
      }
      if (!d.data_fim_solicitacao) {
        reject('É preciso informar a data fim')
        return
      }
      if (!v) {
        reject('É preciso informar o valor de teto')
        return
      }
      var resultado = {plano_id: p, estabelecimento_id: e, data_hora_inicio: d.data_inicio_solicitacao, data_hora_fim: d.data_fim_solicitacao, valor: v}
      resolve(resultado)
    })
  }

  buscarTeto = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var txt = '';

    if (Object.keys(this.plano).length == 0 || this.plano == 0 &&
        Object.keys(this.estabelecimento).length == 0 || this.estabelecimento == 0 &&
        Object.keys(this.periodo).length == 0) {
      this.mostraErro('É preciso selecionar pelo menos um item do filtro para buscar os dados')
      return
    }
    
    if (this.plano && this.plano != 0 && Object.keys(this.plano).length != 0) {
      txt += `&PlanoId=${this.plano}`
    }
    
    if (this.estabelecimento && this.estabelecimento != 0 && Object.keys(this.estabelecimento).length != 0) {
      txt += `&EstabelecimentoId=${this.estabelecimento}`
    }
    
    if (this.periodo && Object.keys(this.periodo).length != 0) {
      txt += `&DataInicio=${this.periodo.data_inicio_solicitacao}&DataFim=${this.periodo.data_fim_solicitacao}`
    }
    
    this.service.Get(`TetoGastoPlano?Ativo=true${txt}`).then(
      result => {
        this.listaTetos = result['conteudo'];
        this.listaTetos.forEach(element => {
          element.editarTeto = false
        })
        if (this.listaTetos.length == 0) {
          Swal.fire('Atenção', 'Não foram encontrados dados que correspondem a busca', 'warning').then(
            result => {
              this.limpaBusca()
            }
          )
        }
        else
          Swal.close()
      },
      error => {
        this.mostraErro(error)
      }
    )
  }

  limpaBusca = () => {
    this.listaTetos = []
    this.plano = {}
    this.estabelecimento = {}
    this.periodo = {}
  }

  limpaData = () => {
    this.periodo = {}
  }

  editarInfoTeto = (teto:any) => {
    teto.editarTeto = !teto.editarTeto
  }

  setPeriodoEdicaoTeto = (novoPeriodo: any) => {
    this.valorTetoEditado.data_hora_inicio = this.global.dateServerMask(novoPeriodo[0])
    this.valorTetoEditado.data_hora_fim = this.global.dateServerMask(novoPeriodo[1])
  }

  salvarEditarTeto = (teto: any) => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    
    var Obj = {}

    if (Object.keys(this.valorTetoEditado).length == 0) {
      Swal.fire('Atenção', 'Não foram identificadas alterações', 'warning')
      return
    }

    if (this.valorTetoEditado.valor){
      Obj['valor'] = this.valorTetoEditado.valor
    }
    if (this.valorTetoEditado.data_hora_inicio){
      Obj['dataInicio'] = this.valorTetoEditado.data_hora_inicio
    }
    if (this.valorTetoEditado.data_hora_fim){
      Obj['dataFim'] = this.valorTetoEditado.data_hora_fim
    }

    this.service.Patch_body(`TetoGastoPlano/${teto.id}`, Obj).then(
      result => {
        Swal.fire('Sucesso', 'As alterações foram salvas com sucesso', 'success').then(
          result => {
            this.valorTetoEditado = {}
            this.buscarTeto()
          }
        )
      },
      error => {
        console.log(error)
        this.mostraErro(error.error.messagem)
      }
    )
  }

}
