import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import * as $ from "jquery";

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {

  @Input() estabelecimento_id: any = 0;
  filtro: string = "";
  @Input() listaBloco: any = [];
  bloco: any = {};

  @Output() retornoListaBloco = new EventEmitter()

  constructor(public service: ApiService,
    private authService: AuthService) { }

  ngOnInit() {
    if (this.estabelecimento_id != 0)
      this.obterListaBloco();
  }

  obterListaBloco = () => {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Estabelecimento/${this.estabelecimento_id}/Bloco`).then(res => {
      this.listaBloco = res;
      this.retornoListaBloco.emit(this.listaBloco)
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  validaPost = () => {
    if (this.bloco.nome == null || this.bloco.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome do bloco.", 'error');
      return false;
    }
    if (this.bloco.id == null)
      this.bloco.estabelecimento_id = this.estabelecimento_id;
    return true;
  }

  salvarEditarBloco = () => {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    if (this.bloco.id == null)
      this.service.Post(`Bloco`, this.bloco).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Bloco salvo com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.obterListaBloco();
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    else {
      this.service.Put(`Bloco/${this.bloco.id}`, this.bloco).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Bloco atualizado com sucesso!'
          }).then(
            result => {
              if (result.isConfirmed) {
                this.obterListaBloco();
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    }
  }

  editarBloco = (bloco) => {
    this.bloco = bloco;
    this.abrirModalNovoBloco();
  }

  changeStatusBloco = () => {
    this.bloco.ativo != this.bloco.ativo;
  }

  abrirModalNovoBloco = () => {
    if (this.bloco.id == null)
      this.bloco.ativo = true;
    $("#modalAdicionarBloco").toggleClass("show");
    $("#modalAdicionarBloco").css("display", "block");
  }

  fecharModal = () => {
    this.bloco = {};
    $("#modalAdicionarBloco").toggleClass("show");
    $("#modalAdicionarBloco").css("display", "none");
  }

  filtroLista() {
    if (this.listaBloco.length === 0) {
      return this.listaBloco;
    }
    return this.listaBloco.filter(p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  confirmationAtivarDesativar = (id, ativo) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse bloco?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`Bloco/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListaBloco();
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

}
