import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var Simditor: any;
import * as $ from "jquery";
import { Global } from 'src/app/global';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../service/apiServices';
import { AuthService } from '../../../../service/auth/auth.service';

@Component({
  selector: 'app-evolution-registration',
  templateUrl: './evolution-registration.component.html',
  styleUrls: ['./evolution-registration.component.css']
})
export class EvolutionRegistrationComponent implements OnInit {

  prescricao: any = {};
  @Input() presenca: any = {};
  @Input() prontuario: any = {};
  @Output() goBack = new EventEmitter();
  @Output() retornoPost: EventEmitter<any> = new EventEmitter();

  evolucao: any = {}
  historico_evolucao: any = {}
  listaOcupacao: any = []
  usuario: any = {};
  cidadao_id: any;
  atendimento_id: any;
  uid: any;
  selectAll: any = false;

  /// editor de texto
  toolbar: any = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'table',
    'hr',
    'indent',
    'outdent',
    'alignment'
  ]

  @Input() listaEvolucao: any = [];

  listaAux: any = [];

  dadosImpresso: any;

  constructor(
    public service: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) {

    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });

    /// configuração do editor de texto
    Simditor.locale = 'en-US';
  }

  ngOnInit() {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    var data = new Date().toLocaleString('pt-br', { timeZone: 'America/Sao_Paulo' })
    this.evolucao.data = data.split(' ')[0];
    this.evolucao.hora = data.split(' ')[1];
    setTimeout(() => {
      var that = this;
      var descricao = new Simditor({
        textarea: $('#texto_evolucao'),
        upload: false,
        tabIndent: true,
        toolbar: that.toolbar,
        toolbarFloat: true
      });
      Swal.close()
    }, 500);
  }

  salvarEvolucao = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading();

    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;

  
    var Obj = {
      prontuario_id: parseInt(this.prontuario.id),
      presenca_id: this.presenca.id,
      texto: $('#texto_evolucao').val()
    }

    this.service.Post(`Prontuario/${this.prontuario.id}/ProntuarioEvolucao`, Obj).then(
      result => {
        Swal.fire('Sucesso', 'Evolução salva com sucesso', 'success').then(
          result => {
            $('#texto_evolucao').val('');
            var editor = new Simditor({
              textarea: $('#texto_evolucao')
            })
            editor.setValue('');
            this.voltar();
          }
        )
      },
      error => {
        if (error.status == 401 || error.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  voltar = () => {
    this.evolucao = {};
    $('#texto_evolucao').val("");
    this.goBack.emit();
  }

}
