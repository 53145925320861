import { Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../service/apiServices';
import { AuthService } from '../../service/auth/auth.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-nursing-station',
  templateUrl: './nursing-station.component.html',
  styleUrls: ['./nursing-station.component.css']
})
export class NursingStationComponent implements OnInit {

  filtro: string = "";
  @Input() listaBloco: any = [];
  postoEnfermagem: any = {};
  bloco_id_filtro: any = 0;
  clinica_id_filtro: any = 0;
  bloco_id: any = 0;
  listaClinicas: any = [];
  listapostoEnfermagens: any = [];


  constructor(public service: ApiService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  obterListaClinica = (bloco_id) => {
    if (bloco_id == 0) {
      this.clinica_id_filtro = 0;
      this.listaClinicas = [];
      this.listapostoEnfermagens = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`Bloco/${bloco_id}/ClinicaMedica`).then(res => {
      this.listaClinicas = res;
      this.listapostoEnfermagens = [];
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  obterListapostoEnfermagem = (clinica_id) => {
    if (clinica_id == 0) {
      this.listapostoEnfermagens = [];
      return;
    }
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Get(`ClinicaMedica/${clinica_id}/postoEnfermagem`).then(res => {
      this.listapostoEnfermagens = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire('Erro', `${res.error.erros[0].messagem}`, 'error');
    });
  }

  validaPost = () => {
    if (this.postoEnfermagem.nome == null || this.postoEnfermagem.nome == "") {
      Swal.fire('Erro', "Favor preencher o nome da posto enfermagem.", 'error');
      return false;
    }

    if (this.postoEnfermagem.restrito_idade) {
      if (this.postoEnfermagem.idade_minima == null) {
        Swal.fire('Erro', "Favor preencher a idade mínima.", 'error');
        return false;
      }

      if (this.postoEnfermagem.idade_maxima == null) {
        Swal.fire('Erro', "Favor preencher a idade máxima.", 'error');
        return false;
      }
    }
    if (this.postoEnfermagem.clinica_medica_id == null || this.postoEnfermagem.clinica_medica_id == 0) {
      Swal.fire('Erro', "Favor informar a clínica médica.", 'error');
      return false;
    }
    return true;
  }

  salvarEditarpostoEnfermagem = () => {
    if (!this.validaPost())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    if (this.postoEnfermagem.id == null)
      this.service.Post(`PostoEnfermagem`, this.postoEnfermagem).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Posto Enfermagem salva com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(
            result => {
              if (result.isConfirmed) {
                this.bloco_id_filtro = this.postoEnfermagem.blocoId;
                this.clinica_id_filtro = this.postoEnfermagem.clinica_medica_id;
                this.obterListapostoEnfermagem(this.postoEnfermagem.clinica_medica_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    else {
      this.service.Put(`postoEnfermagem/${this.postoEnfermagem.id}`, this.postoEnfermagem).then(res => {
        setTimeout(() => {
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: 'Posto de enfermagem atualizada com sucesso!'
          }).then(
            result => {
              if (result.isConfirmed) {
                this.bloco_id_filtro = this.postoEnfermagem.blocoId;
                this.clinica_id_filtro = this.postoEnfermagem.clinica_medica_id;
                this.obterListapostoEnfermagem(this.postoEnfermagem.clinica_medica_id);
                this.fecharModal();
              }
            }
          );
        }, 1000);
      }).catch(res => {
        if (res.status == 401 || res.error.status == 401) {
          Swal.close()
          this.authService.logoutUser()
        }
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: res.error.erros[0].messagem
        }).then(
          result => {
            if (result.isConfirmed) {

            }
          }
        );
      });
    }
  }

  editarpostoEnfermagem = (postoEnfermagem) => {
    this.postoEnfermagem = postoEnfermagem;
    this.postoEnfermagem.clinica_medica_id = this.clinica_id_filtro;
    //aqui
    this.postoEnfermagem.blocoId = 0;
    this.abrirModalPostoEnfermagem();
  }

  selecionarBloco = (bloco_id) => {
    this.bloco_id = bloco_id;
    this.obterListaClinica(bloco_id);
  }

  changeStatuspostoEnfermagem = () => {
    this.postoEnfermagem.ativo != this.postoEnfermagem.ativo;
  }

  abrirModalPostoEnfermagem = () => {
    if (this.postoEnfermagem.id == null) {
      this.postoEnfermagem.ativo = true;
      this.postoEnfermagem.blocoId = 0;
      this.bloco_id_filtro = 0;
      this.postoEnfermagem.clinica_medica_id = 0;
      this.clinica_id_filtro = 0;
      this.listaClinicas = [];
      this.listapostoEnfermagens = [];
    }
    $("#modalAdicionarEnfermagem").toggleClass("show");
    $("#modalAdicionarEnfermagem").css("display", "block");
  }

  fecharModal = () => {
    this.postoEnfermagem = {};
    $("#modalAdicionarEnfermagem").toggleClass("show");
    $("#modalAdicionarEnfermagem").css("display", "none");
  }

  filtroLista() {
    if (this.listapostoEnfermagens.length === 0) {
      return this.listapostoEnfermagens;
    }
    return this.listapostoEnfermagens.filter(p => p.nome.toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase()));
  }

  confirmationAtivarDesativar = (id, ativo, clinica_id) => {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja ${ativo ? "ativar" : "desativar"} esse Posto Enfermagem?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          this.service.AtivarDesativar(`postoEnfermagem/${id}`, ativo).then((res) => {
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: 'Operação realizada com sucesso!'
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.obterListapostoEnfermagem(this.clinica_id_filtro);
                }
              }
            );
          }).catch((err) => {
            if (err.status == 401 || err.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: err.error.erros[0].messagem
            });
          })
        }
      }
    );
  }

}
