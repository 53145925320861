import { Component, OnChanges, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from './service/apiServices';
import { AuthService } from './service/auth/auth.service';
import * as $ from "jquery";
declare var document: any;
declare var simpleDatatables: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnChanges {

  currentRoute: any = "";
  shortBody: boolean = true;  

  constructor(private router: Router,
    private authService: AuthService,
    public service: ApiService,
  ) {
    this.getUser();
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(event => {
        this.currentRoute = event['url'].split("?")[0]
        this.inicializarJs();
      });

  }

  ngOnChanges() {
  }

  obterMenus = (usuario_id) => {
    this.service.Get(`Usuario/${usuario_id}/MenuLateral`).then((res) => {
      this.authService.saveMenus(res);
    }).catch((err) => {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: err.error.erros[0].messagem
      });
    })
  }

  inicializarJs() {
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

  }

  async getUser() {
    var currentUser = await localStorage.getItem('@DigtVentry:currentUser');
    var user = JSON.parse(currentUser);
    if (user) {
      this.authService.saveUser(user);
      this.obterMenus(user.usuario_id);
    } else {
      this.authService.logoutUser();
    }
  }

  gotToTop() {
    window.scrollTo(0, 0);
  }

  extendBody = (event) => {
    this.shortBody = event;
    if (this.shortBody) {
      $(document.body).removeClass("toggle-sidebar")
    }
    if (!this.shortBody) {
      $(document.body).addClass("toggle-sidebar")
    }
  }

}
