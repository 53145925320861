import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { ApiService } from '../../../service/apiServices';
import { AuthService } from '../../../service/auth/auth.service';
import { Global } from '../../../global';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-exams-attendance',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsAttendanceComponent implements OnInit {

  seeList: any = true;
  listFavoriteText: any = []

  usuario: any = {};
  presenca: any;

  uid: any;
  @Input() atendimento: any = {};
  @Input() listaExames: any = [];
  @Input() listaHistorico: any = [];
  grupoExames: any = [];
  visualizar_prontuario: any = false;
  constructor(
    private service: ApiService,
    private global: Global,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
    this.authService.visualizarProntuario.subscribe(res => {
      if (res) {
        this.visualizar_prontuario = res;
      }
    });
  }

  ngOnInit() {
    this.obterGruposExames();
  }

  ngOnChanges() {

  }

  obterListaExames = () => {
    const uid = this.route.snapshot.paramMap.get('uuid');
    this.uid = uid;
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Atendimento/${this.uid}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.listaExames = result
        // this.seeList = !this.seeList;
        Swal.close()
      },
      error => {
        Swal.fire('Erro', error.error.messagem, 'error')
      }
    )
  }

  carregarHistorico() {
    Swal.fire('Favor aguarde')
    Swal.showLoading();
    this.service.Get(`Cidadao/${this.atendimento.cidadao_id}/Solicitacao?tipo_solicitacao=EXAME`).then(
      result => {
        this.listaHistorico = result
        Swal.close()
      }
    )
  }

  voltarParaLista = () => {
    this.seeList = !this.seeList
    if (this.seeList) {
      this.obterListaExames()
    }
  }



  obterGruposExames = () => {
    this.service.Get(`ClinicaMedica/${this.presenca.clinica_medica_id}/GrupoSigtap`).then(
      result => {
        this.grupoExames = result;
      }
    )
  }
}
