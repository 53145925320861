import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css']
})
export class MultiselectComponent implements OnInit {

  @Input() list: any;
  @Input() typeSelect: any;
  @Output() statusSelecionado = new EventEmitter();

  selectedList: string = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.selectedList = ''
    for (let item of this.list){
      if (item.checked){
        if (this.selectedList == ''){
          this.selectedList+=item.nome
        }
        else {
          this.selectedList+=', '+item.nome
        }
      }
    }
    this.statusSelecionado.emit(this.list)
  }

  listStatus = (item) => {
    this.selectedList = ''
    item.checked = !item.checked
    for (let item of this.list){
      if (item.checked){
        if (this.selectedList == ''){
          this.selectedList+=item.nome
        }
        else {
          this.selectedList+=', '+item.nome
        }
      }
    }
    this.statusSelecionado.emit(this.list)
  }

}
