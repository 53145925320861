import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var jquery: any;
declare var $: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-internment',
  templateUrl: './internment.component.html',
  styleUrls: ['./internment.component.css']
})
export class InternmentComponent implements OnInit {

  listInternamento: any = [
    {
      id: 1,
      nome: "leito 01",
      numero: "01",
      status: 1
    },
    {
      id: 2,
      nome: "leito 02",
      numero: "02",
      status: 2
    },
    {
      id: 3,
      nome: "leito 03",
      numero: "03",
      status: 3
    },
    {
      id: 4,
      nome: "leito 04",
      numero: "04",
      status: 1
    },
    {
      id: 5,
      nome: "leito 05",
      numero: "05",
      status: 3
    },
    {
      id: 1,
      nome: "leito 01",
      numero: "01",
      status: 1
    },
    {
      id: 2,
      nome: "leito 02",
      numero: "02",
      status: 2
    },
    {
      id: 3,
      nome: "leito 03",
      numero: "03",
      status: 3
    },
    {
      id: 4,
      nome: "leito 04",
      numero: "04",
      status: 1
    },
    {
      id: 5,
      nome: "leito 05",
      numero: "05",
      status: 3
    },

  ];
  listaClinicasModal: any = [];
  listaEstabelecimentos: any = [];
  listaBlocos: any = [];
  listaClinicas: any = [];
  listaSala: any = [];
  listaPostoEnfermagem: any = [];
  listaLeitos: any = [];
  listaLeitosDisponiveis: any = [];
  filtro: any = {
    clinica_id: 0,
    posto_enfermagem_id: 0,
    status: 0
  };
  usuario: any = {};
  presenca: any = {};
  primeiroLoadFeito: any = false;
  clinica_id: any = 0;
  listaAtendimento: any = [];
  listaAtendimentoAux: any = [];
  leitoSelecionado: any = null;
  objectKeys: any = [];

  transferenciaInterna: any = {};
  leitoDesocupado: any = {};
  listaMotivoDesocuparLeito: any = [{ 'nome': 'Selecione...', 'id': 0 }, { 'nome': 'Alta', 'id': 1 }, { 'nome': 'Transferência Externa', 'id': 2 }, { 'nome': 'Obito', 'id': 3 }];
  leitoReservado: any = {};

  filtroCidadaoTxt: any = "";
  detalhesLeitoItem: any = {};

  filtroModal: any = {
    clinica_id: 0,
    posto_enfermagem_id: 0,
    status: 0
  };
  listaPostoEnfermagemModal: any = [];


  listaInternamento: any = [];

  aba: any = 1;
  listaClinicas_: any = [];
  //paginacao
  $pagination = $('#pagination_');
  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  actualPage: any = 1;
  total_paginas: any = [];
  previusPage: any = 1;
  indexInicial: any = 0;
  indexFinal: any = 5;
  loading: any = false;

  filtro_: any = {
    clinica_id: 0,
    posto_enfermagem_id: 0
  };
  cidadao_query: any = "";

  constructor(
    private route: ActivatedRoute,
    public service: ApiService,
    private alerts: NgxAlertsService,
    private router: Router,
    private global: Global,
    private authService: AuthService
  ) {
    this.objectKeys = Object.keys;
    this.authService.currentUser.subscribe(res => {
      if (res) {
        this.usuario = res;
      }
    });
    this.authService.currentPresenca.subscribe(res => {
      if (res) {
        this.presenca = res;
      }
    });
  }

  ngOnInit() {
    this.getListLeitos();
    this.getListClinica();
  }

  carregarBlocos = () => {
    if (this.filtro.estabelecimento_id) {
      this.getListBlocos(this.filtro.estabelecimento_id)
    }
  }

  carregarClinica = () => {
    if (this.filtro.bloco_id) {
      // this.getListClinica(this.filtro.bloco_id)
    }
  }

  carregarSala = () => {
    if (this.filtro.clinica_id) {
      this.getListPostoEnfermagem(this.filtro.clinica_id);
    }
  }

  carregarLeito = () => {
    if (this.filtro.posto_enfermagem_id) {
      // this.getListLeitos(this.filtro.posto_enfermagem_id);
    }
  }

  getListEstabs = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(`Estabelecimento`).then(res => {
      var obj: any = res;
      this.listaEstabelecimentos = obj.filter(x => x.ativo);
      if (this.primeiroLoadFeito) {
        return;
      }
      if (this.presenca.estabelecimento_id) {
        this.filtro.estabelecimento_id = this.presenca.estabelecimento_id;
        this.carregarBlocos();
      }
      Swal.close()
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  getListBlocos = (id) => {
    this.service.Get(`Estabelecimento/${id}/Bloco`).then(res => {
      var obj: any = res;
      this.listaBlocos = obj.filter(x => x.ativo);
      if (this.primeiroLoadFeito) {
        return;
      }
      if (this.presenca.bloco_id) {
        this.filtro.bloco_id = this.presenca.bloco_id;
        this.carregarClinica();
      }

    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  getListClinica = () => {
    this.service.Get(`Estabelecimento/${this.presenca.estabelecimento_id}/ClinicaMedica`).then(res => {
      var obj: any = res;
      obj.unshift({ id: 0, nome: 'Selecione...', ativo: true });
      this.listaClinicas = obj.filter(x => x.ativo);
      this.listaClinicasModal = obj.filter(x => x.ativo);
      if (this.primeiroLoadFeito) {
        return;
      }
      if (this.presenca.clinica_medica_id) {
        this.filtro.clinica_id = this.presenca.clinica_medica_id;
        this.primeiroLoadFeito = true;
        this.carregarSala();
      }
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  getListSala = (id) => {
    this.service.Get(`ClinicaMedica/${id}/Sala`).then(res => {
      var obj: any = res;
      this.listaSala = obj.filter(x => x.ativo);
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  getListPostoEnfermagem = (id) => {
    this.service.Get(`ClinicaMedica/${id}/postoEnfermagem`).then(res => {
      var obj: any = res;
      obj.unshift({ id: 0, nome: 'Todos', ativo: true });
      this.listaPostoEnfermagem = obj.filter(x => x.ativo);
      this.filtro.posto_enfermagem_id = 0;
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  selecioarLeito = (leito) => {

  }

  emitError = (error) => {
    if (typeof error == 'string') {
      Swal.fire('Erro', `${error}`, 'error');
      return
    }
    if (error.status == 401 || error.error.status == 401) {
      Swal.close()
      this.authService.logoutUser()
    }
    if (error.status === 404) {
      Swal.fire('Atenção', `${error.error}`, 'warning');
      return
    }
    if (error.status) {
      Swal.fire({ icon: 'error', title: 'Erro', html: `${error.status} - ${error.error.erros[0].messagem}` })
      return
    }
    if (error.alert) {
      Swal.fire('Atenção', `${error.txt}`, 'warning');
      return
    }
  }

  abrirModalInternamento = (leito) => {
    this.leitoSelecionado = leito;

    if (leito.status == 'LIVRE' || leito.status == 'RESERVADO') {
      $("#modalInternacao").toggleClass("show");
      $("#modalInternacao").css("display", "block");
      this.clinica_id = 0;
      this.listaAtendimento = [];
    } else {
      $("#visualizarLeito").toggleClass("show");
      $("#visualizarLeito").css("display", "block");
    }
  }

  fecharModalInternamento = () => {
    $("#modalInternacao").toggleClass("show");
    $("#modalInternacao").css("display", "none");
  }

  fecharModalVisualizar = () => {
    $("#visualizarLeito").toggleClass("show");
    $("#visualizarLeito").css("display", "none");
  }

  carregarAtendimentos() {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var url = "";
    if (this.leitoSelecionado.status == "RESERVADO") {
      url = `Prontuario?cidadao_id=${this.leitoSelecionado.leito_reservado_cidadao_id}&clinica_medica_id=${this.clinica_id}&status=ABERTO`;
    } else {
      url = `Prontuario?clinica_medica_id=${this.clinica_id}&status=ABERTO`;
    }
    this.service.Get(url).then(res => {
      this.listaAtendimento = res;
      for (let item of this.listaAtendimento) {
        this.global.applyMask(item)
      }
      this.listaAtendimentoAux = res;
      Swal.close();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  internarPaciente(item) {
    Swal.fire({
      title: 'Atenção',
      text: `Deseja internar o paciente ${item.atendimento_cidadao_nome} no leito ${this.leitoSelecionado.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire('Favor aguarde');
          Swal.showLoading();
          var obj = {
            "leito_id": this.leitoSelecionado.id,
            "prontuario_id": item.id,
            "internado_por_id": this.presenca.id
          }
          this.service.Post(`Internamento`, obj).then(res => {
            setTimeout(() => {
              Swal.close()
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                html: 'Paciente internado com sucesso!',
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(
                result => {
                  if (result.isConfirmed) {
                    this.getListLeitos();
                    this.fecharModalInternamento();
                  }
                }
              );
            }, 1000);
          }).catch(res => {
            if (res.status == 401 || res.error.status == 401) {
              Swal.close()
              this.authService.logoutUser()
            }
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              html: res.error.erros[0].messagem
            }).then(
              result => {
                if (result.isConfirmed) {

                }
              }
            );
          })
        }
      }
    );

  }

  changeStatusLista(status) {
    this.getListLeitos();
  }

  getListLeitos = () => {
    var url = `Leito?ativo=true`;
    if (this.filtro.posto_enfermagem_id != 0) {
      url += `&posto_enfermagem_id=${this.filtro.posto_enfermagem_id}`;
    }
    if (this.filtro.status != 0) {
      url += `&status=${this.filtro.status}`;
    }
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    this.service.Get(url).then(res => {
      var obj: any = res;
      this.listaLeitos = obj.filter(x => x.ativo);
      var results = this.listaLeitos.reduce(function (results, org) {
        (results[org.posto_enfermagem_id] = results[org.posto_enfermagem_id] || []).push(org);
        return results;
      }, {});
      this.listaLeitos = results;
      Swal.close();
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  exibirEsconderMenuLeito(index) {
    let aberto = false;
    $(`#exibir-esconder-menu-leito${index}`).map((i, item) => {
      if (item.style.display == 'block')
        aberto = true;
      else
        aberto = false;
    })
    $(".exibir-esconder-menu-leito").css({ "display": "none" });
    $(`#exibir-esconder-menu-leito${index}`).map((i, item) => {
      if (aberto)
        $(`#exibir-esconder-menu-leito${index}`).css({ "display": "none" });
      else
        $(`#exibir-esconder-menu-leito${index}`).css({ "display": "block" });
    })
  }

  fecharModalLeitosDisponiveis = () => {
    $("#modalLeitosDisponíveis").toggleClass("show");
    $("#modalLeitosDisponíveis").css("display", "none");
  }

  getLeitosDisponiveis = (leito) => {
    this.transferenciaInterna = {};
    this.transferenciaInterna.internamento_id = parseInt(leito.internamento_id);
    this.transferenciaInterna.presenca_id = this.presenca.id;
    this.transferenciaInterna.leito_origem_id = leito.id;

    this.filtroModal.clinica_id = this.presenca.clinica_medica_id;
    this.getListPostoEnfermagemModal(this.filtroModal.clinica_id);
    $("#modalLeitosDisponíveis").toggleClass("show");
    $("#modalLeitosDisponíveis").css("display", "block");
    this.obterLeitoModal();
  }

  escolherLeitoDisponivel(item) {
    this.transferenciaInterna.leito_destino_id = item.id;
    Swal.fire({
      title: 'Atenção',
      text: `Deseja transferir o paciente para o leito ${item.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Motivo',
            text: `Informe o motivo de transferir o paciente para o leito ${item.nome}.`,
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545',
            input: 'text'
          }).then(
            res => {
              if (res.isConfirmed) {
                if (res.value == null || res.value == "") {
                  Swal.fire('Erro', "Para transferir o paciente é necessário informar o motivo.", 'error');
                  return;
                }
                this.transferenciaInterna.observacao = res.value;
                Swal.fire({
                  title: 'Favor aguarde',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                });
                Swal.showLoading();
                this.service.Post(`Internamento/${this.transferenciaInterna.internamento_id}/Transferencia`, this.transferenciaInterna).then(res => {
                  setTimeout(() => {
                    Swal.close()
                    Swal.fire({
                      icon: 'success',
                      title: 'Sucesso!',
                      html: 'Trnasferência realizada com sucesso!',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    }).then(
                      result => {
                        if (result.isConfirmed) {
                          this.fecharModalLeitosDisponiveis();
                          this.getListLeitos();
                        }
                      }
                    );
                  }, 1000);
                }).catch(res => {
                  if (res.status == 401 || res.error.status == 401) {
                    Swal.close()
                    this.authService.logoutUser()
                  }
                  Swal.close()
                  Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    html: res.error.erros[0].messagem
                  }).then(
                    result => {
                      if (result.isConfirmed) {

                      }
                    }
                  );
                });
              }
            }
          );
        }
      }
    );
  }

  fecharModalEncerramentoLeito = () => {
    $("#modalEncerramentoLeito").toggleClass("show");
    $("#modalEncerramentoLeito").css("display", "none");
  }

  desocuparLeito(leito) {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja desocupar o leito ${leito.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.leitoDesocupado = {};
          this.leitoDesocupado.internamento_id = parseInt(leito.internamento_id);
          this.leitoDesocupado.presenca_id = this.presenca.id;
          this.leitoDesocupado.motivo = 0;
          this.leitoDesocupado.id = leito.id;
          $("#modalEncerramentoLeito").toggleClass("show");
          $("#modalEncerramentoLeito").css("display", "block");
        }
      }
    );
  }

  postDesocuparLeito() {
    if (!this.validaPostDesocuparLeito())
      return;
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`Internamento/${this.leitoDesocupado.internamento_id}/Encerramento`, this.leitoDesocupado).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Leito desocupado com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              setTimeout(() => {
                this.limparLeito();
              }, 1000);
              this.fecharModalEncerramentoLeito();
              // this.getListLeitos();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.messagem ? res.error.messagem : res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  limparLeito() {
    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    var obj = {
      "esterelizado_por_id": this.presenca.id,
      "observacao": "Esterilizado"
    }
    this.service.Post(`Leito/${this.leitoDesocupado.id}/Esterelizado`, obj).then(res => {
      this.getListLeitos();
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  trocaCampoDesocuparLeito(motivo) {
    if (motivo == 0 || motivo == 1) {
      this.leitoDesocupado.estabelecimento_destino_nome = "";
      this.leitoDesocupado.obito_em_ = "";
    }
    if (motivo == 2) {
      this.leitoDesocupado.obito_em_ = "";
    }
    if (motivo == 3) {
      this.leitoDesocupado.estabelecimento_destino_nome = "";
    }
  }

  validaPostDesocuparLeito() {
    if (this.leitoDesocupado.motivo == 0) {
      Swal.fire('Erro', "Favor informar o motivo.", 'error');
      return false;
    }
    if (this.leitoDesocupado.motivo == 2 && (this.leitoDesocupado.estabelecimento_destino_nome == null || this.leitoDesocupado.estabelecimento_destino_nome == "")) {
      Swal.fire('Erro', "Favor informar o nome do estabelecimento.", 'error');
      return false;
    }

    if (this.leitoDesocupado.motivo == 3 && (this.leitoDesocupado.obito_em_ == null || this.leitoDesocupado.obito_em_.length != 12)) {
      Swal.fire('Erro', "Favor informar uma data de óbito válida.", 'error');
      return false;
    }

    var dataInvalida = true;

    var d = this.leitoDesocupado.obito_em_[0] + this.leitoDesocupado.obito_em_[1];
    var m = this.leitoDesocupado.obito_em_[2] + this.leitoDesocupado.obito_em_[3];
    var a = this.leitoDesocupado.obito_em_[4] + this.leitoDesocupado.obito_em_[5] + this.leitoDesocupado.obito_em_[6] + this.leitoDesocupado.obito_em_[7];
    var h = this.leitoDesocupado.obito_em_[8] + this.leitoDesocupado.obito_em_[9];
    var mi = this.leitoDesocupado.obito_em_[10] + this.leitoDesocupado.obito_em_[11];

    if ((parseInt(d) <= 31) && (parseInt(m) <= 12) && (parseInt(a) > 1900) && (parseInt(h) <= 23) && (parseInt(mi) <= 59))
      dataInvalida = false;

    if (this.leitoDesocupado.motivo == 3 && dataInvalida) {
      Swal.fire('Erro', "Favor informar uma data de óbito válida.", 'error');
      return false;
    }

    if (this.leitoDesocupado.motivo == 1) {
      delete this.leitoDesocupado.obito_em_;
      delete this.leitoDesocupado.estabelecimento_destino_nome
    }

    if (this.leitoDesocupado.motivo == 2) {
      delete this.leitoDesocupado.obito_em_;
    }

    if (this.leitoDesocupado.motivo == 3) {
      this.leitoDesocupado.obito_em = a + "-" + m + "-" + d + "T" + h + ":" + mi;
      delete this.leitoDesocupado.obito_em_;
      delete this.leitoDesocupado.estabelecimento_destino_nome;
    }

    return true;
  }

  confirmacaoInterditarLeito(item) {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja interditar o leito ${item.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Motivo',
            text: `Informe o motivo de interditar o leito ${item.nome}.`,
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545',
            input: 'text'
          }).then(
            res => {
              if (res.isConfirmed) {
                if (res.value == null || res.value == "") {
                  Swal.fire('Erro', "Para interditar o leito é necessário informar o motivo.", 'error');
                  return;
                }
                var leitoInterditado = {
                  "interditado_por_id": this.presenca.id,
                  "observacao": res.value
                };
                Swal.fire({
                  title: 'Favor aguarde',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                });
                Swal.showLoading();
                this.service.Post(`Leito/${item.id}/Interditado`, leitoInterditado).then(res => {
                  setTimeout(() => {
                    Swal.close()
                    Swal.fire({
                      icon: 'success',
                      title: 'Sucesso!',
                      html: 'Leito interditado com sucesso!',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    }).then(
                      result => {
                        if (result.isConfirmed) {
                          this.getListLeitos();
                        }
                      }
                    );
                  }, 1000);
                }).catch(res => {
                  if (res.status == 401 || res.error.status == 401) {
                    Swal.close()
                    this.authService.logoutUser()
                  }
                  Swal.close()
                  Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    html: res.error.messagem
                  }).then(
                    result => {
                      if (result.isConfirmed) {

                      }
                    }
                  );
                });
              }
            }
          );
        }
      }
    );
  }

  confirmacaoLiberarLeito(item) {
    let url = '';
    if (item.status == "AGUARDANDO_ESTERILIZACAO") {
      url = `Leito/${item.id}/Esterelizado/Liberado`;
    } else
      if (item.status == "INTERDITADO") {
        url = `Leito/${item.id}/Interditado/Liberado`;
      } else if (item.status == "RESERVADO") {
        url = `Leito/${item.id}/Reservado/Liberado`;
      }

    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja liberar o leito ${item.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Observação',
            text: `Insira uma observação se necessário para o leito ${item.nome}.`,
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545',
            input: 'text'
          }).then(
            res => {
              if (res.isConfirmed) {
                var obs = "";
                if (res.value != null || res.value != "")
                  obs = res.value;
                var leitoLiberado = {
                  "liberado_por_id": this.presenca.id,
                  "observacao": obs
                };
                Swal.fire({
                  title: 'Favor aguarde',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                });
                Swal.showLoading();

                if (item.status == "RESERVADO") {
                  leitoLiberado["id"] = item.leito_reservado_id;
                  leitoLiberado["cidadao_id"] = item.leito_reservado_cidadao_id;
                }
                this.service.Post(url, leitoLiberado).then(res => {
                  setTimeout(() => {
                    Swal.close()
                    Swal.fire({
                      icon: 'success',
                      title: 'Sucesso!',
                      html: 'Leito liberado com sucesso!',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    }).then(
                      result => {
                        if (result.isConfirmed) {
                          this.getListLeitos();
                        }
                      }
                    );
                  }, 1000);
                }).catch(res => {
                  if (res.status == 401 || res.error.status == 401) {
                    Swal.close()
                    this.authService.logoutUser()
                  }
                  Swal.close()
                  Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    html: res.error.messagem
                  }).then(
                    result => {
                      if (result.isConfirmed) {

                      }
                    }
                  );
                });
              }
            }
          );
        }
      }
    );
  }

  confirmacaoReservarLeito(leito) {
    Swal.fire({
      title: 'Atenção',
      text: `Tem certeza que deseja reservar o leito ${leito.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3545'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.leitoReservado = {};
          this.leitoReservado.leito_id = leito.id;
          $("#modalReservarLeito").toggleClass("show");
          $("#modalReservarLeito").css("display", "block");
        }
      }
    );
  }

  fecharModalReservarLeito = () => {
    $("#modalReservarLeito").toggleClass("show");
    $("#modalReservarLeito").css("display", "none");
  }

  postReservarLeito() {
    if (!this.validaPostReservarLeito())
      return;

    var objReservado = {
      "cidadao_id": 0,
      "observacao": this.leitoReservado.observacao,
      "cidadao_nome": this.leitoReservado.cidadao_nome,
      "cidadao_cns": this.leitoReservado.cidadao_cns,
      "cidadao_cpf": this.leitoReservado.cidadao_cpf,
      "cidadao_origem": this.leitoReservado.cidadao_origem,
      "quadro_clinico": this.leitoReservado.quadro_clinico,
      //"status": this.leitoReservado.status,
      "reservado_por_id": this.presenca.id
    }

    Swal.fire({
      title: 'Favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.service.Post(`Leito/${this.leitoReservado.leito_id}/Reservado`, objReservado).then(res => {
      setTimeout(() => {
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: 'Leito reservado com sucesso!',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(
          result => {
            if (result.isConfirmed) {
              this.fecharModalReservarLeito();
              this.getListLeitos();
            }
          }
        );
      }, 1000);
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  validaPostReservarLeito() {
    if (this.leitoReservado.cidadao_nome == null || this.leitoReservado.cidadao_nome == "") {
      Swal.fire('Erro', "Favor informar o nome do paciente.", 'error');
      return false;
    }
    if (this.leitoReservado.cidadao_origem == null || this.leitoReservado.cidadao_origem == "") {
      Swal.fire('Erro', "Favor informar a origem do paciente.", 'error');
      return false;
    }
    if (this.leitoReservado.quadro_clinico == null || this.leitoReservado.quadro_clinico == "") {
      Swal.fire('Erro', "Favor informar o quadro clínico do paciente.", 'error');
      return false;
    }
    return true;
  }

  tempoStatus(atualizado_em) {
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let dataAtual: any = new Date();
    let dataAtualizacao: any = new Date(atualizado_em);
    let tempoEspera = dataAtual.getTime() - dataAtualizacao.getTime();
    let diasEspera = tempoEspera / day;
    let horasEspera = (diasEspera % 1) * 24;
    let minutosEspera = (horasEspera % 1) * 60;

    return (Math.trunc(diasEspera) + " Dia(s) : " + Math.trunc(horasEspera) + " Hora(s) : " + Math.trunc(minutosEspera) + " Minuto(s)");
  }

  exibirProntuario(item) {
    window.open(`prontuario/${item.internamento_prontuario_atendimento_id}`, '_blank');
  }

  filtrarCidadao() {
    if (this.listaAtendimento.length > 0 && this.filtroCidadaoTxt) {
      // this.listaAtendimento = this.listaAtendimentoAux.filter(x => x.atendimento_cidadao_nome.toLowerCase().includes(this.filtroCidadaoTxt.toLowerCase()) || x.atendimento_cidadao_cpf.includes(this.filtroCidadaoTxt) || x.atendimento_cidadao_cns.includes(this.filtroCidadaoTxt))
      this.listaAtendimento = this.listaAtendimentoAux.filter(x => {
        const nomeInclui = x.atendimento_cidadao_nome && x.atendimento_cidadao_nome.toLowerCase().includes(this.filtroCidadaoTxt.toLowerCase());
        const cpfInclui = x.atendimento_cidadao_cpf && x.atendimento_cidadao_cpf.includes(this.filtroCidadaoTxt);
        const cnsInclui = x.atendimento_cidadao_cns && x.atendimento_cidadao_cns.includes(this.filtroCidadaoTxt);

        // Verifique se pelo menos um dos campos não é nulo e atende ao critério de filtro.
        return nomeInclui || cpfInclui || cnsInclui;
      });
    } else {
      this.listaAtendimento = this.listaAtendimentoAux;
    }
  }

  visualizarDetalhes(item) {
    this.obterReservaLeito(item);
  }

  obterReservaLeito(item) {
    Swal.showLoading();
    this.service.Get(`Leito/${item.id}/Reservado`).then(res => {
      Swal.close();
      this.detalhesLeitoItem = res;
      $("#detalhesLeito").toggleClass("show");
      $("#detalhesLeito").css("display", "block");
    }).catch(res => {
      if (res.status == 401 || res.error.status == 401) {
        Swal.close()
        this.authService.logoutUser()
      }
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        html: res.error.erros[0].messagem
      }).then(
        result => {
          if (result.isConfirmed) {

          }
        }
      );
    })
  }

  fecharModalDetalhesReservarLeito = () => {
    $("#detalhesLeito").toggleClass("show");
    $("#detalhesLeito").css("display", "none");
  }

  carregarPostoEnfermagemModal = () => {
    if (this.filtroModal.clinica_id) {
      this.obterLeitoModal();
      this.getListPostoEnfermagemModal(this.filtroModal.clinica_id);
    }
  }

  getListPostoEnfermagemModal = (id) => {
    this.service.Get(`ClinicaMedica/${id}/postoEnfermagem`).then(res => {
      var obj: any = res;
      obj.unshift({ id: 0, nome: 'Todos', ativo: true });
      this.listaPostoEnfermagemModal = obj.filter(x => x.ativo);
      this.filtroModal.posto_enfermagem_id = 0;
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }


  carregarLeitoModal = () => {
    if (this.filtroModal.posto_enfermagem_id) {
      this.obterLeitoModal()
    }
  }

  obterLeitoModal = () => {
    Swal.fire('Favor aguarde');
    Swal.showLoading();
    var url = 'Leito?ativo=true&status=LIVRE';

    if (this.filtroModal.clinica_id) {
      url = `${url}&clinica_medica_id=${this.filtroModal.clinica_id}`
    }

    if (this.filtroModal.posto_enfermagem_id) {
      url = `${url}&posto_enfermagem_id=${this.filtroModal.posto_enfermagem_id}`
    }

    this.service.Get(url).then(res => {
      Swal.close();
      this.listaLeitosDisponiveis = res;
      if (this.listaLeitosDisponiveis.length == 0) {
        Swal.fire('Atenção', "Não há leito livre disponível no momento.", 'warning');
      }
      else {

        const agrupadoPorPostoEnfermagem = {};

        this.listaLeitosDisponiveis.forEach((item) => {
          const postoEnfermagemId = item.posto_enfermagem_id;

          if (!agrupadoPorPostoEnfermagem[postoEnfermagemId]) {
            agrupadoPorPostoEnfermagem[postoEnfermagemId] = [];
          }

          agrupadoPorPostoEnfermagem[postoEnfermagemId].push(item);
        });
        this.listaLeitosDisponiveis = agrupadoPorPostoEnfermagem;
      }
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  showAba(aba) {
    this.aba = aba;
    if (aba == 2) {
      this.getListClinicaLista();
      this.getInternamentos(1);
    }
  }


  getInternamentos(page = 1) {
    this.loading = true;
    var url = `Internamento?prontuario_encerrado=false&clinica_medica_id=${this.filtro_.clinica_id}&posto_enfermagem_id=${this.filtro_.posto_enfermagem_id}&pagina=${page}&quantidade=5`;
    if (this.cidadao_query) {
      url = url += `&cidadao_query=${this.cidadao_query}`;
    }
    this.service.Get(url).then((res: any) => {
      Swal.close();
      this.total_paginas = [];
      this.listaInternamento = res;
      for (let index = 0; index < res.total_paginas; index++) {
        const element = index;
        this.total_paginas.push(index + 1);
      }

      if (res.conteudo.length > 0) {
        this.paginas = res.total_elementos;
        this.mostrando = 1;
        this.mostrando2 = res.conteudo.length;
      }
      var primeiraChamada = true;
      try {
        $('#pagination_').twbsPagination('destroy');
        let that = this;
        $('#pagination_').twbsPagination({
          totalPages: res.total_paginas,
          visiblePages: 7,
          first: "Primeira",
          prev: `<span aria-hidden="true">&laquo;</span>`,
          next: `<span aria-hidden="true">&raquo;</span>`,
          last: "Última",
          onPageClick: function (event = null, page = 0) {
            if (!primeiraChamada) {
              that.getInternamentosPag(page);
            } else {
              primeiraChamada = false;
            }
          }
        });
      } catch (error) { }
      this.loading = false;
    }).catch((err) => {
      Swal.close();
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        Swal.fire({
          title: 'Error!',
          text: err.error.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    });
  }

  getInternamentosPag(page = 1) {
    this.loading = true;
    this.actualPage = page;
    var url = `Internamento?prontuario_encerrado=false&clinica_medica_id=${this.filtro_.clinica_id}&posto_enfermagem_id=${this.filtro_.posto_enfermagem_id}&pagina=${page}&quantidade=5`;
    if (this.cidadao_query) {
      url = url += `&cidadao_query=${this.cidadao_query}`;
    }
    this.service.Get(url).then((res: any) => {
      Swal.close();
      this.total_paginas = [];
      this.listaInternamento = res;
      for (let index = 0; index < res.total_paginas; index++) {
        const element = index;
        this.total_paginas.push(index + 1);
      }

      if (res.conteudo.length > 0) {
        if (page == 1) {
          this.paginas = res.total_elementos;
          this.mostrando = 1;
          this.mostrando2 = res.conteudo.length;
        } else
          if (page > 1) {
            if (this.total_paginas.length == page) {
              this.paginas = res.total_elementos;
              this.mostrando = res.total_elementos - res.conteudo.length + 1
              this.mostrando2 = res.total_elementos;
            } else {
              this.paginas = res.total_elementos;
              this.mostrando = (res.conteudo.length * page) - res.conteudo.length + 1;
              this.mostrando2 = (res.conteudo.length * page);
            }
          }
      }
      this.loading = false;
    }).catch((err) => {
      Swal.close();
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      }
    });
  }

  goToPagination(page: any, next: any = false) {

    if (page == this.actualPage) {
      return;
    }
    if (page == 0) {
      return;
    }
    this.previusPage = JSON.parse(JSON.stringify(this.actualPage));
    this.actualPage = page;
    if (next) {
      if (this.total_paginas.length > 5) {
        if (this.actualPage < this.previusPage) {
          if (this.indexInicial > 0) {
            let c = this.indexInicial - this.actualPage;
            if (c >= 0 && c < 5) {
              this.indexFinal = this.indexFinal - 1;
              this.indexInicial = this.indexInicial - 1;
            }
          }
        } else {
          let c = this.total_paginas.length - this.indexInicial;
          if (c > 5) {
            this.indexInicial = this.indexInicial + 1;
            this.indexFinal = this.indexFinal + 1;
          }
        }
      }
    } else {
      if (this.total_paginas.length > 5) {
        if (this.indexInicial > 0) {
          let c = this.indexInicial - this.actualPage;
          if (c >= 0 && c < 5) {
            this.indexFinal = this.indexFinal - 1;
            this.indexInicial = this.indexInicial - 1;
          }
        }
      }
    }

    this.getInternamentosPag(page);
  }

  continuarAtendimento(item: any) {
    window.open(`prontuario/${item.prontuario_atendimento_id}`, '_blank');    
  }

  pesquisarInternamentos() {
    Swal.fire('buscando');
    Swal.showLoading();
    this.getInternamentos(1);
  }

  carregarPosto() {
    if (this.filtro_.clinica_id) {
      this.getListPostoEnfermagemLista(this.filtro_.clinica_id);
    }
  }

  getListPostoEnfermagemLista = (id) => {
    this.service.Get(`ClinicaMedica/${id}/postoEnfermagem`).then(res => {
      var obj: any = res;
      obj.unshift({ id: 0, nome: 'Todos', ativo: true });
      this.listaPostoEnfermagem = obj.filter(x => x.ativo);
      this.filtro_.posto_enfermagem_id = 0;
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

  getListClinicaLista = () => {
    this.service.Get(`Estabelecimento/${this.presenca.estabelecimento_id}/ClinicaMedica`).then(res => {
      var obj: any = res;
      obj.unshift({ id: 0, nome: 'Selecione...', ativo: true });
      this.listaClinicas_ = obj.filter(x => x.ativo);
      if (this.presenca.clinica_medica_id) {
        this.filtro_.clinica_id = this.presenca.clinica_medica_id;
        this.carregarPosto();
      }
    }).catch(res => {
      Swal.close()
      this.emitError(res)
    })
  }

}
